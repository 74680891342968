//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from 'react';
import $ from 'jquery';
import CryptoJS from 'crypto-js';
import _ from 'underscore';
import ReactTable from "react-table";
import Modal from 'react-modal';
//IMPORT REACT COMPONENT CSS
import 'sweetalert2/src/sweetalert2.scss';
import "react-table/react-table.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "react-toggle/style.css";
import 'react-toastify/dist/ReactToastify.css';
//IMPORT CLASS COMPONENT
import { InvoiceEnquiryCartIcons, JobCardEstimateCartIcons } from '../ServiceRegistration/IconComponents';
import { CheckNumberFormat, CheckNumberFormat_WithoutDecimal, Truncate_2DecimalPlaces } from '../Invoice/InvoiceValidations';
import { InvoiceProductQtyModal, EstimateProductChangeModal } from '../CommonModalPages';
import { ToastContainer, toast } from 'react-toastify';
import { CurrencyFormat } from '../Invoice/CurrencyFormater';
import { SetCurrentPage } from '../ConstSiteFunction';


var enquiryData;


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "491px"
  }
};

class ServiceRegistartionEstimate extends Component {

  constructor(props) {
    super()


    var companyId = CryptoJS.AES.decrypt(localStorage.getItem('CompanyId'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
    var staffId = CryptoJS.AES.decrypt(localStorage.getItem('staffId'), "shinchanbaby").toString(CryptoJS.enc.Utf8)
    var employeeName = CryptoJS.AES.decrypt(localStorage.getItem('EmployeeName'), "shinchanbaby").toString(CryptoJS.enc.Utf8)
    var role = CryptoJS.AES.decrypt(localStorage.getItem('Role'), "shinchanbaby").toString(CryptoJS.enc.Utf8)


    this.state = {
      companyId: companyId,
      employeeName: employeeName,
      role: role,
      staffId: staffId,
      estimateData: [],
      estimateColumns: [],
      ratewithTaxSet: true,//used for hide and show the col header based on taxstatus 
      ratewithoutTaxSet: false,
      sgst: '',
      cgst: '',
      igst: ''

    }
    // this.data = makeData();

    this.EditEstimateProduct = this.EditEstimateProduct.bind(this);

    this.ProductInfoFunc = this.ProductInfoFunc.bind(this);

    /* this.ProductQtyFunc=this.ProductQtyFunc.bind(this);
     this.AddEnquiryProduct=this.AddEnquiryProduct.bind(this);
     this.MinusEnquiryProduct=this.MinusEnquiryProduct.bind(this);
     this.DeleteEnquiryProduct=this.DeleteEnquiryProduct.bind(this); */


  }

  componentDidMount() {

    SetCurrentPage("ServiceRegistartionEstimate");
    var self = this;

    //console.log("DID MOUNT PROPS DATA :",this.props);
    //console.log("DID MOUNT PROPS CART ENQUIRY DATA :",self.props.stateData.estimateData);

    console.log("DID MOUNT PROPS CART ENQUIRY DATA :", self.props.stateData.taxStatus);
    // Dynamic header hide and show based on withtax or without
    if (self.props.stateData.taxStatus == true) {
      self.state.ratewithoutTaxSet = false;
      self.state.ratewithTaxSet = true;
    }
    else {
      self.state.ratewithoutTaxSet = true;
      self.state.ratewithTaxSet = false;
    }
    self.setState({
      ratewithoutTaxSet: self.state.ratewithoutTaxSet,
      ratewithTaxSet: self.state.ratewithTaxSet
    })
    console.log("DID MOUNT PROPS CART ENQUIRY DATA :", self.props.stateData.estimateData);

    //CURRENCY FORMATTER
    this.CurrencyFormat();

    this.PopulateEstimateData(self.props.stateData.estimateData);

  }

  /*
FUNCTION FOR SETTING UP THE CURRENCY DETAILS BY DEFAULT
*/
  CurrencyFormat() {

    var currencyData = CurrencyFormat();
    //console.log("currencyData :",currencyData);

    this.state.currencySymbol = currencyData.currencySymbol;
    this.state.currencyCode = currencyData.currencyCode;
    this.state.amountInWords = "";

    this.setState({
      currencySymbol: this.state.currencySymbol,
      currencyCode: this.state.currencyCode,
      amountInWords: this.state.amountInWords,
    })

  }

  PopulateEstimateData(estimateData) {


    var self = this;
    this.state.estimateData = [];
    var count = 0;
    var totalRate = Truncate_2DecimalPlaces(0);
    var totalRatewithtax = 0;
    // console.log("estimateData",estimateData);

    $.each(estimateData, function (i, item) {

      count = Number(count) + 1;
      self.state.estimateData[i] = {
        cgst: item.cgst,
        sgst: item.sgst,
        igst: item.igst,
        "SNO": count,
        "ProductId": item.ProductId,
        "Item": item.Item,
        "ProductType": item.ProductType,
        "RatewithoutTax":item.RatewithoutTax,
        "RatewithTax":item.RatewithTax,
        "OriginalRate":item.OriginalRate,//after edited a rate.. if want to c the original rate 
      //  "Rate": item.Rate,
        // "Quantity":<input name={"qty"+item.ProductId} value={self.state["qty"+item.ProductId]} onChange={self.onChangeQuantity} />
        "Actions": <div>
          <JobCardEstimateCartIcons onEstimateProductEdit={self.EditEstimateProduct} />
        </div>
      }

             // CALCULATING TOTAL RATE WITH TAX AND WITHOUT TAX TOTAL
     
      totalRate = Truncate_2DecimalPlaces(Number(totalRate) + Number(item.Rate));
      totalRatewithtax=Truncate_2DecimalPlaces(Number(totalRatewithtax)+Number(parseInt(item.RatewithTax)));

    });

    self.state.totalEstimateAmount=totalRate;
    self.state.totalEstimateAmountwithTax=totalRatewithtax;
    self.setState({
        totalEstimateAmount:self.state.totalEstimateAmount,
        totalEstimateAmountwithTax: self.state.totalEstimateAmountwithTax,
    })
    // console.log(self.state.totalEstimateAmount);
    // console.log(self.state.totalEstimateAmountwithTax);


    if (this.state.estimateData.length > 0) {
      this.state.estimateColumns = this.GetColumns();
    } else {
      this.state.estimateColumns = [
        {
          Header: "SNO",
        },
        {
          Header: "ProductId",
        },
        {
          Header: "ProductType",
        },
        {
          Header: "Item",
        },
        {
          Header:"RatewithoutTax",
          // hidden: this.state.ratewithoutTaxSet,
         // show: false,
        },
        {
          Header:"RatewithTax",
          //hidden: this.state.ratewithTaxSet,
          //show: false,
        },
        {
          Header:"OriginalRate",
        },
        {
          Header: "Actions",
        }
      ]
    }

    this.setState({
      estimateData: this.state.estimateData,
      estimateColumns: this.state.estimateColumns,
    })

    //console.log("self.state.estimateData :",self.state.estimateData,self.state.estimateColumns);




  }

  GetColumns() {

    return Object.keys(this.state.estimateData[0]).map(key => {

       //based on selection hide/show the col header 
       if((key=="RatewithoutTax" && this.state.ratewithTaxSet==true) || (key=="RatewithTax" && this.state.ratewithoutTaxSet==true))
       {
        return {
          Header: key,
          accessor: key,
          show:false,
      };
       }
        else if (key != "cgst" && key != "sgst" && key != "igst"&& key !="ProductId")    
          { 
          return {
                      Header: key,
                      accessor: key,
                  };
          }
          else
        {
            return {
              Header: key,
              accessor: key,
              show: false
            };
        }
                });
        

  }

  onTrRowClick = (state, rowInfo, column, instance) => {
    var self = this;

    //  //console.log("ROW INFO :",rowInfo);

    if (typeof rowInfo !== "undefined") {
      return {
        onClick: (e, handleOriginal) => {
          this.setState({
            selected: rowInfo.index
          });
          if (handleOriginal) {
            handleOriginal()
          }

          //console.log("rowInfo :",rowInfo);

          self.state.rowInfo = rowInfo;

          self.state.estimateProductSno = rowInfo.original["SNO"];
          self.state.estimateProductId = rowInfo.original["ProductId"];
          self.state.estimateProductName = rowInfo.original["Item"];
          self.state.estimateProductType = rowInfo.original["ProductType"];
          //   self.state.estimateProductQty=rowInfo.original["Quantity"];
          self.state.estimateProductRate=rowInfo.original["RatewithoutTax"];
          self.state.estimateProductRatewithtax=rowInfo.original["RatewithTax"];
          self.state.estimateProductRatewithtax=rowInfo.original["OriginalRate"];
          self.state.rowIndexValue = rowInfo.index;


          self.setState({
            estimateProductSno: self.state.estimateProductSno,
            estimateProductId: self.state.estimateProductId,
            estimateProductName: self.state.estimateProductName,
            estimateProductType: self.state.estimateProductType,
            //  estimateProductQty:self.state.estimateProductQty,
            estimateProductRate: self.state.estimateProductRate,
            estimateProductRatewithtax:self.state.estimateProductRatewithtax,
            rowIndexValue:self.state.rowIndexValue,
            rowInfo:self.state.rowInfo,
          })


          /* if(this.state.action=="Add"){
              var newQty=Number(self.state.cart_EnquiryProductQty)+Number(1);
              this.setState(oldState => {

                  let data = oldState.enquiryData.slice();
                  let copy = Object.assign({}, data[rowInfo.index]);
                  copy.SNO=self.state.cartEnquiryProductSno;
                  copy.ProductId=self.state.cartEnquiryProductId;
                  copy.Product=self.state.cartEnquiryProductName;
                  copy.ProductType=self.state.cartEnquiryProductType;
                  copy.Quantity = newQty;
                  copy.Actions=<div>
                              <JobCardEstimateCartIcons  onEstimateProductEdit={self.EditEstimateProduct} />
                              </div> ;
                  data[rowInfo.index] = copy;
                
                  self.state.enquiryData=data;
                  this.state.action="";

                  this.setState({
                      enquiryData: data,
                      action:this.state.action,
                  });

                  self.props.SetEnquiryTableData(self.state.enquiryData);
                });

               
           
           }else if(this.state.action=="Minus"){

            var newQty=Number(self.state.cart_EnquiryProductQty)-Number(1);

            if(newQty <= 0){
              newQty=1;
              toast.error(<div><BiIcons.BiEdit />! Enquiry product quantity cannot be 0 </div>, {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               marginTop: "60px",
             });
            }

            this.setState(oldState => {

                let data = oldState.enquiryData.slice();
                let copy = Object.assign({}, data[rowInfo.index]);
                copy.SNO=self.state.cartEnquiryProductSno;
                copy.ProductId=self.state.cartEnquiryProductId;
                copy.Product=self.state.cartEnquiryProductName;                  
                copy.ProductType=self.state.cartEnquiryProductType;
                copy.Quantity = newQty;
                copy.Actions=<div>
                <JobCardEstimateCartIcons  onEstimateProductEdit={self.EditEstimateProduct} />
                </div> ;
                data[rowInfo.index] = copy;
          
                self.state.enquiryData=data;
                this.state.action="";
                this.setState({
                    enquiryData: data,
                    action:this.state.action,
                });
                self.props.SetEnquiryTableData(self.state.enquiryData);
              });


           }else if(this.state.action=="Delete"){
            
            this.setState(oldState => {

             //   let data = oldState.enquiryData.splice(rowInfo.index,1);
              //  let copy = Object.assign({}, data[rowInfo.index]);
              //  copy.Quantity = newQty;
             //   data[rowInfo.index] = copy;
             alert("rowInfo.index :"+rowInfo.index);
             var enquiryData=[...self.state.enquiryData];

          var enquiryData1=enquiryData.splice(rowInfo.index,1);
             //console.log("enquiryData  :",enquiryData1);

             self.state.enquiryData=enquiryData;
             this.state.action="";
                this.setState({
                    enquiryData: enquiryData,
                    action:this.state.action,
                });
                self.props.SetEnquiryTableData(self.state.enquiryData);
              });
           }
           */
        },
        style: {
          //  background: rowInfo.index === this.state.selected ? 'rgb(66 139 202)' : '',
          // color: rowInfo.index === this.state.selected ? 'white' : ''
        },
      }
    }
    else {
      return {
        onClick: (e, handleOriginal) => {
          if (handleOriginal) {
            handleOriginal()
          }
        },
        style: {
          // background: 'white',
          // color: 'black'
        },
      }
    }

  };

  handleInputChange = (cellInfo, event) => {
    let data = [...this.state.data];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };



  onChangeQuantity = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    //console.log("onChangeQuantity name: ",name," value :",value);

    this.state[name] = value;
    this.setState({
      [name]: value
    });
  }

  EditEstimateProduct() {
    //console.log("EditEnquiryProduct called");
    var self = this;
    self.state.action = "Edit";

    this.ProductInfoModal();

  }

  ProductInfoModal() {
    var self = this;
    self.state.productInfoModal = true;

    self.setState({
      productInfoModal: self.state.productInfoModal,
    })
  }

  ProductInfoCloseModal(buttonData){

    var self=this;

    if(buttonData=="Save"){
    console.log("self.state.estimateData",self.state.estimateData);
     var temp=self.state.newRate+self.state.estimateData
    //  console.log(self.state.newRate);
    //  console.log(self.state.estimateData[self.state.rowIndexValue].sgst);
    //  console.log(self.state.estimateData[self.state.rowIndexValue].igst);
    //  console.log(self.state.estimateData[self.state.rowIndexValue].cgst);

      let sgst=self.state.estimateData[self.state.rowIndexValue].sgst;
      let cgst=self.state.estimateData[self.state.rowIndexValue].igst;
      let  igst= self.state.estimateData[self.state.rowIndexValue].cgst;
 
    //  CALCULATING TOTAL RATE FOR WITH TAX
     var Ratewithtax=(Number(parseInt(self.state.newRate))+(self.state.newRate*sgst)/100)+((self.state.newRate*cgst)/100)
      +((self.state.newRate*igst/100));

      Ratewithtax=Truncate_2DecimalPlaces(Ratewithtax);
      
      console.log(Ratewithtax);

      this.setState(oldState => {
        let data = oldState.estimateData.slice();
        let copy = Object.assign({}, data[self.state.rowInfo.index]);

        copy.SNO=self.state.estimateProductSno;
        copy.ProductId=self.state.estimateProductId;
        copy.Item=self.state.estimateProductName;              
        copy.ProductType=self.state.estimateProductType;
        copy.RatewithoutTax=self.state.newRate;
        copy.RatewithTax=Ratewithtax;
       // copy.Quantity = self.state.newQty;
        copy.Actions=<div>
        <JobCardEstimateCartIcons  onEstimateProductEdit={self.EditEstimateProduct} /> </div> ;
        data[self.state.rowInfo.index] = copy;
       console.log(data[self.state.rowInfo.index]);
        self.state.newRate="";
        self.state.estimateData=data;
        this.setState({
          estimateData: data,
        });
        // console.log(self.state.totalEstimateAmount);
        // console.log(self.state.totalEstimateAmountwithTax);
        self.props.SetEstimateTableData(self.state.estimateData,self.state.totalEstimateAmount,self.state.totalEstimateAmountwithTax);
        self.PopulateEstimateData(self.state.estimateData);
      });

    }

    self.state.productInfoModal=false;
    self.state.productInfoCloseModal=true;

    self.setState({
      productInfoModal:self.state.productInfoModal,
      productInfoCloseModal:self.state.productInfoCloseModal,
    })
  }


  ProductInfoFunc = (value) => {

    var self = this;
    //  //console.log("ProductQtyMinusFunc :",value);
    this.state.newRate = value;

  }

  /*
AddEnquiryProduct(){

//console.log("AddEnquiryProduct called");
var self=this;
self.state.action="Add";

 
 
} 
 
 
MinusEnquiryProduct(){

//console.log("MinusEnquiryProduct called");
var self=this;
var newQty;
self.state.action="Minus";
 

 
 
} 
 
DeleteEnquiryProduct(){

//console.log("DeleteEnquiryProduct called");
var self=this;
var newQty;
self.state.action="Delete";

 
}
*/



  render() {

    //  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});


    return (

      <div>


        <div className="row">


        <div>
    <h3>Total estimated Amount </h3>
    <span><label> Exclusive of Tax:{this.state.currencySymbol}  {this.state.totalEstimateAmount}</label></span><br></br>
    <span><label> Inclusive of Tax:{this.state.currencySymbol}  {this.state.totalEstimateAmountwithTax}</label></span>
</div>

          <ReactTable style={{ overflow: "auto" }}
            data={this.state.estimateData}
            columns={this.state.estimateColumns}
            noDataText="No Data Available"
            filterable
            defaultPageSize={5}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
            showPaginationTop={true}
            showPaginationBottom={false}
            getTdProps={this.onTrRowClick}
          />

        </div>

        <div class="text-center">
        </div>

        <Modal
          isOpen={this.state.productInfoModal}
          //  onAfterOpen={this.customerafterOpenModal}
          onRequestClose={this.state.productInfoCloseModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <EstimateProductChangeModal stateData={this.state} ProductInfoFunc={this.ProductInfoFunc} />
          <button onClick={() => this.ProductInfoCloseModal("Save")} >Save</button>
          <button onClick={() => this.ProductInfoCloseModal("Close")} >Close</button>
        </Modal>


        <ToastContainer style={{ marginTop: "60px" }} />

      </div>
    )
  }
}
export default ServiceRegistartionEstimate;




