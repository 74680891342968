import React, { lazy, Component, Suspense } from 'react';
//import { BackButtonComponent } from '../ServiceRegistration/ButtonComponent';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import $ from 'jquery';
//import LoadingComponent from '../Loading/LoadingComponent';
import "react-table/react-table.css";
import ReactTable from "react-table";
import _ from 'underscore';
import CryptoJS from 'crypto-js';
import ReactApexChart from 'react-apexcharts';
import DashboardOverall from '../../MaincontentDashboard/DashboardOverall';


// Import Css
//import './Card.scss';
//import './card.css';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, } from "reactstrap";
import remainder from './remainder.png';
import instock from './instock.png';
import outofstock2 from './outofstock2.png';
import cancel from './cancel.png';
import available from './available.png';
import enquiry from './enquiry.png';
import product from './product.png';
import uparrow from './uparrow.png';
import downarrow from './downarrow.png';
import outofstock from './outofstock.png';


//const DashboardOverall = lazy(() => import('./DashboardOverall'));

class InventoryDashboard extends Component {

  constructor() {
    super()
    this.state = {

      TopSellingseries: [44, 55, 41, 17, 15],
      TopSellingoptions: {
        chart: {
          type: 'donut',
        },
        labels: ['Spanner 90', 'Fuel Oil Cap ', 'Tyre 30', 'Castor Engine Oil', '32 Bold'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      Leastseries: [21, 55, 11, 17, 85],
      Leastoptions: {
        chart: {
          type: 'donut',
        },
        labels: ['Spanner 40', 'Checkout Spanner', 'Tool Box', 'Metal Cap', ' Bold'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      MostPurchasedseries: [44, 55, 13, 43, 22],
      MostPurchasedoptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Checkout Spanner', 'Bold', 'Spanner 90', 'Metal Cap', 'Spanner 90'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      LeastPurchasedseries: [24, 15, 13, 63, 22],
      LeastPurchasedoptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Spanner 90', 'Battery', 'Spanner 90', 'Metal Cap', 'Tool'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

      MostEnquiredData: [],
      MostEnquiredColumns: [{
        Header: "SNo",
        accessor: "SNo"
      },
      {
        Header: "Product Name",
        accessor: "Product Name"
      },
      {
        Header: "Type",
        accessor: "Type"
      },
      {
        Header: "Date",
        accessor: "Date"
      },

      ],
      NewEnquiredData: [],
      NewEnquiredColumns: [{
        Header: "SNo",
        accessor: "SNo"
      },
      {
        Header: "Product Name",
        accessor: "Product Name"
      },
      {
        Header: "Type",
        accessor: "Type"
      },
      {
        Header: "Date",
        accessor: "Date"
      },

      ],
      TopVendorsData: [],
      TopVendorsColumns: [{
        Header: "SNo",
        accessor: "SNo"
      },
      {
        Header: "Vendor Name",
        accessor: "Vendor Name"
      },
      {
        Header: "Company Name",
        accessor: "Company Name"
      },
      {
        Header: "TotalPurchase",
        // accessor:"Total Purchase"
      },

      ],
      StockedProductData: [],
      StockedProductColumns: [{
        Header: "SNo",
        accessor: "SNo"
      },
      {
        Header: "Product Name",
        accessor: "Product Name"

      },
      {
        Header: "Category",
        accessor: "Category"

      },


      ],
    }
  }

  componentDidMount() {
    var self = this;

    var empSites = CryptoJS.AES.decrypt(localStorage.getItem('EmpSites'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
    var currentSite = CryptoJS.AES.decrypt(localStorage.getItem('CurrentSite'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
    var emparray = empSites.split(",");
    this.state.siteOptions = _.map(emparray, function (site) { return { label: site, value: site }; });
    this.state.siteOptions.push({ label: "All", value: "All" });
    this.setState({
      siteOptions: this.state.siteOptions
    })
    var MostEnquiredDataList = [
      {
        sNo: '1',
        ProductName: 'Spanner90',
        Type: 'Parts',
        date: '2022-03-18'

      },
      {
        sNo: '2',
        ProductName: 'Bolt',
        Type: 'Service',
        date: '2022-03-19'
      },
      {
        sNo: '3',
        ProductName: 'Spanner60',
        Type: 'Parts',
        date: '2022-03-20'
      }
    ];
    var NewEnquiredDataList = [

      {
        sNo: '1',
        ProductName: 'Bolt',
        Type: 'Service',
        date: '2022-03-19'
      },
      {
        sNo: '2',
        ProductName: 'Spanner60',
        Type: 'Parts',
        date: '2022-03-20'
      }
    ];
    var TopVendorsDataList = [

      {
        sNo: '1',
        VendorName: 'Suresh',
        CompanyName: 'Wheelfull',
        TotalPurchase: '10'
      },
      {
        sNo: '2',
        VendorName: 'Suresh',
        CompanyName: 'Parts',
        TotalPurchase: '20'
      }
    ];

    var StockedProductDataList = [

      {
        SNo: '1',
        ProductName: 'Bolt',
        Category: 'parts'
      },

    ];


    $.each(MostEnquiredDataList, function (i, item) {

      self.state.MostEnquiredData[i] = {
        "SNo": item.sNo,
        "Product Name": item.ProductName,
        "Type": item.Type,
        "Date": item.date
      }
    })
    $.each(NewEnquiredDataList, function (i, item) {

      self.state.NewEnquiredData[i] = {
        "SNo": item.sNo,
        "Product Name": item.ProductName,
        "Type": item.Type,
        "Date": item.date
      }
    })
    $.each(TopVendorsDataList, function (i, item) {

      self.state.TopVendorsData[i] = {
        "SNo": item.sNo,
        "Vendor Name": item.VendorName,
        "Company Name": item.CompanyName,
        "TotalPurchase ": item.TotalPurchase
      }
    })
    $.each(StockedProductDataList, function (i, item) {

      self.state.StockedProductData[i] = {
        "SNo": item.SNo,
        "Product Name": item.ProductName,
        "Category": item.Category,

      }
    })

    self.setState({

      MostEnquiredData: [...self.state.MostEnquiredData],
      NewEnquiredData: [...self.state.NewEnquiredData],
      TopVendorsData: [...self.state.TopVendorsData],
      StockedProductData: [...self.state.StockedProductData],
    })

    // console.log("recentJobCardData :",self.state.recentJobCardData);
  }



  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        {/*  <Suspense fallback={<LoadingComponent />} > */}
        <div>
          <Route path="/" component={DashboardOverall} />
        </div>
        {/* </Suspense> */}
      </Router>,
      document.getElementById('contentRender'));
  }





  render() {
    return (
      <div>

        <div class="container-fluid">
          <div className="">
            <div className="">
              {/*     <BackButtonComponent name={"Dashboard"} 
             click={() => this.BackbtnFunc()} />
    */}
            </div>
            <div className="inv_HeaderCls">
              <h3>Inventory Dashboard</h3>
            </div>
          </div>

          <div>
            <Row style={{ marginBottom: '20px' }}>
              <div class="col-md-3">
                <Card className="card-stats">
                  <CardBody>
                    <h4 class="tiles_text">Total Product</h4>
                    <Row>
                      <div class="col-md-4">
                        <h4>5</h4>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div class="inventory_icon" style={{}}>
                          <img src={product} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>

                </Card>
              </div>
              <div class="col-md-3">
                <Card className="card-stats">
                  <CardBody>
                    <h4 class="tiles_text">Above Quantity Limit</h4>
                    <Row>
                      <div class="col-md-4">
                        <h4>20</h4>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div class="inventory_icon" style={{}}>
                          <img src={uparrow} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
              <div class="col-md-3">
                <Card className="card-stats">
                  <CardBody>
                    <h4 class="tiles_text">Below Quantity Limit</h4>
                    <Row>
                      <div class="col-md-4">
                        <h4>20</h4>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div class="inventory_icon" style={{}}>
                          <img src={downarrow} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
              <div class="col-md-3">
                <Card className="card-stats">
                  <CardBody>
                    <h4 class="tiles_text">Out of Stock</h4>
                    <Row>
                      <div class="col-md-4">
                        <h4>10</h4>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div class="inventory_icon" style={{}}>
                          <img src={outofstock} style={{ padding: '10px', width: '60px' }} />

                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </div>
          <div>
            <Row style={{ marginBottom: '20px' }}>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={enquiry} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Total Enquiry</h4>
                          <h4>45</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>

                </Card>
              </div>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={available} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Available Enquiry</h4>
                          <h4>20</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={cancel} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Not Available Enquiry</h4>
                          <h4>10</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </div>
          <div>
            <Row style={{ marginBottom: '20px' }}>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={instock} style={{ padding: '10px', width: '70px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Instock Enquiry</h4>
                          <h4>45</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>

                </Card>
              </div>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={outofstock2} style={{ padding: '10px', width: '70px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Out of Stock </h4>
                          <h4>20</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
              <div class="col-md-4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div class="col-md-4">
                        <div class="inventory_icon" style={{}}>
                          <img src={remainder} style={{ padding: '10px', width: '60px' }} />
                        </div>
                      </div>
                      <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                        <div >
                          <h4 class="tiles_text">Total Enquiry Remainder</h4>
                          <h4>10</h4>
                        </div>
                      </div>
                    </Row>
                    <CardFooter>
                      <hr />
                    </CardFooter>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h4 class="tiles_text">Top Selling Product</h4>
              <Card className="card-stats">
                <CardBody>
                  <ReactApexChart options={this.state.TopSellingoptions} series={this.state.TopSellingseries} type="donut" style={{ width: '70%' }} />
                </CardBody>
              </Card>
            </div>
            <div class="col-md-6">
              <h4 class="tiles_text">Least Selling Product</h4>
              <Card className="card-stats">
                <CardBody>
                  <ReactApexChart options={this.state.Leastoptions} series={this.state.Leastseries} type="donut" style={{ width: '70%' }} />
                </CardBody>
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h4 class="tiles_text">Most Purchased Product</h4>
              <Card className="card-stats">
                <CardBody>
                  <ReactApexChart options={this.state.MostPurchasedoptions} series={this.state.MostPurchasedseries} type="pie" width={380} />
                </CardBody>
              </Card>
            </div>
            <div class="col-md-6">
              <h4 class="tiles_text">Least Purchased Product</h4>
              <Card className="card-stats">
                <CardBody>
                  <ReactApexChart options={this.state.LeastPurchasedoptions} series={this.state.LeastPurchasedseries} type="pie" width={380} />
                </CardBody>
              </Card>
            </div>
          </div>
          {/* <div id="chart">
              <ReactApexChart options={this.state.options} series={this.state.series} type="donut" style={{width:'40%'}} />
            </div> */}

          <div>
            <Row>
              <div class="col-md-6">
                <div>
                  <h4 class="tiles_text">Most Enquired Product</h4>
                </div>
                <ReactTable style={{ overflow: "auto", borderRadius: '10px' }}
                  data={this.state.MostEnquiredData}
                  columns={this.state.MostEnquiredColumns}
                  noDataText="No Data Available"
                  filterable
                  defaultPageSize={10}
                  className="-highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                // getTdProps={this.onTrRowClick}
                />
              </div>
              <div class="col-md-6">
                <div>
                  <h4 class="tiles_text">New Enquired Product</h4>
                </div>
                <ReactTable style={{ overflow: "auto", borderRadius: '10px' }}
                  data={this.state.NewEnquiredData}
                  columns={this.state.NewEnquiredColumns}
                  noDataText="No Data Available"
                  filterable
                  defaultPageSize={10}
                  className="-highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                // getTdProps={this.onTrRowClick}
                />
              </div>
            </Row>
          </div>

          <div>
            <Row>
              <div class="col-md-6">
                <div>
                  <h4 class="tiles_text">Top Vendors</h4>
                </div>
                <ReactTable style={{ overflow: "auto", borderRadius: '10px' }}
                  data={this.state.TopVendorsData}
                  columns={this.state.TopVendorsColumns}
                  noDataText="No Data Available"
                  filterable
                  defaultPageSize={10}
                  className="-highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                // getTdProps={this.onTrRowClick}
                />
              </div>
              <div class="col-md-6">
                <div>
                  <h4 class="tiles_text">Recently Stocked Product</h4>
                </div>
                <ReactTable style={{ overflow: "auto", borderRadius: '10px' }}
                  data={this.state.StockedProductData}
                  columns={this.state.StockedProductColumns}
                  noDataText="No Data Available"
                  filterable
                  defaultPageSize={10}
                  className="-highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                // getTdProps={this.onTrRowClick}
                />
              </div>
            </Row>
          </div>




          {/* <div class="">
          <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Most Enquired Product</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.MostEnquiredData}
                columns={this.state.MostEnquiredColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                // defaultFilterMethod={(filter, row, column) => {
                //   const id = filter.pivotId || filter.id;
                //   return row[id] !== undefined
                //     ? String(row[id])
                //       .toLowerCase()
                //       .indexOf(filter.value.toLowerCase()) !== -1
                //     : true;
                // }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">New Enquired Product</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.NewEnquiredData}
                columns={this.state.NewEnquiredColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                // defaultFilterMethod={(filter, row, column) => {
                //   const id = filter.pivotId || filter.id;
                //   return row[id] !== undefined
                //     ? String(row[id])
                //       .toLowerCase()
                //       .indexOf(filter.value.toLowerCase()) !== -1
                //     : true;
                // }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
          </Row>
        </div> */}


        </div>
      </div>
    );

  }
}

export default InventoryDashboard;