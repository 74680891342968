//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Swal from "sweetalert2/dist/sweetalert2.js";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";

//IMPORT CLASS COMPONENT CSS
import "./LoginPage.css";
import "./NewLoginStyles.css";
import TextField from "@mui/material/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";

//IMPORT CLASS COMPONENT
import { SetCurrentPage } from "./ConstSiteFunction";

import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

// IMPORT IMAGES SECTION
import resetImg from "./image/Reset password.svg";

import LoadingComponent from "./Loading/LoadingComponent";
import { AlertMessageInfoComponent } from "./Assets_Components/MessagePage_Components/MessagePage_Components";
const LoginPage = lazy(() => import("./LoginPage"));
const OTPverifypage = lazy(() => import("./OTPverifypage"));

const styles = {
  width: "70%",
  marginTop: "10px",
};

const inputstyles = {
  marginTop: "30px",
  width: "90%",
  textAlign: "center",
  marginLeft: "20px",
  marginRight: "20px",
};

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      emailId: "",
    };
  }

  componentDidMount() {
    SetCurrentPage("ForgotPassword");
    window.scrollTo(0, 0);
  }

  handleChangeemailid(value) {
    this.setState({
      emailId: value,
    });
  }

  forgotpwd() {
    this.setState({
      emailId: this.state.emailId,
    });
    var self = this;
    $.ajax({
      type: "POST",
      data: JSON.stringify({
        emailId: this.state.emailId,
      }),

      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/Password/ForgetPassword",
      contentType: "application/json",
      dataType: "json",
      async: false,

      success: function (data, textStatus, jqXHR) {
        if (data.response == "EmailId_Doesnot_Exist") {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "The Provided Email Id Is Invalid . Kindly Check Your EmailId",
            false,
            "3000"
          );
        } else {
          AlertMessageInfoComponent(
            "center",
            "info",
            "Kindly check your mail to get the OTP",
            false,
            "3000"
          );

          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <OTPverifypage emailId={self.state.emailId} />,
                </div>
              </Suspense>
            </Router>,
            document.getElementById("root")
          );
        }
      },
    });
  }
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <LoginPage />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("root")
    );
  }

  render() {
    return (
      <div className="container wavebg">
        <div className="container-fluid container-shadow">
          <div className="row">
            <div className="col-md-6">
              <img src={resetImg} className="feat_image" />
            </div>
            <div className="col-md-6">
              <div className="text-center">
                <h2>Reset Your Password</h2>
              </div>
              <p className="text-p">
                Enter your registered email address or mobile number to set new
                password
              </p>
              <IconTextField
                sx={inputstyles}
                type="email"
                id="emailId"
                value={this.state.emailId}
                onChange={(e) => this.handleChangeemailid(e.target.value)}
                placeholder="Email-Id/Mobile No* "
                iconStart={<AccountCircle sx={styles} />}
              />

              <div className="button-div">
                <p>
                  Remember password?
                  <span onClick={() => this.BackbtnFunc()} className="pointer">
                    Login
                  </span>
                </p>
                <div className="row">
                  <button
                    onClick={() => this.BackbtnFunc()}
                    className="reset-button"
                  >
                    Cancle
                  </button>
                  <button
                    onClick={() => this.forgotpwd()}
                    className="reset-button"
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

export default ForgotPassword;
