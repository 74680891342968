//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactTooltip from "react-tooltip";
//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
import "react-toastify/dist/ReactToastify.css";
//IMPORT REACT ICONS
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as GrIcons from "react-icons/gr";
import * as GiIcons from "react-icons/gi";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as ImIcons from "react-icons/im";

//IMPORT CLASS COMPONENT CSS
import "../BackButtoncss.css";
import "../datepicker.css";
//IMPORT CLASS COMPONENT
import { ToastContainer, toast } from "react-toastify";
import plus from "./image/plus.png";
import addfile1 from "./image/addfile1.png";
import visionfile1 from "./image/visionfile1.png";
import editfile1 from "./image/editfile1.png";
import deletefile1 from "./image/deletefile1.png";
import billfile1 from "./image/billfile1.png";
import download1 from "./image/download1.png";
import datasync from "./image/data-syn.png";
import wallet12 from "./image/wallet12.png";
import warning from "./image/warning.png";
import success from "./image/success.png";
import sms from "./image/sms.png";
import activate from "./image/activate.png";
import deactivate from "./image/deactivate.png";
import allowLocation from "./image/location success.png";
import restrictLocation from "./image/location block.png";
import migration from "./image/migration.png";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WrongLocationOutlinedIcon from "@mui/icons-material/WrongLocationOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SyncIcon from "@mui/icons-material/Sync";

const buttonStyles = {
  borderRadius: "35px",
  backgroundColor: "rgb(38 62 172)",
  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Roboto,sans-serif",
  padding: "5px 10px 10px 10px",
  fontWeight: "bold",
  width: "140px",
  height: "30px",
  marginRight: "10px",
};

export const Invoice_xlDownldBtn = function () {
  return (
    <div>
      <SimCardDownloadOutlinedIcon
        alt="logo"
        data-tip
        data-for="downloadInvoiceTip"
        className="icons-size-col"
      />
      <ReactTooltip id="downloadInvoiceTip" place="left" effect="solid">
        Download
      </ReactTooltip>
    </div>
  );
};
/*
data-tip data-for="instantInvoiceestimateTip" - USED TO CONVERT/GENERATE AN 
ESTIMATE TO THE CREATED JOBCARD - ADDED BY DURGA 16-04-2022
*/

export const ServiceRegistrationIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <DescriptionOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="instantInvoiceTip"
            onClick={props.onInstantInvoice}
          />
        </li>
        {/* <li>
          <ReceiptOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="instantInvoiceestimateTip"
            onClick={props.onInstantEstimateInvoice}
          />
        </li>
  */}
        <li>
          <NoteAddOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="addServiceTip"
            onClick={props.ondAddService}
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="viewServiceTip"
            onClick={props.onViewService}
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="editServiceTip"
            onClick={props.onEditService}
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="deleteServiceTip"
            onClick={props.onDeleteService}
          />
        </li>
        <li>
          <SimCardDownloadOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="downloadServiceTip"
            onClick={props.onDownloadService}
          />
        </li>
        <li>
          <SyncIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="importToJobCardTip"
            onClick={props.onImportToJobcardService}
          />
        </li>
      </ul>

      <ReactTooltip id="instantInvoiceTip" place="top" effect="solid">
        Make Invoice
      </ReactTooltip>

      <ReactTooltip id="instantInvoiceestimateTip" place="top" effect="solid">
        Generate Estimate Invoice
      </ReactTooltip>

      <ReactTooltip id="addServiceTip" place="top" effect="solid">
        Add Job card
      </ReactTooltip>

      <ReactTooltip id="viewServiceTip" place="top" effect="solid">
        View Job Card
      </ReactTooltip>

      <ReactTooltip id="editServiceTip" place="top" effect="solid">
        Edit Job Card
      </ReactTooltip>

      <ReactTooltip id="deleteServiceTip" place="top" effect="solid">
        Delete Job Card
      </ReactTooltip>

      <ReactTooltip id="downloadServiceTip" place="top" effect="solid">
        Download Job Card List
      </ReactTooltip>

      <ReactTooltip id="importToJobCardTip" place="top" effect="solid">
        Sync to JobCard
      </ReactTooltip>
    </div>
  );
};

export const VehicleIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addVehicleTip"
            onClick={props.ondAddVehicle}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewVehicleTip"
            onClick={props.onViewVehicle}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editVehicleTip"
            onClick={props.onEditVehicle}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteVehicleTip"
            onClick={props.DeleteVehicle}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addVehicleTip" place="top" effect="solid">
        Add Vehicle
      </ReactTooltip>

      <ReactTooltip id="viewVehicleTip" place="top" effect="solid">
        View Vehicle
      </ReactTooltip>

      <ReactTooltip id="editVehicleTip" place="top" effect="solid">
        Edit Vehicle
      </ReactTooltip>

      <ReactTooltip id="deleteVehicleTip" place="top" effect="solid">
        Delete Vehicle
      </ReactTooltip>

      <ReactTooltip id="downloadVehicleTip" place="top" effect="solid">
        Download Vehicle List
      </ReactTooltip>
    </div>
  );
};

export const ProductIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addProductTip"
            onClick={props.ondAddProduct}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewProductTip"
            onClick={props.onViewProduct}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editProductTip"
            onClick={props.onEditProduct}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteProductTip"
            onClick={props.DeleteProduct}
            className="icons-size-col"
          />
        </li>
        <li>
          <ChangeCircleOutlinedIcon
            alt="logo"
            data-tip
            data-for="migrateProductTip"
            onClick={props.onMigrateProduct}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addProductTip" place="left" effect="solid">
        Add Product
      </ReactTooltip>

      <ReactTooltip id="viewProductTip" place="left" effect="solid">
        View Product
      </ReactTooltip>

      <ReactTooltip id="editProductTip" place="left" effect="solid">
        Edit Product
      </ReactTooltip>

      <ReactTooltip id="deleteProductTip" place="left" effect="solid">
        Delete Product
      </ReactTooltip>

      <ReactTooltip id="downloadProductTip" place="left" effect="solid">
        Download
      </ReactTooltip>

      <ReactTooltip id="migrateProductTip" place="top" effect="solid">
        Migrate Product
      </ReactTooltip>
    </div>
  );
};

export const PackageIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn" style={{ marginRight: "37px" }}>
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addPackageTip"
            onClick={props.onAddPackage}
            className="icons-size-col"
          />
        </li>
        <li>
          <BookmarkAddIcon
            alt="logo"
            data-tip
            data-for="addItemsPackageTip"
            onClick={props.onAddItemsToPackage}
            className="icons-size-col"
          />
        </li>

        <li>
          <BookmarkRemoveIcon
            alt="logo"
            data-tip
            data-for="removeItemsPackageTip"
            onClick={props.onRemoveItemsFromPackage}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewPackageTip"
            onClick={props.onViewPackage}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editPackageTip"
            onClick={props.onEditPackageName}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deletePackageTip"
            onClick={props.onDeletePackage}
            className="icons-size-col"
          />
        </li>
        {/* <li>
        <GrIcons.GrDocumentDownload data-tip data-for="downloadProductTip" onClick={props.onDownloadProduct} /> 
        </li> */}
      </ul>

      <ReactTooltip id="addPackageTip" place="top" effect="solid">
        Add Package
      </ReactTooltip>

      <ReactTooltip id="addItemsPackageTip" place="top" effect="solid">
        Add Items to Package
      </ReactTooltip>

      <ReactTooltip id="removeItemsPackageTip" place="top" effect="solid">
        Remove Items From Package
      </ReactTooltip>

      <ReactTooltip id="viewPackageTip" place="top" effect="solid">
        View Package
      </ReactTooltip>

      <ReactTooltip id="editPackageTip" place="top" effect="solid">
        Edit Package
      </ReactTooltip>

      <ReactTooltip id="deletePackageTip" place="top" effect="solid">
        Delete Package
      </ReactTooltip>

      <ReactTooltip id="downloadPackageTip" place="top" effect="solid">
        Download Package
      </ReactTooltip>
    </div>
  );
};

/*
 data-tip data-for="instantInvoiceTip" ICON USED FOR CONVERTING ESTIMATE 
 INVOICE INTO SALE INVOICE - ADDED BY DURGA 16-04-2022
*/

export const InvoiceIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        {/* <li>
          <FileCopyOutlinedIcon
            alt="logo"
            data-tip
            data-for="instantInvoiceTip"
            onClick={props.onInvoiceConvert}
            className="icons-size-col"
          />
        </li>
        */}
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addInvoiceTip"
            onClick={props.onInvoiceAdd}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            src={visionfile1}
            alt="logo"
            data-tip
            data-for="viewInvoiceTip"
            onClick={props.onInvoiceView}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editInvoiceTip"
            onClick={props.onInvoiceEdit}
            className="icons-size-col"
          />
        </li>
        <li>
          <AccountBalanceWalletOutlinedIcon
            data-tip
            data-for="payInvoiceTip"
            onClick={props.onInvoicePay}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onInvoiceDelete}
            className="icons-size-col"
          />
        </li>
        <li>
          <SimCardDownloadOutlinedIcon
            className="icons-size-col"
            alt="logo"
            data-tip
            data-for="downloadInvoiceTip"
            onClick={props.onInvoiceDownload}
          />
        </li>
      </ul>
      <ReactTooltip id="addInvoiceTip" place="top" effect="solid">
        Add Invoice
      </ReactTooltip>

      <ReactTooltip id="instantInvoiceTip" place="top" effect="solid">
        Convert SaleInvoice
      </ReactTooltip>

      <ReactTooltip id="viewInvoiceTip" place="top" effect="solid">
        View Invoice
      </ReactTooltip>

      <ReactTooltip id="editInvoiceTip" place="top" effect="solid">
        Edit Invoice
      </ReactTooltip>

      <ReactTooltip id="payInvoiceTip" place="top" effect="solid">
        Pay Invoice
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete Invoice
      </ReactTooltip>

      <ReactTooltip id="downloadInvoiceTip" place="top" effect="solid">
        Download Invoice
      </ReactTooltip>
    </div>
  );
};

export const Delete_DownloadIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onDelete}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete
      </ReactTooltip>
    </div>
  );
};

export const DeleteIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <FaIcons.FaRegTrashAlt
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onInvoiceDelete}
          />
        </li>
      </ul>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete
      </ReactTooltip>
    </div>
  );
};

export const EnquiryIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addEnquiryTip"
            onClick={props.onAddEnquiry}
            className="icons-size-col"
          />
        </li>
        <li>
          <MessageOutlinedIcon
            alt="logo"
            data-tip
            data-for="smsEnquiryTip"
            onClick={props.onSmsEnquiry}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onEnquiryDelete}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addEnquiryTip" place="top" effect="solid">
        Add
      </ReactTooltip>

      <ReactTooltip id="smsEnquiryTip" place="top" effect="solid">
        Sms
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete
      </ReactTooltip>
    </div>
  );
};

export const Employee_Vendor_Customer_Icons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addemp_cust_vend_Tip"
            onClick={props.onAddEmpVendCust}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewemp_cust_vend_Tip"
            onClick={props.onViewEmpVendCust}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editemp_cust_vend_"
            onClick={props.onEditEmpVendCust}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteemp_cust_vend_Tip"
            onClick={props.onDeleteEmpVendCust}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addemp_cust_vend_Tip" place="top" effect="solid">
        Add
      </ReactTooltip>

      <ReactTooltip id="viewemp_cust_vend_Tip" place="top" effect="solid">
        View
      </ReactTooltip>

      <ReactTooltip id="editemp_cust_vend_" place="top" effect="solid">
        Edit
      </ReactTooltip>

      <ReactTooltip id="deleteemp_cust_vend_Tip" place="top" effect="solid">
        Delete
      </ReactTooltip>

      <ReactTooltip id="downloademp_cust_vend_Tip" place="top" effect="solid">
        Download
      </ReactTooltip>
    </div>
  );
};

export const BankIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addeBankTip"
            onClick={props.onAddBank}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editBankTip"
            onClick={props.onEditBank}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteBankTip"
            onClick={props.onDeleteBank}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addeBankTip" place="top" effect="solid">
        Add
      </ReactTooltip>

      <ReactTooltip id="editBankTip" place="top" effect="solid">
        Edit
      </ReactTooltip>

      <ReactTooltip id="deleteBankTip" place="top" effect="solid">
        Delete
      </ReactTooltip>

      <ReactTooltip id="downloadBankTip" place="top" effect="solid">
        Download
      </ReactTooltip>
    </div>
  );
};

export const CustomerInvoiceIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewInvoiceTip"
            onClick={props.onInvoiceView}
            className="icons-size-col"
          />
        </li>
        <li>
          <AccountBalanceWalletOutlinedIcon
            alt="logo"
            data-tip
            data-for="payInvoiceTip"
            onClick={props.onInvoicePay}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="viewInvoiceTip" place="top" effect="solid">
        View Invoice
      </ReactTooltip>

      <ReactTooltip id="payInvoiceTip" place="top" effect="solid">
        Pay Invoice
      </ReactTooltip>
    </div>
  );
};

export const VehcileRegistartionListStartIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <FaIcons.FaEye
            data-tip
            data-for="TakeUpServiceTip"
            onClick={props.onInvoiceView}
          />
        </li>
      </ul>

      <ReactTooltip id="TakeUpServiceTip" place="top" effect="solid">
        Takeup Service
      </ReactTooltip>
    </div>
  );
};

export const VehcileRegistartionListCompleteIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <FaIcons.FaEye
            data-tip
            data-for="finishServiceTip"
            onClick={props.onInvoiceView}
          />
        </li>
      </ul>

      <ReactTooltip id="finishServiceTip" place="top" effect="solid">
        Finish Service
      </ReactTooltip>
    </div>
  );
};

export const InvoiceCartIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn_txt">
        <li>
          <BiIcons.BiEditAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="EditInvoiceCartProduct"
            onClick={props.onInvoiceCartProductEdit}
          />
          <span></span>
        </li>
        <li>
          <BiIcons.BiPlus
            className="FaRegTrashAlts"
            data-tip
            data-for="AddInvoiceCartProduct"
            onClick={props.onInvoiceCartProductAdd}
          />
          <span></span>
        </li>
        <li>
          <HiIcons.HiMinusSm
            className="FaRegTrashAlts"
            data-tip
            data-for="ReduceInvoiceCartFully"
            onClick={props.onInvoiceCartProductMinus}
          />
          <span></span>
        </li>
        <li>
          <FaIcons.FaRegTrashAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="deleteInvoiceCartProduct"
            onClick={props.onInvoiceCartProductDelete}
          />
          <span></span>
        </li>
        <li>
          <MdIcons.MdRemoveShoppingCart
            className="FaRegTrashAlts"
            data-tip
            data-for="deleteInvoiceCartFully"
            onClick={props.onInvoiceCartFullyDelete}
          />
          <span></span>
        </li>
      </ul>

      <ReactTooltip id="EditInvoiceCartProduct" place="top" effect="solid">
        Edit Product
      </ReactTooltip>
      <ReactTooltip id="AddInvoiceCartProduct" place="top" effect="solid">
        Add Product
      </ReactTooltip>

      <ReactTooltip id="ReduceInvoiceCartFully" place="top" effect="solid">
        Reduce Product
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceCartProduct" place="top" effect="solid">
        Delete Product
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceCartFully" place="top" effect="solid">
        Delete Cart Totally
      </ReactTooltip>
    </div>
  );
};

export const InvoiceEnquiryCartIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn_txt">
        <li>
          <BiIcons.BiEditAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="EditInvoiceEnquiryCartProduct"
            onClick={props.onInvoiceEnquiryCartProductEdit}
          />
        </li>
        <li>
          <GrIcons.GrFormAdd
            className="FaRegTrashAlts"
            style={{ color: "white" }}
            data-tip
            data-for="AddInvoiceEnquiryCartProduct"
            onClick={props.onInvoiceEnquiryCartProductAdd}
          />
        </li>
        <li>
          <HiIcons.HiMinusSm
            className="FaRegTrashAlts"
            data-tip
            data-for="ReduceInvoiceEnquiryCartFully"
            onClick={props.onInvoiceEnquiryCartProductMinus}
          />
        </li>
        <li>
          <FaIcons.FaRegTrashAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="DeleteInvoiceEnquiryCartFully"
            onClick={props.onInvoiceEnquiryCartProductDelete}
          />
        </li>
      </ul>

      <ReactTooltip
        id="EditInvoiceEnquiryCartProduct"
        place="top"
        effect="solid"
      >
        Edit Quantity
      </ReactTooltip>

      <ReactTooltip
        id="AddInvoiceEnquiryCartProduct"
        place="top"
        effect="solid"
      >
        Add Quantity
      </ReactTooltip>

      <ReactTooltip
        id="ReduceInvoiceEnquiryCartFully"
        place="top"
        effect="solid"
      >
        Reduce Quantity
      </ReactTooltip>

      <ReactTooltip
        id="DeleteInvoiceEnquiryCartFully"
        place="top"
        effect="solid"
      >
        Delete Product
      </ReactTooltip>
    </div>
  );
};

export const JobCardEstimateCartIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn_txt">
        <li>
          <BiIcons.BiEditAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="EditInvoiceEnquiryCartProduct"
            onClick={props.onEstimateProductEdit}
          />
        </li>
      </ul>

      <ReactTooltip
        id="EditInvoiceEnquiryCartProduct"
        place="top"
        effect="solid"
      >
        Edit
      </ReactTooltip>
    </div>
  );
};

export const SaleInvoiceIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="addInvoiceTip"
            onClick={props.onInvoiceAdd}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewInvoiceTip"
            onClick={props.onInvoiceView}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editInvoiceTip"
            onClick={props.onInvoiceEdit}
            className="icons-size-col"
          />
        </li>
        <li>
          <AccountBalanceWalletOutlinedIcon
            alt="logo"
            data-tip
            data-for="payInvoiceTip"
            onClick={props.onInvoicePay}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onInvoiceDelete}
            className="icons-size-col"
          />
        </li>
        <li>
          <SimCardDownloadOutlinedIcon
            alt="logo"
            data-tip
            data-for="downloadInvoiceTip"
            onClick={props.onInvoiceDownload}
            className="icons-size-col"
          />
        </li>
      </ul>
      <ReactTooltip id="addInvoiceTip" place="top" effect="solid">
        Add Invoice
      </ReactTooltip>

      <ReactTooltip id="viewInvoiceTip" place="top" effect="solid">
        View Invoice
      </ReactTooltip>

      <ReactTooltip id="editInvoiceTip" place="top" effect="solid">
        Edit Invoice
      </ReactTooltip>

      <ReactTooltip id="payInvoiceTip" place="top" effect="solid">
        Pay Invoice
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete Invoice
      </ReactTooltip>

      <ReactTooltip id="downloadInvoiceTip" place="top" effect="solid">
        Download Invoice
      </ReactTooltip>
    </div>
  );
};

export const ToastWarningIcons = function (props) {
  //console.log("PROPS :",props);

  return (
    toast.error(
      <div>
        <span style={{ display: "flex" }}>
          <img
            src={warning}
            alt="logo"
            style={{ width: "20px", paddingRight: "5px", marginTop: "-3px" }}
          />
          {props.message}
        </span>
      </div>,
      {
        position: props.position,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        marginTop: "60px",
      }
    ),
    {
      /*  <div>
   
      <span>
      <img src={warning} alt="logo" style={{width: '20px',paddingRight:'5px',marginTop:'-3px'}} />
    </span>

  </div>  */
    }
  );
};

export const ToastSuccessIcons = function (props) {
  return (
    toast.error(
      <div>
        <span style={{ display: "flex" }}>
          <img
            src={success}
            alt="logo"
            style={{ width: "20px", paddingRight: "5px", marginTop: "-3px" }}
          />
          {props.message}
        </span>
      </div>,
      {
        position: props.position,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        marginTop: "60px",
      }
    ),
    {
      /*<div>
   
      <span>
      <img src={success} alt="logo" style={{width: '20px',paddingRight:'5px',marginTop:'-3px'}} />
    </span>

  </div> */
    }
  );
};

export const InformationIcon = function (props) {
  console.log("INFO ICON PROPS :", props);

  return (
    <div>
      <ul className="reactIcon_Btn_txt">
        <li>
          <BsIcons.BsInfoCircle
            className="FaRegTrashAlts"
            data-tip
            data-for="EditInvoiceEnquiryCartProduct"
            onClick={props.onInformationIconClicked}
          />
        </li>
      </ul>

      <ReactTooltip
        id="EditInvoiceEnquiryCartProduct"
        place="top"
        effect="solid"
      >
        {props.infoMessage}
      </ReactTooltip>
    </div>
  );
};

export const ReportIcons = function (props) {
  return (
    <div className="reactIcon_Dcls">
      <ul className="reactIcon_Btn">
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="viewReportTip"
            onClick={props.onReportView}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="editReportTip"
            onClick={props.onReportEdit}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteReportTip"
            onClick={props.onReportDelete}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="viewReportTip" place="top" effect="solid">
        View Report
      </ReactTooltip>
      <ReactTooltip id="editReportTip" place="top" effect="solid">
        Edit Report
      </ReactTooltip>
      <ReactTooltip id="deleteReportTip" place="top" effect="solid">
        Delete Report
      </ReactTooltip>
    </div>
  );
};

export const EnquiryIcons1 = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn" style={{ marginRight: "37px" }}>
        <li>
          <MessageOutlinedIcon
            alt="logo"
            data-tip
            data-for="smsEnquiryTip"
            onClick={props.onSmsEnquiry}
            className="icons-size-col"
          />
        </li>
        <li>
          <DeleteOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="deleteInvoiceTip"
            onClick={props.onEnquiryDelete}
            className="icons-size-col"
          />
        </li>
        <li>
          <SimCardDownloadOutlinedIcon
            alt="logo"
            data-tip
            data-for="downloadInvoiceTip"
            onClick={props.onDownload}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="addEnquiryTip" place="top" effect="solid">
        Add
      </ReactTooltip>

      <ReactTooltip id="smsEnquiryTip" place="top" effect="solid">
        Sms
      </ReactTooltip>

      <ReactTooltip id="deleteInvoiceTip" place="top" effect="solid">
        Delete
      </ReactTooltip>

      <ReactTooltip id="downloadInvoiceTip" place="top" effect="solid">
        Download
      </ReactTooltip>
    </div>
  );
};

export const PlanConfigurationIcon = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <CheckCircleOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="planActivateTip"
            onClick={props.onActivate}
            className="icons-size-col"
          />
        </li>
        <li>
          <CancelOutlinedIcon
            alt="logo"
            data-tip
            data-for="planDeactivateTip"
            onClick={props.onDeactivate}
            className="icons-size-col"
          />
        </li>
        <li>
          <DriveFileRenameOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="planEditTip"
            onClick={props.onEdit}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="planActivateTip" place="top" effect="solid">
        Activate
      </ReactTooltip>

      <ReactTooltip id="planDeactivateTip" place="top" effect="solid">
        Deactivate
      </ReactTooltip>

      <ReactTooltip id="planEditTip" place="top" effect="solid">
        Edit
      </ReactTooltip>
    </div>
  );
};

export const DownloadButton = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <SimCardDownloadOutlinedIcon
            alt="logo"
            onClick={props.DownLoadList}
            className="icons-size-col"
            data-tip
            data-for="downloadInvoiceTip"
          />
        </li>
      </ul>
      <ReactTooltip
        id="downloadInvoiceTip"
        place="top"
        effect="solid"
        style={{ fontsize: "13px", padding: "8px 21px" }}
      >
        Download
      </ReactTooltip>
    </div>
  );
};

export const EditTableIcons = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn_txt">
        <li>
          <BiIcons.BiEditAlt
            className="FaRegTrashAlts"
            data-tip
            data-for="Edit"
            onClick={props.onEdit}
          />
        </li>
      </ul>

      <ReactTooltip id="Edit" place="top" effect="solid">
        Edit
      </ReactTooltip>

      <ReactTooltip id="Delete" place="top" effect="solid">
        Delete
      </ReactTooltip>
    </div>
  );
};

export const SalesPlan_AppUsers_Icon = function (props) {
  return (
    <div>
      <ul className="reactIcon_Btn">
        <li>
          <NoteAddOutlinedIcon
            alt="logo"
            data-tip
            data-for="planViewTip"
            onClick={props.onView}
            className="icons-size-col"
          />
        </li>
        <li>
          <CheckCircleOutlineOutlinedIcon
            alt="logo"
            data-tip
            data-for="planActivateTip"
            onClick={props.onActivate}
            className="icons-size-col"
          />
        </li>
        <li>
          <CancelOutlinedIcon
            alt="logo"
            data-tip
            data-for="planDeactivateTip"
            onClick={props.onDeactivate}
            className="icons-size-col"
          />
        </li>
        <li>
          <RemoveRedEyeOutlinedIcon
            alt="logo"
            data-tip
            data-for="planEditTip"
            onClick={props.onEdit}
            className="icons-size-col"
          />
        </li>
        <li>
          <AddLocationAltOutlinedIcon
            alt="logo"
            data-tip
            data-for="allowLocationTip"
            onClick={props.onAllLocation}
            className="icons-size-col"
          />
        </li>
        <li>
          <WrongLocationOutlinedIcon
            alt="logo"
            data-tip
            data-for="restrictLocationTip"
            onClick={props.onRestrictLocation}
            className="icons-size-col"
          />
        </li>
      </ul>

      <ReactTooltip id="planActivateTip" place="top" effect="solid">
        Activate
      </ReactTooltip>

      <ReactTooltip id="planDeactivateTip" place="top" effect="solid">
        Deactivate
      </ReactTooltip>

      <ReactTooltip id="planEditTip" place="top" effect="solid">
        Edit
      </ReactTooltip>

      <ReactTooltip id="planViewTip" place="top" effect="solid">
        View
      </ReactTooltip>

      <ReactTooltip id="allowLocationTip" place="top" effect="solid">
        Allow Location
      </ReactTooltip>

      <ReactTooltip id="restrictLocationTip" place="top" effect="solid">
        Restrict Location
      </ReactTooltip>
    </div>
  );
};
