import React from 'react';
export const FormErrors = ({formErrors}) =>
  <div className='formErrors'>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        return (
          <p style={{color:"red",fontSize: '13px',}}  key={i}>{fieldName} {formErrors[fieldName]}</p>
        )
      } else {
        return '';
      }
    })}
</div>


export const ErrorClass =({errorContent}) =>
  {  
   // console.log(errorContent.length);
    
    if(errorContent.length>0){ 
        return ( <p style={{color:"red",fontSize: '13px',fontWeight: "400"} } >{errorContent}</p>);

      } 
      else if(errorContent.length==0){
        return '';
  }
}

/*export const ErrorClass =({errorContent}) =>
  {  
    console.log(errorContent.length);
    
    if(errorContent.length>0){ 
        return (<div className="test"> <p style={{color:"red",fontSize: '13px'} } >{errorContent}</p></div>);

      } 
      else if(errorContent.length==0){
        return (<div className="test"> </div>);
  }
} */