import React, { Component } from "react";
import "./SiteRegister.css";

export class Confirm extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  SiteRegistrationFun = (e) => {
    // e.preventDefault();
    this.props.SiteRegistrationFun();
  };

  render() {
    const {
      values: {
        companyId,
        companyName,
        emailId,
        contactNo,
        planName,
        currencyCode,
        doorNo,
        floor,
        state,
        zipCode,
        city,
        street,
        area,
        country,
        region,
        timeZone,
      },
    } = this.props;

    //const { values, handleUserInput,handleUserInputTimeZone,handleUserInputRegion} = this.props;

    return (
      <div className="form-container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <h4 className="mb-5 animate__animated animate__bounce head-text">
              Company Details
            </h4>
            <div className="profile-info">
              <div className="profile-user-info">
                <div className="profile-info-row">
                  <div className="profile-info-name">Company Name </div>
                  <div className="profile-info-value">
                    <span className="span-word-break">{companyName}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name">Email Id </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{emailId}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name"> Phone Number </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{contactNo}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name"> Currency </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{currencyCode}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name">plan </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{planName}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* col 2 section */}

          <div className="col-xs-12 col-sm-6">
            <h4 className="mb-5 animate__animated animate__bounce head-text">
              Company Address
            </h4>
            <div className="profile-info">
              <div
                className="profile-user-info"
                style={{ paddingBottom: "35px" }}
              >
                <div className="profile-info-row">
                  <div className="profile-info-name"> Door No </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{doorNo}</span>
                  </div>
                </div>

                <div className="profile-info-row">
                  <div className="profile-info-name"> Floor No</div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{floor}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name"> Street </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{street}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name"> city </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{city}</span>
                  </div>
                </div>
                <div className="profile-info-row">
                  <div className="profile-info-name"> Area </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{area}</span>
                  </div>
                </div>

                <div className="profile-info-row">
                  <div className="profile-info-name">State </div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{state}</span>
                  </div>
                </div>

                <div className="profile-info-row">
                  <div className="profile-info-name">Pincode</div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{zipCode}</span>
                  </div>
                </div>

                <div className="profile-info-row">
                  <div className="profile-info-name">Region</div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{region}</span>
                  </div>
                </div>

                <div className="profile-info-row">
                  <div className="profile-info-name">TimeZone</div>

                  <div className="profile-info-value">
                    <span className="span-word-break">{timeZone}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="row">
          <div className="col-md-6 col-xs-4">
            <button className="btn btn-danger" onClick={this.back}>
              Previous
            </button>
          </div>
          <div className="col-md-6 col-xs-3 text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.SiteRegistrationFun()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
