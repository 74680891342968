//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from 'react';
import CryptoJS from "crypto-js";
import moment from 'moment-timezone';
import _ from 'underscore';
import currencyFormatter from "currency-formatter";

export const GetCurrentSite = () => {
    var currentSite = CryptoJS.AES.decrypt(localStorage.getItem('CurrentSite'), "shinchanbaby").toString(CryptoJS.enc.Utf8);

    return currentSite;
}

export const GetEmployeeSite = () => {
    var empSites = CryptoJS.AES.decrypt(localStorage.getItem('EmpSites'), "shinchanbaby").toString(CryptoJS.enc.Utf8);

    return empSites;
}


export const GetSiteDetails = () => {
    var siteDetails = CryptoJS.AES.decrypt(localStorage.getItem('SiteDetails'), "shinchanbaby").toString(CryptoJS.enc.Utf8);

    return siteDetails;
}

export const GetSiteCode = () => {
    var currentSite = GetCurrentSite();
    var siteDetails = GetSiteDetails();
    // var currencies=GetCurrencies();

    var siteDetailsArray = JSON.parse(`[ ${siteDetails}]`);

    //console.log("siteDetailsArray :", siteDetailsArray);
    //console.log("siteDetailsArray POSITION 0:", siteDetailsArray[0]);

    var currentSiteData = _.findWhere(siteDetailsArray[0], { siteName: currentSite });
    //console.log("currentSiteData :", currentSiteData);

    var siteCode = currentSiteData.siteCode;
    console.log("siteCode :", siteCode);

    return siteCode;
}


export const GetCurrencies = () => {
    var currencies = currencyFormatter.currencies;
    //console.log("currencies :", currencies);
    return currencies;
}


export const SetCurrentPage = (currentPageName) => {
    //console.log("SetCurrentPage :", currentPageName);
    localStorage.setItem('PreviousVistedPage', CryptoJS.AES.encrypt(currentPageName, "shinchanbaby"));
}

export const GetPreviousPage = () => {
    var PreviousVistedPage = CryptoJS.AES.decrypt(localStorage.getItem('PreviousVistedPage'), "shinchanbaby").toString(CryptoJS.enc.Utf8);

    return PreviousVistedPage;
}


export const OffsetValueCalcFunc = (city, offset) => {


    var currentSite = GetCurrentSite();
    var siteDetails = GetSiteDetails();
    // var currencies=GetCurrencies();

    var siteDetailsArray = JSON.parse(`[ ${siteDetails}]`);

    //console.log("siteDetailsArray :", siteDetailsArray);
    //console.log("siteDetailsArray POSITION 0:", siteDetailsArray[0]);

    var currentSiteData = _.findWhere(siteDetailsArray[0], { siteName: currentSite });
   
   // console.log("currentSiteData :", currentSiteData);

    var empZone = currentSiteData.timeZone;
    //console.log("empZone :", empZone);


    var offset = moment.tz(moment.utc(), empZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS
    var timings = CalcTime(empZone, offsetValue);
    var todayDate = GetTimeZoneDate(offsetValue);
    var currenttime = timings.toLocaleTimeString([], { hour12: false });

    var date_Time = {
        date: todayDate,
        time: currenttime,
    }

    return date_Time;
}


export const OffsetValueCalcFuncDynamicTimeZone = (timeZone) => {


    var empZone =timeZone;
    console.log("empZone :", empZone);


    var offset = moment.tz(moment.utc(), empZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS
    var timings = CalcTime(empZone, offsetValue);
    var todayDate = GetTimeZoneDate(offsetValue);
    var currenttime = timings.toLocaleTimeString([], { hour12: false });

    var date_Time = {
        date: todayDate,
        time: currenttime,
    }

    return date_Time;
}

export const CalcTime = (city, offset) => {
    // create Date object for current location
    var d = new Date();


    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000 * offset));

    // return time as a string
    return nd;
}


export const GetTimeZoneDate = (offset) => {
    //  var offset = -8;
    var todayDate = new Date(new Date().getTime() + offset * 3600 * 1000).toUTCString().replace(/ GMT$/, "")


    var d1 = new Date(todayDate);
    var d2 = d1.getFullYear() + "-"
        + ('0' + (d1.getMonth() + 1)).slice(-2) + "-"
        + ('0' + d1.getDate()).slice(-2);


    return d2;

}

export const Check_AccessTime = () => {

    var dateTimeData=OffsetValueCalcFunc();

    var date=dateTimeData.date;
    var time=dateTimeData.time;

  // var time='00:35';
    var serverStopTime='23:30';
    var serverStartTime='07:00';

    var format = 'HH:mm';

    var actualTime = moment(time,format);
    var stopTime = moment(serverStopTime, format);
    var startTime = moment(serverStartTime, format);
  
  //  if (startTime.hour() >=12 && endTime.hour() <=12 )
  if (actualTime.hour() <= stopTime.hour() && actualTime.hour() <= startTime.hour())
    {
        startTime.add(1, "days");   // handle spanning days
    }
    

  /* if (actualTime.hour() > startTime.hour())
   {
       startTime.add(-1, "days");   // handle spanning days
   }
   */

 //   console.log("actualTime :",actualTime);
 //   console.log("stopTime :",stopTime);
 //   console.log("startTime :",startTime);


    var serverAccessData=false;

    if(actualTime.isAfter(stopTime)){
    //    alert("AFTER STOP TIME")
        serverAccessData=false;
    }else if(actualTime.isBefore(startTime)){
    //    alert("BEFORE START TIME")
        serverAccessData=false;
    }else{
      //  alert("NOT BEFORE OR AFTER SERVER START & END TIME");
        serverAccessData=true;
    }

  
var serverAccess_Data={
    serverAccessData:serverAccessData,
}

    return serverAccess_Data;

}

 
export const Check_AccessTime_Login = () => {

    var companyZone =  "Asia/Kolkata";
    var offset = moment.tz(moment.utc(), companyZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS

    var timings = CalcTime(companyZone, offsetValue);
    var todayDate = GetTimeZoneDate(offsetValue);
    var time = timings.toLocaleTimeString([], { hour12: false });

   
    var serverStopTime='23:30';
    var serverStartTime='07:00';

    var format = 'HH:mm';

    var actualTime = moment(time,format);
    var stopTime = moment(serverStopTime, format);
    var startTime = moment(serverStartTime, format);
  
  //  if (startTime.hour() >=12 && endTime.hour() <=12 )
  if (actualTime.hour() <= stopTime.hour() && actualTime.hour() <= startTime.hour())
    {
        startTime.add(1, "days");   // handle spanning days
    }

    var serverAccessData=false;

    if(actualTime.isAfter(stopTime)){
       // alert("AFTER STOP TIME")
        serverAccessData=false;
    }else if(actualTime.isBefore(startTime)){
       // alert("BEFORE START TIME")
        serverAccessData=false;
    }else{
       // alert("NOT BEFORE OR AFTER SERVER START & END TIME");
        serverAccessData=true;
    }

  
var serverAccess_Data={
    serverAccessData:serverAccessData,
}

    return serverAccess_Data;

}

export const GetCustomerMessageConfigurationDetails=(featureName,optedMode) =>{
    var customerMessageConfigDetails = CryptoJS.AES.decrypt(localStorage.getItem('MessageConfiguration_Customer'), "shinchanbaby").toString(CryptoJS.enc.Utf8);

   // alert("featureName :"+featureName);

    var customerMessageConfigDetailsArray = JSON.parse(`[ ${customerMessageConfigDetails}]`);
console.log("customerMessageConfigDetailsArray :",customerMessageConfigDetailsArray);

var currentFeatureData=_.where(customerMessageConfigDetailsArray[0],{feature:featureName});

console.log("currentFeatureData :",currentFeatureData);

var currentOptedMode;
var featureStatus;

if(optedMode=="sms"){
     currentOptedMode=currentFeatureData[0].smsStatus;
     featureStatus=FetchFeatureStatusMode(currentOptedMode);
      

}else if(optedMode=="email"){
 currentOptedMode=currentFeatureData[0].emailStatus;
 featureStatus=FetchFeatureStatusMode(currentOptedMode);
  
}else if(optedMode=="whatsapp"){
    currentOptedMode=currentFeatureData[0].whatsAppStatus;
    featureStatus=FetchFeatureStatusMode(currentOptedMode);
  
}

console.log("currentOptedMode :",currentOptedMode);

return featureStatus;
}

export const FetchFeatureStatusMode=(currentOptedMode) =>{

    var featureStatus;
    if(currentOptedMode==0){
        featureStatus=false;
    }else if(currentOptedMode==1){
        featureStatus=true;
    }
    return featureStatus; 

}

