//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "../../../../registerServiceWorker";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import CryptoJS from "crypto-js";
import { confirmAlert } from "react-confirm-alert"; // Import
import Swal from "sweetalert2/dist/sweetalert2.js";
import Case from "case";
import SelectSearch from "react-select";
import _ from "underscore";

import CreatableSelect from "react-select/creatable";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import SubmitButtonComponent, {
  Double_BackButtonComponent,
  CancelButtonComponent,
} from "../../../../ServiceRegistration/ButtonComponent";
import LoadingComponent from "../../../../Loading/LoadingComponent";
import PackageList from "./PackageList";
import { CreatableSelectField } from "../../../../Assets_Components/Input_Components/Input_Components";
import { ReactTableWithOnClick } from "../../../../Assets_Components/Table_Components/Table_Components";
import {
  GetCurrentSite,
  GetEmployeeSite,
  SetCurrentPage,
} from "../../../../ConstSiteFunction";
import {
  AlertMessageInfoComponent,
  NetworkErrorComponent,
} from "../../../../Assets_Components/MessagePage_Components/MessagePage_Components";
import { Truncate_2DecimalPlaces } from "../../../../Invoice/InvoiceValidations";
import { DeleteIcons } from "../../../../ServiceRegistration/IconComponents";

import CapitalCaseFunc from "../../../../ServiceRegistration/CommonTextFormatComponent";

var productList = [];
var packageList = [];

class AddPackage extends Component {
  constructor() {
    super();

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      data: [],
      columns: [
        { Header: "SNO", accessor: "SNO" },
        { Header: "ProductId", accessor: "ProductId", show: false },
        { Header: "Name", accessor: "Name" },
        { Header: "Rate", accessor: "Rate" },
        { Header: "CGST", accessor: "CGST" },
        { Header: "SGST", accessor: "SGST" },
        { Header: "IGST", accessor: "IGST" },
        { Header: "Total Rate", accessor: "TotalRate" },
      ],

      pageSize: 10,
      selected: -1,

      modalIsOpen: false,

      selectedPackage: [],
      selectedProduct: [],

      rateWithOutTax: 0,
      rateWithTax: 0,
      totalPackageRate: 0,

      cgst: 0,
      sgst: 0,
      igst: 0,

      packageCreateStatus: "Not_Created",
    };

    this.GetProduct_Service_Package =
      this.GetProduct_Service_Package.bind(this);
    this.PopulateFields = this.PopulateFields.bind(this);
    this.PopulateTableData = this.PopulateTableData.bind(this);
    this.ClearField = this.ClearField.bind(this);
    this.ClearPage = this.ClearPage.bind(this);
    this.ClearProductRate = this.ClearProductRate.bind(this);
    this.ClearProductInfo = this.ClearProductInfo.bind(this);
    this.DeleteProduct = this.DeleteProduct.bind(this);
    this.CancelFunc = this.CancelFunc.bind(this);
    this.ProductCalculation = this.ProductCalculation.bind(this);
    this.AddProductFunc = this.AddProductFunc.bind(this);
    this.SubmitFunc = this.SubmitFunc.bind(this);
    this.PopulateTableData = this.PopulateTableData.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("AddPackage");

    this.GetProduct_Service_Package();
  }

  GetProduct_Service_Package() {
    var self = this;

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        site: GetCurrentSite(),
      }),
      contentType: "application/json",
      dataType: "json",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/ProductServiceInventoryPackageList",

      async: false,
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });

        console.log("PRODUCT PRODUCT SERVICE PACKAGE LIST :", data);

        self.PopulateFields(data);
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  PopulateFields(data) {
    productList = [];
    packageList = [];

    productList = data.productList;
    packageList = data.packageList;

    var productOptions = [];
    var packageOptions = [];

    this.state.productOptions = [];
    this.state.packageOptions = [];

    $.each(productList, function (i, item) {
      productOptions.push({ value: item.productId, label: item.productName });
    });

    /* var subPackageList = _.uniq(
      packageList,
      "packageName"
    );
    */

    $.each(packageList, function (i, item) {
      packageOptions.push({ value: item.packageId, label: item.packageName });
    });

    this.state.productOptions = productOptions;
    this.state.packageOptions = packageOptions;
    this.setState({
      productOptions: this.state.productOptions,
      packageOptions: this.state.packageOptions,
    });
  }

  handleChangeSelectPackage = (newValue, actionMeta) => {
    /*console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    console.groupEnd(); */

    console.log("SELECT PACKAGE :", newValue);
    //  //console.log("SELECT CUSTOMER MOBILE NO:",newValue.value);

    console.log(
      "GetCurrentSite() :",
      JSON.stringify(GetCurrentSite()).toString()
    );

    var self = this;

    this.ClearProductInfo();
    this.ClearProductRate();

    if (newValue != null) {
      var packageCheck = _.where(packageList, { packageId: newValue.value });

      console.log("packageCheck :", packageCheck);
      if (packageCheck[0].site == GetCurrentSite()) {
        this.ClearPage();
        AlertMessageInfoComponent(
          "center",
          "warning",
          "The package " +
            newValue.label +
            " already exist in the current location",
          false,
          "3000"
        );
      } else {
        this.state.selectedPackage = newValue;
        this.state.packageId = newValue.value;
        this.state.packageCreateStatus = "Not_Created";

        this.setState({
          selectedPackage: this.state.selectedPackage,
          packageId: this.state.packageId,
          packageCreateStatus: this.state.packageCreateStatus,
        });
      }
    }
  };

  handleInputChangePackage = (inputValue, actionMeta) => {
    // alert("handleInputChangeCustomer");

    console.group("Input Changed");
    console.log(inputValue);
    //   console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    var self = this;

    var validatedPackageName = CapitalCaseFunc(inputValue);
    this.state.selectedPackage = {
      value: validatedPackageName,
      label: validatedPackageName,
    };
    this.state.packageCreateStatus = "Created";

    this.setState({
      selectedPackage: this.state.selectedPackage,
      packageCreateStatus: this.state.packageCreateStatus,
    });
    this.ClearProductInfo();
    this.ClearProductRate();
  };

  handleChangeSelectProduct = (newValue, actionMeta) => {
    /*console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    console.groupEnd(); */

    console.log("SELECT PRODUCT :", newValue);
    //  //console.log("SELECT CUSTOMER MOBILE NO:",newValue.value);

    var self = this;

    if (newValue != null) {
      this.state.selectedProduct = newValue;
      this.state.productId = newValue.value;

      var productInfo = _.where(productList, {
        productId: this.state.productId,
      });

      console.log("productInfo :", productInfo);

      this.state.productName = productInfo[0].productName;
      this.state.rateWithOutTax = productInfo[0].productRate;
      this.state.cgst = productInfo[0].cgst;
      this.state.sgst = productInfo[0].sgst;
      this.state.igst = productInfo[0].igst;

      this.setState({
        selectedProduct: this.state.selectedProduct,
        productId: this.state.productId,

        productName: this.state.productName,
        rateWithOutTax: this.state.rateWithOutTax,
        cgst: this.state.cgst,
        sgst: this.state.sgst,
        igst: this.state.igst,
      });
      this.ProductCalculation("rateWithOutTax");
    } else {
      this.ClearProductInfo();
      this.ClearProductRate();
    }
  };

  ClearField() {
    var self = this;
    self.state.selectedPackage = [];
    self.state.packageId = "";
    self.state.packageCreateStatus = "Not_Created";

    self.setState({
      selectedPackage: self.state.selectedPackage,
      packageId: self.state.packageId,
      packageCreateStatus: self.state.packageCreateStatus,
    });

    this.ClearProductInfo();
    this.ClearProductRate();
  }

  ClearProductInfo() {
    var self = this;
    self.state.selectedProduct = [];
    self.state.productId = "";

    self.setState({
      selectedProduct: self.state.selectedProduct,
      productId: self.state.productId,
    });
  }

  ClearProductRate() {
    var self = this;
    self.state.cgst = 0;
    self.state.sgst = 0;
    self.state.igst = 0;

    self.state.rateWithOutTax = 0;
    self.state.rateWithTax = 0;

    self.setState({
      cgst: self.state.cgst,
      sgst: self.state.sgst,
      igst: self.state.igst,

      rateWithOutTax: self.state.rateWithOutTax,
      rateWithTax: self.state.rateWithTax,
    });
  }

  ClearPage() {
    var self = this;
    self.ClearField();
    self.ClearProductInfo();
    self.ClearProductRate();

    self.state.data = [];
    self.state.totalPackageRate = Truncate_2DecimalPlaces(parseFloat(0));

    self.setState({
      data: self.state.data,
      totalPackageRate: self.state.totalPackageRate,
    });
  }

  ProductCalculation(rateType) {
    /* CHECKING IF TAX VAUES ARE NULL -- 
   IF IT'S NULL ASSIGN 0 ELSE ASSIGN ORIGINAL VALUE
   */
    this.state.cgst = this.state.cgst != null ? this.state.cgst : 0;
    this.state.sgst = this.state.sgst != null ? this.state.sgst : 0;
    this.state.igst = this.state.igst != null ? this.state.igst : 0;

    if (rateType == "rateWithOutTax") {
      var rateWithOutTax = this.state.rateWithOutTax;

      var rate = Truncate_2DecimalPlaces(
        (Number(rateWithOutTax) *
          (100 + Number(this.state.cgst) + Number(this.state.sgst))) /
          100
      );

      this.state.rateWithTax = rate;
      this.setState({
        rateWithTax: this.state.rateWithTax,
      });
    } else if (rateType == "rateWithTax") {
      var rateWithTax = this.state.rateWithTax;

      var rate = Truncate_2DecimalPlaces(
        Number(rateWithTax * 100) /
          Number(
            Number(100) + Number(this.state.cgst) + Number(this.state.sgst)
          )
      );

      this.state.rateWithOutTax = rate;
      this.setState({
        rateWithOutTax: this.state.rateWithOutTax,
      });
    }
  }

  AddProductFunc() {
    var self = this;
    if (
      this.state.selectedPackage != "" &&
      this.state.selectedProduct != "" &&
      this.state.rateWithOutTax !== "" &&
      this.state.rateWithTax != ""
    ) {
      var productCheck = _.where(this.state.data, {
        ProductId: this.state.productId,
      });
      console.log("productCheck :", productCheck);

      if (productCheck.length == 0) {
        var count = 0;
        var tableData = [];
        var recentData = {
          SNO: count,
          ProductId: this.state.productId,
          Name: this.state.productName,
          Rate: this.state.rateWithOutTax,
          CGST: this.state.cgst,
          SGST: this.state.sgst,
          IGST: this.state.igst,
          TotalRate: this.state.rateWithTax,
        };

        this.state.data.unshift(recentData);
        this.setState({
          data: this.state.data,
        });

        tableData = [...this.state.data];

        this.PopulateTableData(tableData);
      } else {
        AlertMessageInfoComponent(
          "center",
          "warning",
          "Product already exist",
          false,
          "3000"
        );
      }
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly fill-in all mandatory fields",
        false,
        "3000"
      );
    }

    this.ClearProductInfo();
    this.ClearProductRate();
  }

  onTrRowClick = (state, rowInfo, column, instance) => {
    var self = this;

    if (typeof rowInfo !== "undefined") {
      return {
        onClick: (e, handleOriginal) => {
          //  var column=column;

          this.setState({
            selected: rowInfo.index,
          });
          if (handleOriginal) {
            handleOriginal();
          }

          console.log("ROW INFO :", rowInfo);

          this.state.rowIndexValue = rowInfo.index;

          this.setState({
            rowIndexValue: this.state.rowIndexValue,
          });
        },
        style: {
          background: rowInfo.index === this.state.selected ? "lightgrey " : "",
          color: rowInfo.index === this.state.selected ? "black" : "",
        },
      };
    } else {
      return {
        onClick: (e, handleOriginal) => {
          if (handleOriginal) {
            handleOriginal();
          }
        },
      };
    }
  };

  SubmitFunc() {
    var self = this;

    console.log(
      "SUBMIT DATA :",
      JSON.stringify({
        companyId: this.state.companyId,
        site: GetCurrentSite(),
        packageName: this.state.selectedPackage.label,
        data: JSON.stringify(this.state.data),
        packageId: this.state.selectedPackage.value,
      })
    );

    if (this.state.data.length > 0) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: this.state.companyId,
          site: GetCurrentSite(),
          packageName: this.state.selectedPackage.label,
          data: JSON.stringify(this.state.data),
          packageId: this.state.selectedPackage.value,
          packageCreateStatus: this.state.packageCreateStatus,
        }),
        contentType: "application/json",
        dataType: "json", //15.206.129.105:8080
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/AddProductServicePackage",
        async: false,
        crossDomain: true,
        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
          self.PopulateFields(data);
          if (data.response == "Package_Added") {
            AlertMessageInfoComponent(
              "center",
              "success",
              "The package " +
                self.state.selectedPackage.label +
                " is added successfully",
              false,
              "3000"
            );
            self.CancelFunc();
          } else {
            AlertMessageInfoComponent(
              "center",
              "warning",
              "Failed to add the package " +
                self.state.selectedPackage.label +
                ", try after sometime",
              false,
              "3000"
            );
          }
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "No data to submit",
        false,
        "3000"
      );
    }
  }

  CancelFunc() {
    var self = this;
    self.ClearField();
    self.ClearPage();
  }

  PopulateTableData(tableData) {
    var self = this;
    console.log("tableData :", tableData);
    this.state.data = [];
    this.state.totalPackageRate = 0;

    this.setState({
      data: this.state.data,
      totalPackageRate: this.state.totalPackageRate,
    });

    var count = 0;

    $.each(tableData, function (i, item) {
      count = Number(count) + Number(1);
      self.state.data[i] = {
        SNO: count,
        ProductId: item.ProductId,
        Name: item.Name,
        Rate: item.Rate,
        CGST: item.CGST,
        SGST: item.SGST,
        IGST: item.IGST,
        TotalRate: item.TotalRate,
      };

      self.state.totalPackageRate = Truncate_2DecimalPlaces(
        parseFloat(self.state.totalPackageRate) + parseFloat(item.TotalRate)
      );

      self.setState({
        totalPackageRate: self.state.totalPackageRate,
      });
    });
  }

  DeleteProduct() {
    var self = this;

    var data = self.state.data;
    self.state.data = [];

    data.splice(this.state.rowIndexValue, 1);

    self.PopulateTableData(data);

    console.log("DeleteProduct AFTER SPLICE EMPTY :", self.state.data);

    console.log("DeleteProduct AFTER SPLICE :", data);

    self.state.selected = -1;
    self.setState({
      selected: self.state.selected,
    });

    console.log("DeleteProduct AFTER SPLICE self.state.data:", self.state.data);
  }

  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  render() {
    return (
      <div className="container-fluid">
        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
        <div className="two_HeaderCls">
          <Double_BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Add Package</h3>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Package<span class="mandatory-fields">*</span>
            </label>

            <CreatableSelectField
              multiSelectValue={false}
              onChange={this.handleChangeSelectPackage}
              onCreateOption={this.handleInputChangePackage}
              creatableSelectOpions={this.state.packageOptions}
              selectedOption={this.state.selectedPackage}
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Product/Service<span class="mandatory-fields">*</span>
            </label>

            <CreatableSelectField
              multiSelectValue={false}
              onChange={this.handleChangeSelectProduct}
              creatableSelectOpions={this.state.productOptions}
              selectedOption={this.state.selectedProduct}
              isValidNewOption={() => false}
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker">
              Rate Exclusive of Tax <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="Rate Exclusive of Tax"
              className="form-control"
              name="rateWithOutTax"
              value={this.state.rateWithOutTax}
            />
          </div>

          <div className="col-md-3">
            <label className="control-label selectpicker">
              CGST(%) <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="CGST"
              className="form-control"
              name="cgst"
              value={this.state.cgst}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker">
              SGST(%) <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="SGST"
              className="form-control"
              name="sgst"
              value={this.state.sgst}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker">
              IGST(%)<span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="IGST"
              className="form-control"
              name="igst"
              value={this.state.igst}
              readOnly
            />
          </div>

          <div className="col-md-3">
            <label className="control-label selectpicker">
              Rate Inclusive of Tax <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              readOnly
              placeholder="Rate Inclusive of Tax"
              className="form-control"
              value={this.state.rateWithTax}
              name="rateWithTax"
            />
          </div>
          <div className="col-md-3">
            <div className="text-center" style={{ padding: "25px 0 0 0" }}>
              <button
                style={{ fontWeight: "bold" }}
                type="button"
                onClick={() => this.AddProductFunc()}
                className="btn btn-default btnfirst"
              >
                Add
              </button>
              <span></span>
              <button
                style={{ fontWeight: "bold" }}
                type="button"
                onClick={() => this.ClearField()}
                className="btn btn-default"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="text-center"
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SubmitButtonComponent
              onClick={this.SubmitFunc}
              style={{ width: "100px" }}
            />
            <CancelButtonComponent
              onClick={this.CancelFunc}
              style={{ width: "100px" }}
            />
          </div>
        </div>

        <div className="inv_list_cls">
          <h3>Package Rate:{this.state.totalPackageRate}</h3>
          <div className="inline-flex">
            <DeleteIcons onInvoiceDelete={this.DeleteProduct} />
          </div>
        </div>

        <div id="tableOverflow" className="hideContent">
          <ReactTableWithOnClick
            data={this.state.data}
            columns={this.state.columns}
            pageSize={this.state.pageSize}
            onTrRowClick={this.onTrRowClick}
          />
        </div>
      </div>
    );
  }
}

export default AddPackage;
