//IMPORT FOR REACT NPM
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import _ from "underscore";
import currencyFormatter from "currency-formatter";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CryptoJS from "crypto-js";
import moment from "moment-timezone";

//IMPORT FOR REACT CLASS
import { ContactNumberValidation } from "../ServiceRegistration/ValidationComponent";
import AccountSetup from "./AccountSetup";
import AddressProfiles from "./AddressProfiles";
import Confirm from "./Confirm";
import Success from "./Success";
import LoginPage from "../LoginPage";
import LoadingComponent from "../Loading/LoadingComponent";
import OTPSignUp from "../OTPSignUp";
const SiteLocation = lazy(() => import("../SiteLocation"));

const ct = require("countries-and-timezones");
var currencies;
var currencyArray = [];
var planData = [];
export class Form extends Component {
  state = {
    step: 1,
    emailId: "",
    contactNo: "",
    companyId: "",
    companyName: "",
    planName: "",
    currencyOption: "",
    currencyOptions: [],
    contactNoMessage: "",
    contactNoColor: "",
    doorNo: "",
    floor: "",
    street: "",
    city: "",
    area: "",
    state: "",
    zipCode: "",
    pincode: "",
    timeZone: "",
    selectedTimeZone: "",
    contactNoErr: "",
    planOption: "",
    currencyCode: "",
    formErrors: {
      companyNameErr: "",
      emailIdErr: "",
      planNameErr: "",
      doorNoErr: "",
      floorErr: "",
      streetErr: "",
      cityErr: "",
      areaErr: "",
      stateErr: "",
      zipCodeErr: "",
    },
    companyNameValid: false,
    emailIdValid: false,
    contactNoValid: false,
    planNameValid: false,
    doorNoValid: true,
    floorValid: true,
    streetValid: true,
    cityValid: true,
    areaValid: true,
    stateValid: true,
    zipCodeValid: true,
    timeZoneValid: false,
    regionValid: false,
    currencyValid: true,

    formValid: false,
    addressFormValid: false,
  };
  componentDidMount() {
    console.log("Account Setup");
    //SetCurrentPage("SiteRegister");
    this.GetCountry();

    this.state.currencyOptions = [];

    currencies = currencyFormatter.currencies;
    //console.log("currencies :", currencies);

    if (currencies.length != 0) {
      $.each(currencies, function (i, item) {
        currencyArray.push({
          label: item.code + " - " + item.symbol,
          value: item.code,
        });
      });
      this.state.currencyOptions = currencyArray;
    }

    this.setState({
      currencyOptions: this.state.currencyOptions,
    });

    var currentCurrencyFormatChosen = _.findWhere(currencies, { code: "ARS" });
    //console.log("currentCurrencyFormatChosen DID MOUNT :", currentCurrencyFormatChosen);
    this.state.currencyCode = "ARS";
    this.state.currencyOption = { label: "ARS" + " - " + "$", value: "ARS" };
    this.SetCurrencyValue(currentCurrencyFormatChosen);

    //GET THE PLAN INFO
    this.GetPlanInfo();
  }
  /*
FUNCTION USED TO SELECT THE REGION
*/
  handleUserInputRegion = (e) => {
    var self = this;

    const value = e.value;
    this.setState(
      {
        region: value,
        selectedRegion: e,
        valid: true,
        timeZone: "",
        regionValid: true,
      },
      this.validateForm
    );

    const Timezones = ct.getTimezonesForCountry(value);
    var timeZoneOptions = [];
    for (var i = 0; i < Timezones.length; i++) {
      timeZoneOptions.push({
        label: Timezones[i].name,
        value: Timezones[i].name,
      });
    }

    self.state.timeZoneOptions = timeZoneOptions;
    self.setState({
      timeZoneOptions: timeZoneOptions,
    });
  };
  /*
FUNCTION USED TO SELECT THE TIME ZONE
*/
  handleUserInputTimeZone = (e) => {
    var self = this;

    //const value = e.value;
    self.state.timeZone = e.value;
    self.setState(
      {
        timeZone: self.state.timeZone,
        selectedTimeZone: e,
        valid: true,
        timeZoneValid: true,
      },
      this.validateForm
    );

    console.log("timeZone", self.state.timeZone);
  };
  /*
CODE FOR SELECTING THE PLAN
*/
  handleChangeSelectedPlan = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.planOption = e;

    this.state.planName = e.label;
    this.state.planId = e.value;
    this.setState(
      {
        planName: this.state.planName,
        planId: this.state.planId,
        planNameValid: true,
      },
      this.validateForm
    );

    this.state.planExplanation = "";
    var currentPlanData = _.where(planData, { planId: this.state.planId });

    this.state.planExplanation = currentPlanData[0].explanation;

    if (
      this.state.planExplanation == undefined ||
      this.state.planExplanation == null
    ) {
      this.state.planExplanation = "";
    }
    this.setState({
      planName: this.state.planName,
      planId: this.state.planId,
      planExplanation: this.state.planExplanation,
    });

    this.handleUserPackageInput();
  };
  handleUserPackageInput = (e) => {
    //  const name = e.target.name;
    //   const value = e.target.value;

    this.setState({
      planName: this.state.planName,
      permission:
        "Jobcard," +
        "SaleInvoice," +
        "EstimateInvoice,CustomerPayment," +
        "ProductService,Vendor," +
        "PurchaseInvoice,Vehicle," +
        "Customer,VehicleMakeandModel,AddExpense,AddEnquiry,AddBookService," +
        "ServiceCalendar,ServiceConfirmation," +
        "EmployeeDetails,AddRole,Salary,SalaryReport," +
        "BankDetails,CheckInOut,ManualAttendance,Report,Reports,SalesReport,EstimateReport," +
        "ExpenseReport,PurchaseReport,MessageCenterReport,ProfitandLossReport," +
        "InventoryReport,AuditReport,BillDueReport,EnquiryReport,FeedBackReport," +
        "PaymentHistoryReport,BookedServiceReports," +
        "PaymentReceivablesReports,OfferMessages,Emails,Configuration," +
        "TaskMapping,Location,CompanySetting,ImportQRcode,ImportLogo,ExportExcel,ImportExcel",
      permissionHeader:
        "JobCard,Inventory,CRM,Expense,Enquiry,BookService,ServiceCalendar," +
        "ServiceConfirmation,Employee,Bank,Attendance,Reports,Communication,Configuration,TaskMapping",
    });
  };
  /*
CODE FOR CURRENCY DROPDOWN
*/
  handleUserInputCurrency = (e) => {
    var self = this;

    //console.log("E VALUE :", e);

    this.state.currencyOption = e;
    this.setState(
      {
        currencyOption: this.state.currencyOption,
        currencyValid: true,
      },
      this.validateForm
    );
    var currentCurrencyFormatChosen = _.findWhere(currencies, {
      code: e.value,
    });
    //console.log("currentCurrencyFormatChosen :", currentCurrencyFormatChosen);

    this.state.currencyCode = e.value;
    this.SetCurrencyValue(currentCurrencyFormatChosen);
  };
  SetCurrencyValue(currentCurrencyFormatChosen) {
    this.state.infoCurrencyInputVal = 10000;
    var infoC_ForamatterVal = currencyFormatter.format(
      this.state.infoCurrencyInputVal,
      { code: currentCurrencyFormatChosen.code }
    );

    //console.log("currencyFormatter", currencyFormatter, currentCurrencyFormatChosen.code, infoC_ForamatterVal)

    this.state.infoCurrencyInputVal = this.state.infoCurrencyInputVal;
    this.state.infoCurrencyCode = currentCurrencyFormatChosen.code;
    this.state.infoCurrencySymbol = currentCurrencyFormatChosen.symbol;
    this.state.infoC_ForamatterVal = infoC_ForamatterVal;

    this.setState({
      infoCurrencyInputVal: this.state.infoCurrencyInputVal,
      infoCurrencyCode: this.state.infoCurrencyCode,
      infoCurrencySymbol: this.state.infoCurrencySymbol,
      infoC_ForamatterVal: this.state.infoC_ForamatterVal,
    });

    //console.log("SetCurrencyValue ...... this.state :", this.state);
  }
  GetPlanInfo() {
    var self = this;
    $.ajax({
      type: "POST",
      data: JSON.stringify({}),
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/SalesPlan/SelectSalesPlanDetails",
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        console.log("data :", data);
        planData = data.salesPlanList;
        var applicablePlanList = _.where(planData, { status: "0" });
        var planOptions = [];

        $.each(applicablePlanList, function (i, item) {
          planOptions.push({ label: item.planName, value: item.planId });
        });
        self.state.planOptions = planOptions;
        self.setState({
          planOptions: self.state.planOptions,
        });
      },
      error: function (data) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }
  /*
FUNCTION USED TO LIST THE COUNTRY NAMES
*/
  GetCountry() {
    var self = this;

    const countries = ct.getAllCountries();

    var groupedData = _.groupBy(countries, "id");

    var partionedData = _.partition(groupedData, ",")[1];

    var options = [];
    var tabdata = "";
    for (var z = 0; z < _.size(partionedData); z++) {
      var partionedData1 = partionedData[z];
      options.push({
        label: partionedData1[0].name,
        value: partionedData1[0].id,
      });
    }

    self.state.options = options;
    self.setState({
      options: options,
    });
  }
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };
  /*
FUNCTION USED TO HANDLE THE USERINPUT
*/
  handleUserInput = (input) => (e) => {
    console.log("handleUserInput-1");
    this.setState({ [input]: e.target.value }, () => {
      this.validateField(input, e.target.value);
    });
  };
  /*
FUNCTION USED TO VALIDATE THE USER INPUT
*/
  validateField(fieldName, value) {
    console.log("name", value);
    let fieldValidationErrors = this.state.formErrors;
    let companyNameValid = this.state.companyNameValid;
    let emailIdValid = this.state.emailIdValid;
    let contactNoValid = this.state.contactNoValid;
    let planNameValid = this.state.planNameValid;
    let doorNoValid = this.state.doorNoValid;
    let floorValid = this.state.floorValid;
    let streetValid = this.state.streetValid;
    let cityValid = this.state.cityValid;
    let areaValid = this.state.areaValid;
    let stateValid = this.state.stateValid;
    let zipCodeValid = this.state.zipCodeValid;

    switch (fieldName) {
      case "companyName":
        // companyNameValid = value.length > 0;
        // fieldValidationErrors.companyName = companyNameValid ? '' : ' is InCorrect';
        console.log("handleUserInput-2");
        if (value.length == 0) {
          fieldValidationErrors.companyNameErr = "";
          companyNameValid = false;
        } else if (value.length > 99) {
          fieldValidationErrors.companyNameErr =
            "Sorry! You are Exceeding the Limit";
          companyNameValid = false;
        } else if (value.length < 3) {
          fieldValidationErrors.companyNameErr =
            "Should Contain Atleast 3 Characters";
          companyNameValid = false;
        } else {
          companyNameValid = value.match(/^[a-zA-Z]([a-zA-Z0-9]|[- @\.#&!])*$/);
          fieldValidationErrors.companyNameErr = companyNameValid
            ? ""
            : "Invaild format";
          //'Sorry You are Exceeding the Limit
        }
        break;

      case "emailId":
        emailIdValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.emailIdErr = emailIdValid ? "" : "Invaild format";
        break;

      //   case 'contactNo'://mobile no validation code
      //     // contactNoValid = value.match(/^[0-9]{10}$/);
      //     // fieldValidationErrors.contactNoErr = contactNoValid ? '' : ' should be 10 digit number';
      //     this.handleOnChange();
      //     break;

      case "planName":
        console.log("planName");
        planNameValid = value.length >= 1;
        fieldValidationErrors.planNameErr = planNameValid
          ? ""
          : " is too short";
        break;
      case "doorNo":
        doorNoValid = value.length < 20;
        fieldValidationErrors.doorNoErr = doorNoValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "floor":
        floorValid = value.length < 20;
        fieldValidationErrors.floorErr = floorValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "street":
        streetValid = value.length < 50;
        fieldValidationErrors.streetErr = streetValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "area":
        areaValid = value.length < 50;
        fieldValidationErrors.areaErr = areaValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "city":
        cityValid = value.length < 50;
        fieldValidationErrors.cityErr = cityValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "state":
        stateValid = value.length < 50;
        fieldValidationErrors.stateErr = stateValid
          ? ""
          : "Sorry! You are Exceeding the Limit";
        break;
      case "zipCode":
        zipCodeValid = value.match(/^[0-9]{6}$/);
        fieldValidationErrors.zipCodeErr = zipCodeValid
          ? ""
          : "Should be 6 digit number";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        companyNameValid: companyNameValid,
        emailIdValid: emailIdValid,
        contactNoValid: contactNoValid,
        planNameValid: planNameValid,
        doorNoValid: doorNoValid,
        stateValid: stateValid,
        cityValid: cityValid,
        areaValid: areaValid,
        floorValid: floorValid,
        zipCodeValid: zipCodeValid,
      },
      this.validateForm
    );
  }
  /*
FUNCTION USED FOR BUTTON DISABLE
*/
  validateForm() {
    console.log("handleUserInput-3");
    this.setState({
      formValid:
        this.state.companyNameValid &&
        this.state.emailIdValid &&
        this.state.contactNoValid &&
        this.state.planNameValid &&
        this.state.currencyValid,
      addressFormValid:
        this.state.zipCodeValid &&
        this.state.floorValid &&
        this.state.areaValid &&
        this.state.doorNoValid &&
        this.state.stateValid &&
        this.state.cityValid &&
        this.state.streetValid &&
        this.state.timeZoneValid &&
        this.state.regionValid,
    });
  }
  /*
FUNCTION USED TO VALIDATE THE CONTACT NO BASED ON COUNTRY CODE
*/
  handleOnChange = (value) => {
    console.log("vLUE", "e", value, "contactno", this.state.contactNo);

    if (value == undefined || value == null || value == "") {
      console.log(
        "contactNoErr",
        this.state.contactNoErr,
        "contactNoColor",
        this.state.contactNoColor
      );
      this.setState(
        { contactNoErr: "", contactNoColor: "", contactNoValid: false },
        this.validateForm
      );
      console.log(
        "aftr contactNoErr",
        this.state.contactNoErr,
        "contactNoColor",
        this.state.contactNoColor
      );
    } else {
      var validationData = ContactNumberValidation(value); //value holding contactno with country code ex:+918765678987
      console.log("validationData", validationData);
      this.state.contactNoErr = validationData.message;
      this.state.contactNoColor = validationData.color;
      this.setState({
        contactNoErr: this.state.contactNoErr,
        contactNoColor: this.state.contactNoColor,
        contactNo: value,
      });
      console.log(
        "contactNoErr",
        this.state.contactNoErr,
        "contactNoColor",
        this.state.contactNoColor
      );

      if (validationData.message == "Phone Number is Valid") {
        this.setState({ contactNoValid: true }, this.validateForm);
      } else {
        this.setState({ contactNoValid: false }, this.validateForm);
      }
    }
    console.log("contact no end vali");
  };
  GetTimeZoneDate(offset) {
    var todayDate = new Date(new Date().getTime() + offset * 3600 * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");

    var d1 = new Date(todayDate);
    var d2 =
      d1.getFullYear() +
      "-" +
      ("0" + (d1.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d1.getDate()).slice(-2);

    return d2;
  }
  /*
FUNCTION USED TO STORE THE COMPANY REGISTRATION DETAILS
*/
  SiteRegistrationFun = () => {
    var self = this;
    var key = "shinchanbaby";

    var companyZone = self.state.timeZone;
    var offset = moment.tz(moment.utc(), companyZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS

    var todayDate = self.GetTimeZoneDate(offsetValue);

    var hostedZone = "Asia/Kolkata";
    //var hostedZone="ohio";
    var offset = moment.tz(moment.utc(), hostedZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS

    var decDate = self.GetTimeZoneDate(offsetValue); // EX:2019-12-25

    // var decDateConstraint=decDate.split("-")[0]+"-"+"12"+"-"+"22";

    var hostedYear = decDate.split("-")[0];

    //new Date(year, month, date, hours, minutes, seconds, ms)
    var decDateConstraint = new Date(hostedYear, 11, 28, 23, 30, 0, 0);
    decDate = new Date(decDate);
    decDateConstraint = new Date(decDateConstraint);

    var startDate = new Date(todayDate);
    var endDate = new Date(decDate);

    if (decDate >= decDateConstraint) {
      // if( startDate >= endDate){
      self.state.holidayTableNextYear = "Yes";
      //   alert("YES CREATE TABLE FOR FUTURE");
      //   }
    } else {
      //  alert("DON'T CREATE TABLE FOR FUTURE");
    }

    //  //console.log("PINCODE BEFORE CHECK :",self.state.pincode);
    if (self.state.pincode == "") {
      self.state.pincode = "000000";
    }
    if (self.state.zipCode == "") {
      self.state.zipCode = "000000";
    }

    self.setState({
      zipCode: self.state.zipCode,
    });

    //  //console.log("PINCODE AFTER CHECK :",self.state.pincode);

    localStorage.setItem(
      "companyName",
      CryptoJS.AES.encrypt(self.state.companyName, key)
    );

    localStorage.setItem(
      "CurrencyCode",
      CryptoJS.AES.encrypt(self.state.currencyCode, key)
    );
    localStorage.setItem(
      "EmailId",
      CryptoJS.AES.encrypt(self.state.emailId, key)
    );
    localStorage.setItem(
      "contactNo",
      CryptoJS.AES.encrypt(self.state.contactNo, key)
    );
    localStorage.setItem(
      "Password",
      CryptoJS.AES.encrypt(self.state.password, key)
    );
    //  localStorage.setItem('Address', CryptoJS.AES.encrypt(self.state.address, key));
    localStorage.setItem(
      "planName",
      CryptoJS.AES.encrypt(self.state.planName, key)
    );
    localStorage.setItem(
      "planId",
      CryptoJS.AES.encrypt(self.state.planId, key)
    );
    localStorage.setItem(
      "permission",
      CryptoJS.AES.encrypt(self.state.permission, key)
    );
    localStorage.setItem(
      "permissionHeader",
      CryptoJS.AES.encrypt(self.state.permissionHeader, key)
    );
    localStorage.setItem(
      "DoorNo",
      CryptoJS.AES.encrypt(self.state.doorNo, key)
    );
    localStorage.setItem("Floor", CryptoJS.AES.encrypt(self.state.floor, key));
    localStorage.setItem(
      "Street",
      CryptoJS.AES.encrypt(self.state.street, key)
    );
    localStorage.setItem("City", CryptoJS.AES.encrypt(self.state.city, key));
    localStorage.setItem("Area", CryptoJS.AES.encrypt(self.state.area, key));
    localStorage.setItem(
      "Zipcode",
      CryptoJS.AES.encrypt(self.state.zipCode, key)
    );
    localStorage.setItem(
      "Pincode",
      CryptoJS.AES.encrypt(self.state.pincode, key)
    );
    localStorage.setItem("State", CryptoJS.AES.encrypt(self.state.state, key));

    localStorage.setItem("Date", CryptoJS.AES.encrypt(todayDate, key));
    localStorage.setItem(
      "TimeZone",
      CryptoJS.AES.encrypt(self.state.timeZone, key)
    );
    localStorage.setItem(
      "HolidayTableNextYear",
      CryptoJS.AES.encrypt(self.state.holidayTableNextYear, key)
    );

    var self = this;

    if (
      self.state.timeZone != "" &&
      self.state.timeZone != undefined &&
      self.state.currencyCode != ""
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyName: self.state.companyName,
          emailId: self.state.emailId,
          password: self.state.password,
          contactNo: self.state.contactNo,
          roleName: self.state.roleName,
          doorNo: self.state.doorNo,
          floor: self.state.floor,
          street: self.state.street,
          city: self.state.city,
          area: self.state.area,
          zipCode: self.state.zipCode,
          pincode: self.state.pincode,
          currencyCode: self.state.currencyCode,
          state: self.state.state,

          //    attendanceoption:self.state.attendanceoption,
          //  holidayTableNextYear:self.state.holidayTableNextYear,
          //  timeZone:self.state.timeZone,
          //   date:todayDate,
        }),
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/SiteRegistration/RegisterSite",
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (data, textStatus, jqXHR) {
          console.log("data", data);
          if (data.emailId == "EMAILID") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "The Email Id You Have Entered Is Already Registered For An Organization ",
              showConfirmButton: false,
              timer: 2000,
            });

            /*   self.state.companyName = self.state.companyName;

            self.state.emailId = self.state.emailId;
            self.state.password = self.state.password;
            self.state.contactNo = self.state.contactNo;
            self.state.roleName = self.state.roleName;
            self.state.doorNo = self.state.doorNo;
            self.state.floor = self.state.floor;
            self.state.street = self.state.street;
            self.state.city = self.state.city;
            self.state.area = self.state.area;
            self.state.zipCode = self.state.zipCode;
            self.state.pincode = self.state.pincode;
            self.state.timeZone = self.state.timeZone;
            self.state.planName = self.state.planName;
            self.state.planName = self.state.planId;

            self.setState({
              companyName: self.state.companyName,

              emailId: self.state.emailId,
              password: self.state.password,
              contactNo: self.state.contactNo,
              roleName: self.state.roleName,
              doorNo: self.state.doorNo,
              floor: self.state.floor,
              street: self.state.street,
              city: self.state.city,
              area: self.state.area,
              zipCode: self.state.zipCode,
              pincode: self.state.pincode,
              timeZone: self.state.timeZone,
              date: todayDate,
              region: self.state.selectedRegion,
              planName: self.state.planName,
              planId: self.state.planId,
            });
            */

            console.log("emaild exist err");
          } else if (data.contactNo == "MOBILE") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "The Mobile No You Have Entered Is Already Registered For An Organization", // Message dialog
              showConfirmButton: false,
              timer: 2000,
            });

            /* self.state.companyName = self.state.companyName;

            self.state.emailId = self.state.emailId;
            self.state.password = self.state.password;
            self.state.contactNo = self.state.contactNo;
            self.state.roleName = self.state.roleName;
            self.state.doorNo = self.state.doorNo;
            self.state.floor = self.state.floor;
            self.state.street = self.state.street;
            self.state.city = self.state.city;
            self.state.area = self.state.area;
            self.state.zipCode = self.state.zipCode;
            self.state.pincode = self.state.pincode;
            self.state.timeZone = self.state.timeZone;
            self.state.planName = self.state.planName;
            self.state.planId = self.state.planId;

            self.setState({
              companyName: self.state.companyName,

              emailId: self.state.emailId,
              password: self.state.password,
              contactNo: self.state.contactNo,
              roleName: self.state.roleName,
              doorNo: self.state.doorNo,
              floor: self.state.floor,
              street: self.state.street,
              city: self.state.city,
              area: self.state.area,
              zipCode: self.state.zipCode,
              pincode: self.state.pincode,
              timeZone: self.state.timeZone,
              date: todayDate,
              region: self.state.selectedRegion,
              planName: self.state.planName,
              planId: self.state.planId,
            });
*/
            console.log("mobileno exist err");
          } else if (data.response != "Mailed_Otp") {
            console.log("Mailed_Otp  err");
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "Error Occured While Mailing OTP , Kindly Try Registering Again ", // Message dialog
              showConfirmButton: false,
              timer: 2000,
            });

            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <LoginPage />
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
          } else {
            /* Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Mailed OTP To Your Registered EmailId",
            showConfirmButton: false,
            timer: 2000
          })
          */

            //localStorage.setItem('otp1',data.otp);
            localStorage.setItem(
              "OTP",
              CryptoJS.AES.encrypt(JSON.stringify(data.otp), key)
            );
            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  {/* <SiteLocation /> */}
                  <OTPSignUp />
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
            console.log("success end");
          }
          self.state.timeZone = "";
        },
        error: function (data) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Network Connection Problem",
            showConfirmButton: false,
            timer: 2000,
          });
        },
      });
    } else {
      if (self.state.timeZone == "" || self.state.timeZone == undefined) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Kindly Select TimeZone",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (self.state.currencyCode == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Kindly Select CurrencyCode for your Organization",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  render() {
    /*PARAMETER PASSING TO OTHER COMPONENTS*/
    const { step } = this.state;
    const {
      companyNameErr,
      emailIdErr,
      planNameErr,
      doorNoErr,
      floorErr,
      streetErr,
      cityErr,
      areaErr,
      stateErr,
      zipCodeErr,
    } = this.state.formErrors;

    const {
      companyId,
      companyName,
      emailId,
      contactNo,
      contactNoErr,
      planName,
      currencyOption,
      currencyCode,
      doorNo,
      floor,
      city,
      state,
      street,
      zipCode,
      area,
      country,
      region,
      timeZone,
      currencyOptions,
      currencyOptionplanOption,
      planOptions,
      contactNoColor,
      selectedRegion,
      options,
      timeZoneOptions,
      selectedTimeZone,
      formValid,
      addressFormValid,
      planOption,
    } = this.state;
    const values = {
      companyNameErr,
      emailIdErr,
      contactNoErr,
      planNameErr,
      doorNoErr,
      floorErr,
      streetErr,
      cityErr,
      areaErr,
      stateErr,
      zipCodeErr,
      companyId,
      companyName,
      emailId,
      contactNo,
      planName,
      currencyOption,
      currencyCode,
      doorNo,
      floor,
      state,
      zipCode,
      city,
      street,
      area,
      country,
      region,
      timeZone,
      currencyOptions,
      currencyOptionplanOption,
      planOptions,
      contactNoColor,
      selectedRegion,
      options,
      timeZoneOptions,
      selectedTimeZone,
      formValid,
      addressFormValid,
      planOption,
    };

    switch (step) {
      case 1:
        return (
          <AccountSetup
            nextStep={this.nextStep}
            values={values}
            handleUserInput={this.handleUserInput}
            handleUserInputCurrency={this.handleUserInputCurrency}
            handleChangeSelectedPlan={this.handleChangeSelectedPlan}
            handleOnChange={this.handleOnChange}
          />
        );
      case 2:
        return (
          <AddressProfiles
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleUserInput={this.handleUserInput}
            handleUserInputRegion={this.handleUserInputRegion}
            handleUserInputTimeZone={this.handleUserInputTimeZone}
            values={values}
          />
        );
      case 3:
        return (
          <Confirm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            SiteRegistrationFun={this.SiteRegistrationFun}
          />
        );
      case 4:
        return <Success />;
    }
  }
}

export default Form;
