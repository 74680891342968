//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";

import * as XLSX from "xlsx";

export const DownloadExcelWithoutSummary = function (
  xlsMainHeader,
  xlsColumnHeader,
  xlsRowsData,
  fileName,
  sheetName,
  columnWidth
) {
  alert("DownloadExcelWithoutSummary");

  console.log("xlsRowsData :",xlsRowsData);

  var createXLSLFormatObj = [];

  var xlsMainHeader = xlsMainHeader;

  createXLSLFormatObj.push(xlsMainHeader);

  var emptyRowData = [];
  createXLSLFormatObj.push(emptyRowData);
  createXLSLFormatObj.push(emptyRowData);

  /* XLS Head Columns */
  var xlsColumnHeader = xlsColumnHeader;

  createXLSLFormatObj.push(xlsColumnHeader);

  var indexValue = 0;
  var listInnerRowData = [];

  for (var z = 0; z < xlsRowsData.length; z++) {
    if (xlsRowsData[z] != "+") {
        console.log("xlsRowsData[z] :",xlsRowsData[z]);
      listInnerRowData.push(xlsRowsData[z]);
    } else {
      createXLSLFormatObj.push(listInnerRowData);
      listInnerRowData = [];
    }
  }

  /* File Name */
  var filename = fileName;

  /* Sheet Name */
  var ws_name = sheetName;

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  var newIndexValue = Number(indexValue) + 4;
  newIndexValue = Number(newIndexValue) / 11;
  newIndexValue = Math.round(newIndexValue);
  newIndexValue = Number(newIndexValue) + 3;

  /* merge cells A1:B1 */
  var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 28 } };

  /* merge cells A1:B1 */
  /*var merge1 = {
    s: { r: newIndexValue, c: 0 },
    e: { r: newIndexValue, c: 35 },
  };
  */

  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  /* add merges */
  /* if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge1);
*/
  /* set column width */
  var wscols = columnWidth;

  /* set row height */
  var wsrows = [
    { hpt: 25 }, // "points"
    //	{hpx: 16}, // "pixels"
    //	{hpx: 24, level:3},
    //	{hidden: true}, // hide row
    //	{hidden: false}
    ,
  ];

  /*add column width */
  ws["!cols"] = wscols;

  /* add row height */
  ws["!rows"] = wsrows;

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  XLSX.writeFile(wb, filename, { cellStyles: true });
};
