//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
//IMPORT REACT ICONS
import ReactDOM from "react-dom";
import registerServiceWorker from "../../../../registerServiceWorker";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import CryptoJS from "crypto-js";
import ReactTable from "react-table";
import _ from "underscore";

//IMPORT CLASS COMPONENT
import DashboardOverall from "../../../../MaincontentDashboard/DashboardOverall";
import { BackButtonComponent } from "../../../../ServiceRegistration/ButtonComponent";
import {
  NetworkErrorComponent,
  NoDataComponent,
} from "../../../../Assets_Components/MessagePage_Components/MessagePage_Components";
import {
  GetCurrentSite,
  GetEmployeeSite,
  OffsetValueCalcFunc,
  SetCurrentPage,
} from "../../../../ConstSiteFunction";
import LoadingComponent from "../../../../Loading/LoadingComponent";
import PackageList from "./PackageList";
import Edit_PackageName_Component, {
  AddItems_To_Package_Component,
  RemoveItems_From_Package_Component,
} from "./EditPackageComponents";


var dataList=[];

class EditPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      site: GetCurrentSite(),
      modalIsOpen: false,
      editPackageTypeSelected: props.module,
      packageId: props.packageId,
      packageName: props.packageName,
    };
  }

  componentDidMount() {
    SetCurrentPage("EditPackage");

    console.log("props :", this.props.module);

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });

    if (
      this.state.editPackageTypeSelected == "AddItems_To_Package" ||
      this.state.editPackageTypeSelected == "RemoveItems_From_Package"
    ) {
      this.GetPackageItems();
    }
  }

  /*
    FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
    IMPLEMENTED BY PRIYANKA - 03-06-2022
    */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  /*
       FUNCTION USED FOR GETTING THE INITIAL DATA REQUIRED FOR POPULATING THE FIELDS - 
       IMPLEMENTED BY PRIYANKA - 04-06-2022
       */
  GetPackageItems() {
    var self = this;
    dataList=[];

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        packageId: this.state.packageId,
      }),
      contentType: "application/json",
      dataType: "json",
      // url: "http://localhost:8080/GarageAppAPI_Cust/master/ProductMigrationDisplayData",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/GetProductServicePackageItems",

      async: false,
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        console.log("PACKAGE VIEW DATA :", data);

        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
        dataList=data.productServicePackageList;
        if(self.state.editPackageTypeSelected=="AddItems_To_Package"){
        window.AddItems_To_Package_Component.PopulateExistingItemsData(dataList);
        }else  if(self.state.editPackageTypeSelected=="RemoveItems_From_Package"){
          window.RemoveItems_From_Package_Component.PopulateExistingItemsData(dataList);
        }

      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  RenderComponenets(selectedModule) {
    switch (selectedModule) {
      case "Edit_Package_Name":
        return <Edit_PackageName_Component stateData={this.state}  />;
      case "AddItems_To_Package":
        return (
          <AddItems_To_Package_Component stateData={this.state} dataList={dataList} />
        );
      case "RemoveItems_From_Package":
        return (
          <RemoveItems_From_Package_Component
            stateData={this.state} dataList={dataList}
          />
        );
    }
  }

  render() {
    return (
      <div className="container-fluid">
        
        {this.RenderComponenets(this.state.editPackageTypeSelected)}

        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
      </div>
    );
  }
}
export default EditPackage;
