//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
//IMPORT REACT ICONS
import ReactDOM from "react-dom";
import registerServiceWorker from "../../../../registerServiceWorker";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import CryptoJS from "crypto-js";
import ReactTable from "react-table";
import _ from "underscore";
import { CreatableSelectField } from "../../../../Assets_Components/Input_Components/Input_Components";

//IMPORT CLASS COMPONENT
import DashboardOverall from "../../../../MaincontentDashboard/DashboardOverall";
import SubmitButtonComponent, {
  BackButtonComponent,
  CancelButtonComponent,
  SubmitProceedButtonComponent,
} from "../../../../ServiceRegistration/ButtonComponent";
import {
  AlertMessageInfoComponent,
  NetworkErrorComponent,
  NoDataComponent,
} from "../../../../Assets_Components/MessagePage_Components/MessagePage_Components";
import {
  GetCurrentSite,
  GetEmployeeSite,
  OffsetValueCalcFunc,
  SetCurrentPage,
} from "../../../../ConstSiteFunction";
import LoadingComponent from "../../../../Loading/LoadingComponent";
import PackageList from "./PackageList";
import {
  ReactTableWithOnClick,
  ReactTableWithOutOnClick,
} from "../../../../Assets_Components/Table_Components/Table_Components";
import { Truncate_2DecimalPlaces } from "../../../../Invoice/InvoiceValidations";
import CapitalCaseFunc from "../../../../ServiceRegistration/CommonTextFormatComponent";

var dataList=[];
export class ViewPackage extends Component {
  constructor(props) {
    super(props);

    window.View_Package_Component = this;

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      site: GetCurrentSite(),
      modalIsOpen: false,

      existingItemsData: [],
      columns: [
        { Header: "SNO", accessor: "SNO" },
        { Header: "ProductId", accessor: "ProductId", show: false },
        { Header: "Name", accessor: "Name" },
        { Header: "Rate", accessor: "Rate" },
        { Header: "CGST", accessor: "CGST" },
        { Header: "SGST", accessor: "SGST" },
        { Header: "IGST", accessor: "IGST" },
        { Header: "Total Rate", accessor: "TotalRate" },
      ],

      pageSize: 10,
      selected: -1,

      rateWithOutTax: 0,
      rateWithTax: 0,

      cgst: 0,
      sgst: 0,
      igst: 0,

      packageId: props.packageId,
      packageName: props.packageName,

      existingTotalPackageRate: 0,
    };
    this.ProductCalculation = this.ProductCalculation.bind(this);
    this.PopulateExistingItemsData = this.PopulateExistingItemsData.bind(this);
    this.GetPackageItems = this.GetPackageItems.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("EditPackage");

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });

    this.GetPackageItems();

    console.log("ADD ITEMS TO PACKAGE PROPS DATA :", this.props);
  }

  GetPackageItems() {
    var self = this;
    dataList = [];

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        packageId: this.state.packageId,
      }),
      contentType: "application/json",
      dataType: "json",
      // url: "http://localhost:8080/GarageAppAPI_Cust/master/ProductMigrationDisplayData",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/GetProductServicePackageItems",

      async: false,
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        console.log("PACKAGE VIEW DATA :", data);

        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
        dataList = data.productServicePackageList;
        self.PopulateExistingItemsData(dataList);
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  ProductCalculation(rateType) {
    /* CHECKING IF TAX VAUES ARE NULL -- 
     IF IT'S NULL ASSIGN 0 ELSE ASSIGN ORIGINAL VALUE
     */
    this.state.cgst = this.state.cgst != null ? this.state.cgst : 0;
    this.state.sgst = this.state.sgst != null ? this.state.sgst : 0;
    this.state.igst = this.state.igst != null ? this.state.igst : 0;

    if (rateType == "rateWithOutTax") {
      var rateWithOutTax = this.state.rateWithOutTax;

      var rate = Truncate_2DecimalPlaces(
        (Number(rateWithOutTax) *
          (100 + Number(this.state.cgst) + Number(this.state.sgst))) /
          100
      );

      this.state.rateWithTax = rate;
      this.setState({
        rateWithTax: this.state.rateWithTax,
      });
    } else if (rateType == "rateWithTax") {
      var rateWithTax = this.state.rateWithTax;

      var rate = Truncate_2DecimalPlaces(
        Number(rateWithTax * 100) /
          Number(
            Number(100) + Number(this.state.cgst) + Number(this.state.sgst)
          )
      );

      this.state.rateWithOutTax = rate;
      this.setState({
        rateWithOutTax: this.state.rateWithOutTax,
      });
    }
  }

  PopulateExistingItemsData(dataList) {
    var self = this;
    console.log("PopulateExistingItemsData  :", dataList);
    this.state.existingItemsData = [];
    this.state.existingTotalPackageRate = 0;

    this.setState({
      existingItemsData: this.state.existingItemsData,
      existingTotalPackageRate: this.state.existingTotalPackageRate,
    });

    var count = 0;

    $.each(dataList, function (i, item) {
      count = Number(count) + Number(1);

      var productInfo = _.where(dataList, {
        productId: item.productId,
      });

      console.log("item.productId, :", item.productId);
      console.log("productInfo :", productInfo);

      self.state.rateWithOutTax = productInfo[0].productRate;
      self.state.cgst = productInfo[0].cgst;
      self.state.sgst = productInfo[0].sgst;
      self.state.igst = productInfo[0].igst;

      self.setState({
        productId: self.state.productId,

        productName: self.state.productName,
        rateWithOutTax: self.state.rateWithOutTax,
        cgst: self.state.cgst,
        sgst: self.state.sgst,
        igst: self.state.igst,
      });

      self.ProductCalculation("rateWithOutTax");

      self.state.existingItemsData[i] = {
        SNO: count,
        ProductId: item.productId,
        Name: item.productName,
        Rate: self.state.rateWithOutTax,
        CGST: self.state.cgst,
        SGST: self.state.sgst,
        IGST: self.state.igst,
        TotalRate: self.state.rateWithTax,
      };

      self.state.existingTotalPackageRate = Truncate_2DecimalPlaces(
        parseFloat(self.state.existingTotalPackageRate) +
          parseFloat(self.state.rateWithTax)
      );

      self.setState({
        existingTotalPackageRate: self.state.existingTotalPackageRate,
      });
    });
  }

  /*
        FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
        IMPLEMENTED BY PRIYANKA - 03-06-2022
        */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>View Package</h3>
        </div>

        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />

        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Package<span class="mandatory-fields">*</span>
            </label>

            {/* <CreatableSelectField
                multiSelectValue={false}
                onChange={this.handleChangeSelectPackage}
                onCreateOption={this.handleInputChangePackage}
                creatableSelectOpions={this.state.packageOptions}
                selectedOption={this.state.selectedPackage}
                readOnly
              /> */}
            <input
              type="text"
              placeholder="Package"
              className="form-control"
              name="packageName"
              value={this.state.packageName}
            />
          </div>
        </div>

        <h3>Package Rate:{this.state.existingTotalPackageRate}</h3>

        <div>
          <div id="tableOverflow" className="hideContent">
            <ReactTableWithOutOnClick
              data={this.state.existingItemsData}
              columns={this.state.columns}
              pageSize={this.state.pageSize}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ViewPackage;
