import React from "react";
import { Loader } from 'rsuite';


export default class LoadingComponent extends  React.Component {

    render() {
        return (
   
    <div>
        <Loader backdrop  size="lg" content="loading..." vertical />
    </div>

        )}

}
