//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Dropdown,
} from "react-pro-sidebar";
import $ from "jquery";
import CryptoJS from "crypto-js";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { IconContext } from "react-icons";
import PureModal from "react-pure-modal";
import _ from "underscore";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SelectSearch from "react-select";
import { Loader } from "rsuite";
import Scrollspy from "react-scrollspy";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
import "react-pure-modal/dist/react-pure-modal.min.css";
import "react-pro-sidebar/dist/css/styles.css";

//IMPORT REACT ICONS
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as GrIcons from "react-icons/gr";
import * as AiIcons from "react-icons/ai";
import * as TiIcons from "react-icons/ti";
import * as BsIcons from "react-icons/bs";
import * as HiIcons from "react-icons/hi";
import * as ImIcons from "react-icons/im";
import * as BiIcons from "react-icons/bi";
import * as GiIcons from "react-icons/gi";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";

//IMPORT CLASS COMPONENT CSS
import "./GenericDashboardCSS.css";
import "./TopNavbarCss.css";
import "../Invoice/MenuCollapseCSS.css";

//IMPORT CLASS COMPONENT
import {
  GetEmployeeSite,
  SetCurrentPage,
  Check_AccessTime,
  Check_AccessTime_Login,
} from "../ConstSiteFunction";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";
import GlobalSearch from "../Global Search/GlobalSearch";
import LoadingComponent from "../Loading/LoadingComponent";
import usericon from "./images/usericon.png";
import { Truncate_2DecimalPlaces } from "../Invoice/InvoiceValidations";
import featuresData from "../Admin Configurations/featuresData.json";
import PackageList from "../Customer_Features_Modules/Inventory/Products/Package_Module/PackageList";

const sidebarBg = lazy(() => import("./images/bg.jpg"));

const ImportExport = lazy(() => import("../ImportExport"));
const DashboardOverall = lazy(() =>
  import("../MaincontentDashboard/DashboardOverall")
);
const VehicleRegistrationList = lazy(() =>
  import("../VehicleRegistrationList")
);
const VehicleList1 = lazy(() => import("../VehicleList"));
const ProductList1 = lazy(() => import("../ProductList"));
const VendorList1 = lazy(() => import("../VendorList"));
const CustomerList1 = lazy(() => import("../CustomerList"));
const InvoiceList1 = lazy(() => import("../InvoiceList"));
const EstimateList1 = lazy(() => import("../EstimateList"));
const Expense1 = lazy(() => import("../ExpenseMenu"));
const PurchaseInvoiceList1 = lazy(() => import("../PurchaseInvoiceList"));
const EnquiryMenuPage = lazy(() => import("../EnquiryMenuPage"));
const AddAppointment = lazy(() => import("../AddAppointment"));
const AppointmentConfirmation = lazy(() =>
  import("../AppointmentConfirmation")
);
const StaffList1 = lazy(() => import("../StaffList"));

const AddRole1 = lazy(() => import("../AddRole"));
const Salary = lazy(() => import("../Salary"));
const SalaryReport1 = lazy(() => import("../SalaryReport"));
const BankReport = lazy(() => import("../BankReport"));
const Checkinout = lazy(() => import("../Checkinout"));
const Attendance = lazy(() => import("../Attendance"));
const AttendanceReportMenuPage = lazy(() =>
  import("../AttendanceReportMenuPage")
);

const ReportMenuPage = lazy(() => import("../ReportMenuPage"));
const MessageCenterEmailPage = lazy(() =>
  import("../Communication/MessageCenterEmailPage")
);
const MessageCenterMessagePage = lazy(() =>
  import("../Communication/MessageCenterMessagePage")
);
const TaskMappingElite = lazy(() => import("../TaskMappingElite"));
const AppointmentCalendar = lazy(() => import("../AppointmentCalendar"));

const ImportLogo = lazy(() => import("../ImportLogo"));
const ImportQRCode = lazy(() => import("../ImportQRCode"));
const CompanySetting = lazy(() => import("../CompanySettings"));
const ExportMenuPage = lazy(() => import("../ExportMenuPage"));
const LocationPage = lazy(() => import("../LocationPage"));
const LoginPage = lazy(() => import("../LoginPage"));
const Help = lazy(() => import("../Help"));

const ChangePassword1 = lazy(() => import("../ChangePassword"));
const ImportMenuPage = lazy(() => import("../ImportMenuPage"));
const ServiceRegistration = lazy(() =>
  import("../ServiceRegistration/ServiceRegistration")
);
const CustomerInvoiceList = lazy(() =>
  import("../CustomerPayment/CustomerInvoiceList")
);
const ConfigurationPage = lazy(() => import("../ConfigurationPage"));

const CustomerwisePayment = lazy(() =>
  import("../CustomerPayment/CustomerwisePayment")
);
const VehicleMakeModelComponent = lazy(() =>
  import("../ServiceRegistration/VehicleMakeModelComponent")
);
const VehicleMakeModel = lazy(() =>
  import("../ServiceRegistration/VehicleMakeModel")
);
const InvoiceMenuPage = lazy(() => import("../Invoice/InvoiceMenuPage"));
const MenuCollapsible = lazy(() => import("../Invoice/MenuCollapsible"));
const InvoiceListMenuPage = lazy(() =>
  import("../Invoice/InvoiceListMenuPage")
);

const AdminConfigutaionPage = lazy(() =>
  import("../Admin Configurations/AdminConfigurationPage")
);
const MyAccountDashBoard = lazy(() =>
  import("../My Account/MyAccount DashBoard/MyAccountDashBoard")
);
const MyAccountSidebar = lazy(() =>
  import("../My Account/MyAccount DashBoard/MyAccountSidebar")
);

var image = true;
var rtl = 0;
var collapsed = false;
var toggled = false;
//var [toggled, setToggled] = false;
let closedStyle = {
  height: 0,
};

let openStyle = {
  height: "auto",
};

// AWS URL : 15.206.129.105

//"https://wildfly.garageapp.in:443/GarageAppIN_API/
//"https://wildfly.garageapp.in:443/GarageAppIN_API/
//http://15.206.129.105:8080/GarageManagementInAPINew/
//"----https://wildfly.garageapp.in:443/GarageAppIN_API

export default class NewMenuBar extends Component {
  constructor() {
    super();

    var companyId = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var companyName = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var staffId = CryptoJS.AES.decrypt(
      localStorage.getItem("staffId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var employeeName = CryptoJS.AES.decrypt(
      localStorage.getItem("EmployeeName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var companyName_mobile;

    var employeeSites = CryptoJS.AES.decrypt(
      localStorage.getItem("EmpSites"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var employeeRole = CryptoJS.AES.decrypt(
      localStorage.getItem("Role"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var employeeCurrentSite = CryptoJS.AES.decrypt(
      localStorage.getItem("CurrentSite"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    var restrictedFeatures = CryptoJS.AES.decrypt(
      localStorage.getItem("RestrictedFeatures"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var restrictedFeaturesHeader = CryptoJS.AES.decrypt(
      localStorage.getItem("RestrictedFeaturesHeader"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    if (companyName.length > 20) {
      companyName_mobile = companyName.substr(0, 20) + "..";
    } else {
      companyName_mobile = companyName;
    }

    this.state = {
      employeeSites: employeeSites,
      employeeRole: employeeRole,
      employeeCurrentSite: employeeCurrentSite,

      companyId: companyId,
      companyName: companyName,
      companyName_mobile: companyName_mobile,
      staffId: staffId,
      employeeName: employeeName,
      options: [],
      employeeName: employeeName,
      options: [],
      modal: true,
      toggled: true,
      collapsed: false,
      contentWidth: "81%",
      modal: true,
      options: true,
      bookingId: "",

      //COLLAPSIBLE
      jobCardMenuopen: false,
      inventoryMenuopen: false,
      CRMMenuopen: false,
      ExpenseMenuopen: false,
      EmployeeMenuopen: false,
      AttendanceMenuopen: false,
      CommunicationMenuopen: false,
      SiteMenuopen: false,
      menuArray: [
        "jobCardMenuopen",
        "inventoryMenuopen",
        "CRMMenuopen",
        "ExpenseMenuopen",
        "EmployeeMenuopen",
        "AttendanceMenuopen",
        "CommunicationMenuopen",
        "SiteMenuopen",
      ],

      featuresData: featuresData,
      restrictedFeatures: restrictedFeatures,
      restrictedFeaturesHeader: restrictedFeaturesHeader,

      refreshinterval: 300000,
    };
    this.Check_For_Access = this.Check_For_Access.bind(this);
  }

  componentDidMount() {
    //console.log("path ", window.location.pathname);

    /*
            console.log("*********************************");
            console.log("CONTENT WIDTH :",this.state.contentWidth);
            console.log("CONTENT WIDTH :",this.state.collapsed);
            console.log("*********************************");
            */
    var self = this;
    //SET THE CURRENT PAGE/COMPONENET NAME IN LOCAL STORAGE
    SetCurrentPage("NewMenuBar");

    //USED TO HIDE  THE FIELDS THAT ARE  RESTRICTED IN THE PLAN
    this.SetFeatures();

    //   this.Dashboard();
    var empSites = GetEmployeeSite();
    var currentSite = CryptoJS.AES.decrypt(
      localStorage.getItem("CurrentSite"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var emparray = empSites.split(",");
    //console.log("emparray.length ", emparray.length, empSites);

    if (emparray.length === 1) {
      localStorage.setItem(
        "CurrentSite",
        CryptoJS.AES.encrypt(emparray[0], "shinchanbaby")
      );
      this.state.modal = false;
      this.Dashboard();
    } else {
      this.state.options = _.map(emparray, function (site) {
        return { label: site, value: site };
      });
      if (currentSite === "") {
        this.state.modal = true;
      } else {
        this.state.modal = false;
        this.Dashboard();
      }
    }
    this.setState({
      modal: this.state.modal,
      options: this.state.options,
    });
    //console.log("Modal ", this.state.options, this.state.modal);

    var self = this;
    $("#contentRender").click(function () {
      self.CloseOpenMenu("None");
    });
    $(".btn-toggle").click(function () {
      self.CloseOpenMenu("None");
    });

    $(".pro-inner-list-item").click(function () {
      self.CloseOpenMenu("None");
      self.CloseToggleSidebar();
    });
    $(".pro-sub-menu1").click(function () {
      self.CloseOpenMenu("None");
      self.CloseToggleSidebar();
    });
    $(".pro-sidebar.md .pro-inner-list-item").click(function () {
      $(".pro-sidebar.md").toggleClass("active");
    });
    $(".pro-sidebar.md .pro-sub-menu1").click(function () {
      $(".pro-sidebar.md").toggleClass("active");
    });

    /* ONCLICK ON ANYWHERE OUTSIDE THE PAGE */
    $(".contentRender").click(function () {
      //  alert("CONTENT RENDER");
      $(".dropdown-menu1").removeClass("show"); //CLOSE/HIDE USER PROFILE WHEN ANY WHERE ON THE PAGE IS CLICKED
      $(".dropdown-menus1").removeClass("show"); //CLOSE/HIDE GOOGLE TRANSLATE WHEN ANY WHERE ON THE PAGE IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN ANY WHERE ON THE PAGE IS CLICKED
    });

    /* USER PROFILE ICON ON CLICK */
    $(".dropdown-toggle1").click(function () {
      // alert("USER PROFILE");

      var employeeName = CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);

      var employeeSites = CryptoJS.AES.decrypt(
        localStorage.getItem("EmpSites"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);
      var employeeRole = CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);
      var employeeCurrentSite = CryptoJS.AES.decrypt(
        localStorage.getItem("CurrentSite"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);

      self.state.employeeName = employeeName;
      self.state.employeeRole = employeeRole;
      self.state.employeeCurrentSite = employeeCurrentSite;
      self.state.employeeSites = employeeSites;

      self.setState({
        employeeName: (self.state.employeeName = employeeName),
        employeeRole: (self.state.employeeRole = employeeRole),
        employeeCurrentSite: self.state.employeeCurrentSite,
        employeeSites: self.state.employeeSites,
      });

      $(".dropdown-menu1").addClass("show"); //SHOW THE PROFILE CARD WHEN PROFILE ICON IS CLICKED
      $(".dropdown-menus1").removeClass("show"); // CLOSE/HIDE THE GOOGLE TRANSLATE WHEN PROFILE ICON IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN PROFILE ICON IS CLICKED
    });

    /* SITE ASSIGNED ON CLICK */
    $(".siteassigned-collapsible").click(function () {
      //  alert("SITE ASSIGNED");
      $(".dropdown-menu1").addClass("show"); //SHOW THE PROFILE CARD WHEN PROFILE ICON IS CLICKED
      $(".dropdown-menus1").removeClass("show"); // CLOSE/HIDE THE GOOGLE TRANSLATE WHEN PROFILE ICON IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN PROFILE ICON IS CLICKED
    });

    /* ON CLICK IN USER PROFILE CARD */
    $(".userprofile_css").click(function () {
      //  alert("PROFILE CSS");

      $(".dropdown-menu1").addClass("show"); //SHOW THE PROFILE CARD WHEN PROFILE ICON IS CLICKED
      $(".dropdown-menus1").removeClass("show"); // CLOSE/HIDE THE GOOGLE TRANSLATE WHEN PROFILE ICON IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN PROFILE ICON IS CLICKED
    });

    /* GLOBAL SEARCH ICON ON CLICK */
    $(".btn-toggles1").click(function () {
      $(".dropdown-menu1").removeClass("show"); // CLOSE/HIDE THE PROFILE CARD WHEN GLOBAL SEARCH ICON IS CLICKED
      $(".dropdown-menus1").removeClass("show"); // CLOSE/HIDE THE GOOGLE TRANSLATE WHEN GLOBAL SEARCH ICON IS CLICKED
    });

    /* GOOGLE TRANSLATE ICON ON CLICK */
    $(".dropdown-togglee1").click(function () {
      $(".dropdown-menus1").addClass("show");
      $(".dropdown-menu1").removeClass("show"); //CLOSE/HIDE  THE PROFILE CARD WHEN GOOGLE TRANSLATE ICON IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN GOOGLE TRANSALTE ICON IS CLICKED
    });

    /* SETTINGS ICON ON CLICK */
    $(".dropdown-settings").click(function () {
      //  alert("SETTINGS");

      $(".dropdown-menu1").removeClass("show"); //CLOSE/HIDE  THE PROFILE CARD WHEN GOOGLE TRANSLATE ICON IS CLICKED
      $(".dropdown-menus1").removeClass("show"); // CLOSE/HIDE THE GOOGLE TRANSLATE WHEN GLOBAL SEARCH ICON IS CLICKED
      window.globalSearchComponent.EmptyGlobalSearch(); // CLOSE/HIDE THE GLOBAL SEARCH WHEN SETTINGS ICON IS CLICKED
    });

    $(document).ready(function () {
      var key = "shinchanbaby";

      localStorage.setItem(
        "isLoggedIn",
        CryptoJS.AES.encrypt("true".toString(), key)
      );

      if (localStorage.getItem("isLoggedIn")) {
        var login = CryptoJS.AES.decrypt(
          localStorage.getItem("isLoggedIn"),
          "shinchanbaby"
        ).toString(CryptoJS.enc.Utf8);
        if (login == "true") {
          //   self.state.refreshinterval = setInterval(() => self.Check_For_Access(), 30000);
          // this.state.offlineinterval = setInterval(() => this.offlineData(), 2000);
        }
      }
    });
  }

  Check_For_Access() {
    var self = this;

    var accessTime;

    if (this.state.employeeRole == "Proprietor") {
      accessTime = Check_AccessTime_Login();
    } else {
      accessTime = Check_AccessTime();
    }

    // alert("accessTime :"+accessTime.serverAccessData);
    if (accessTime.serverAccessData == false) {
      self.LogoutFunc();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshinterval);
    // clearInterval(this.state.offlineinterval);
  }

  /*
    FUNCTION TO HIDE THE FEATURES RESTRICTED IN THE PLAN
    */
  SetFeatures() {
    var self = this;
    var restrictedFeaturesArray = this.state.restrictedFeatures.split(",");
    var restrictedFeaturesHeaderArray =
      this.state.restrictedFeaturesHeader.split(",");

    // console.log("restrictedFeaturesArray :",restrictedFeaturesArray);
    //   console.log("restrictedFeaturesHeaderArray :",restrictedFeaturesHeaderArray);

    var flattendedData = this.state.featuresData.flatMap(
      (elem) => elem.children
    );
    flattendedData = _.compact(flattendedData);

    //  console.log("flattendedData :",flattendedData);

    $.each(restrictedFeaturesArray, function (i, item) {
      var currentSubMenuFeature = _.where(flattendedData, { label: item });

      //     console.log("ITEM :",item," currentSubMenuFeature :",currentSubMenuFeature);
      if (currentSubMenuFeature.length > 0) {
        var subMenuFeatureClassName = currentSubMenuFeature[0].tagClassName;
        $("." + subMenuFeatureClassName).hide();
      }
    });

    $.each(restrictedFeaturesHeaderArray, function (i, item) {
      var currentMainMenuFeature = _.where(self.state.featuresData, {
        label: item,
      });
      //  console.log("ITEM :",item," currentMainMenuFeature :",currentMainMenuFeature);
      if (currentMainMenuFeature.length > 0) {
        var mainMenuFeatureClassName = currentMainMenuFeature[0].tagClassName;
        $("." + mainMenuFeatureClassName).hide();
      }
    });
  }

  //TO OPEN THE CLICKED MENU & CLOSE OTHER MENU
  handleChangedMenuOpen(menuName) {
    var self = this;
    var menuNameCalled = menuName;

    var response = new Promise((resolve, reject) => {
      var response = "Proceed";
      //  alert("AT START  :"+ this.state[menuNameCalled]);
      //OPEN THE CURRENT CLICKED MENU
      this.state[menuNameCalled] = !this.state[menuNameCalled];

      this.setState({
        [menuNameCalled]: this.state[menuNameCalled],
      });

      resolve(response);

      return response;
    });
    response.then(function (response) {
      if (response == "Proceed") {
        //CLOSE OTHER OPNED MENU EXCEPT THE CURRENT MENU
        self.CloseOpenMenu(menuNameCalled);
      }
    });
  }
  CloseOpenMenu(menuNameCalled) {
    var self = this;

    var closeMenuArray = _.without(this.state.menuArray, menuNameCalled);

    $.each(closeMenuArray, function (i, item) {
      self.state[item] = false;

      self.setState({
        [item]: self.state[item],
      });
    });
  }

  handlePageClick(pageClicked) {
    if (pageClicked == "JobCard") {
      var flag = this.CheckPermission("Jobcard");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={VehicleRegistrationList} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "Invoice") {
      var flag = this.CheckPermission("SaleInvoice");
      var flag1 = this.CheckPermission("EstimateInvoice");

      if (flag == 0 || flag1 == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route
                  path="/"
                  component={() => (
                    <InvoiceMenuPage
                      pageCalledFrom="NewMenuBar"
                      data="SaleInvoice"
                      stateData={this.state}
                    />
                  )}
                />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "InvoiceList") {
      var flag = this.CheckPermission("SaleInvoice");
      var flag1 = this.CheckPermission("EstimateInvoice");
      if (flag == 0 || flag1 == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={InvoiceListMenuPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "CustomerPayment") {
      var flag = this.CheckPermission("CustomerPayment");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={CustomerwisePayment} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "ProductList") {
      var flag = this.CheckPermission("ProductService");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={ProductList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "ProductPackage") {
      var flag = this.CheckPermission("ProductPackage");
      flag = 0;
      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={PackageList} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "Vendor") {
      var flag = this.CheckPermission("Vendor");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={VendorList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "PurchaseInvoiceList") {
      var flag = this.CheckPermission("PurchaseInvoice");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={PurchaseInvoiceList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "VehicleList") {
      var flag = this.CheckPermission("Vehicle");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={VehicleList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "CustomerList") {
      var flag = this.CheckPermission("Customer");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={CustomerList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "VehicleMakeModelFuelType") {
      var flag = this.CheckPermission("VehicleMakeandModel");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={VehicleMakeModel} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "Expense") {
      var flag = this.CheckPermission("Expense");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={Expense1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "EnquiryFunc") {
      var flag = this.CheckPermission("Enquiry");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={EnquiryMenuPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "BookServiceFunc") {
      var flag = this.CheckPermission("BookService");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AddAppointment} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "ServiceCalendarFunc") {
      var flag = this.CheckPermission("ServiceCalendar");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AppointmentCalendar} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "BookServiceConfirmationFunc") {
      var flag = this.CheckPermission("ServiceConfirmation");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AppointmentConfirmation} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "StaffList") {
      var flag = this.CheckPermission("Employee");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={StaffList1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "AddRole") {
      var flag = this.CheckPermission("AddRole");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AddRole1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "Salary") {
      var flag = this.CheckPermission("Salary");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={Salary} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "SalaryReport") {
      var flag = this.CheckPermission("SalaryReport");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={SalaryReport1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "BankDetails") {
      var flag = this.CheckPermission("Bank");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={BankReport} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "ReportFunc") {
      var flag = this.CheckPermission("Report");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={ReportMenuPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "CheckInOutAttendance") {
      var flag = this.CheckPermission("CheckInOut");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={Checkinout} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "Attendance") {
      var flag = this.CheckPermission("ManualAttendance");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={Attendance} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "AttendanceReportMenuPage") {
      var flag = this.CheckPermission("Report");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AttendanceReportMenuPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "MessageFunc") {
      var flag = this.CheckPermission("OfferMessages");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={MessageCenterMessagePage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "EmailFunc") {
      var flag = this.CheckPermission("Emails");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={MessageCenterEmailPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    } else if (pageClicked == "AddRole") {
      var flag = this.CheckPermission("AddRole");
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={AddRole1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "ConfigurationFunc") {
      var flag = this.CheckPermission("Configuration");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={ConfigurationPage} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "TaskmappingElite") {
      var flag = this.CheckPermission("TaskMapping");

      //console.log("CHECKIN OUT FLAG :", flag);
      if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={TaskMappingElite} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
      } else {
        this.AccesDeniedMessage();
      }
    }
    if (pageClicked == "AdminConsolePage") {
      //  if (flag == 0) {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={AdminConfigutaionPage} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
      // } else {
      //     this.AccesDeniedMessage();
      //  }
    }
  }

  CheckPermission(moduleName) {
    var permission = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Permissions"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8)
    );

    var flag = 1; //false

    //console.log("PERMISSION LIST :", permission);
    var presentStatus = _.where(permission, { permission: moduleName });

    if (presentStatus.length > 0) {
      flag = 0; //true
      //console.log("PERMISSION FLAG 0 ");
    } else {
      var permission = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("PermissionHeader"),
          "shinchanbaby"
        ).toString(CryptoJS.enc.Utf8)
      );
      var presentHeaderStatus = _.where(permission, {
        permissionHeader: moduleName,
      });

      if (presentHeaderStatus.length > 0) {
        flag = 0; //true
        //console.log("PERMISSION HEADER FLAG 0 ");
      }
    }

    return flag;
  }

  AccesDeniedMessage() {
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Access Denied",
      text: "You are not Allowed to Access this Page",
      showConfirmButton: false,
      timer: 2000,
    });
  }

  handleToggleSidebar() {
    this.setState((prevState) => {
      return {
        contentWidth: prevState.collapsed ? "81%" : "95%",
        toggled: !prevState.toggled,
        collapsed: !prevState.collapsed,
      };
    });
  }
  CloseToggleSidebar() {
    if (!this.state.collapsed) {
      this.setState((prevState) => {
        return {
          contentWidth: prevState.collapsed ? "81%" : "95%",
          collapsed: !prevState.collapsed,
        };
      });
    }
  }
  HandleCollapse() {
    this.setState((prevState) => {
      return {
        contentWidth: prevState.collapsed ? "81%" : "95%",
        collapsed: !prevState.collapsed,
      };
    });
  }

  setModal(status) {
    this.setState({
      modal: status,
    });
  }

  OpenModal(status) {
    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    this.state.options = _.map(emparray, function (site) {
      return { label: site, value: site };
    });
    var currentSite = CryptoJS.AES.decrypt(
      localStorage.getItem("CurrentSite"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    this.setState({
      options: this.state.options,
      selectedSite: { label: currentSite, value: currentSite },
      modal: status,
    });
  }

  handleCurrentSite = (e) => {
    const value = e.value;
    this.state.currentSite = value;
    localStorage.setItem(
      "CurrentSite",
      CryptoJS.AES.encrypt(value, "shinchanbaby")
    );
    this.setState({
      selectedSite: e,
      modal: false,
    });
    this.Dashboard();

    /*  let inter = setInterval(() => {
              var accessTime=Check_AccessTime();
              alert("accessTime :"+accessTime.serverAccessData);
              if(accessTime.serverAccessData==true){
                  this.LogoutFunc();
              }
          }, 300000); //5 mins once sync
          */
  };

  Dashboard() {
    $(".pro-sidebar").show();
    $(".toggle_btn").show();

    this.setState((prevState) => {
      return {
        contentWidth: prevState.collapsed ? "81%" : "95%",
        toggled: !prevState.toggled,
        collapsed: !prevState.collapsed,
      };
    });

    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={DashboardOverall} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );

    /*  console.log("*********************************");
          console.log("CONTENT WIDTH BEFORE DASHBOARD:",this.state.contentWidth);
          console.log("CONTENT WIDTH BEFORE DASHBOARD:",this.state.collapsed);
          console.log("*********************************");
          */
  }

  /*
    ***********************************************************************************
    SETTING ICONS FUNCTIONS BEGINS
    ***********************************************************************************/

  ImportLogoFunc() {
    var flag = this.CheckPermission("ImportLogo");
    if (flag == 0) {
      // $(".pro-sidebar").hide();
      //  $(".toggle_btn").hide();

      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={ImportLogo} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }

  ImportQRCodeFunc() {
    var flag = this.CheckPermission("ImportQRcode");
    if (flag == 0) {
      //  $(".pro-sidebar").hide();
      //   $(".toggle_btn").hide();
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={ImportQRCode} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }

  ImportExport() {
    var flag = this.CheckPermission("ImportQRcode");
    if (flag == 0) {
      //  $(".pro-sidebar").hide();
      //   $(".toggle_btn").hide();
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={ImportExport} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }

  ExportFunc() {
    var flag = this.CheckPermission("ExportExcel");
    if (flag == 0) {
      //  $(".pro-sidebar").hide();
      //  $(".toggle_btn").hide();

      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={ExportMenuPage} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }
  LocationFunc() {
    var flag = this.CheckPermission("Location");
    if (flag == 0) {
      //   $(".pro-sidebar").hide();
      //   $(".toggle_btn").hide();

      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route exact path="/" component={LocationPage} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }
  ImportFunc() {
    var flag = this.CheckPermission("ImportExcel");
    if (flag == 0) {
      //   $(".pro-sidebar").hide();
      //   $(".toggle_btn").hide();
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={ImportMenuPage} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }

  HelpFunc() {
    // $(".pro-sidebar").hide();
    //  $(".toggle_btn").hide();
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={Help} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
  }

  ChangePassword() {
    // helpFuncValue= "helpchangepassword";
    // $(".pro-sidebar").hide();
    //  $(".toggle_btn").hide();
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={ChangePassword1} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
  }

  CompanySetting() {
    var flag = this.CheckPermission("CompanySetting");
    if (flag == 0) {
      //  $(".pro-sidebar").hide();
      //   $(".toggle_btn").hide();
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route path="/" component={CompanySetting} />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }

  LogoutFunc() {
    //   $(".pro-sidebar").hide();
    //   $(".toggle_btn").hide();
    localStorage.clear();
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={LoginPage} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("root")
    );
  }

  MyAccountFunc() {
    /*
        console.log("*********************************");
        console.log("CONTENT WIDTH BEFORE MYACCOUNT:",this.state.contentWidth);
        console.log("CONTENT WIDTH BEFORE MYACCOUNT:",this.state.collapsed);
        console.log("*********************************");
        */

    $(".pro-sidebar").hide();
    $(".toggle_btn").hide();
    //   $("  this.state.contentWidth ").hide();

    Swal.fire({
      position: "center",
      icon: "error",
      title: "InProgress",
      // text: 'To date cannot be before From date',
      showConfirmButton: false,
      timer: 2000,
    });

    /*
                  ReactDOM.render(
                    <Router>
                       <Suspense fallback={<LoadingComponent />} >      
                        <div>
                          <Route path="/" component={MyAccountSidebar} />
                          <Route path="/" component={MyAccountDashBoard} />      
                        </div>
                        </Suspense>
                    </Router>,
                    document.getElementById('contentRender'));
                    
                  this.setState(prevState => {
                      return {
                          
                          contentWidth: prevState.collapsed ? "100%" : "100%",
                          collapsed: !prevState.collapsed
                          
                      };
                  });
        
                  if (!this.state.collapsed) {
                      this.setState(prevState => {
                          return {
                              contentWidth: prevState.collapsed ? "100%" : "100%",
                              collapsed: !prevState.collapsed
                          };
                      });
                  }
                  this.setState(prevState => {
                      return {
                         
                          contentWidth: prevState.collapsed ? "100%" : "100%",
                          collapsed: !prevState.collapsed
                      };
                  });
                  
                  */
    /*
                  console.log("*********************************");
                  console.log("CONTENT WIDTH AFTER MYACCOUNT:",this.state.contentWidth);
                  console.log("CONTENT WIDTH AFTER MYACCOUNT:",this.state.collapsed);
                  console.log("*********************************");
                  */
  }

  /*
 ***********************************************************************************
 SETTING ICONS FUNCTIONS ENDS
 ***********************************************************************************/

  handleToggle() {
    this.setState({
      show: !this.state.show,
    });
  }

  checkbutton() {
    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        // parameter: 'CustomizedAppointment'
        //  date:this.state.currentDate,
        //  empSites:GetEmployeeSite(),
        //  currencyCode:this.state.currencyCode,
      }),

      //  https://wildfly.garageapp.in:443/GarageApp--API_Testing
      //  https://wildfly.garageapp.in:443/GarageAppIN_API

      //    url: "https://wildfly.garageapp.in:443/GarageAppIN_API/UpdateFeaturesCode/UpdateSalePurchaseRateWithoutTax",
      //  url: "https://wildfly.garageapp.in:443/GarageAppIN_API/UpdateFeaturesCode/UpdateReferenceInvoiceNo",
      //   url: "https://wildfly.garageapp.in:443/GarageAppIN_API/UpdateFeaturesCode/UpdateUniversalProductId",

      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        console.log("data :", data);
      },
      error: function (data) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  render() {
    const { show } = this.state;
    return (
      <div>
        {/* <button
          onClick={() => {
            this.checkbutton();
          }}
        >
          Check update
        </button>
        */}

        <div className="main_header main_Header_sm">
          <div className="header_sm_device">
            <a href="index2.html" className="logo header_sm_device_logo">
              <span className="logo-lg">
                <b style={{ fontWeight: "100", fontSize: "17px" }}>
                  {this.state.companyName_mobile}
                </b>
              </span>
            </a>
          </div>

          <nav
            className="navbar navbar-inverse navbar_css"
            style={{
              backgroundColor: " #f1f1f9",
              borderColor: "#eaedfa",
              boxShadow:
                "10px 1px 14px 0 rgb(111 100 255 / 24%), 10px 1px 2px rgb(0 0 0 / 0%)",
            }}
          >
            <div
              className="btn-toggle"
              id="btn-toggle"
              style={{ fontSize: "28px" }}
            >
              <FaIcons.FaBars
                className="toggle_btn"
                onClick={() => this.handleToggleSidebar()}
              />
              <span style={{ marginLeft: "16px" }}>
                <ImIcons.ImHome onClick={() => this.Dashboard()} />
              </span>
            </div>
            <div className="btn-homepage" style={{ fontSize: "28px" }}></div>
            <div className="comp_name_cent">
              <a
                className=""
                style={{
                  color: "#263eac",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
                href="#"
              >
                {this.state.companyName}
              </a>
            </div>

            <ul
              className="nav navbar-nav navbar-right pull-right "
              style={{
                display: "flex",
                marginRight: "15px",
                marginTop: "-60px",
                height: "40px",
              }}
            >
              {/*
               *******************************************************************************************
               *********************** GLOBAL SEARCH CODE BEGINS *****************************************
               ******************************************************************************************
               */}
              <li
                className="topnav_glb_search_btn btn-toggles1"
                style={{ marginRight: "10px" }}
              >
                <div className="search-container">
                  <GlobalSearch />
                </div>
              </li>

              {/*
               *******************************************************************************************
               *********************** GLOBAL SEARCH CODE ENDS *****************************************
               ******************************************************************************************
               */}

              {/*
               *******************************************************************************************
               *********************** GOOGLE TRANSLATE CODE BEGINS *****************************************
               ******************************************************************************************
               */}
              <li className="">
                <a
                  href="#"
                  className="dropdown-toggle dropdown-togglee1 user_profile sidemenu_autoclose_settings "
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="icon-big text-center icon-warning">
                    <SiIcons.SiGoogletranslate
                      style={{ color: "#fff", fontSize: "20px" }}
                    />
                  </div>
                  &nbsp;
                </a>
                <ul className="lang_trans">
                  <div className="dropdown-menu dropdown-menus1 goog">
                    <GoogleTranslate />
                  </div>
                </ul>
              </li>

              {/*
               *******************************************************************************************
               *********************** GOOGLE TRANSLATE CODE ENDS *****************************************
               ******************************************************************************************
               */}

              {/*
               *******************************************************************************************
               *********************** USER PROFILE ICON CODE BEGINGS *****************************************
               ******************************************************************************************
               */}
              <li>
                {/* <div className="userprofile_css"> */}
                <a
                  href="#"
                  className="dropdown-toggle dropdown-toggle1 user_profile sidemenu_autoclose_settings userprofile_css"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ heiht: "40px" }}
                >
                  {/* <img src={usericon} alt="logo" className="profileMenu " style={{ width: '30px', marginTop: '13px' }} /> */}
                  <div className="icon-big text-center icon-warning">
                    <i
                      className="fa fa-user"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu1 menu_css"
                  style={{
                    borderRadius: "20px",
                    width: "25rem",
                    height: "60rem",
                    marginRight: "-60px",
                    boxShadow:
                      "rgba(65, 63, 63, 0.3) 8px 6px 20px, rgba(0, 0, 0, 0.22) 2px 1px 2px",
                  }}
                >
                  <div className="text-center">
                    <div className="outcircle ">
                      <i className="fa fa-user fa-4x" aria-hidden="true"></i>
                    </div>
                  </div>
                  <li className="user_dropdown">
                    <span
                      style={{
                        color: "#3c3b3b",
                      }}
                    >
                      <span
                        className="settings_Top_Submenu"
                        style={{ paddingLeft: "10px", fontSize: "14px" }}
                      >
                        <BsIcons.BsPersonFill />
                        <span style={{ fontSize: "14px" }}>
                          {this.state.employeeName}
                        </span>
                      </span>
                    </span>
                  </li>
                  <li role="separator" className="set_divider" />
                  <li className="user_dropdown">
                    <span
                      style={{
                        float: "",
                        color: "#3c3b3b",
                      }}
                    >
                      <span
                        className="settings_Top_Submenu"
                        style={{ paddingLeft: "10px", fontSize: "14px" }}
                      >
                        <AiIcons.AiOutlineNumber />
                        <span style={{ fontSize: "14px" }}>Employee Id:</span>
                        {this.state.staffId}
                      </span>
                    </span>
                  </li>

                  <li role="separator" className=" set_divider"></li>
                  <li className="user_dropdown">
                    <span
                      style={{
                        float: "",
                        color: "#3c3b3b",
                      }}
                    >
                      <span
                        className="settings_Top_Submenu"
                        style={{ paddingLeft: "10px", fontSize: "14px" }}
                      >
                        <SiIcons.SiAuth0 />
                        <span style={{ fontSize: "14px" }}>Role:</span>
                        {this.state.employeeRole}
                      </span>
                    </span>
                  </li>

                  <li role="separator" className=" set_divider"></li>
                  <li className="user_dropdown">
                    <span
                      style={{
                        float: "",
                        color: "#3c3b3b",
                      }}
                    >
                      <span
                        className="settings_Top_Submenu"
                        style={{ paddingLeft: "10px", fontSize: "14px" }}
                      >
                        <RiIcons.RiUserLocationFill />
                        <span style={{ fontSize: "14px" }}>Working Site: </span>
                        {this.state.employeeCurrentSite}
                      </span>
                    </span>
                  </li>
                  {/* Site Assigned */}
                  <div className="collapsed_group">
                    <div className="col-sm-12">
                      <div className="panel-group">
                        <div className="panel panel-default">
                          <div className="panel-heading text-center">
                            <h4 className="panel-title1">
                              <a
                                href="#"
                                className="siteassigned-collapsible"
                                onClick={() =>
                                  this.handleChangedMenuOpen("SiteMenuopen")
                                }
                              >
                                Site Assigned
                              </a>
                            </h4>
                          </div>
                          <div
                            className={
                              this.state.SiteMenuopen
                                ? "panel-collapse"
                                : "panel-collapse panel-close"
                            }
                          >
                            <ul className="list-group1 submenu_css">
                              <li className="list-group-item1 pro-menu-item">
                                {this.state.employeeSites}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>

              {/*
               *******************************************************************************************
               *********************** USER PROFILE ICON CODE ENDS *****************************************
               ******************************************************************************************
               */}

              {/*
               *******************************************************************************************
               *********************** SETTINGS ICON CODE BEGINS *****************************************
               ******************************************************************************************
               */}
              <li className="btn-toggles1 settingMainMenu">
                <a
                  href="#"
                  className="dropdown-toggle dropdown-settings user_profile sidemenu_autoclose_settings "
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ heiht: "40px" }}
                >
                  <div className="icon-big text-center icon-warning">
                    <i
                      className="fa fa-cog"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </a>
                <ul
                  className="dropdown-menu"
                  id="setting_dropdown"
                  style={{
                    marginLeft: "-140px",
                    zIndex: "20",
                    borderRadius: "12px",
                    marginTop: "10px",
                  }}
                >
                  <li role="separator" className=" set_divider"></li>
                  <li className="importQRCodeSubMenu">
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                      onClick={() => this.ImportExport()}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-import"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <BsIcons.BsFillFolderSymlinkFill />
                      </div>
                      &nbsp;
                      <div className="settings_Top_Submenu">Import/Export</div>
                    </a>
                  </li>

                  <li role="separator" className=" set_divider"></li>

                  <li
                    className="locationSubMenu"
                    onClick={() => this.LocationFunc()}
                  >
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-map-marker setbtn_glyp_icon"
                        style={{ padding: "1px" }}
                      >
                        <RiIcons.RiRoadMapFill />
                      </div>
                      &nbsp;<div className="settings_Top_Submenu">Location</div>
                    </a>
                  </li>

                  <li role="separator" className=" set_divider"></li>

                  <li
                    className="changeLocationSubMenu"
                    onClick={() => this.OpenModal(true)}
                  >
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-map-marker setbtn_glyp_icon"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <TiIcons.TiLocation />
                      </div>
                      &nbsp;
                      <div className="settings_Top_Submenu">
                        Change Location
                      </div>
                    </a>
                  </li>

                  <li role="separator" className="set_divider" />
                  <li onClick={() => this.HelpFunc()}>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-question-sign"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <MdIcons.MdHelp />
                        &nbsp;
                      </div>
                      <div className="settings_Top_Submenu">Help</div>
                    </a>
                  </li>

                  <li role="separator" className="set_divider" />
                  <li onClick={() => this.ChangePassword()}>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-eye-open"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <RiIcons.RiLockPasswordFill />
                      </div>
                      &nbsp;
                      <div className="settings_Top_Submenu">
                        Change Password
                      </div>
                    </a>
                  </li>

                  <li role="separator" className=" set_divider"></li>
                  <li onClick={() => this.MyAccountFunc()}>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-import"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <BsIcons.BsFillPersonFill />
                      </div>
                      &nbsp;
                      <div className="settings_Top_Submenu">My Account</div>
                    </a>
                  </li>

                  <li role="separator" className="set_divider" />
                  <li onClick={() => this.CompanySetting()}>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="fa fa-cogs setbtn_glyp_icon"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <RiIcons.RiSettings4Fill />
                      </div>
                      &nbsp;
                      <div className="settings_Top_Submenu">
                        Company Setting
                      </div>
                    </a>
                  </li>

                  {/* <li role="separator" className="set_divider" /> */}
                  <li>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-log-out"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <RiIcons.RiEarthFill />
                      </div>
                      &nbsp;<div className="settings_Top_Submenu">V 3.7.0</div>
                    </a>
                  </li>

                  <li role="separator" className="set_divider" />
                  <li onClick={() => this.LogoutFunc()}>
                    <a
                      href="#"
                      className="user_p_menu"
                      style={{
                        backgroundColor: "",
                        color: "black",
                        display: "flex",
                      }}
                    >
                      <div
                        className="icon_size"
                        // className="glyphicon glyphicon-log-out"
                        style={{
                          padding: "1px",
                        }}
                      >
                        <BsIcons.BsFillArrowRightCircleFill />
                      </div>
                      &nbsp; <div className="settings_Top_Submenu">Logout</div>
                    </a>
                  </li>
                  <li role="separator" className="set_divider" />
                </ul>
              </li>

              {/*
               *******************************************************************************************
               *********************** SETTINGS ICON CODE ENDS *****************************************
               ******************************************************************************************
               */}
            </ul>
          </nav>
        </div>

        {/*
         *******************************************************************************************
         *********************** SIDE NAV BAR CODE BEGINS *****************************************
         ******************************************************************************************
         */}

        <IconContext.Provider
          value={{ color: "white", style: { fontSize: "22px" } }}
        >
          <div className="wrapper" style={{ backgroundColor: "#eaedfa" }}>
            <ProSidebar
              /*  style={{ position: "fixed" }} */
              image={image ? sidebarBg : false}
              rtl={rtl}
              collapsed={this.state.collapsed}
              toggled={this.state.toggled}
              breakPoint={"md"}
              onToggle={() => this.handleToggleSidebar()}
            >
              <SidebarHeader></SidebarHeader>

              <SidebarContent style={{ color: "white" }}>
                <nav className="pro-menu">
                  <div className="collapsed_group">
                    <div className="panel-group jobCardMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen("jobCardMenuopen")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <RiIcons.RiDashboardLine />
                                </span>
                              </span>
                              <span className="pro-item-content">Job Card</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.jobCardMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item jobcardSubmenu"
                                    onClick={() =>
                                      this.handlePageClick("JobCard")
                                    }
                                  >
                                    Job Card
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item invoiceSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("Invoice")
                                    }
                                  >
                                    Invoice
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item invoiceListSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("InvoiceList")
                                    }
                                  >
                                    Invoice List
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item customerPaymentSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("CustomerPayment")
                                    }
                                  >
                                    Customer Payment
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group inventoryMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen("inventoryMenuopen")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <GiIcons.GiStoneBlock />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Inventory
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.inventoryMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item addProductServiceSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("ProductList")
                                    }
                                  >
                                    Add Product/Service
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item addProductServiceSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("ProductPackage")
                                    }
                                  >
                                    Package
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item vendorSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("Vendor")
                                    }
                                  >
                                    Vendor
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item purchaseSubMenu"
                                    onClick={() =>
                                      this.handlePageClick(
                                        "PurchaseInvoiceList"
                                      )
                                    }
                                  >
                                    Purchase
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group crmMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen("CRMMenuopen")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <GiIcons.GiHumanTarget />
                                </span>
                              </span>
                              <span className="pro-item-content">CRM</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.CRMMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item vehicleSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("VehicleList")
                                    }
                                  >
                                    Vehicle
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item customerSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("CustomerList")
                                    }
                                  >
                                    Customer
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item vehicleMakeModelSubMenu"
                                    onClick={() =>
                                      this.handlePageClick(
                                        "VehicleMakeModelFuelType"
                                      )
                                    }
                                  >
                                    Vehicle Make&Model
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group expenseMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() => this.handlePageClick("Expense")}
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <RiIcons.RiCurrencyFill />
                                </span>
                              </span>
                              <span className="pro-item-content">Expense</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.ExpenseMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        ></div>
                      </div>
                    </div>
                    <div className="panel-group enquiryMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("EnquiryFunc")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <AiIcons.AiFillMessage />
                                </span>
                              </span>
                              <span className="pro-item-content">Enquiry</span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group bookServiceMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("BookServiceFunc")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <FaIcons.FaAddressBook />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Book Service
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group serviceCalendarMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("ServiceCalendarFunc")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <FaIcons.FaCalendarAlt />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Service Calendar
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group serviceConfirmationMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick(
                                  "BookServiceConfirmationFunc"
                                )
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <BsIcons.BsFillPersonCheckFill />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Service Confirmation
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group employeeMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen("EmployeeMenuopen")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <BsIcons.BsFillPersonLinesFill />
                                </span>
                              </span>
                              <span className="pro-item-content">Employee</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.EmployeeMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item addEmployeeSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("StaffList")
                                    }
                                  >
                                    Employee
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item addRoleSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("AddRole")
                                    }
                                  >
                                    Add Role
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item addSalarySubMenu"
                                    onClick={() =>
                                      this.handlePageClick("Salary")
                                    }
                                  >
                                    Salary
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item salaryReportSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("SalaryReport")
                                    }
                                  >
                                    Salary Report
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group attendanceMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen("AttendanceMenuopen")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <FaIcons.FaRegIdCard />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Attendance
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.AttendanceMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item checkinoutSubMenu"
                                    onClick={() =>
                                      this.handlePageClick(
                                        "CheckInOutAttendance"
                                      )
                                    }
                                  >
                                    Check In/Out
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item manualAttendanceSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("Attendance")
                                    }
                                  >
                                    Manual Attendance
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item attendanceReportSubMenu"
                                    onClick={() =>
                                      this.handlePageClick(
                                        "AttendanceReportMenuPage"
                                      )
                                    }
                                  >
                                    Report
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group communicationMainMenu">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h4 className="panel-title pro-menu-item pro-sub-menu">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handleChangedMenuOpen(
                                  "CommunicationMenuopen"
                                )
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <BiIcons.BiMailSend />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Communication
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className={
                            this.state.CommunicationMenuopen
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <div className="pro-inner-item pro-inner-item1">
                            <div className="pro-inner-list-item popper-element">
                              <div className="popper-inner">
                                <ul className="list-group submenu_css">
                                  <li
                                    className="list-group-item pro-menu-item smsSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("MessageFunc")
                                    }
                                  >
                                    Offer Messages
                                  </li>
                                  <li
                                    className="list-group-item pro-menu-item emailSubMenu"
                                    onClick={() =>
                                      this.handlePageClick("EmailFunc")
                                    }
                                  >
                                    Emails
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group bankMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("BankDetails")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <RiIcons.RiBankLine />
                                </span>
                              </span>
                              <span className="pro-item-content">Bank</span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="panel-group reportsMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() => this.handlePageClick("ReportFunc")}
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <HiIcons.HiOutlineDocumentReport />
                                </span>
                              </span>
                              <span className="pro-item-content">Reports</span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="panel-group configurationMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("ConfigurationFunc")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <FiIcons.FiSettings />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Configuration
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group taskMappingMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("ExpenseMenuopen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("TaskmappingElite")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <BsIcons.BsShieldLock />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Task Mapping
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group adminConfigurationMainMenu">
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          onClick={() =>
                            this.handleChangedMenuOpen("AdminMenuOpen")
                          }
                        >
                          <h4 className="panel-title pro-menu-item pro-sub-menu pro-sub-menu1">
                            <a
                              href="#"
                              className="pro-inner-item"
                              onClick={() =>
                                this.handlePageClick("AdminConsolePage")
                              }
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <RiIcons.RiUserSettingsLine
                                    style={{ fontSize: "24px" }}
                                  />
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Admin Configurations
                              </span>
                            </a>
                            <div
                              className={
                                this.state.ExpenseMenuopen
                                  ? "panel-collapse"
                                  : "panel-collapse panel-close"
                              }
                            ></div>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </SidebarContent>

              <SidebarFooter style={{ textAlign: "center" }}></SidebarFooter>
            </ProSidebar>
            <PureModal
              header="Choose Site"
              footer={""}
              isOpen={this.state.modal}
              closeButton="close"
              closeButtonPosition="bottom"
              onClose={() => {
                var currentSite = CryptoJS.AES.decrypt(
                  localStorage.getItem("CurrentSite"),
                  "shinchanbaby"
                ).toString(CryptoJS.enc.Utf8);
                if (currentSite !== "") {
                  this.setModal(false);
                  return true;
                }
              }}
              width={400}
            >
              <div style={{ height: "200px" }}>
                <p>Select the Site going to handle</p>
                <SelectSearch
                  options={this.state.options}
                  value={this.state.selectedSite}
                  onChange={(e) => this.handleCurrentSite(e)}
                  name="WorkingSite"
                  placeholder="Select Working Site "
                />
              </div>
            </PureModal>
            <div>
              <div>
                <div
                  id="contentRender"
                  onClick={() => this.CloseToggleSidebar()}
                  style={{
                    position: "fixed",
                    overflow: "auto",
                    background: "#f9f9fd",
                    width: this.state.contentWidth,
                  }}
                  className="contentRender contentRender_sm"
                ></div>
              </div>
            </div>
          </div>
        </IconContext.Provider>

        {/*
         *******************************************************************************************
         *********************** SIDE NAV BAR CODE ENDS *****************************************
         ******************************************************************************************
         */}
      </div>
    );
  }
}
