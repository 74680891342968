//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { FormErrors } from "./FormErrors";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import CryptoJS from "crypto-js";
import LoginPage from "./LoginPage";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment-timezone";
import SelectSearch from "react-select";
import Select from "react-select";
import _ from "underscore";
import currencyFormatter from "currency-formatter";
import * as BsIcons from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { NetworkErrorComponent } from "../components/Assets_Components/MessagePage_Components/MessagePage_Components";

//New Register Section
import { Form } from "./SignUp/Form";

//IMPORT REACT COMPONENT CSS
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "sweetalert2/src/sweetalert2.scss";
//IMPORT CLASS COMPONENT
import { BackButtonComponent } from "./ServiceRegistration/ButtonComponent";
import LoadingComponent from "./Loading/LoadingComponent";
import { SetCurrentPage } from "./ConstSiteFunction";
const SiteLocation = lazy(() => import("./SiteLocation"));
const ct = require("countries-and-timezones");

var currencies;
var currencyArray = [];
var planData = [];
class SiteRegister extends Component {
  constructor(props) {
    super(props);
    var proprietor;
    var date = moment()
      .add(2, "d") //replace 2 with number of days you want to add
      .toDate();

    this.state = {
      modalIsOpen: false,
      date: date,
      emailId: "",
      contactNo: "",
      companyId: "",
      companyName: "",
      planName: "",
      permission: [],
      permissionHeader: [],
      doorNo: "",
      floor: "",
      street: "",
      city: "",
      area: "",
      zipCode: "",
      pincode: "",
      currencyCode: "",

      // attendanceoption:'',
      roleName: proprietor,
      timezone: "",
      currencyOptions: [],
      formErrors: {
        companyName: "",
        emailId: "",
        contactNo: "",

        //    attendanceoption:'',
        planName: "",
        zipCode: "",
      },

      companyNameValid: false,
      emailIdValid: false,
      contactNoValid: false,

      planNameValid: false,
      zipCodeValid: false,
    };
    this.setState({
      roleName: proprietor,
      date: date,
    });
  }

  componentDidMount() {
    SetCurrentPage("SiteRegister");
    this.GetCountry();

    this.state.currencyOptions = [];

    currencies = currencyFormatter.currencies;
    //console.log("currencies :", currencies);

    if (currencies.length != 0) {
      $.each(currencies, function (i, item) {
        currencyArray.push({
          label: item.code + " - " + item.symbol,
          value: item.code,
        });
      });
      this.state.currencyOptions = currencyArray;
    }

    this.setState({
      currencyOptions: this.state.currencyOptions,
    });

    var currentCurrencyFormatChosen = _.findWhere(currencies, { code: "ARS" });
    //console.log("currentCurrencyFormatChosen DID MOUNT :", currentCurrencyFormatChosen);
    this.state.currencyCode = "ARS";
    this.state.currencyOption = { label: "ARS" + " - " + "$", value: "ARS" };
    this.SetCurrencyValue(currentCurrencyFormatChosen);

    //GET THE PLAN INFO
    this.GetPlanInfo();
  }

  GetPlanInfo() {
    var self = this;
    $.ajax({
      type: "POST",
      data: JSON.stringify({}),
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/SalesPlan/SelectSalesPlanDetails",
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });

        console.log("data :", data);
        planData = data.salesPlanList;
        var applicablePlanList = _.where(planData, { status: "0" });
        var planOptions = [];

        $.each(applicablePlanList, function (i, item) {
          planOptions.push({ label: item.planName, value: item.planId });
        });
        self.state.planOptions = planOptions;
        self.setState({
          planOptions: self.state.planOptions,
        });
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  handleUserPackageInput = (e) => {
    //  const name = e.target.name;
    //   const value = e.target.value;

    this.setState({
      planName: this.state.planName,
      permission:
        "Jobcard," +
        "SaleInvoice," +
        "EstimateInvoice,CustomerPayment," +
        "ProductService,ProductPackage,Vendor," +
        "PurchaseInvoice,Vehicle," +
        "Customer,VehicleMakeandModel,AddExpense,AddEnquiry,AddBookService," +
        "ServiceCalendar,ServiceConfirmation," +
        "EmployeeDetails,AddRole,Salary,SalaryReport," +
        "BankDetails,CheckInOut,ManualAttendance,Report,Reports,SalesReport,EstimateReport," +
        "ExpenseReport,PurchaseReport,MessageCenterReport,ProfitandLossReport," +
        "InventoryReport,AuditReport,BillDueReport,EnquiryReport,FeedBackReport," +
        "PaymentHistoryReport,BookedServiceReports," +
        "PaymentReceivablesReports,OfferMessages,Emails,Configuration," +
        "TaskMapping,Location,CompanySetting,ImportQRcode,ImportLogo,ExportExcel,ImportExcel",
      permissionHeader:
        "JobCard,Inventory,CRM,Expense,Enquiry,BookService,ServiceCalendar," +
        "ServiceConfirmation,Employee,Bank,Attendance,Reports,Communication,Configuration,TaskMapping",
    });

    //  this.validateField(name, value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let companyNameValid = this.state.companyNameValid;
    let emailIdValid = this.state.emailIdValid;
    let contactNoValid = this.state.contactNoValid;

    //   let attendanceoptionValid=this.state.attendanceoptionValid;
    let planNameValid = this.state.planNameValid;
    let zipCodeValid = this.state.zipCodeValid;

    switch (fieldName) {
      case "companyName":
        companyNameValid = value.length > 0;
        fieldValidationErrors.CompanyName = companyNameValid
          ? ""
          : " is InCorrect";
        break;

      case "emailId":
        emailIdValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.EmailId = emailIdValid ? "" : " is InCorrect";
        break;

      case "contactNo":
        contactNoValid = value.match(/^[0-9]{10}$/);
        fieldValidationErrors.ContactNo = contactNoValid
          ? ""
          : " should be 10 digit number";
        break;

      // case 'attendanceoption':
      //         attendanceoptionValid =value.match(/^([\w.%+-]+)$/i);
      //         fieldValidationErrors.attendanceoption = attendanceoptionValid ? '' : ' is InCorrect';
      //         break;
      case "planName":
        planNameValid = value.length >= 1;
        fieldValidationErrors.PlanName = planNameValid ? "" : " is too short";
        break;

      case "zipCode":
        zipCodeValid = value.match(/^[0-9]{6}$/);
        fieldValidationErrors.PinCode = zipCodeValid
          ? ""
          : " should be 6 digit number";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        companyNameValid: companyNameValid,
        emailIdValid: emailIdValid,
        contactNoValid: contactNoValid,
        //  attendanceoptionValid:attendanceoptionValid,

        planNameValid: planNameValid,
        zipCodeValid: zipCodeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.companyName &&
        this.state.emailIdValid &&
        this.state.contactNoValid &&
        // && this.state.attendanceoptionValid

        this.state.planNameValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  EmptyValue() {
    this.state.emailId = "";
    this.state.contactNo = "";
    this.state.companyName = "";
    this.state.planName = "";
    //  this.state.attendanceoption='';
    this.state.emailIdValid = false;
    this.state.contactNoValid = false;
    this.state.companyNameValid = false;
    //  this.state.attendanceoptionValid = false;
    this.state.planNameValid = false;
    this.state.zipCodeValid = false;

    this.setState({
      emailId: "",
      contactNo: "",
      companyName: "",
      // attendanceoption:'',
      planName: "",
      emailIdValid: false,
      contactNoValid: false,
      companyNameValid: false,
      zipCodeValid: false,
      //   attendanceoptionValid: false,
    });
    this.validateForm();
  }

  SiteRegcistrationFun() {
    var key = "shinchanbaby";

    var companyZone = this.state.timeZone;
    var offset = moment.tz(moment.utc(), companyZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS

    var todayDate = this.GetTimeZoneDate(offsetValue);

    var hostedZone = "Asia/Kolkata";
    //var hostedZone="ohio";
    var offset = moment.tz(moment.utc(), hostedZone).utcOffset();
    var offsetValue = Number(offset) / 60; //CONVERTING MIN INTO HRS

    var decDate = this.GetTimeZoneDate(offsetValue); // EX:2019-12-25

    // var decDateConstraint=decDate.split("-")[0]+"-"+"12"+"-"+"22";

    var hostedYear = decDate.split("-")[0];

    //new Date(year, month, date, hours, minutes, seconds, ms)
    var decDateConstraint = new Date(hostedYear, 11, 28, 23, 30, 0, 0);
    decDate = new Date(decDate);
    decDateConstraint = new Date(decDateConstraint);

    var startDate = new Date(todayDate);
    var endDate = new Date(decDate);

    if (decDate >= decDateConstraint) {
      // if( startDate >= endDate){
      this.state.holidayTableNextYear = "Yes";
      //   alert("YES CREATE TABLE FOR FUTURE");
      //   }
    } else {
      //  alert("DON'T CREATE TABLE FOR FUTURE");
    }

    //  //console.log("PINCODE BEFORE CHECK :",this.state.pincode);
    if (this.state.pincode == "") {
      this.state.pincode = "000000";
    }
    if (this.state.zipCode == "") {
      this.state.zipCode = "000000";
    }

    this.setState({
      zipCode: this.state.zipCode,
    });

    //  //console.log("PINCODE AFTER CHECK :",this.state.pincode);

    localStorage.setItem(
      "companyName",
      CryptoJS.AES.encrypt(this.state.companyName, key)
    );

    localStorage.setItem(
      "CurrencyCode",
      CryptoJS.AES.encrypt(this.state.currencyCode, key)
    );
    localStorage.setItem(
      "EmailId",
      CryptoJS.AES.encrypt(this.state.emailId, key)
    );
    localStorage.setItem(
      "contactNo",
      CryptoJS.AES.encrypt(this.state.contactNo, key)
    );
    localStorage.setItem(
      "Password",
      CryptoJS.AES.encrypt(this.state.password, key)
    );
    //  localStorage.setItem('Address', CryptoJS.AES.encrypt(this.state.address, key));
    localStorage.setItem(
      "planName",
      CryptoJS.AES.encrypt(this.state.planName, key)
    );
    localStorage.setItem(
      "planId",
      CryptoJS.AES.encrypt(this.state.planId, key)
    );
    localStorage.setItem(
      "permission",
      CryptoJS.AES.encrypt(this.state.permission, key)
    );
    localStorage.setItem(
      "permissionHeader",
      CryptoJS.AES.encrypt(this.state.permissionHeader, key)
    );
    localStorage.setItem(
      "DoorNo",
      CryptoJS.AES.encrypt(this.state.doorNo, key)
    );
    localStorage.setItem("Floor", CryptoJS.AES.encrypt(this.state.floor, key));
    localStorage.setItem(
      "Street",
      CryptoJS.AES.encrypt(this.state.street, key)
    );
    localStorage.setItem("City", CryptoJS.AES.encrypt(this.state.city, key));
    localStorage.setItem("Area", CryptoJS.AES.encrypt(this.state.area, key));
    localStorage.setItem(
      "Zipcode",
      CryptoJS.AES.encrypt(this.state.zipCode, key)
    );
    localStorage.setItem(
      "Pincode",
      CryptoJS.AES.encrypt(this.state.pincode, key)
    );
    localStorage.setItem("State", CryptoJS.AES.encrypt(this.state.state, key));

    localStorage.setItem("Date", CryptoJS.AES.encrypt(todayDate, key));
    localStorage.setItem(
      "TimeZone",
      CryptoJS.AES.encrypt(this.state.timeZone, key)
    );
    localStorage.setItem(
      "HolidayTableNextYear",
      CryptoJS.AES.encrypt(this.state.holidayTableNextYear, key)
    );

    var self = this;

    if (
      this.state.timeZone != "" &&
      this.state.timeZone != undefined &&
      this.state.currencyCode != ""
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyName: this.state.companyName,
          emailId: this.state.emailId,
          password: this.state.password,
          contactNo: this.state.contactNo,
          roleName: this.state.roleName,
          doorNo: this.state.doorNo,
          floor: this.state.floor,
          street: this.state.street,
          city: this.state.city,
          area: this.state.area,
          zipCode: this.state.zipCode,
          pincode: this.state.pincode,
          currencyCode: this.state.currencyCode,
          state: this.state.state,

          //    attendanceoption:this.state.attendanceoption,
          //  holidayTableNextYear:this.state.holidayTableNextYear,
          //  timeZone:this.state.timeZone,
          //   date:todayDate,
        }),
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/SiteRegistration/RegisterSite",
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });

          if (data.emailId == "EMAILID") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "The Email Id You Have Entered Is Already Registered For An Organization ",
              showConfirmButton: false,
              timer: 2000,
            });
            self.state.companyName = self.state.companyName;

            self.state.emailId = self.state.emailId;
            self.state.password = self.state.password;
            self.state.contactNo = self.state.contactNo;
            self.state.roleName = self.state.roleName;
            self.state.doorNo = self.state.doorNo;
            self.state.floor = self.state.floor;
            self.state.street = self.state.street;
            self.state.city = self.state.city;
            self.state.area = self.state.area;
            self.state.zipCode = self.state.zipCode;
            self.state.pincode = self.state.pincode;
            self.state.timeZone = self.state.timeZone;
            self.state.planName = self.state.planName;
            self.state.planName = self.state.planId;

            self.setState({
              companyName: self.state.companyName,

              emailId: self.state.emailId,
              password: self.state.password,
              contactNo: self.state.contactNo,
              roleName: self.state.roleName,
              doorNo: self.state.doorNo,
              floor: self.state.floor,
              street: self.state.street,
              city: self.state.city,
              area: self.state.area,
              zipCode: self.state.zipCode,
              pincode: self.state.pincode,
              timeZone: self.state.timeZone,
              date: todayDate,
              region: self.state.selectedRegion,
              planName: self.state.planName,
              planId: self.state.planId,
            });
          } else if (data.contactNo == "MOBILE") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "The Mobile No You Have Entered Is Already Registered For An Organization", // Message dialog
              showConfirmButton: false,
              timer: 2000,
            });

            self.state.companyName = self.state.companyName;

            self.state.emailId = self.state.emailId;
            self.state.password = self.state.password;
            self.state.contactNo = self.state.contactNo;
            self.state.roleName = self.state.roleName;
            self.state.doorNo = self.state.doorNo;
            self.state.floor = self.state.floor;
            self.state.street = self.state.street;
            self.state.city = self.state.city;
            self.state.area = self.state.area;
            self.state.zipCode = self.state.zipCode;
            self.state.pincode = self.state.pincode;
            self.state.timeZone = self.state.timeZone;
            self.state.planName = self.state.planName;
            self.state.planId = self.state.planId;

            self.setState({
              companyName: self.state.companyName,

              emailId: self.state.emailId,
              password: self.state.password,
              contactNo: self.state.contactNo,
              roleName: self.state.roleName,
              doorNo: self.state.doorNo,
              floor: self.state.floor,
              street: self.state.street,
              city: self.state.city,
              area: self.state.area,
              zipCode: self.state.zipCode,
              pincode: self.state.pincode,
              timeZone: self.state.timeZone,
              date: todayDate,
              region: self.state.selectedRegion,
              planName: self.state.planName,
              planId: self.state.planId,
            });
          } else if (data.response != "Mailed_Otp") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "Error Occured While Mailing OTP , Kindly Try Registering Again ", // Message dialog
              showConfirmButton: false,
              timer: 2000,
            });
            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <LoginPage />
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
          } else {
            /* Swal.fire({
               position: 'center',
               icon: 'success',
               title: "Mailed OTP To Your Registered EmailId",
               showConfirmButton: false,
               timer: 2000
             })
             */

            //localStorage.setItem('otp1',data.otp);
            localStorage.setItem(
              "OTP",
              CryptoJS.AES.encrypt(JSON.stringify(data.otp), key)
            );
            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <SiteLocation />
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
          }

          self.state.timezone = "";
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      if (this.state.timeZone == "" || this.state.timeZone == undefined) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Kindly Select TimeZone",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (this.state.currencyCode == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Kindly Select CurrencyCode for your Organization",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  }
  cancelFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <LoginPage />
        </Suspense>
      </Router>,
      document.getElementById("root")
    );
  }

  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={LoginPage} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("root")
    );
    registerServiceWorker();
  }
  AttendanceFunc = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  handleUserInputRegion = (e) => {
    var self = this;

    const value = e.value;
    this.setState({
      region: value,
      selectedRegion: e,
      valid: true,
      timeZone: "",
    });

    const Timezones = ct.getTimezonesForCountry(value);
    var timeZoneOptions = [];
    for (var i = 0; i < Timezones.length; i++) {
      timeZoneOptions.push({
        label: Timezones[i].name,
        value: Timezones[i].name,
      });
    }

    self.state.timeZoneOptions = timeZoneOptions;
    self.setState({
      timeZoneOptions: timeZoneOptions,
    });
  };

  handleUserInputTimeZone(e) {
    var self = this;

    const value = e.value;
    this.setState({
      timeZone: value,
      selectedTimeZone: e,
      valid: true,
    });
  }

  GetCountry() {
    var self = this;

    const countries = ct.getAllCountries();

    var groupedData = _.groupBy(countries, "id");

    var partionedData = _.partition(groupedData, ",")[1];

    var options = [];
    var tabdata = "";
    for (var z = 0; z < _.size(partionedData); z++) {
      var partionedData1 = partionedData[z];
      options.push({
        label: partionedData1[0].name,
        value: partionedData1[0].id,
      });
    }

    self.state.options = options;
    self.setState({
      options: options,
    });
  }

  GetTimeZoneDate(offset) {
    //  var offset = -8;
    var todayDate = new Date(new Date().getTime() + offset * 3600 * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");

    var d1 = new Date(todayDate);
    var d2 =
      d1.getFullYear() +
      "-" +
      ("0" + (d1.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d1.getDate()).slice(-2);

    return d2;
  }

  /*
  CODE FOR CURRENCY DROPDOWN
  */
  handleUserInputCurrency = (e) => {
    var self = this;

    //console.log("E VALUE :", e);

    this.state.currencyOption = e;
    this.setState({
      currencyOption: this.state.currencyOption,
    });
    var currentCurrencyFormatChosen = _.findWhere(currencies, {
      code: e.value,
    });
    //console.log("currentCurrencyFormatChosen :", currentCurrencyFormatChosen);

    this.state.currencyCode = e.value;
    this.SetCurrencyValue(currentCurrencyFormatChosen);
  };

  SetCurrencyValue(currentCurrencyFormatChosen) {
    this.state.infoCurrencyInputVal = 10000;
    var infoC_ForamatterVal = currencyFormatter.format(
      this.state.infoCurrencyInputVal,
      { code: currentCurrencyFormatChosen.code }
    );

    //console.log("currencyFormatter", currencyFormatter, currentCurrencyFormatChosen.code, infoC_ForamatterVal)

    this.state.infoCurrencyInputVal = this.state.infoCurrencyInputVal;
    this.state.infoCurrencyCode = currentCurrencyFormatChosen.code;
    this.state.infoCurrencySymbol = currentCurrencyFormatChosen.symbol;
    this.state.infoC_ForamatterVal = infoC_ForamatterVal;

    this.setState({
      infoCurrencyInputVal: this.state.infoCurrencyInputVal,
      infoCurrencyCode: this.state.infoCurrencyCode,
      infoCurrencySymbol: this.state.infoCurrencySymbol,
      infoC_ForamatterVal: this.state.infoC_ForamatterVal,
    });

    //console.log("SetCurrencyValue ...... this.state :", this.state);
  }

  /*
CODE FOR SELECTING THE PLAN
*/
  handleChangeSelectedPlan = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.planOption = e;

    this.state.planName = e.label;
    this.state.planId = e.value;
    this.setState(
      {
        planName: this.state.planName,
        planId: this.state.planId,
      },
      () => {
        this.validateField("planName", this.state.planName);
      }
    );

    this.state.planExplanation = "";
    var currentPlanData = _.where(planData, { planId: this.state.planId });

    this.state.planExplanation = currentPlanData[0].explanation;

    if (
      this.state.planExplanation == undefined ||
      this.state.planExplanation == null
    ) {
      this.state.planExplanation = "";
    }
    this.setState({
      planName: this.state.planName,
      planId: this.state.planId,
      planExplanation: this.state.planExplanation,
    });

    this.handleUserPackageInput();
  };

  render() {
    const { country, region } = this.state;

    return (
      <div class="container-fluid">
        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />

        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Company Registration Form</h3>
        </div>
        <div>
          <Form />
        </div>
      </div>
    );
  }
}

export default SiteRegister;
