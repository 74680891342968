//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "underscore";
import ReactTable from "react-table";

//IMPORT REACT COMPONENT CSS
import "react-table/react-table.css";
import "sweetalert2/src/sweetalert2.scss";

//IMPORT CLASS COMPONENT CSS
import "./datepicker.css";
import "./ReactTableCSS.css";
import "./AvailableStockReport.css";
import "./ProfitLossReport.css";
import "./Availablebar.scss";

//IMPORT CLASS COMPONENT
import { NetworkErrorComponent } from "../components/Assets_Components/MessagePage_Components/MessagePage_Components";
import { NoDataComponent } from "../components/Assets_Components/MessagePage_Components/MessagePage_Components";
import LoadingComponent from "./Loading/LoadingComponent";
import { Double_BackButtonComponent } from "./ServiceRegistration/ButtonComponent";
import {
  GetCurrentSite,
  GetEmployeeSite,
  SetCurrentPage,
} from "./ConstSiteFunction";
import { FilterOptions, SiteDropDown_JobCard } from "./SiteDropDown";

const ReportMenuPage = lazy(() => import("./ReportMenuPage"));
const ReportMenuPagePremium = lazy(() => import("./ReportMenuPagePremium"));
const ReportMenuPageBasic = lazy(() => import("./ReportMenuPageBasic"));

var days1;
var today;
var currentRow;
var greaterNo;
var rowInfoData = "";
var dataList = [];

class AvailableStockReport extends Component {
  constructor(props) {
    today = new Date();
    var companyId = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
      companyId: companyId,
      period: "",
      errorData: "",
      data: [],
      columns: [],
      site: GetCurrentSite(),
      modalIsOpen: false,
    };
    this.handleSite = this.handleSite.bind(this);
    this.getColumns = this.getColumns.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("AvailableStockReport");
    window.scrollTo(0, 0);
    this.GetStockDetails();
  }

  handleSite = (e) => {
    this.handleSite = this.handleSite.bind(this);
    this.state.site = e.toString();
    //console.log("e ", this.state.site);
    this.setState({
      site: e.toString(),
    });
    //console.log("e ", dataList, e.toString());
    //var result = FilterOptions(dataList.serviceRegistrationList, this.state.site);
    //  // this.RendData(result);
    // console.log("*** handleSite result :", result);
    var statusData = [];
    var currentData;

    var result = FilterOptions(dataList, this.state.site);
    // this.RendData(result);
    console.log("*** handleSite result :", result);
    this.PopulateTable(result);
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });

    if (value != 0) {
      var isNumberDt = $.isNumeric(value);

      if (isNumberDt !== false) {
        var sign_data = Math.sign(value);

        if (sign_data != -1) {
          var decimal_data = value - Math.floor(value) !== 0;

          if (decimal_data == false) {
            this.state.errorData = "";
          } else {
            //decimal data not accepted
            this.state.errorData = "! DecimalValue Not Accepted";
          }
        } else {
          //negative sign not accepted
          this.state.errorData = "! Negative Value Not Accepted";
        }
      } else {
        //not  a number
        this.state.errorData = "! Enter A Valid Number";
      }
    } else {
      //no zero accepted
      this.state.errorData = "! Zero Value Not Accepted";
    }
  };

  PopulateTable(data) {
    var self = this;

    var no = 0;
    self.state.data = [];
    self.setState({
      data: self.state.data,
    });

    $.each(data, function (i, item) {
      var action = <div style={{ textAlign: "center" }}>-</div>;
      if (item.productCategory == "Own" && item.productType != "service") {
        /* action = <div style={{ textAlign: "center" }}><button id="add" href="#myModalview"
                     data-toggle="modal" data-target="#myModalview">Add</button></div>;
                     */
        action = (
          <div className="div-add-btn">
            <button
              id="add"
              href="#myModalview"
              className="add-btn"
              data-toggle="modal"
              data-target="#myModalview"
            >
              Add
            </button>
          </div>
        );
      }
      console.log(
        "item.productName :",
        item.productName,
        " item.productMeasurementUnit :",
        item.productMeasurementUnit
      );

      var productMeasurementUnit =
        " (" + item.productMeasurementUnit.split("(")[1];

      if (item.productType == "product") {
        // var color = "green";
        var color = "#2196f3";
        var widthPercent = item.quantity;
        no = Number(no) + Number(1);
        var redStyling;

        widthPercent = Number(item.quantity) / 5;

        if (Number(widthPercent) <= 5) {
          widthPercent = 5;
        }
        while (Number(widthPercent) > 100) {
          widthPercent = Number(widthPercent) / 5;
        }

        if (Number(item.quantity) != 0) {
          if (Number(item.quantity) < Number(item.quantityLimit)) {
            //  color = "yellow";
            color = "#f32821";
          }

          /*  self.state.data[i] = {
                          "ProductId": item.productId,
                          "SNO": no,
                          "Name": item.productName,
                          "Available Stock": <div><div className="w3-progress-container w3-round-xlarge ">
                              <div className="w3-progressbar w3-round-xlarge  " style={{ width: +widthPercent + "%", backgroundColor: color }}></div>
                          </div><span className='number'>{item.quantity + productMeasurementUnit + " Remaining"}</span></div>,
                          "Quantity": item.quantity,
                          "Unit": productMeasurementUnit,
                          "Action": action,
                          "Site": item.site,
                          "QuantityLimit": item.quantityLimit
                      };
                      */
          self.state.data[i] = {
            ProductId: item.productId,
            SNO: no,
            Name: item.productName,
            "Available Stock": (
              <div>
                <div id="prog-bar-cont">
                  <div id="prog-bar">
                    <div
                      id="background"
                      className="w3-progressbar w3-round-xlarge
                                      progress-bar progress-bar-striped active"
                      style={{
                        width: +widthPercent + "%",
                        backgroundColor: color,
                      }}
                    ></div>
                  </div>
                </div>
                <span className="number">
                  {item.quantity + productMeasurementUnit + " Remaining"}
                </span>
              </div>
            ),
            Quantity: item.quantity,
            Unit: productMeasurementUnit,
            Action: action,
            QuantityLimit: item.quantityLimit,
          };
        } else if (Number(item.quantity) == 0) {
          color = "red";
          widthPercent = "100";

          self.state.data[i] = {
            ProductId: item.productId,
            SNO: no,
            Name: item.productName,
            "Available Stock": (
              <div>
                <div id="prog-bar-cont">
                  <div id="prog-bar">
                    <div
                      id="background"
                      className="w3-progressbar w3-round-xlarge
                                      progress-bar progress-bar-striped active"
                      style={{
                        width: +widthPercent + "%",
                      }}
                    ></div>
                  </div>
                </div>
                <span className="number">
                  {item.quantity + productMeasurementUnit + " Remaining"}
                </span>
              </div>
            ),
            Quantity: item.quantity,
            Unit: productMeasurementUnit,
            Action: action,
            Site: item.site,
            QuantityLimit: item.quantityLimit,
          };
        }
      } else {
        //when product type is service
        self.state.data[i] = {
          ProductId: item.productId,
          SNO: no,
          Name: item.productName,
          "Available Stock": (
            <div>
              <span>{"SERVICE"}</span>
            </div>
          ),
          Quantity: "",
          Action: action,
          Site: "",
          QuantityLimit: "",
        };
      }
    });

    self.state.columns = self.getColumns();
    self.setState({
      data: self.state.data,
      columns: self.state.columns,
    });
  }

  GetStockDetails() {
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    //         var companyId = CryptoJS.AES.decrypt(localStorage.getItem('CompanyId'), "shinchanbaby").toString(CryptoJS.enc.Utf8)
    //   self.state.companyId=companyId;
    //   this.setState({
    //       companyId:self.state.companyId,
    //   })
    var self = this;
    this.state.data = [];

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        empSites: GetEmployeeSite(),
      }),

      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/InventoryReport/AvailableStockReport",
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
        console.log("AVAILABLE STOCK SUMMARY REPORT :", data);
        dataList = data;
        self.handleSite(self.state.site);
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  onRowClick = (state, rowInfo, column, instance) => {
    var self = this;
    return {
      onClick: (e, handleOriginal) => {
        if (column.Header == "Action") {
          if (rowInfo != undefined) {
            rowInfoData = "";

            self.state.productId = rowInfo.original["ProductId"];
            self.state.productName = rowInfo.original["Name"]; // get current row 1st TD value
            //  var quantity = rowInfo.original["Available Stock"].props.children[1].props.children;
            //  quantity = quantity.match(/\d/g);
            //  quantity = quantity.join("");

            self.state.oldquantity = rowInfo.original["Quantity"];
            self.state.quantity = rowInfo.original["Quantity"];
            self.state.quantityLimit = rowInfo.original["QuantityLimit"];

            self.setState({
              productName: self.state.productName,
              productId: self.state.productId,
              quantity: self.state.quantity,
              oldquantity: self.state.oldquantity,
            });

            rowInfoData = rowInfo;

            // $.noConflict();
            // $('#myModalview').modal('show');
          }
        }
      },
    };
  };

  AddQuantity() {
    var self = this;

    var productId = rowInfoData.original["ProductId"];
    var sno = rowInfoData.original["SNO"];
    var name = rowInfoData.original["Name"];
    var availableStock = rowInfoData.original["Available Stock"];
    var productMeasurementUnit = rowInfoData.original["Unit"];
    var action = rowInfoData.original["Action"];
    var quantityLimit = rowInfoData.original["QuantityLimit"];
    var rowIndexValue = rowInfoData.index;

    if (this.state.errorData == "") {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: this.state.companyId,
          productId: this.state.productId,
          quantity: this.state.quantity,
        }),

        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/InventoryReport/ProductUpdate",
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
          if (data.productCategory == "Success") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Add Qunatity For The Product " + self.state.productName,
              showConfirmButton: false,
              timer: 2000,
            });

            // var color = "green";
            var color = "#2196f3";
            var quantity =
              Number(self.state.oldquantity) + Number(self.state.quantity);
            var widthPercent = quantity;

            widthPercent = Number(quantity) / 5;

            if (Number(widthPercent) <= 5) {
              widthPercent = 5;
            }

            while (Number(widthPercent) > 100) {
              widthPercent = Number(widthPercent) / 5;
            }

            if (Number(quantity) != 0) {
              if (Number(quantity) < Number(self.state.quantityLimit)) {
                // color = "yellow";
                color = "#f32821";
              }

              var array = [...self.state.data]; // make a new copy of array instead of mutating the same array directly.
              array.splice(rowIndexValue, 1);
              self.state.data = [];
              self.state.data = array;
              self.setState({ data: array });

              for (var i = self.state.data.length; i > rowIndexValue; i--) {
                self.state.data[i] = self.state.data[i - 1];
              }

              self.state.data[rowIndexValue] = {
                ProductId: productId,
                SNO: sno,
                Name: name,
                "Available Stock": (
                  <div>
                    <div className="w3-progress-container w3-round-xlarge">
                      <div
                        className="w3-progressbar w3-round-xlarge "
                        style={{
                          width: widthPercent + "%",
                          backgroundColor: color,
                        }}
                      ></div>
                    </div>
                    <span>
                      {quantity + productMeasurementUnit + " Remaining"}
                    </span>
                  </div>
                ),
                Quantity: quantity,
                Unit: productMeasurementUnit,
                Action: action,
                QuantityLimit: quantityLimit,
              };
            } else if (Number(quantity) == 0) {
              color = "red";
              widthPercent = "50";

              var array = [...self.state.data]; // make a new copy of array instead of mutating the same array directly.
              array.splice(rowIndexValue, 1);
              self.state.data = [];
              self.state.data = array;
              self.setState({ data: array });

              for (var i = self.state.data.length; i > rowIndexValue; i--) {
                self.state.data[i] = self.state.data[i - 1];
              }

              self.state.data[rowIndexValue] = {
                ProductId: productId,
                SNO: sno,
                Name: name,
                "Available Stock": (
                  <div>
                    <div className="w3-progress-container w3-round-xlarge">
                      <div
                        className="w3-progressbar w3-round-xlarge diagnalstrips"
                        style={{
                          width: +widthPercent + "%",
                          backgroundColor: color,
                        }}
                      ></div>
                    </div>
                    <span>
                      {quantity + " (" + productMeasurementUnit + " Remaining"}
                    </span>
                  </div>
                ),
                Quantity: quantity,
                Unit: productMeasurementUnit,
                Action: action,
                QuantityLimit: quantityLimit,
              };
            }
          } else if (data.productCategory == "Fail") {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Try Updating Afetr SomeTime",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Updation Failed Due To Improper Data",
        showConfirmButton: false,
        timer: 2000,
      });
    }

    self.state.productId = "";
    self.state.productName = "";
    self.state.quantity = "";
    self.state.oldquantity = "";
    self.state.quantityLimit = "";
    self.state.errorData = "";

    self.setState({
      productId: self.state.productId,
      productName: self.state.productName,
      quantity: self.state.quantity,
      oldquantity: self.state.oldquantity,
      quantityLimit: self.state.quantityLimit,
      errorData: self.state.errorData,
    });
  }

  getColumns() {
    return Object.keys(this.state.data[0]).map((key) => {
      if (
        key != "ProductId" &&
        key != "QuantityLimit" &&
        key != "Quantity" &&
        key != "Unit"
      ) {
        return {
          Header: key,
          accessor: key,
        };
      } else {
        return {
          Header: key,
          accessor: key,
          show: false,
        };
      }
    });
  }

  BackbtnFunc() {
    var planName = CryptoJS.AES.decrypt(
      localStorage.getItem("PlanName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route exact path="/" component={ReportMenuPage} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  render() {
    return (
      <div className="container-fluid">
        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
        <div className="two_HeaderCls">
          <Double_BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Available Stock Report</h3>
          {/*  <button type="button" id="print" className="btn btn-default btn_rpt_print "
              onClick={() => this.printdiv('printarea')} >
              <i className="fa fa-print" aria-hidden="true"
                style={{ fontSize: "17px", border: "none" }}> Print</i></button>
         */}
        </div>

        <div>
          <div className="row">
            <div className="col-sm-4">
              <div>
                <div id="prog-bar-cont-1">
                  <div id="prog-bar-1">
                    <div
                      className="w3-progressbar w3-round-xlarge 
                                      progress-bar progress-bar-striped active"
                      style={{
                        width: "100%",
                        backgroundColor: "#2196f3",
                      }}
                    ></div>
                  </div>
                </div>
                <p className="stock-text">Stock Above Limit</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <div id="prog-bar-cont-2">
                  <div id="prog-bar-2">
                    <div
                      className="w3-progressbar w3-round-xlarge 
                                      progress-bar progress-bar-striped active"
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                      }}
                    ></div>
                  </div>
                </div>
                <p className="stock-text">Stock Below Limit</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <div id="prog-bar-cont-3">
                  <div id="prog-bar-3">
                    <div
                      className="w3-progressbar w3-round-xlarge 
                                      progress-bar progress-bar-striped active"
                    ></div>
                  </div>
                </div>
                <p className="stock-text">Stock Empty</p>
              </div>
            </div>
          </div>

          <SiteDropDown_JobCard
            onSiteDropDown={this.handleSite}
            data={this.state.site}
          />

          <ReactTable
            data={this.state.data}
            columns={this.state.columns}
            NoDataComponent={NoDataComponent}
            filterable={false}
            defaultPageSize={10}
            className="-striped -highlight reactTable_cls mt-10"
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
            showPaginationTop={true}
            showPaginationBottom={false}
            getTdProps={this.onRowClick}
          />

          <div className="text-center" id="tableheading"></div>
          <table
            className="table table-bordered table-styles"
            id="availablestocktable"
          ></table>
          <div className="modal fade" id="myModalview" data-backdrop="false">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Quantity</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <label for="productname">PrductName</label>

                  <input
                    type="text"
                    className="textfield"
                    onChange={this.handleUserInput}
                    value={this.state.productName}
                    id="productname"
                    name="productName"
                    readOnly
                  />

                  <label for="quantity">Quantity</label>

                  <input
                    type="text"
                    className="textfield"
                    onChange={this.handleUserInput}
                    value={this.state.quantity}
                    id="quantity"
                    name="quantity"
                  />
                  <span id="quantityerror">{this.state.errorData}</span>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.AddQuantity()}
                    data-dismiss="modal"
                  >
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AvailableStockReport;
