//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import ForgotPassword from "./ForgotPassword";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LandingPage_tagarage from "./LandingPage_tagarage/LandingPage_tagarage";
import _ from "underscore";

//IMPORT REACT COMPONENT CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import "sweetalert2/src/sweetalert2.scss";

//IMPORT CLASS COMPONENT CSS
import "./LoginPage.css";
import "./LoginPageNewCss.css";
import "./NewLoginStyles.css";

//IMPORT CLASS COMPONENT
import { AlertMessageInfoComponent, NetworkErrorComponent } from "../components/Assets_Components/MessagePage_Components/MessagePage_Components";
import LoadingComponent from "./Loading/LoadingComponent";
import { SetCurrentPage, Check_AccessTime_Login } from "./ConstSiteFunction";
import NewMenuBar from "./Topnavbar/NewMenuBar";
import GenericDashboardBasic from "./Topnavbar/GenericDashboardBasic";
import GenericDashboardPremium from "./Topnavbar/GenericDashboardPremium";
import SiteRegister from "./SiteRegister";
import LicenseEntryForm from "./LicenseEntryForm";

import Addresslogo from "./image/Garageapp-MasterLogo.png";
import carimg from "./image/Car.PNG";
import truckimg from "./image/truck.PNG";
import Shipimg from "./image/ship.PNG";
import TextField from "@mui/material/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import SimpleImageSlider from "react-simple-image-slider";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LogOut } from "./Common Components/CommonComponents";

const styles = {
  width: "80%",
  marginTop: "10px",
};

class LoginPage extends Component {
  constructor() {
    super();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    this.state = {
      modalIsOpen: false,
      emailId: "",
      password: "",
      date: date,
      formErrors: { emailId: "", password: "" },
      emailIdValid: false,
      passwordValid: false,
      showPassword: false,
    };
    this.setState({
      date: date,
    });
    this.CheckLogin = this.CheckLogin.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("LoginPage");
    window.scrollTo(0, 0);
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }

    this.state.interval_refresh = setInterval(() => this.CheckLogin(), 200000);
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailIdValid = this.state.emailIdValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "emailId":
        emailIdValid = value.length >= 10;
        {
          /*  emailIdValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);*/
        }
        fieldValidationErrors.EmailId = emailIdValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid =
          value.length >= 5 &&
          value.match(/^((?=.*[0-9])(?=.*[A-Z])(?=.{8,}))/);
        fieldValidationErrors.Password = passwordValid
          ? ""
          : "should be at least 8 character long/include at least one capital letter and number";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailIdValid: emailIdValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailIdValid && this.state.passwordValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  Fpassword() {
    ReactDOM.render(<ForgotPassword />, document.getElementById("root"));
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  handleClickShowPassword = (e) => {
    var passwordInput = document.getElementById("password");
    var passStatus = document.getElementById("togglePassword");
    if (passwordInput.type == "password") {
      passwordInput.type = "text";
      this.state.showPassword = true;
      this.setState({
        showPassword: this.state.showPassword,
      });
    } else {
      passwordInput.type = "password";
      this.state.showPassword = false;
      this.setState({
        showPassword: this.state.showPassword,
      });
    }
  };

  Login() {
    var key = "shinchanbaby";

    localStorage.setItem(
      "EmailId",
      CryptoJS.AES.encrypt(this.state.emailId, key)
    );
    localStorage.setItem(
      "Password",
      CryptoJS.AES.encrypt(this.state.password, key)
    );
    var self = this;

    var accessTime = Check_AccessTime_Login();

    if( this.state.emailId!="" && this.state.emailId!=undefined && this.state.password!="" && this.state.password!=undefined ){

    if (
      accessTime.serverAccessData == true ||
      accessTime.serverAccessData == false
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          emailId: this.state.emailId,
          password: this.state.password,
          date: this.state.date,
        }),

        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/Login/LoginCheck1",

        contentType: "application/json",
        dataType: "json",
        async: false,

        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
          console.log("LOGIN DATA :", data);
          if (window.location.pathname.toLowerCase() === "/signup") {
            var url_string = window.location.href;
            var newurl = "/";
            window.history.replaceState({}, document.title, newurl);
          }

          if (data.staffId == "NOT_REGISTERED") {
            self.state.userLoginStatus = false;
            self.setState({
              userLoginStatus: self.state.userLoginStatus,
            });

            Swal.fire({
              position: "center",
              icon: "warning",
              title: "Please Register to Login",
              showConfirmButton: false,
              timer: 2000,
            });

            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <div>
                    <Route path="/" component={LoginPage} />
                  </div>
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
            registerServiceWorker();
          } else if (data.staffId == "PASSWORD_INCORRECT") {
            self.state.userLoginStatus = false;
            self.setState({
              userLoginStatus: self.state.userLoginStatus,
            });

            Swal.fire({
              position: "center",
              icon: "warning",
              title:
                "Incorrect Password.Enter Correct Password or Click on Forgot Password to reset",
              showConfirmButton: false,
              timer: 2000,
            });

            ReactDOM.render(
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <div>
                    <Route path="/" component={LoginPage} />
                  </div>
                </Suspense>
              </Router>,
              document.getElementById("root")
            );
            registerServiceWorker();
          } else if (data.status == "active") {
            if (data.emailId == "Success") {
              self.state.userLoginStatus = true;
              self.setState({
                userLoginStatus: self.state.userLoginStatus,
              });

              var notificationsConfigList = data.notificationsConfigList;

              var whatsAppArray = _.where(notificationsConfigList, {
                moduleName: "whatsApp",
              });

              localStorage.setItem(
                "WhatsAppKeyId",
                CryptoJS.AES.encrypt(whatsAppArray[0].keyId, key)
              );
              localStorage.setItem(
                "WhatsAppKeyToken",
                CryptoJS.AES.encrypt(whatsAppArray[0].keyToken, key)
              );
              localStorage.setItem(
                "WhatsAppSenderNo",
                CryptoJS.AES.encrypt(whatsAppArray[0].senderNo, key)
              );

              localStorage.setItem(
                "MessageConfiguration_Customer",
                CryptoJS.AES.encrypt(
                  JSON.stringify(data.featureDetailsList),
                  "shinchanbaby"
                )
              );

              localStorage.setItem(
                "isLoggedIn",
                CryptoJS.AES.encrypt("true".toString(), key)
              );

              localStorage.setItem(
                "CompanyId",
                CryptoJS.AES.encrypt(data.companyId, key)
              );
              localStorage.setItem(
                "CompanyEmailId",
                CryptoJS.AES.encrypt(data.companyEmailId, key)
              );
              localStorage.setItem(
                "ContactNo",
                CryptoJS.AES.encrypt(data.contactNo, key)
              );

              localStorage.setItem(
                "CompanyAddress",
                CryptoJS.AES.encrypt(JSON.stringify(data.companyAddress), key)
              );
              localStorage.setItem(
                "DoorNo",
                CryptoJS.AES.encrypt(data.doorNo, key)
              );
              localStorage.setItem(
                "Floor",
                CryptoJS.AES.encrypt(data.floor, key)
              );
              localStorage.setItem(
                "Street",
                CryptoJS.AES.encrypt(data.street, key)
              );
              localStorage.setItem(
                "Place",
                CryptoJS.AES.encrypt(data.place, key)
              );
              localStorage.setItem(
                "State",
                CryptoJS.AES.encrypt(data.state, key)
              );
              localStorage.setItem(
                "Area",
                CryptoJS.AES.encrypt(data.area, key)
              );
              localStorage.setItem(
                "Zipcode",
                CryptoJS.AES.encrypt(data.zipCode, key)
              );

              localStorage.setItem(
                "LandlineNo",
                CryptoJS.AES.encrypt(data.landlineNo, key)
              );
              localStorage.setItem(
                "FeedbackNo",
                CryptoJS.AES.encrypt(data.feedbackNo, key)
              );
              localStorage.setItem(
                "CompanyName",
                CryptoJS.AES.encrypt(data.companyName, key)
              );
              localStorage.setItem(
                "LicenseKey",
                CryptoJS.AES.encrypt(data.licenseKey, key)
              );
              localStorage.setItem(
                "Status",
                CryptoJS.AES.encrypt(data.status, key)
              );
              localStorage.setItem(
                "Roles",
                CryptoJS.AES.encrypt(JSON.stringify(data.employeeRolelist), key)
              );
              localStorage.setItem(
                "Permissions",
                CryptoJS.AES.encrypt(
                  JSON.stringify(data.employeePermisionlist),
                  key
                )
              );
              localStorage.setItem(
                "PermissionHeader",
                CryptoJS.AES.encrypt(
                  JSON.stringify(data.headerPermissionList),
                  key
                )
              );
              localStorage.setItem(
                "SiteDetails",
                CryptoJS.AES.encrypt(JSON.stringify(data.siteDetailList), key)
              );

              if (data.roleName == "Proprietor") {
                localStorage.setItem(
                  "EmpSites",
                  CryptoJS.AES.encrypt(
                    _.pluck(data.siteDetailList, "siteName").toString(),
                    key
                  )
                );
              } else {
                localStorage.setItem(
                  "EmpSites",
                  CryptoJS.AES.encrypt(data.empSites, key)
                );
              }

              localStorage.setItem(
                "CurrentSite",
                CryptoJS.AES.encrypt("", key)
              );

              //console.log("data.roleName ", data.roleName);
              localStorage.setItem(
                "Role",
                CryptoJS.AES.encrypt(data.roleName, key)
              );
              localStorage.setItem(
                "staffId",
                CryptoJS.AES.encrypt(data.staffId, key)
              );
              localStorage.setItem(
                "EmployeeName",
                CryptoJS.AES.encrypt(data.employeeName, key)
              );
              localStorage.setItem(
                "EmpList",
                CryptoJS.AES.encrypt(JSON.stringify(data.employeeList), key)
              );
              localStorage.setItem(
                "VendorList",
                CryptoJS.AES.encrypt(JSON.stringify(data.vendorList), key)
              );

              /* localStorage.setItem(
                "CustomerList",
                CryptoJS.AES.encrypt(JSON.stringify(data.customerList), key)
              );
              */

              localStorage.setItem(
                "CategoryList",
                CryptoJS.AES.encrypt(JSON.stringify(data.categoryList), key)
              );
              localStorage.setItem(
                "UserList",
                CryptoJS.AES.encrypt(JSON.stringify(data.userList), key)
              );

              localStorage.setItem(
                "PlanName",
                CryptoJS.AES.encrypt(data.planName, key)
              );
              localStorage.setItem(
                "RestrictedFeatures",
                CryptoJS.AES.encrypt(data.restrictedFeatures, key)
              );
              localStorage.setItem(
                "RestrictedFeaturesHeader",
                CryptoJS.AES.encrypt(data.restrictedFeaturesHeader, key)
              );

              localStorage.setItem(
                "AttendanceDate",
                CryptoJS.AES.encrypt(data.attendanceDate, key)
              );
              localStorage.setItem(
                "LandlineNo",
                CryptoJS.AES.encrypt(data.landlineNo, key)
              );
              localStorage.setItem(
                "FeedbackNo",
                CryptoJS.AES.encrypt(data.feedbackNo, key)
              );
              localStorage.setItem(
                "ConfigValue",
                CryptoJS.AES.encrypt(data.configValue, key)
              );
              localStorage.setItem(
                "ToggleValue",
                CryptoJS.AES.encrypt(data.toggleValue, key)
              );

              localStorage.setItem(
                "GSTNo",
                CryptoJS.AES.encrypt(data.gstNo, key)
              );
              localStorage.setItem(
                "BankName",
                CryptoJS.AES.encrypt(data.bankName, key)
              );
              localStorage.setItem(
                "BranchName",
                CryptoJS.AES.encrypt(data.branchName, key)
              );
              localStorage.setItem(
                "AccountNo",
                CryptoJS.AES.encrypt(data.accountNo, key)
              );
              localStorage.setItem(
                "IfscCode",
                CryptoJS.AES.encrypt(data.ifscCode, key)
              );
              localStorage.setItem(
                "AccountName",
                CryptoJS.AES.encrypt(data.accountName, key)
              );
              localStorage.setItem(
                "CompanyLogo",
                CryptoJS.AES.encrypt(data.companyLogo, key)
              );
              localStorage.setItem(
                "CompanyQRCode",
                CryptoJS.AES.encrypt(data.qrCodeFileName, key)
              );

              localStorage.setItem(
                "CompanyRegisteredDate",
                CryptoJS.AES.encrypt(data.companyRegisteredDate, key)
              );

              localStorage.setItem(
                "BankDetailsStatus",
                CryptoJS.AES.encrypt(data.bankDetailsStatus, key)
              );

              var planName = CryptoJS.AES.decrypt(
                localStorage.getItem("PlanName"),
                "shinchanbaby"
              ).toString(CryptoJS.enc.Utf8);

              ReactDOM.render(
                <Router>
                  <Suspense fallback={<LoadingComponent />}>
                    <div>
                      <Route path="/" component={NewMenuBar} />
                    </div>
                  </Suspense>
                </Router>,
                document.getElementById("root")
              );
              registerServiceWorker();
            } else {
              self.state.userLoginStatus = false;
              self.setState({
                userLoginStatus: self.state.userLoginStatus,
              });

              Swal.fire({
                position: "center",
                icon: "warning",
                title: "Login Id or Password Incorrect",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          } else if (data.status == "inactive") {
            self.state.userLoginStatus = false;
            self.setState({
              userLoginStatus: self.state.userLoginStatus,
            });
            var notificationsConfigList = data.notificationsConfigList;

            var whatsAppArray = _.where(notificationsConfigList, {
              moduleName: "whatsApp",
            });

            localStorage.setItem(
              "WhatsAppKeyId",
              CryptoJS.AES.encrypt(whatsAppArray[0].keyId, key)
            );
            localStorage.setItem(
              "WhatsAppKeyToken",
              CryptoJS.AES.encrypt(whatsAppArray[0].keyToken, key)
            );
            localStorage.setItem(
              "WhatsAppSenderNo",
              CryptoJS.AES.encrypt(whatsAppArray[0].senderNo, key)
            );

            localStorage.setItem(
              "MessageConfiguration_Customer",
              CryptoJS.AES.encrypt(
                JSON.stringify(data.featureDetailsList),
                "shinchanbaby"
              )
            );

            localStorage.setItem(
              "isLoggedIn",
              CryptoJS.AES.encrypt("true".toString(), key)
            );

            localStorage.setItem(
              "CompanyId",
              CryptoJS.AES.encrypt(data.companyId, key)
            );
            localStorage.setItem(
              "CompanyEmailId",
              CryptoJS.AES.encrypt(data.companyEmailId, key)
            );
            localStorage.setItem(
              "ContactNo",
              CryptoJS.AES.encrypt(data.contactNo, key)
            );

            localStorage.setItem(
              "CompanyAddress",
              CryptoJS.AES.encrypt(JSON.stringify(data.companyAddress), key)
            );
            localStorage.setItem(
              "DoorNo",
              CryptoJS.AES.encrypt(data.doorNo, key)
            );
            localStorage.setItem(
              "Floor",
              CryptoJS.AES.encrypt(data.floor, key)
            );
            localStorage.setItem(
              "Street",
              CryptoJS.AES.encrypt(data.street, key)
            );
            localStorage.setItem(
              "Place",
              CryptoJS.AES.encrypt(data.place, key)
            );
            localStorage.setItem(
              "State",
              CryptoJS.AES.encrypt(data.state, key)
            );
            localStorage.setItem("Area", CryptoJS.AES.encrypt(data.area, key));
            localStorage.setItem(
              "Zipcode",
              CryptoJS.AES.encrypt(data.zipCode, key)
            );

            localStorage.setItem(
              "LandlineNo",
              CryptoJS.AES.encrypt(data.landlineNo, key)
            );
            localStorage.setItem(
              "FeedbackNo",
              CryptoJS.AES.encrypt(data.feedbackNo, key)
            );
            localStorage.setItem(
              "CompanyName",
              CryptoJS.AES.encrypt(data.companyName, key)
            );
            localStorage.setItem(
              "LicenseKey",
              CryptoJS.AES.encrypt(data.licenseKey, key)
            );
            localStorage.setItem(
              "Status",
              CryptoJS.AES.encrypt(data.status, key)
            );
            localStorage.setItem(
              "Roles",
              CryptoJS.AES.encrypt(JSON.stringify(data.employeeRolelist), key)
            );
            localStorage.setItem(
              "Permissions",
              CryptoJS.AES.encrypt(
                JSON.stringify(data.employeePermisionlist),
                key
              )
            );
            localStorage.setItem(
              "PermissionHeader",
              CryptoJS.AES.encrypt(
                JSON.stringify(data.headerPermissionList),
                key
              )
            );
            localStorage.setItem(
              "SiteDetails",
              CryptoJS.AES.encrypt(JSON.stringify(data.siteDetailList), key)
            );

            if (data.roleName == "Proprietor") {
              localStorage.setItem(
                "EmpSites",
                CryptoJS.AES.encrypt(
                  _.pluck(data.siteDetailList, "siteName").toString(),
                  key
                )
              );
            } else {
              localStorage.setItem(
                "EmpSites",
                CryptoJS.AES.encrypt(data.empSites, key)
              );
            }

            localStorage.setItem("CurrentSite", CryptoJS.AES.encrypt("", key));
            localStorage.setItem(
              "Role",
              CryptoJS.AES.encrypt(data.roleName, key)
            );
            localStorage.setItem(
              "staffId",
              CryptoJS.AES.encrypt(data.staffId, key)
            );
            localStorage.setItem(
              "EmployeeName",
              CryptoJS.AES.encrypt(data.employeeName, key)
            );
            localStorage.setItem(
              "EmpList",
              CryptoJS.AES.encrypt(JSON.stringify(data.employeeList), key)
            );
            localStorage.setItem(
              "VendorList",
              CryptoJS.AES.encrypt(JSON.stringify(data.vendorList), key)
            );
            localStorage.setItem(
              "CustomerList",
              CryptoJS.AES.encrypt(JSON.stringify(data.customerList), key)
            );

            localStorage.setItem(
              "CategoryList",
              CryptoJS.AES.encrypt(JSON.stringify(data.categoryList), key)
            );
            localStorage.setItem(
              "UserList",
              CryptoJS.AES.encrypt(JSON.stringify(data.userList), key)
            );
            localStorage.setItem(
              "PlanName",
              CryptoJS.AES.encrypt(data.planName, key)
            );
            localStorage.setItem(
              "RestrictedFeatures",
              CryptoJS.AES.encrypt(data.restrictedFeatures, key)
            );
            localStorage.setItem(
              "RestrictedFeaturesHeader",
              CryptoJS.AES.encrypt(data.restrictedFeaturesHeader, key)
            );

            localStorage.setItem(
              "AttendanceDate",
              CryptoJS.AES.encrypt(data.attendanceDate, key)
            );
            localStorage.setItem(
              "LandlineNo",
              CryptoJS.AES.encrypt(data.landlineNo, key)
            );
            localStorage.setItem(
              "FeedbackNo",
              CryptoJS.AES.encrypt(data.feedbackNo, key)
            );
            localStorage.setItem(
              "ConfigValue",
              CryptoJS.AES.encrypt(data.configValue, key)
            );
            localStorage.setItem(
              "ToggleValue",
              CryptoJS.AES.encrypt(data.toggleValue, key)
            );

            localStorage.setItem(
              "GSTNo",
              CryptoJS.AES.encrypt(data.gstNo, key)
            );
            localStorage.setItem(
              "BankName",
              CryptoJS.AES.encrypt(data.bankName, key)
            );
            localStorage.setItem(
              "BranchName",
              CryptoJS.AES.encrypt(data.branchName, key)
            );
            localStorage.setItem(
              "AccountNo",
              CryptoJS.AES.encrypt(data.accountNo, key)
            );
            localStorage.setItem(
              "IfscCode",
              CryptoJS.AES.encrypt(data.ifscCode, key)
            );
            localStorage.setItem(
              "AccountName",
              CryptoJS.AES.encrypt(data.accountName, key)
            );
            localStorage.setItem(
              "CompanyLogo",
              CryptoJS.AES.encrypt(data.companyLogo, key)
            );
            localStorage.setItem(
              "CompanyQRCode",
              CryptoJS.AES.encrypt(data.qrCodeFileName, key)
            );

            localStorage.setItem(
              "CompanyRegisteredDate",
              CryptoJS.AES.encrypt(data.companyRegisteredDate, key)
            );
            ReactDOM.render(
              <LicenseEntryForm />,
              document.getElementById("root")
            );
            registerServiceWorker();
          } else {
            self.state.userLoginStatus = false;
            self.setState({
              userLoginStatus: self.state.userLoginStatus,
            });

            Swal.fire({
              position: "center",
              icon: "warning",
              title: "License Renewal is Required",
              showConfirmButton: false,
              timer: 2000,
            });
            ReactDOM.render(<LoginPage />, document.getElementById("root"));
          }
        },

        error: function (data) {
          self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
        },
      });
    } else if (accessTime.serverAccessData == false) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Server will be under maintenance from 11:30 pm to 07:00 am",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }else{
    AlertMessageInfoComponent(
      "center",
      "warning",
      "Kindly fillin username and password, to proceed further",
      false,
      "3000"
    );
  }
  }
  SignUpFunc() {
    ReactDOM.render(<SiteRegister />, document.getElementById("root"));
  }
  HomeFunc() {
    ReactDOM.render(<LandingPage_tagarage />, document.getElementById("root"));
  }

  CheckLogin() {
    //  alert("CHECK LOGIN CALLED");

    if (this.state.userLoginStatus == true) {
      //  alert("ADMIN CHECK");
      var currentSite = CryptoJS.AES.decrypt(
        localStorage.getItem("CurrentSite"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);

      this.Login();

      localStorage.setItem(
        "CurrentSite",
        CryptoJS.AES.encrypt(currentSite, "shinchanbaby")
      );
    } else if (
      this.state.userType == "User_Franchise" &&
      this.state.userLoginStatus == false
    ) {
      //  alert("FRANCHISE CHECK false");
      LogOut();
    }
  }

  render() {
    return (
      <div>
        <div>
          <div className="bg-images">
            <div className="container">
            <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-5 center-block">
                  <img
                    src={Addresslogo}
                    alt="logo"
                    className="logo-size text-center"
                  />
                  <h1 className="text-center logo-name">GarageApp</h1>
                  <h3 className="text-center login-sec">
                    Login to your account
                  </h3>
                  <div className="input-icons">
                    <i className="fa fa-user icon"></i>
                    <input
                      type="text"
                      value={this.state.emailId}
                      onChange={this.handleUserInput}
                      id="emailId"
                      name="emailId"
                      placeholder="User Name"
                      className="form-control-input input-field"
                    />
                  </div>
                  <div className="input-icons">
                    <i className="fa fa-lock icon" aria-hidden="true"></i>
                    <input
                      type="password"
                      value={this.state.password}
                      onChange={this.handleUserInput}
                      name="password"
                      id="password"
                      placeholder="password"
                      className="form-control-input"
                    />
                  </div>
                  <a href="#" className="a-tags">
                    <div
                      className="btn btn-danger-button"
                      onClick={() => this.Login()}
                    >
                      Log In
                    </div>
                  </a>
                  <div className="home-forget-section">
                    <i
                      className="fa fa-home home-icons"
                      aria-hidden="true"
                      onClick={() => this.HomeFunc()}
                    ></i>
                    <a href="#" className="a-tags">
                      <p
                        className="text-center forget-sec"
                        onClick={() => this.Fpassword()}
                      >
                        Forget Password?
                      </p>
                    </a>
                  </div>
                  <hr className="hr-class" />
                  <h3 className="text-center login-sec">Not a member yet</h3>
                  <a href="#" className="btn-sign-in">
                    <div
                      className="btn btn-info-button text-center"
                      onClick={() => this.SignUpFunc()}
                    >
                      Sign up
                    </div>
                  </a>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
