//IMPORT FOR REACT NPM
import React, { Component } from 'react'
import _, { values } from 'underscore';
import SelectSearch from 'react-select';
//IMPORT CSS
import './SiteRegister.css';
import 'animate.css';
//IMPORT FOR REACT CLASS
import { ErrorClass } from '../FormErrors';

class SocialProfiles extends Component 
{
   
continue = e => {
  e.preventDefault();
  this.props.nextStep();
};

back = e => {
  e.preventDefault();
  this.props.prevStep();
};
/*USED TO DISPLAY THE UI BOX AS RED COLOR-added by durga */
errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }
    render() {
      const { values, handleUserInput,handleUserInputTimeZone,handleUserInputRegion} = this.props;
        return (
          <div className="form-container">
            <h1 className="mb-5 animate__animated animate__bounce">Company Address</h1>
            <div className="row">
              <div className="col-md-6">
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.doorNoErr)}`}> 
                  <label htmlFor="doorNo">Door No</label>
                  <input
                    type="text"
                    className="form-control"
                    name="doorNo"
                    maxLength={21}
                    value={values.doorNo}
                    onChange={handleUserInput("doorNo")}
                    placeholder="Your doorNo.."
                  />
                <ErrorClass errorContent={values.doorNoErr}></ErrorClass>
                </div>
                
              </div>
              <div className="col-md-6">
                <div className={`form-group ${this.errorClass(values.floorErr)}`}>
                {/* <div className="form-group"> */}
                  <label htmlFor="floor">Floor No</label>
                  <input
                    type="text"
                    className="form-control"
                    name="floor"
                    maxLength={21}
                    value={values.floor}
                    onChange={handleUserInput("floor")}
                    placeholder="Your floor No.."
                  />
                  <ErrorClass errorContent={values.floorErr}></ErrorClass>
                </div>
                
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                 <div className={`form-group ${this.errorClass(values.streetErr)}`}> 
                {/* <div className="form-group"> */}
                  <label htmlFor="street">Street</label>
                  <input
                    type="text"
                    className="form-control"
                    name="street"maxLength={51}
                    value={values.street}
                    onChange={handleUserInput("street")} 
                    placeholder="Your Street.."
                  />
                  <ErrorClass errorContent={values.streetErr}></ErrorClass>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.cityErr)}`}> 
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    maxLength={51}
                    value={values.city}
                    onChange={handleUserInput("city")} 
                    placeholder="Your City.."
                  />
                  <ErrorClass errorContent={values.cityErr}></ErrorClass>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.areaErr)}`}> 
                  <label htmlFor="area">Area</label>
                  <input
                    type="text"
                    className="form-control"
                    name="area"
                    maxLength={51}
                    value={values.area}
                    onChange={handleUserInput("area")}
                    placeholder="Your Area.."
                  />
                  <ErrorClass errorContent={values.areaErr}></ErrorClass>
                </div>
              </div>
            
            </div>

            <div className="row">
            <div className="col-md-6">
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.stateErr)}`}> 
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    maxLength={51}
                    value={values.state}
                    onChange={handleUserInput("state")}
                    placeholder="Your State.."
                  />
                  <ErrorClass errorContent={values.stateErr}></ErrorClass>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.zipCodeErr)}`}> 
                  <label htmlFor="zipCode">ZipCode</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zipCode"
                    maxLength={10}
                    value={values.zipCode}
                    onChange={handleUserInput("zipCode")}
                    placeholder="Your zipCode.."
                  />
                  <ErrorClass errorContent={values.zipCodeErr}></ErrorClass>
                </div>
                </div>
                
             
          
            </div>

            <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="github">Region<span className="mandatory-fields">*</span></label>
                  <SelectSearch options={values.options}
                            value={values.selectedRegion}
                            onChange={handleUserInputRegion}
                            name="region" placeholder="Select Region " />
                </div>
              </div>
             
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="github">TimeZone<span className="mandatory-fields">*</span></label>
                  <SelectSearch options={values.timeZoneOptions}
                            value={values.selectedTimeZone}
                            onChange={ handleUserInputTimeZone}
                            name="timeZone" placeholder="Select TimeZone " />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6 col-xs-4">
                <button className="btn btn-danger" onClick={this.back}>
                  Previous
                </button>
              </div>
              <div className="col-md-6 col-xs-3 text-right">
                <button className="btn btn-primary"disabled={!values.addressFormValid}  onClick={this.continue}>
                  Next
                </button>
              </div>
            </div>
          </div>
        );
    }

 }

export default SocialProfiles
