import React, { Component } from 'react'
import signupimg from "../image/Confirmed1.svg";

export class Success extends Component {
    render() {
        return (
            <div className='success-div'>
                <h1 className="text-white">Account Created Successfully!</h1>
                <img src={signupimg} />
            </div>
        )
    }
}

export default Success
