//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
//IMPORT REACT COMPONENT CSS
import "../datepicker.css";
//IMPORT CLASS COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
//IMPORT REACT ICONS
import * as IoIcons from "react-icons/io5";

const buttonStyles = {
  backgroundColor: "rgb(38 62 172)",
  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Roboto,sans-serif",
  padding: "5px 10px 10px 10px",
  fontWeight: "bold",
  padding: "6px 12px",
  marginRight: "15px",
  // marginLeft: "auto",
  marginTop: "20px",
  // display:"flex"
};

const buttonStylessub = {
  backgroundColor: "rgb(38 62 172)",
  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Roboto,sans-serif",
  padding: "5px 10px 10px 10px",
  fontWeight: "bold",
  padding: "6px 12px",
  marginTop: "20px",
  marginRight: "15px",
  display: "inline-block !important",
};

const backBtnStyle = {
  fontSize: "35px",
  cursor: "pointer",
  display: "inline-block",
  color: "rgb(38 62 172)",
};

let SubmitButtonComponent;
export default SubmitButtonComponent = ({ onClick }) => (
  <button
    style={buttonStylessub}
    onClick={onClick}
    className="btn btn-default  submitLeave_Form"
  >
    Submit
  </button>
);

export const SubmitProceedButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Next
  </button>
);

export const BuyButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Buy
  </button>
);

export const ClearButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Clear
  </button>
);

export const CancelButtonComponent = ({ onClick }) => (
  <button
    style={buttonStylessub}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Cancel
  </button>
);

export const AddButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Add
  </button>
);

export const UpdateButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Update
  </button>
);

export const EditButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Edit
  </button>
);

export const SaveButtonComponent = ({ onClick }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form"
  >
    Save
  </button>
);

export const BackButtonComponent = (props) => {
  //console.log("pop ",props)
  return (
    <div style={backBtnStyle} className="cmn_backbtn">
      <IoIcons.IoCaretBackCircle onClick={props.click} />
    </div>
  );
};

export const ConvertButtonComponent = ({ onClick, buttonName }) => (
  <button
    style={buttonStyles}
    onClick={onClick}
    className="btn btn-default submitLeave_Form btn-botton"
  >
    {buttonName}
  </button>
);

export const Double_BackButtonComponent = (props) => {
  //console.log("pop ",props)
  return (
    <div style={backBtnStyle} className="cmn_backbtn">
      <IoIcons.IoPlayBackCircle onClick={props.click} />
    </div>
  );
};
