//IMPORT REACT COMPONENT

import React, {lazy, Component,Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import CryptoJS from 'crypto-js';
import registerServiceWorker from './components/registerServiceWorker';



//import GenericDashboard from '.components/Topnavbar/GenericDashboard';
//import GenericDashboard from './components/Topnavbar/GenericDashboard'
//import LandingPageDigitalPrinter from './components/LandingPageDigitalPrinter';
//import registerServiceWorker from './registerServiceWorker';
//import './components/LandingPage_tagarage/LandingPage_tagaragecss.css';

/* ReactDOM.render(<LandingPageDigitalPrinter/>, document.getElementById('root'));
 */



 //IMPORT REACT COMPONENT
 import LoadingComponent from './components/Loading/LoadingComponent';
 import LandingPage_tagarage from './components/LandingPage_tagarage/LandingPage_tagarage';


const FooterText = lazy(() => import('./components/FooterText'));
const GenericDashboardBasic = lazy(() => import('./components/Topnavbar/GenericDashboardBasic'));
const GenericDashboardPremium = lazy(() => import('./components/Topnavbar/GenericDashboardPremium'));
const GenericDashboardElite = lazy(() => import('./components/Topnavbar/GenericDashboardElite'));

const feedBackForm = lazy(() => import('./components/feedBackForm'));

const NewMenuBar = lazy(() => import('./components/Topnavbar/NewMenuBar'));
const SiteRegister = lazy(() => import('./components/SiteRegister'));

const CustomerInvoiceList = lazy(() => import('./components/CustomerPayment/CustomerInvoiceList'));

const StripeMessagePage = lazy(() => import( './components/Payment Gateway/Stripe/StripeMessagePage'));

const AddAppointmentCustomized=lazy( () => import('./components/AddAppointmentCustomized'));

if (localStorage.getItem('isLoggedIn')) {
	var login = CryptoJS.AES.decrypt(localStorage.getItem('isLoggedIn'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
	if (login == "true") {
		//console.log("path ", window.location.pathname.toLowerCase());
		window.scrollTo(0, 0);
		var planName = CryptoJS.AES.decrypt(localStorage.getItem("PlanName"), "shinchanbaby").toString(CryptoJS.enc.Utf8);
		if (window.location.pathname.toLowerCase() === "/signup") {
			//console.log("path ", window.location.pathname);
			var url_string = window.location.href;
			var newurl = '/';
			window.history.replaceState({}, document.title, newurl);

		}
	
			ReactDOM.render(
				<Router>
					 <Suspense fallback={  <LoadingComponent /> } >	
					<div >
						<Route exact path="/" component={NewMenuBar} />
						<Route exact path="/feedBackForm*" component={feedBackForm} />
						<Route exact path="/CustomerInvoiceList*" component={CustomerInvoiceList} />
						<Route exact path="/StripeMessagePage*" component={StripeMessagePage} />
						<Route  path="/signup*" component={NewMenuBar} />
						<Route  path="/BookService*" component={AddAppointmentCustomized} />
					</div>
					</Suspense>
				</Router>, document.getElementById('root'));
			registerServiceWorker();
		


	}
	else {
		window.scrollTo(0, 0);
		//console.log("path ", window.location.pathname.toLowerCase());
		if (window.location.pathname.toLowerCase() === "/signup") {
			var url_string = window.location.href;
			var newurl = '/signup';
			window.history.replaceState({}, document.title, newurl);
			ReactDOM.render(
				<Router>
					<Suspense fallback={  <LoadingComponent /> } >	
					<div >
						<Route path="/" component={SiteRegister} />
					</div>
					</Suspense>
				</Router>
				, document.getElementById("root"));
			registerServiceWorker();
		} else {
			ReactDOM.render(
				<Router>
						<Suspense fallback={  <LoadingComponent /> } >	
					<div >

						<Route exact path="/" component={LandingPage_tagarage} />
						<Route exact path="/feedBackForm*" component={feedBackForm} />
						<Route exact path="/CustomerInvoiceList*" component={CustomerInvoiceList} />
						<Route exact path="/StripeMessagePage*" component={StripeMessagePage} />
						<Route  path="/signup*" component={LandingPage_tagarage} />
						{/*  <Route  path="/" component={LoginPage}/> 
	 */}
	 		<Route  path="/BookService*" component={AddAppointmentCustomized} />
				
					</div>
					</Suspense>
				</Router>
				, document.getElementById("root"));

		}
	}

}
else {
	window.scrollTo(0, 0);
	//console.log("path ", window.location.pathname.toLowerCase());
	if (window.location.pathname.toLowerCase() === "/signup") {
		var url_string = window.location.href;
		var newurl = '/signup';
		window.history.replaceState({}, document.title, newurl);
		ReactDOM.render(
			<Router>
				<Suspense fallback={  <LoadingComponent /> } >	
				<div >
					<Route path="/" component={SiteRegister} />
				</div>
				</Suspense>
			</Router>
			, document.getElementById("root"));
		registerServiceWorker();
	} else {
		ReactDOM.render(
			<Router>
				<Suspense fallback={  <LoadingComponent /> } >	
				<div >
					<Route exact path="/" component={LandingPage_tagarage} />
					<Route exact path="/feedBackForm*" component={feedBackForm} />
					<Route exact path="/CustomerInvoiceList*" component={CustomerInvoiceList} />
					<Route exact path="/StripeMessagePage*" component={StripeMessagePage} />
					<Route path="/signup*" component={LandingPage_tagarage} />
					{/* 	 <Route  path="/" component={LoginPage}/> 
					
	 */}	
	 		<Route  path="/BookService*" component={AddAppointmentCustomized} />
				
	 </div>
	 </Suspense>
			</Router>
			, document.getElementById("root"));
	}

}

