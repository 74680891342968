//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";
import _ from "underscore";
import lodash from "lodash";
import FilterResults from "react-filter-search";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";

//IMPORT CLASS COMPONENT CSS
import "./GlobalSearchCSS.css";

//IMPORT CLASS COMPONENT
import LoadingComponent from "../Loading/LoadingComponent";
import ServiceRegistration from "../ServiceRegistration/ServiceRegistration";
import featuresData from "../Admin Configurations/featuresData.json";

const VehicleMakeModel = lazy(() =>
  import("../ServiceRegistration/VehicleMakeModel")
);

const VehicleRegistrationList = lazy(() =>
  import("../VehicleRegistrationList")
);
const EstimateList = lazy(() => import("../EstimateList"));
const Estimate = lazy(() => import("../Estimate"));
const CustomerwisePayment = lazy(() =>
  import("../CustomerPayment/CustomerwisePayment")
);
const AddProduct1 = lazy(() => import("../AddProduct"));
const ProductList1 = lazy(() => import("../ProductList"));
const VendorEntryForm1 = lazy(() => import("../VendorEntryForm"));
const VendorList1 = lazy(() => import("../VendorList"));
const PurchaseInvoice1 = lazy(() => import("../PurchaseInvoice"));
const PurchaseInvoiceList1 = lazy(() => import("../PurchaseInvoiceList"));
const VehicleList1 = lazy(() => import("../VehicleList"));
const CustomerList1 = lazy(() => import("../CustomerList"));

const Expense1 = lazy(() => import("../Expense"));
const AddCategory1 = lazy(() => import("../AddCategory"));
const AddUser1 = lazy(() => import("../AddUser"));
const Enquiry = lazy(() => import("../Enquiry"));
const AddAppointment = lazy(() => import("../AddAppointment"));
const AppointmentCalendar = lazy(() => import("../AppointmentCalendar"));
const AppointmentConfirmation = lazy(() =>
  import("../AppointmentConfirmation")
);
const AddStaff = lazy(() => import("../AddStaff"));
const StaffList1 = lazy(() => import("../StaffList"));
const AddRole1 = lazy(() => import("../AddRole"));
const Salary = lazy(() => import("../Salary"));
const SalaryReport1 = lazy(() => import("../SalaryReport"));
const AddBank = lazy(() => import("../AddBank"));
const BankReport = lazy(() => import("../BankReport"));
const Checkinout = lazy(() => import("../Checkinout"));
const Attendance = lazy(() => import("../Attendance"));
const AttendanceReportMenuPage = lazy(() =>
  import("../AttendanceReportMenuPage")
);
const ReportMenuPage = lazy(() => import("../ReportMenuPage"));
const SalesDailyReport = lazy(() => import("../SalesDailyReport"));
const SalesMonthlyReport = lazy(() => import("../SalesMonthlyReport"));
const SalesYearlyReportMonthYear = lazy(() =>
  import("../SalesYearlyReportMonthYear")
);
const SalesDateWiseReport = lazy(() => import("../SalesDateWiseReport"));
const SalesCustomerStatement = lazy(() => import("../SalesCustomerStatement"));
const EstimateDailyReport = lazy(() => import("../EstimateDailyReport"));
const EstimateMonthlyReport = lazy(() => import("../EstimateMonthlyReport"));
const EstimateYearlyReportMonthYear = lazy(() =>
  import("../EstimateYearlyReportMonthYear")
);
const EstimateDateWiseReport = lazy(() => import("../EstimateDateWiseReport"));
const EstimateCustomerStatement = lazy(() =>
  import("../EstimateCustomerStatement")
);
const PurchaseDailyReport = lazy(() => import("../PurchaseDailyReport"));
const PurchaseMonthlyReport = lazy(() => import("../PurchaseMonthlyReport"));
const PurchaseYearlyReportMonthYear = lazy(() =>
  import("../PurchaseYearlyReportMonthYear")
);
const PurchaseDateWiseReport = lazy(() => import("../PurchaseDateWiseReport"));
const VendorCustomerStatement = lazy(() =>
  import("../VendorCustomerStatement")
);
const ExpDailyReport = lazy(() => import("../ExpDailyReport"));
const ExpMonthlyReport = lazy(() => import("../ExpMonthlyReport"));
const ExpYearlyReportMonthYear = lazy(() =>
  import("../ExpYearlyReportMonthYear")
);
const ExpDateWiseReport = lazy(() => import("../ExpDateWiseReport"));
const MessageCenterReport = lazy(() => import("../MessageCenterReport"));
const ProfitLossReport = lazy(() => import("../ProfitLossReport"));
const TillDateProfitLossReport = lazy(() =>
  import("../TillDateProfitLossReport")
);
const AvailableStockReport = lazy(() => import("../AvailableStockReport"));
const InventorySummaryReport = lazy(() => import("../InventorySummaryReport"));
const AuditReportMenu = lazy(() => import("../AuditReportMenu"));
const ProductMigrationReport = lazy(() =>
  import(
    "../../components/Customer_Features_Modules/Reports/Audit_Report/ProductMigrationReport.js"
  )
);
const BillDueReport = lazy(() => import("../BillDueReport"));
const EnquiryReport = lazy(() => import("../EnquiryReport"));
const MonthlyFeedBackReport = lazy(() => import("../MonthlyFeedBackReport"));
const PaymentHistoryReport = lazy(() => import("../PaymentHistoryReport"));
const AppointmentsHistory = lazy(() => import("../AppointmentsHistory"));
const FutureAppointments = lazy(() => import("../FutureAppointments"));
const CustomerPaymentHistory = lazy(() =>
  import("../CustomerPayment/CustomerPaymentHistory")
);
const MessageCenterMessagePage = lazy(() =>
  import("../Communication//MessageCenterMessagePage")
);
const MessageCenterEmailPage = lazy(() =>
  import("../Communication//MessageCenterEmailPage")
);
const ConfigurationPage = lazy(() => import("../ConfigurationPage"));
const TaskMappingElite = lazy(() => import("../TaskMappingElite"));
const ImportLogo = lazy(() => import("../ImportLogo"));
const CompanySetting = lazy(() => import("../CompanySettings"));
const ChangePassword1 = lazy(() => import("../ChangePassword"));
const Help = lazy(() => import("../Help"));
const LocationPage = lazy(() => import("../LocationPage"));
const ImportMenuPage = lazy(() => import("../ImportMenuPage"));
const ExportMenuPage = lazy(() => import("../ExportMenuPage"));
const ImportQRCode = lazy(() => import("../ImportQRCode"));
const InvoiceMenuPage = lazy(() => import("../Invoice/InvoiceMenuPage"));
const SalesInvoiceHistoryReport = lazy(() =>
  import("../Reports/SaleInvoice/SalesInvoiceHistoryReport")
);
const InvoiceListMenuPage = lazy(() =>
  import("../Invoice/InvoiceListMenuPage")
);
const JobCardReport = lazy(() => import("../Reports/JobCard/JobCardReport"));

export default class GlobalSearch extends Component {
  constructor(props) {
    super(props);

    var restrictedFeatures = CryptoJS.AES.decrypt(
      localStorage.getItem("RestrictedFeatures"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var restrictedFeaturesHeader = CryptoJS.AES.decrypt(
      localStorage.getItem("RestrictedFeaturesHeader"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    window.globalSearchComponent = this;

    this.EmptyGlobalSearch = this.EmptyGlobalSearch.bind(this);

    this.state = {
      data: [
        {
          mainModuleName: "Job Card",
          subModuleName: "Job Card - Add",
          keyWords: "Job Service Registration " + "Customer add vehicle",
          parentLabel: "Job Card",
          childLabel: "Job Card",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "jobcardSubmenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Job card - List/View/Update/Delete",
          keyWords:
            "Job Service Registration servicelist delete update list report " +
            "view",
          parentLabel: "Job Card",
          childLabel: "Job Card",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "jobcardSubmenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Add Sale Invoice",
          keyWords:
            "make invoice job service billing print staff payment cart product add sale",
          parentLabel: "Job Card",
          childLabel: "Invoice",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "invoiceSubMenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Sale Invoice - List/View/Print/Pay/Update/Delete",
          keyWords:
            "sale invoice list edit report excel download print view pay update add make" +
            " deleteinvoice status",
          parentLabel: "Job Card",
          childLabel: "Invoice List",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "invoiceListSubMenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Add Estimate Invoice",
          keyWords:
            "make invoice job Pro forma service billing print staff payment cart product add Estimate",
          parentLabel: "Job Card",
          childLabel: "Invoice",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "invoiceSubMenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Estimate Invoice - List/View/Print/Pay/Update/Delete",
          keyWords:
            "Estimate invoice edit Pro forma list report excel download print view pay update add make" +
            " deleteinvoice status",
          parentLabel: "Job Card",
          childLabel: "Invoice List",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "invoiceListSubMenu",
        },
        {
          mainModuleName: "Job Card",
          subModuleName: "Customer Payment",
          keyWords: "payment pay invoice amount total money overall customer",
          parentLabel: "Job Card",
          childLabel: "Customer Payment",
          parentTagClassName: "jobCardMainMenu",
          childTagClassName: "customerPaymentSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Add Product/Service",
          keyWords: "add product service quantity rate limit",
          parentLabel: "Inventory",
          childLabel: "Add Product/Service",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "addProductServiceSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Product/Service - List/View/Update/Delete",
          keyWords:
            "add product service quantity inventory rate limit " +
            "list report view delete edit update",
          parentLabel: "Inventory",
          childLabel: "Add Product/Service",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "addProductServiceSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Add Vendor",
          keyWords: "vendor vendorname  gstno add addvendor",
          parentLabel: "Inventory",
          childLabel: "Vendor",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "vendorSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Vendor - List/View/Update/Delete",
          keyWords:
            "vendor vendorname  gstno add addvendor" +
            "list report view delete edit update",
          parentLabel: "Inventory",
          childLabel: "Vendor",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "vendorSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Add Purchase Invoice",
          keyWords:
            "make invoice job service billing inventory print staff payment cart product add purchase" +
            "payment pay invoice amount total money overall vendor",
          parentLabel: "Inventory",
          childLabel: "Purchase",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "purchaseSubMenu",
        },
        {
          mainModuleName: "Inventory",
          subModuleName: "Purchase Invoice - List/View/Print/Pay/Update/Delete",
          keyWords:
            "make invoice job service billing inventory print staff payment cart product add purchase" +
            "payment pay invoice amount total money overall vendor" +
            "invoice list edit report excel download print view pay update add make" +
            " deleteinvoice status",
          parentLabel: "Inventory",
          childLabel: "Purchase",
          parentTagClassName: "inventoryMainMenu",
          childTagClassName: "purchaseSubMenu",
        },
        {
          mainModuleName: "CRM",
          subModuleName: "Vehicle - Add/List/View/Update/Delete",
          keyWords:
            "vehicle list edit report excel download view update add " +
            " delete",
          parentLabel: "CRM",
          childLabel: "Vehicle",
          parentTagClassName: "crmMainMenu",
          childTagClassName: "vehicleSubMenu",
        },
        {
          mainModuleName: "CRM",
          subModuleName: "Customer - Add/List/View/Update/Delete",
          keyWords:
            "customer list edit report excel download view update add " +
            " delete",
          parentLabel: "CRM",
          childLabel: "Customer",
          parentTagClassName: "crmMainMenu",
          childTagClassName: "customerSubMenu",
        },
        {
          mainModuleName: "CRM",
          subModuleName: "Vehicle Make and Model",
          keyWords:
            "vehicle make model modal fueltype add list edit report delete",
          parentLabel: "CRM",
          childLabel: "Vehicle Make & Model",
          parentTagClassName: "crmMainMenu",
          childTagClassName: "vehicleMakeModelSubMenu",
        },
        {
          mainModuleName: "Expense",
          subModuleName: "Add Expense",
          keyWords: "expense expensereport",
          parentLabel: "Expense",
          childLabel: "Expense",
          parentTagClassName: "expenseMainMenu",
          childTagClassName: "expenseMainMenu",
        },
        {
          mainModuleName: "Expense",
          subModuleName: "Add Category",
          keyWords: "category  addcategory",
          parentLabel: "Expense",
          childLabel: "Expense",
          parentTagClassName: "expenseMainMenu",
          childTagClassName: "expenseMainMenu",
        },
        {
          mainModuleName: "Expense",
          subModuleName: "Add User",
          keyWords: "admin adduser",
          parentLabel: "Expense",
          childLabel: "Expense",
          parentTagClassName: "expenseMainMenu",
          childTagClassName: "expenseMainMenu",
        },
        {
          mainModuleName: "Enquiry",
          subModuleName: "Add Enquiry",
          keyWords: "Enquiry addenquiry",
          parentLabel: "Enquiry",
          childLabel: "Enquiry",
          parentTagClassName: "enquiryMainMenu",
          childTagClassName: "enquiryMainMenu",
        },
        {
          mainModuleName: "Book Service",
          subModuleName: "Book a service",
          keyWords: "book bookservice customerservice appointment",
          parentLabel: "Book Service",
          childLabel: "Book Service",
          parentTagClassName: "bookServiceMainMenu",
          childTagClassName: "bookServiceMainMenu",
        },
        {
          mainModuleName: "Service Calendar",
          subModuleName: "View Service Calendar",
          keyWords: "Calendar bookservice viewCalendar appointment",
          parentLabel: "Service Calendar",
          childLabel: "Service Calendar",
          parentTagClassName: "serviceCalendarMainMenu",
          childTagClassName: "serviceCalendarMainMenu",
        },
        {
          mainModuleName: "Service Confirmation",
          subModuleName: "Booked Service Confirmation",
          keyWords:
            "accept reject Booked Confirmation bookservice deny confirm appointment",
          parentLabel: "Service Confirmation",
          childLabel: "Service Confirmation",
          parentTagClassName: "serviceConfirmationMainMenu",
          childTagClassName: "serviceConfirmationMainMenu",
        },
        {
          mainModuleName: "Employee",
          subModuleName: "Add Employee",
          keyWords: "employee staff addstaff addemployee",
          parentLabel: "Employee",
          childLabel: "Add Employee",
          parentTagClassName: "employeeMainMenu",
          childTagClassName: "addEmployeeSubMenu",
        },
        {
          mainModuleName: "Employee",
          subModuleName: "Employee - List/View/Update/Delete",
          keyWords:
            "employee staff addstaff addemployee list report view update delete edit",
          parentLabel: "Employee",
          childLabel: "Add Employee",
          parentTagClassName: "employeeMainMenu",
          childTagClassName: "addEmployeeSubMenu",
        },
        {
          mainModuleName: "Employee",
          subModuleName: "Add Role",
          keyWords: "role addrole employee staff addstaff addemployee",
          parentLabel: "Employee",
          childLabel: "Add role",
          parentTagClassName: "employeeMainMenu",
          childTagClassName: "addRoleSubMenu",
        },
        {
          mainModuleName: "Employee",
          subModuleName: "Salary",
          keyWords: "employee staff salary addemployee",
          parentLabel: "Employee",
          childLabel: "Add Salary",
          parentTagClassName: "employeeMainMenu",
          childTagClassName: "addSalarySubMenu",
        },
        {
          mainModuleName: "Employee",
          subModuleName: "Salary Report",
          keyWords: "employee staff salary salaryreport addemployee",
          parentLabel: "Employee",
          childLabel: "Salary Report",
          parentTagClassName: "employeeMainMenu",
          childTagClassName: "salaryReportSubMenu",
        },
        {
          mainModuleName: "Bank",
          subModuleName: "Add Bank",
          keyWords: "bank addBank account billingaddress",
          parentLabel: "Bank",
          childLabel: "Bank",
          parentTagClassName: "bankMainMenu",
          childTagClassName: "bankMainMenu",
        },
        {
          mainModuleName: "Bank",
          subModuleName: "Bank - List/Update/Delete",
          keyWords:
            "bank addBank account bankdetails list delete update billingaddress",
          parentLabel: "Bank",
          childLabel: "Bank",
          parentTagClassName: "bankMainMenu",
          childTagClassName: "bankMainMenu",
        },
        {
          mainModuleName: "Attendance",
          subModuleName: "CheckIn/Out",
          keyWords: "attendance checkin checkout",
          parentLabel: "Attendance",
          childLabel: "Check In/Out",
          parentTagClassName: "attendanceMainMenu",
          childTagClassName: "checkinoutSubMenu",
        },
        {
          mainModuleName: "Attendance",
          subModuleName: "Manual Attendance",
          keyWords: "attendance checkin checkout manual",
          parentLabel: "Attendance",
          childLabel: "Manual Attendance",
          parentTagClassName: "attendanceMainMenu",
          childTagClassName: "manualAttendanceSubMenu",
        },
        {
          mainModuleName: "Attendance",
          subModuleName: "Report",
          keyWords:
            "attendance checkin checkout manual report daily monthly period",
          parentLabel: "Attendance",
          childLabel: "Report",
          parentTagClassName: "attendanceMainMenu",
          childTagClassName: "attendanceReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Reports",
          keyWords: "Reports report overall",
          parentLabel: "Reports",
          childLabel: "Reports",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "reportsMainMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Daily Sales",
          keyWords: "sales today daily dailysales current report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Monthly Sales",
          keyWords: "sales Monthly monthlysales report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Yearly Sales",
          keyWords: "sales Yearly yearlysales report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Date Wise Sales",
          keyWords: "sales Date Wise datewise datewisesales period report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Customer Statement",
          keyWords: "sales Customer Statement customerstatement report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Sales Report - Invoice History",
          keyWords:
            "sales invoice History past record transaction operations report",
          parentLabel: "Reports",
          childLabel: "Sales Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "salesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Estimate Report - Daily Estimate",
          keyWords: "Estimate today daily dailyestimate current report",
          parentLabel: "Reports",
          childLabel: "Estimate Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "estimateReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Estimate Report - Monthly Estimate",
          keyWords: "Estimate Monthly monthlyestimate report",
          parentLabel: "Reports",
          childLabel: "Estimate Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "estimateReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Estimate Report - Yearly Estimate",
          keyWords: "Estimate Yearly yearlyestimate report",
          parentLabel: "Reports",
          childLabel: "Estimate Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "estimateReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Estimate Report - Date Wise Estimate",
          keyWords: "Estimate Date Wise datewiseEstimate period report",
          parentLabel: "Reports",
          childLabel: "Estimate Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "estimateReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Estimate Report - Customer Statement",
          keyWords: "Estimate Customer Statement customerstatement report",
          parentLabel: "Reports",
          childLabel: "Estimate Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "estimateReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Purchase Report - Daily Purchase",
          keyWords: "Purchase today daily dailyPurchase current report",
          parentLabel: "Reports",
          childLabel: "Purchase Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "purchaseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Purchase Report - Monthly Purchase",
          keyWords: "Purchase Monthly monthlyPurchase report",
          parentLabel: "Reports",
          childLabel: "Purchase Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "purchaseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Purchase Report - Yearly Purchase",
          keyWords: "Purchase Yearly yearlyPurchase report",
          parentLabel: "Reports",
          childLabel: "Purchase Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "purchaseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Purchase Report - Date Wise Purchase",
          keyWords: "Purchase Date Wise datewisePurchase period report",
          parentLabel: "Reports",
          childLabel: "Purchase Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "purchaseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Purchase Report - Vendor Statement",
          keyWords: "Purchase Vendor Statement Vendorstatement report",
          parentLabel: "Reports",
          childLabel: "Purchase Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "purchaseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Expense Report - Daily Expense",
          keyWords: "Expense today daily dailyExpense current report",
          parentLabel: "Reports",
          childLabel: "Expense Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "expenseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Expense Report - Monthly Expense",
          keyWords: "Expense Monthly monthlyExpense report",
          parentLabel: "Reports",
          childLabel: "Expense Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "expenseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Expense Report - Yearly Expense",
          keyWords: "Expense Yearly yearlyExpense report",
          parentLabel: "Reports",
          childLabel: "Expense Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "expenseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Expense Report - Date Wise Expense",
          keyWords: "Expense Date Wise datewiseExpense period report",
          parentLabel: "Reports",
          childLabel: "Expense Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "expenseReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Message Center Report",
          keyWords: "Message Center Report",
          parentLabel: "Reports",
          childLabel: "Message Center Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "messageCenterReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Profit and Loss Report - Profit and Loss",
          keyWords: "Profit and Loss Report",
          parentLabel: "Reports",
          childLabel: "Profit & Loss",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "profitlossReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Profit and Loss Report - Till Date Profit and Loss",
          keyWords: "Till Date Profit and Loss Report",
          parentLabel: "Reports",
          childLabel: "Profit & Loss Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "profitlossReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Inventory - Available Stock",
          keyWords: "Inventory Available Stock report",
          parentLabel: "Reports",
          childLabel: "Inventory Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "inventoryReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Inventory - Stock Summary Report",
          keyWords: "Inventory Stock Summary Stock report",
          parentLabel: "Reports",
          childLabel: "Inventory Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "inventoryReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Audit Report",
          keyWords: "Audit Report",
          parentLabel: "Reports",
          childLabel: "Audit Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "auditReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Product Migration Report",
          keyWords: "Product Migration Report",
          parentLabel: "Reports",
          childLabel: "Product Migration Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "ProductMigrationReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Bill Due Report",
          keyWords: "Bill Due Report remainder",
          parentLabel: "Reports",
          childLabel: "Bill Due Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "billdueReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Enquiry Report",
          keyWords: "Enquiry Report",
          parentLabel: "Reports",
          childLabel: "Enquiry Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "enquiryReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "FeedBack Report",
          keyWords: "FeedBack Report",
          parentLabel: "Reports",
          childLabel: "Feedback Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "feedbackReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Payment History Report",
          keyWords: "Payment History Report",
          parentLabel: "Reports",
          childLabel: "Payment History Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "paymentHistoryReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Booked Service Reports - Booked Service History",
          keyWords: "Booked Service History Reports",
          parentLabel: "Reports",
          childLabel: "Booked Service Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "bookedServiceReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Booked Service Reports - Booked Services in Future",
          keyWords: "Booked Service Future Reports",
          parentLabel: "Reports",
          childLabel: "Booked Service Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "bookedServiceReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "Payment Receivables Reports - Customer History",
          keyWords: "Payment Receivables Reports Customer History",
          parentLabel: "Reports",
          childLabel: "Payment Recivables Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "paymentRecivablesReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "JobCard Reports - Daily Jobcard Details",
          keyWords: "job card report daily service registration issue ",
          parentLabel: "Reports",
          childLabel: "JobCard Report Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "jobcardReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "JobCard Reports - Monthly Jobcard Details",
          keyWords: "job card report monthly service registration issue ",
          parentLabel: "Reports",
          childLabel: "JobCard Report Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "jobcardReportSubMenu",
        },
        {
          mainModuleName: "Reports",
          subModuleName: "JobCard Reports - Period Jobcard Details",
          keyWords: "job card report period service registration issue ",
          parentLabel: "Reports",
          childLabel: "JobCard Report Report",
          parentTagClassName: "reportsMainMenu",
          childTagClassName: "jobcardReportSubMenu",
        },
        {
          mainModuleName: "Communication",
          subModuleName: "Offer Messages",
          keyWords: "Communication Offer Messages sms",
          parentLabel: "Communication",
          childLabel: "SMS",
          parentTagClassName: "communicationMainMenu",
          childTagClassName: "smsSubMenu",
        },
        {
          mainModuleName: "Communication",
          subModuleName: "Emails",
          keyWords: "Communication Offer Emails notification",
          parentLabel: "Communication",
          childLabel: "Email",
          parentTagClassName: "communicationMainMenu",
          childTagClassName: "emailSubMenu",
        },
        {
          mainModuleName: "Configuration",
          subModuleName: "Configuration",
          keyWords: "Configuration settings",
          parentLabel: "Configuration",
          childLabel: "Configuration",
          parentTagClassName: "configurationMainMenu",
          childTagClassName: "configurationMainMenu",
        },
        {
          mainModuleName: "Task Mapping",
          subModuleName: "Task Mapping",
          keyWords: "Task Mapping permission role access settings",
          parentLabel: "Task Mapping",
          childLabel: "Task Mapping",
          parentTagClassName: "taskMappingMainMenu",
          childTagClassName: "taskMappingMainMenu",
        },
        {
          mainModuleName: "Logo",
          subModuleName: "Import Logo",
          keyWords: "logo importlogo import companylogo symbol brand",
          parentLabel: "Settings",
          childLabel: "Import Logo",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "importLogoSubMenu",
        },
        {
          mainModuleName: "QRCode",
          subModuleName: "Import QRCode",
          keyWords: "Import QRCode importqrcode pay link",
          parentLabel: "Settings",
          childLabel: "Import QRCode",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "importQRCodeSubMenu",
        },
        {
          mainModuleName: "Excel",
          subModuleName: "Export Excel",
          keyWords:
            "Export Excel bluck customer vendor product staff employee add",
          parentLabel: "Settings",
          childLabel: "Export/Import Excel",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "importExportExccelSubMenu",
        },
        {
          mainModuleName: "Excel",
          subModuleName: "Import Excel",
          keyWords:
            "Import Excel bluck customer vendor product staff employee add",
          parentLabel: "Settings",
          childLabel: "Export/Import Excel",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "importExportExccelSubMenu",
        },
        {
          mainModuleName: "Location",
          subModuleName: "Add Location",
          keyWords: "Add Location site sitename multiplesite multiple",
          parentLabel: "Settings",
          childLabel: "Location",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "locationSubMenu",
        },
        {
          mainModuleName: "Help",
          subModuleName: "About Modules",
          keyWords: "About Modules help content flow",
          parentLabel: "Settings",
          childLabel: "Help",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "helpMainMenu",
        },
        {
          mainModuleName: "Password",
          subModuleName: "Change Password",
          keyWords: "Change Password forgot password",
          parentLabel: "Settings",
          childLabel: "Change Password",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "changePasswordMainMenu",
        },
        {
          mainModuleName: "Company Settings",
          subModuleName: "Company Details",
          keyWords: "Company Details settings contactno companyid",
          parentLabel: "Settings",
          childLabel: "Company Settings",
          parentTagClassName: "settingMainMenu",
          childTagClassName: "companySettingsSubMenu",
        },
      ],
      value: "",

      featuresData: featuresData,
      restrictedFeatures: restrictedFeatures,
      restrictedFeaturesHeader: restrictedFeaturesHeader,
    };
  }
  componentDidMount() {
    var self = this;
    window.scrollTo(0, 0);

    //  console.log("DID MOUNT this.state.data :", this.state.data);

    //USED TO HIDE  THE FIELDS THAT ARE  RESTRICTED IN THE PLAN
    this.SetFeatures();

    $("#renderData").hide();
    $("#accessDeniedSpan").hide();
  }

  /*
       FUNCTION TO REMOVE THE FEATURES RESTRICTED IN THE PLAN
       */
  SetFeatures() {
    var self = this;
    var restrictedFeaturesArray = this.state.restrictedFeatures.split(",");
    var restrictedFeaturesHeaderArray =
      this.state.restrictedFeaturesHeader.split(",");

    //  console.log("restrictedFeaturesArray :",restrictedFeaturesArray);
    //  console.log("restrictedFeaturesHeaderArray :",restrictedFeaturesHeaderArray);

    var flattendedData = this.state.featuresData.flatMap(
      (elem) => elem.children
    );
    flattendedData = _.compact(flattendedData);

    //  console.log("flattendedData :",flattendedData);

    var featuresIndexResult = [];
    var data = [...self.state.data];

    $.each(restrictedFeaturesArray, function (i, item) {
      self.state.data.forEach((dataItem, index) =>
        dataItem.childLabel === item ? featuresIndexResult.push(index) : null
      );
      //  console.log("featuresIndexResult  :", featuresIndexResult);
      //  console.log("ITEM :", item, " FEATURES : self.state.data :", self.state.data);
    });

    lodash.pullAt(self.state.data, featuresIndexResult);
    self.setState({
      data: self.state.data,
    });
    //  console.log("self.state.data FEATURES :", self.state.data);

    var featuresHeaderIndexResult = [];
    $.each(restrictedFeaturesHeaderArray, function (i, item) {
      self.state.data.forEach((dataItem, index) =>
        dataItem.parentLabel === item
          ? featuresHeaderIndexResult.push(index)
          : null
      );
      //   console.log("featuresHeaderIndexResult  :", featuresHeaderIndexResult);
    });
    lodash.pullAt(self.state.data, featuresHeaderIndexResult);
    self.setState({
      data: self.state.data,
    });
    //  console.log("self.state.data FEATURES HEADER:", self.state.data);
  }

  handleChange = (event) => {
    const { value } = event.target;

    $("#accessDeniedSpan").hide();

    this.setState({ value });

    if (event.target.value != "") {
      $("#renderData").show();
    } else {
      $("#renderData").hide();
    }
  };
  ModuleOnClickFunc(moduleName) {
    //console.log("MODULE CLICK :", moduleName);

    var self = this;
    $("#accessDeniedSpan").hide();

    switch (moduleName) {
      case "Job Card - Job Card - Add":
        var flag = self.CheckPermission("Jobcard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ServiceRegistration} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Job Card - Job card - List/View/Update/Delete":
        var flag = self.CheckPermission("Jobcard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VehicleRegistrationList} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Job Card - Add Sale Invoice":
        var flag = self.CheckPermission("SaleInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route
                    path="/"
                    component={() => (
                      <InvoiceMenuPage
                        pageCalledFrom="NewMenuBar"
                        data="SaleInvoice"
                        stateData={this.state}
                      />
                    )}
                  />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Job Card - Sale Invoice - List/View/Print/Pay/Update/Delete":
        var flag = self.CheckPermission("SaleInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={InvoiceListMenuPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Job Card - Add Estimate Invoice":
        var flag = self.CheckPermission("EstimateInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Estimate} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Job Card - Estimate Invoice - List/View/Print/Pay/Update/Delete":
        var flag = self.CheckPermission("EstimateInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateList} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Job Card - Customer Payment":
        var flag = self.CheckPermission("CustomerPayment");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={CustomerwisePayment} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Add Product/Service":
        var flag = self.CheckPermission("ProductService");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddProduct1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Product/Service - List/View/Update/Delete":
        var flag = self.CheckPermission("ProductService");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ProductList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Add Vendor":
        var flag = self.CheckPermission("Vendor");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VendorEntryForm1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Vendor - List/View/Update/Delete":
        var flag = self.CheckPermission("Vendor");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VendorList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Add Purchase Invoice":
        var flag = self.CheckPermission("PurchaseInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseInvoice1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Inventory - Purchase Invoice - List/View/Print/Pay/Update/Delete":
        var flag = self.CheckPermission("PurchaseInvoice");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseInvoiceList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "CRM - Vehicle - Add/List/View/Update/Delete":
        var flag = self.CheckPermission("Vehicle");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VehicleList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "CRM - Customer - Add/List/View/Update/Delete":
        var flag = self.CheckPermission("Customer");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={CustomerList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "CRM - Vehicle Make and Model":
        var flag = self.CheckPermission("VehicleMakeandModel");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VehicleMakeModel} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Expense - Add Expense":
        var flag = self.CheckPermission("Expense");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Expense1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Expense - Add Category":
        var flag = self.CheckPermission("Expense");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddCategory1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Expense - Add User":
        var flag = self.CheckPermission("Expense");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddUser1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Enquiry - Add Enquiry":
        var flag = self.CheckPermission("Enquiry");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Enquiry} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Book Service - Book a service":
        var flag = self.CheckPermission("BookService");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddAppointment} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Service Calendar - View Service Calendar":
        var flag = self.CheckPermission("ServiceCalendar");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AppointmentCalendar} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Service Confirmation - Booked Service Confirmation":
        var flag = self.CheckPermission("ServiceConfirmation");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AppointmentConfirmation} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Employee - Add Employee":
        var flag = self.CheckPermission("Employee");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddStaff} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Employee - Employee - List/View/Update/Delete":
        var flag = self.CheckPermission("Employee");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={StaffList1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Employee - Add Role":
        var flag = self.CheckPermission("AddRole");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddRole1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Employee - Salary":
        var flag = self.CheckPermission("Salary");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Salary} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Employee - Salary Report":
        var flag = self.CheckPermission("SalaryReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalaryReport1} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Bank - Add Bank":
        var flag = self.CheckPermission("Bank");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AddBank} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Bank - Bank - List/Update/Delete":
        var flag = self.CheckPermission("Bank");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={BankReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Attendance - CheckIn/Out":
        var flag = self.CheckPermission("CheckInOut");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Checkinout} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Attendance - Manual Attendance":
        var flag = self.CheckPermission("ManualAttendance");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={Attendance} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Attendance - Report":
        var flag = self.CheckPermission("Report");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AttendanceReportMenuPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Reports":
        var flag = self.CheckPermission("JobCard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ReportMenuPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Sales Report - Daily Sales":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesDailyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Sales Report - Monthly Sales":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesMonthlyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Sales Report - Yearly Sales":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesYearlyReportMonthYear} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Sales Report - Date Wise Sales":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesDateWiseReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Sales Report - Customer Statement":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesCustomerStatement} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Sales Report - Invoice History":
        var flag = self.CheckPermission("SalesReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={SalesInvoiceHistoryReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Estimate Report - Daily Estimate":
        var flag = self.CheckPermission("EstimateReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateDailyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Estimate Report - Monthly Estimate":
        var flag = self.CheckPermission("EstimateReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateMonthlyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Estimate Report - Yearly Estimate":
        var flag = self.CheckPermission("EstimateReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateYearlyReportMonthYear} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Estimate Report - Date Wise Estimate":
        var flag = self.CheckPermission("EstimateReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateDateWiseReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Estimate Report - Customer Statement":
        var flag = self.CheckPermission("EstimateReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EstimateCustomerStatement} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Purchase Report - Daily Purchase":
        var flag = self.CheckPermission("PurchaseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseDailyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Purchase Report - Monthly Purchase":
        var flag = self.CheckPermission("PurchaseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseMonthlyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Purchase Report - Yearly Purchase":
        var flag = self.CheckPermission("PurchaseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseYearlyReportMonthYear} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Purchase Report - Date Wise Purchase":
        var flag = self.CheckPermission("PurchaseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PurchaseDateWiseReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Purchase Report - Vendor Statement":
        var flag = self.CheckPermission("PurchaseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={VendorCustomerStatement} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Expense Report - Daily Expense":
        var flag = self.CheckPermission("ExpenseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ExpDailyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Expense Report - Monthly Expense":
        var flag = self.CheckPermission("ExpenseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ExpMonthlyReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Expense Report - Yearly Expense":
        var flag = self.CheckPermission("ExpenseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ExpYearlyReportMonthYear} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Expense Report - Date Wise Expense":
        var flag = self.CheckPermission("ExpenseReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ExpDateWiseReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Message Center Report":
        var flag = self.CheckPermission("MessageCenterReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={MessageCenterReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Profit and Loss Report - Profit and Loss":
        var flag = self.CheckPermission("ProfitandLossReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ProfitLossReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Profit and Loss Report - Till Date Profit and Loss":
        var flag = self.CheckPermission("ProfitandLossReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={TillDateProfitLossReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Inventory - Available Stock":
        var flag = self.CheckPermission("InventoryReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AvailableStockReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Inventory - Stock Summary Report":
        var flag = self.CheckPermission("InventoryReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={InventorySummaryReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Audit Report":
        var flag = self.CheckPermission("AuditReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AuditReportMenu} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Product Migration Report":
        var flag = self.CheckPermission("ProductMigrationReportSubMenu");

        // console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ProductMigrationReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - Bill Due Report":
        var flag = self.CheckPermission("BillDueReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={BillDueReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Enquiry Report":
        var flag = self.CheckPermission("EnquiryReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={EnquiryReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - FeedBack Report":
        var flag = self.CheckPermission("FeedBackReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={MonthlyFeedBackReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Payment History Report":
        var flag = self.CheckPermission("PaymentHistoryReport");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={PaymentHistoryReport} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Booked Service Reports - Booked Service History":
        var flag = self.CheckPermission("BookedServiceReports");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={AppointmentsHistory} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Booked Service Reports - Booked Services in Future":
        var flag = self.CheckPermission("BookedServiceReports");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={FutureAppointments} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Reports - Payment Receivables Reports - Customer History":
        var flag = self.CheckPermission("PaymentReceivablesReports");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={CustomerPaymentHistory} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - JobCard Reports - Daily Jobcard Details":
        var flag = self.CheckPermission("Jobcard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route
                    path="/"
                    component={() => <JobCardReport data="Daily" />}
                  />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - JobCard Reports - Monthly Jobcard Details":
        var flag = self.CheckPermission("Jobcard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route
                    path="/"
                    component={() => <JobCardReport data="Monthly" />}
                  />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Reports - JobCard Reports - Period Jobcard Details":
        var flag = self.CheckPermission("Jobcard");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route
                    path="/"
                    component={() => <JobCardReport data="Period" />}
                  />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Communication - Offer Messages":
        var flag = self.CheckPermission("OfferMessages");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={MessageCenterMessagePage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Communication - Emails":
        var flag = self.CheckPermission("Emails");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={MessageCenterEmailPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Configuration - Configuration":
        var flag = self.CheckPermission("Configuration");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ConfigurationPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Task Mapping - Task Mapping":
        var flag = self.CheckPermission("TaskMapping");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={TaskMappingElite} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Logo - Import Logo":
        var flag = self.CheckPermission("ImportLogo");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ImportLogo} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "QRCode - Import QRCode":
        var flag = self.CheckPermission("ImportQRcode");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ImportQRCode} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Excel - Export Excel":
        var flag = self.CheckPermission("ExportExcel");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ExportMenuPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Excel - Import Excel":
        var flag = self.CheckPermission("ImportExcel");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={ImportMenuPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;
      case "Location - Add Location":
        var flag = self.CheckPermission("Location");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={LocationPage} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      case "Help - About Modules":
        // var flag = self.CheckPermission("checkinout");

        //  //console.log("CHECKIN OUT FLAG :",flag);
        //  if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={Help} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
        // } else {
        //     //ACCESS DENIED
        //     self.AccesDeniedMessage();
        // }
        break;
      case "Password - Change Password":
        // var flag = self.CheckPermission("checkinout");

        //  //console.log("CHECKIN OUT FLAG :",flag);
        //  if (flag == 0) {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />}>
              <div>
                <Route path="/" component={ChangePassword1} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById("contentRender")
        );
        // } else {
        //     //ACCESS DENIED
        //     self.AccesDeniedMessage();
        // }
        break;
      case "Company Settings - Company Details":
        var flag = self.CheckPermission("CompanySetting");

        //console.log("CHECKIN OUT FLAG :", flag);
        if (flag == 0) {
          ReactDOM.render(
            <Router>
              <Suspense fallback={<LoadingComponent />}>
                <div>
                  <Route path="/" component={CompanySetting} />
                </div>
              </Suspense>
            </Router>,
            document.getElementById("contentRender")
          );
        } else {
          //ACCESS DENIED
          self.AccesDeniedMessage();
        }
        break;

      default:
      //console.log("DEFAULT FUNC CALL");
    }
  }

  CheckPermission(moduleName) {
    var permission = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Permissions"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8)
    );

    var flag = 1; //false

    //console.log("PERMISSION LIST :", permission);
    var presentStatus = _.where(permission, { permission: moduleName });

    if (presentStatus.length > 0) {
      flag = 0; //true
      //console.log("PERMISSION FLAG 0 ");
    } else {
      var permission = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("PermissionHeader"),
          "shinchanbaby"
        ).toString(CryptoJS.enc.Utf8)
      );
      var presentHeaderStatus = _.where(permission, {
        permissionHeader: moduleName,
      });

      if (presentHeaderStatus.length > 0) {
        flag = 0; //true
        //console.log("PERMISSION HEADER FLAG 0 ");
      }
    }

    return flag;
  }

  AccesDeniedMessage() {
    /*  Swal.fire({
              position: 'center',
              icon: 'warning',
              title: "Access Denied",
              showConfirmButton: false,
              timer: 2000
          })  */

    $("#accessDeniedSpan").show();
  }

  SpanClick(spanName) {
    //console.log("SPAN CLICK :", spanName);

    this.ModuleOnClickFunc(spanName);

    this.state.value = "";
    this.setState({
      value: this.state.value,
    });
    $(".renderData").hide();
  }

  EmptyGlobalSearch() {
    this.state.value = "";
    this.setState({
      value: "",
    });
    $(".renderData").hide();
  }

  render() {
    const { data, value } = this.state;
    return (
      <div className="search_div_cls">
        <span id="accessDeniedSpan" className="search_a_denied">
          Access Denied !!
        </span>
        <form action="" className="form-search">
          <input
            required
            className="form-input"
            placeholder="Search Here"
            type="text"
            value={value}
            onChange={this.handleChange}
          />
          <i class="fa fa-search fafa"></i>
        </form>
        <FilterResults
          value={value}
          data={data}
          renderResults={(results) => (
            <div id="renderData" className="searchresult_cls renderData">
              {results.map((el) => (
                <div>
                  <span
                    class="mainSpan"
                    id={el.mainModuleName + " - " + el.subModuleName}
                    onClick={(e) =>
                      this.SpanClick(
                        el.mainModuleName + " - " + el.subModuleName
                      )
                    }
                  >
                    <span style={{ color: "#037bde", fontWeight: "600" }}>
                      {el.mainModuleName}
                    </span>
                    - <span>{el.subModuleName}</span>
                  </span>
                </div>
              ))}
            </div>
          )}
        />
      </div>
    );
  }
}
