//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";

import { NoDataComponent } from "../../Assets_Components/MessagePage_Components/MessagePage_Components";

import "../../ReactTableCSS.css";

const SelectTable = selectTableHOC(ReactTable);

export const ReactTableWithOnClick = ({
  data,
  columns,
  pageSize,
  onTrRowClick,
}) => (
  console.log("ReactTableWithOnClick data :", data),
  (
    <ReactTable
      style={{ overflow: "auto", marginBottom: "5%" }}
      data={data}
      columns={columns}
      NoDataComponent={NoDataComponent}
      filterable
      defaultPageSize={pageSize}
      className="-striped -highlight"
      defaultFilterMethod={(filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          : true;
      }}
      showPaginationTop={true}
      showPaginationBottom={false}
      getTrProps={onTrRowClick}
      getProps={(state, rowInfo, column) => {
        return {
          style: {
            background:
              column && column.Header == "Product Name" ? "red" : null,
          },
        };
      }}
    />
  )
);

export const ReactTableWithOutOnClick = ({ data, columns, pageSize }) => (
  console.log("ReactTableWithOutOnClick data :", data),
  (
    <ReactTable
      style={{ overflow: "auto", marginBottom: "5%" }}
      data={data}
      columns={columns}
      NoDataComponent={NoDataComponent}
      filterable
      defaultPageSize={pageSize}
      className="-striped -highlight"
      defaultFilterMethod={(filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          : true;
      }}
      showPaginationTop={true}
      showPaginationBottom={false}
    />
  )
);

export class ReactTableWithCheckBox extends Component {
  constructor(props) {
    super(props);

    window.ReactTableWithCheckBox_Component = this;

    this.state = {
      data: this.props.data,
      columns: this.props.columns,
      selectAll: false,
      selection: [],
      pageSize:this.props.pageSize,
      //  keyField: this.props.keyField,
    };
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  static defaultProps = {
    keyField: "KeyField",
  };

  /* static propTypes = {
    keyField: PropTypes.string
  };
  */

  componentDidMount() {
    console.log("MYTABLE :", this.props);
  }

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key, shift, row) => {
    console.log("SINGLE SEECTION UNCONVERTED KEY :", key);

    var convertedKey = key.split("-")[1];
    if (convertedKey == undefined) {
      convertedKey = key;
    }

    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(convertedKey);
    console.log("SINGLE SEECTION CONVERTED KEY :", convertedKey);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
      console.log("SELECTION UNSELECT :", selection);
    } else {
      // it does not exist so add it
      selection.push(convertedKey);
      console.log("SELECTION SELECT :", selection);
    }
    // update the state
    this.state.selection = selection;
    this.setState({ selection: selection });
    console.log("TOGGLE SINGLE :", this.state.selection);

    this.props.GetSelectedData(this.state.selection);
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(`${item._original[keyField]}`);
      });
    }
    this.state.selection = selection;
    this.setState({ selectAll, selection });
    console.log("TOGGLE ALL :", this.state.selection);

    this.props.GetSelectedData(this.state.selection);
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = (key) => {
    console.log("IS SELECTED key :", key);
    return this.state.selection.includes(`${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    const { selection } = this.state;
    var rowInfo;

    return {
      onClick: (e, handleOriginal) => {
        rowInfo = rowInfo;
        console.log("It was in this row:", rowInfo);

        console.log("ORIGINAL :", rowInfo.original);
        console.log("ROWFN :", rowInfo.original.KeyField);

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
        this.isSelected(rowInfo.original.KeyField);
        this.toggleSelection(rowInfo.original.KeyField, "", "");
      },

      style: {
        background:
          rowInfo &&
          selection.includes(`${rowInfo.original.KeyField}`) &&
          "lightgrey",
      },
    };
  };

  render() {
    return (
      <SelectTable
        {...this.props}
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        NoDataComponent={NoDataComponent}
        selectAll={this.state.selectAll}
        selectType="checkbox"
        toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        showPaginationTop={true}
        showPaginationBottom={false}
        defaultPageSize={this.state.pageSize}
      />
    );
  }
}
