//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
//IMPORT REACT ICONS
import ReactDOM from "react-dom";
import registerServiceWorker from "../../../../registerServiceWorker";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import CryptoJS from "crypto-js";
import ReactTable from "react-table";
import _ from "underscore";
import { CreatableSelectField } from "../../../../Assets_Components/Input_Components/Input_Components";

//IMPORT CLASS COMPONENT
import DashboardOverall from "../../../../MaincontentDashboard/DashboardOverall";
import SubmitButtonComponent, {
  BackButtonComponent,
  CancelButtonComponent,
  SubmitProceedButtonComponent,
} from "../../../../ServiceRegistration/ButtonComponent";
import {
  AlertMessageInfoComponent,
  NetworkErrorComponent,
  NoDataComponent,
} from "../../../../Assets_Components/MessagePage_Components/MessagePage_Components";
import {
  GetCurrentSite,
  GetEmployeeSite,
  OffsetValueCalcFunc,
  SetCurrentPage,
} from "../../../../ConstSiteFunction";
import LoadingComponent from "../../../../Loading/LoadingComponent";
import PackageList from "./PackageList";
import {
  ReactTableWithCheckBox,
  ReactTableWithOnClick,
  ReactTableWithOutOnClick,
} from "../../../../Assets_Components/Table_Components/Table_Components";
import { DeleteIcons } from "../../../../ServiceRegistration/IconComponents";
import { Truncate_2DecimalPlaces } from "../../../../Invoice/InvoiceValidations";
import CapitalCaseFunc from "../../../../ServiceRegistration/CommonTextFormatComponent";

var productList = []; // USED IN AddItems_To_Package_Component & RemoveItems_From_Package_Component
var packageList = []; // USED IN AddItems_To_Package_Component & RemoveItems_From_Package_Component

class Edit_PackageName_Component extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      site: GetCurrentSite(),
      modalIsOpen: false,

      packageId: props.stateData.packageId,
      oldPackageName: props.stateData.packageName,

      packageName: "",
    };
    this.SubmitFunc = this.SubmitFunc.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("EditPackage");

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });
  }

  /*
    FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
    IMPLEMENTED BY PRIYANKA - 03-06-2022
    */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  /*
       FUNCTION USED FOR GETTING THE INITIAL DATA REQUIRED FOR POPULATING THE FIELDS - 
       IMPLEMENTED BY PRIYANKA - 04-06-2022
       */
  SubmitFunc() {
    var self = this;

    console.log(
      "SUBMIT DATA :",
      JSON.stringify({
        companyId: self.state.companyId,
        packageId: self.state.packageId,
        packageName: self.state.packageName,
      })
    );

    if (
      self.state.packageName != "" &&
      self.state.oldPackageName != self.state.packageName
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: self.state.companyId,
          packageId: self.state.packageId,
          packageName: self.state.packageName,
        }),
        contentType: "application/json",
        dataType: "json",
        // url: "http://localhost:8080/GarageAppAPI_Cust/master/ProductMigrationDisplayData",
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/UpdateProductServicePackageName",

        async: false,
        crossDomain: true,
        success: function (data, textStatus, jqXHR) {
          console.log("PRODUCT MIGRATION LIST DATA :", data);

          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });

          if (data.response == "Success") {
            AlertMessageInfoComponent(
              "center",
              "success",
              "Updated package name",
              false,
              "3000"
            );
            self.BackbtnFunc();
          } else if (data.response == "PackageName_AlreadyExist") {
            AlertMessageInfoComponent(
              "center",
              "warning",
              "The package name " + self.state.packageName + " already exist",
              false,
              "3000"
            );
          } else if (data.response == "Failed") {
            AlertMessageInfoComponent(
              "center",
              "warning",
              "Failed to update package name, try after sometime",
              false,
              "3000"
            );
          }
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      if (this.state.oldPackageName == this.state.packageName) {
        AlertMessageInfoComponent(
          "center",
          "warning",
          "No changes made",
          false,
          "3000"
        );
      } else if (this.state.packageName == "") {
        AlertMessageInfoComponent(
          "center",
          "warning",
          "Kindly fill in mandatory fields",
          false,
          "3000"
        );
      }
    }
  }

  handleUserInputPackageName = (e) => {
    const name = e.target.name;
    const value = CapitalCaseFunc(e.target.value);
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Edit Package Name</h3>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker">
              Existing PackageName <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="Existing PackageName"
              className="form-control"
              name="oldPackageName"
              value={this.state.oldPackageName}
              readOnly
            />
          </div>

          <div className="col-md-3">
            <label className="control-label selectpicker">
              New PackageName <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="New PackageName"
              className="form-control"
              name="packageName"
              value={this.state.packageName}
              onChange={this.handleUserInputPackageName}
            />
          </div>
        </div>

        <div className="row">
          <div className="text-center" style={{ marginBottom: "15px" }}>
            <SubmitButtonComponent
              onClick={this.SubmitFunc}
              style={{ width: "100px" }}
            />
            <CancelButtonComponent
              onClick={this.BackbtnFunc}
              style={{ width: "100px" }}
            />
          </div>
        </div>

        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
      </div>
    );
  }
}
export default Edit_PackageName_Component;

export class AddItems_To_Package_Component extends Component {
  constructor(props) {
    super(props);

    window.AddItems_To_Package_Component = this;

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      site: GetCurrentSite(),
      modalIsOpen: false,

      existingItemsData: [],

      data: [],
      columns: [
        { Header: "SNO", accessor: "SNO" },
        { Header: "ProductId", accessor: "ProductId", show: false },
        { Header: "Name", accessor: "Name" },
        { Header: "Rate", accessor: "Rate" },
        { Header: "CGST", accessor: "CGST" },
        { Header: "SGST", accessor: "SGST" },
        { Header: "IGST", accessor: "IGST" },
        { Header: "Total Rate", accessor: "TotalRate" },
      ],

      pageSize: 10,
      selected: -1,

      selectedPackage: [],
      selectedProduct: [],

      rateWithOutTax: 0,
      rateWithTax: 0,
      totalPackageRate: 0,
      existingTotalPackageRate: 0,

      cgst: 0,
      sgst: 0,
      igst: 0,

      packageId: props.stateData.packageId,
      packageName: props.stateData.packageName,

      dataList: props.stateData.dataList,
    };
    this.GetProduct_Service_Package =
      this.GetProduct_Service_Package.bind(this);
    this.PopulateFields = this.PopulateFields.bind(this);
    this.PopulateTableData = this.PopulateTableData.bind(this);
    this.ClearField = this.ClearField.bind(this);
    this.ClearPage = this.ClearPage.bind(this);
    this.ClearProductRate = this.ClearProductRate.bind(this);
    this.ClearProductInfo = this.ClearProductInfo.bind(this);
    this.DeleteProduct = this.DeleteProduct.bind(this);
    this.CancelFunc = this.CancelFunc.bind(this);
    this.ProductCalculation = this.ProductCalculation.bind(this);
    this.AddProductFunc = this.AddProductFunc.bind(this);
    this.SubmitFunc = this.SubmitFunc.bind(this);
    this.PopulateTableData = this.PopulateTableData.bind(this);
    this.PopulateExistingItemsData = this.PopulateExistingItemsData.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("EditPackage");

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });

    this.GetProduct_Service_Package();

    console.log("ADD ITEMS TO PACKAGE PROPS DATA :", this.props);
  }

  GetProduct_Service_Package() {
    var self = this;

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        site: GetCurrentSite(),
      }),
      contentType: "application/json",
      dataType: "json",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/ProductServiceInventoryPackageList",

      async: false,
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });

        console.log("PRODUCT PRODUCT SERVICE PACKAGE LIST :", data);

        self.PopulateFields(data);
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  PopulateFields(data) {
    productList = [];
    packageList = [];

    productList = data.productList;
    packageList = data.packageList;

    var productOptions = [];
    var packageOptions = [];

    this.state.productOptions = [];
    this.state.packageOptions = [];

    $.each(productList, function (i, item) {
      productOptions.push({ value: item.productId, label: item.productName });
    });

    /* var subPackageList = _.uniq(
      packageList,
      "packageName"
    );
    */

    $.each(packageList, function (i, item) {
      packageOptions.push({ value: item.packageId, label: item.packageName });
    });

    this.state.productOptions = productOptions;
    this.state.packageOptions = packageOptions;
    this.setState({
      productOptions: this.state.productOptions,
      packageOptions: this.state.packageOptions,
    });
  }

  handleChangeSelectProduct = (newValue, actionMeta) => {
    /*console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    console.groupEnd(); */

    console.log("SELECT PRODUCT :", newValue);
    //  //console.log("SELECT CUSTOMER MOBILE NO:",newValue.value);

    var self = this;

    if (newValue != null) {
      this.state.selectedProduct = newValue;
      this.state.productId = newValue.value;

      var productInfo = _.where(productList, {
        productId: this.state.productId,
      });

      console.log("productInfo :", productInfo);

      this.state.productName = productInfo[0].productName;
      this.state.rateWithOutTax = productInfo[0].productRate;
      this.state.cgst = productInfo[0].cgst;
      this.state.sgst = productInfo[0].sgst;
      this.state.igst = productInfo[0].igst;

      this.setState({
        selectedProduct: this.state.selectedProduct,
        productId: this.state.productId,

        productName: this.state.productName,
        rateWithOutTax: this.state.rateWithOutTax,
        cgst: this.state.cgst,
        sgst: this.state.sgst,
        igst: this.state.igst,
      });
      this.ProductCalculation("rateWithOutTax");
    } else {
      this.ClearProductInfo();
      this.ClearProductRate();
    }
  };

  ClearField() {
    var self = this;
    self.state.selectedPackage = [];
    self.state.packageId = "";
    self.state.packageCreateStatus = "Not_Created";

    self.setState({
      selectedPackage: self.state.selectedPackage,
      packageId: self.state.packageId,
      packageCreateStatus: self.state.packageCreateStatus,
    });

    this.ClearProductInfo();
    this.ClearProductRate();
  }

  ClearProductInfo() {
    var self = this;
    self.state.selectedProduct = [];
    self.state.productId = "";

    self.setState({
      selectedProduct: self.state.selectedProduct,
      productId: self.state.productId,
    });
  }

  ClearProductRate() {
    var self = this;
    self.state.cgst = 0;
    self.state.sgst = 0;
    self.state.igst = 0;

    self.state.rateWithOutTax = 0;
    self.state.rateWithTax = 0;

    self.setState({
      cgst: self.state.cgst,
      sgst: self.state.sgst,
      igst: self.state.igst,

      rateWithOutTax: self.state.rateWithOutTax,
      rateWithTax: self.state.rateWithTax,
    });
  }

  ClearPage() {
    var self = this;
    self.ClearField();
    self.ClearProductInfo();
    self.ClearProductRate();

    self.state.data = [];
    self.state.totalPackageRate = Truncate_2DecimalPlaces(parseFloat(0));

    self.setState({
      data: self.state.data,
      totalPackageRate: self.state.totalPackageRate,
    });
  }

  ProductCalculation(rateType) {
    /* CHECKING IF TAX VAUES ARE NULL -- 
   IF IT'S NULL ASSIGN 0 ELSE ASSIGN ORIGINAL VALUE
   */
    this.state.cgst = this.state.cgst != null ? this.state.cgst : 0;
    this.state.sgst = this.state.sgst != null ? this.state.sgst : 0;
    this.state.igst = this.state.igst != null ? this.state.igst : 0;

    if (rateType == "rateWithOutTax") {
      var rateWithOutTax = this.state.rateWithOutTax;

      var rate = Truncate_2DecimalPlaces(
        (Number(rateWithOutTax) *
          (100 + Number(this.state.cgst) + Number(this.state.sgst))) /
          100
      );

      this.state.rateWithTax = rate;
      this.setState({
        rateWithTax: this.state.rateWithTax,
      });
    } else if (rateType == "rateWithTax") {
      var rateWithTax = this.state.rateWithTax;

      var rate = Truncate_2DecimalPlaces(
        Number(rateWithTax * 100) /
          Number(
            Number(100) + Number(this.state.cgst) + Number(this.state.sgst)
          )
      );

      this.state.rateWithOutTax = rate;
      this.setState({
        rateWithOutTax: this.state.rateWithOutTax,
      });
    }
  }

  AddProductFunc() {
    var self = this;
    if (
      this.state.selectedProduct != "" &&
      this.state.rateWithOutTax !== "" &&
      this.state.rateWithTax != ""
    ) {
      var productCheck = _.where(this.state.data, {
        ProductId: this.state.productId,
      });

      var existingProductCheck = _.where(this.state.existingItemsData, {
        ProductId: this.state.productId,
      });

      console.log("productCheck :", productCheck);

      if (productCheck.length == 0 && existingProductCheck.length == 0) {
        var count = 0;
        var tableData = [];
        var recentData = {
          SNO: count,
          ProductId: this.state.productId,
          Name: this.state.productName,
          Rate: this.state.rateWithOutTax,
          CGST: this.state.cgst,
          SGST: this.state.sgst,
          IGST: this.state.igst,
          TotalRate: this.state.rateWithTax,
        };

        this.state.data.unshift(recentData);
        this.setState({
          data: this.state.data,
        });

        tableData = [...this.state.data];

        this.PopulateTableData(tableData);
      } else {
        if (productCheck.length > 0) {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Product already exist",
            false,
            "3000"
          );
        } else if (existingProductCheck.length > 0) {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Product already exist in previously added items",
            false,
            "3000"
          );
        }
      }
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly fill-in all mandatory fields",
        false,
        "3000"
      );
    }

    this.ClearProductInfo();
    this.ClearProductRate();
  }

  onTrRowClick = (state, rowInfo, column, instance) => {
    var self = this;

    if (typeof rowInfo !== "undefined") {
      return {
        onClick: (e, handleOriginal) => {
          //  var column=column;

          this.setState({
            selected: rowInfo.index,
          });
          if (handleOriginal) {
            handleOriginal();
          }

          console.log("ROW INFO :", rowInfo);

          this.state.rowIndexValue = rowInfo.index;

          this.setState({
            rowIndexValue: this.state.rowIndexValue,
          });
        },
        style: {
          background: rowInfo.index === this.state.selected ? "lightgrey " : "",
          color: rowInfo.index === this.state.selected ? "black" : "",
        },
      };
    } else {
      return {
        onClick: (e, handleOriginal) => {
          if (handleOriginal) {
            handleOriginal();
          }
        },
      };
    }
  };

  SubmitFunc() {
    var self = this;

    console.log(
      "SUBMIT DATA :",
      JSON.stringify({
        companyId: this.state.companyId,
        site: GetCurrentSite(),
        packageName: this.state.packageName,
        data: JSON.stringify(this.state.data),
        packageId: this.state.packageId,
      })
    );

    if (this.state.data.length > 0) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: this.state.companyId,
          site: GetCurrentSite(),
          packageName: this.state.packageName,
          data: JSON.stringify(this.state.data),
          packageId: this.state.packageId,
        }),
        contentType: "application/json",
        dataType: "json", //15.206.129.105:8080
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/AddItemsToProductServicePackage",
        async: false,
        crossDomain: true,
        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
          self.PopulateFields(data);
          if (data.response == "Package_Added") {
            AlertMessageInfoComponent(
              "center",
              "success",
              "Items added succesfully to the  package " +
                self.state.packageName,
              false,
              "3000"
            );
            self.CancelFunc();
            self.BackbtnFunc();
          } else {
            AlertMessageInfoComponent(
              "center",
              "warning",
              "Failed to add items to the package " +
                self.state.packageName +
                ", try after sometime",
              false,
              "3000"
            );
          }
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "No data to submit",
        false,
        "3000"
      );
    }
  }

  CancelFunc() {
    var self = this;
    self.ClearField();
    self.ClearPage();
  }

  PopulateTableData(tableData) {
    var self = this;
    console.log("tableData :", tableData);
    this.state.data = [];
    this.state.totalPackageRate = Truncate_2DecimalPlaces(
      parseFloat(self.state.existingTotalPackageRate) + parseFloat(0)
    );

    this.setState({
      data: this.state.data,
      totalPackageRate: this.state.totalPackageRate,
    });

    var count = 0;

    $.each(tableData, function (i, item) {
      count = Number(count) + Number(1);
      self.state.data[i] = {
        SNO: count,
        ProductId: item.ProductId,
        Name: item.Name,
        Rate: item.Rate,
        CGST: item.CGST,
        SGST: item.SGST,
        IGST: item.IGST,
        TotalRate: item.TotalRate,
      };

      self.state.totalPackageRate = Truncate_2DecimalPlaces(
        parseFloat(self.state.totalPackageRate) + parseFloat(item.TotalRate)
      );

      self.setState({
        totalPackageRate: self.state.totalPackageRate,
      });
    });
  }

  DeleteProduct() {
    var self = this;

    var data = self.state.data;
    self.state.data = [];

    data.splice(this.state.rowIndexValue, 1);

    self.PopulateTableData(data);

    console.log("DeleteProduct AFTER SPLICE EMPTY :", self.state.data);

    console.log("DeleteProduct AFTER SPLICE :", data);

    self.state.selected = -1;
    self.setState({
      selected: self.state.selected,
    });

    console.log("DeleteProduct AFTER SPLICE self.state.data:", self.state.data);
  }

  PopulateExistingItemsData(dataList) {
    var self = this;
    console.log("PopulateExistingItemsData  :", dataList);
    this.state.existingItemsData = [];
    this.state.totalPackageRate = 0;

    this.setState({
      existingItemsData: this.state.existingItemsData,
      totalPackageRate: this.state.totalPackageRate,
    });

    var count = 0;

    $.each(dataList, function (i, item) {
      count = Number(count) + Number(1);

      var productInfo = _.where(dataList, {
        productId: item.productId,
      });

      console.log("item.productId, :", item.productId);
      console.log("productInfo :", productInfo);

      self.state.rateWithOutTax = productInfo[0].productRate;
      self.state.cgst = productInfo[0].cgst;
      self.state.sgst = productInfo[0].sgst;
      self.state.igst = productInfo[0].igst;

      self.setState({
        productId: self.state.productId,

        productName: self.state.productName,
        rateWithOutTax: self.state.rateWithOutTax,
        cgst: self.state.cgst,
        sgst: self.state.sgst,
        igst: self.state.igst,
      });

      self.ProductCalculation("rateWithOutTax");

      /*  self.state.cgst = self.state.cgst != null ? self.state.cgst : 0;
      self.state.sgst = self.state.sgst != null ? self.state.sgst : 0;
      self.state.igst = self.state.igst != null ? self.state.igst : 0;

      var rateWithOutTax = self.state.rateWithOutTax;

      var rate = Truncate_2DecimalPlaces(
        (Number(rateWithOutTax) *
          (100 + Number(self.state.cgst) + Number(self.state.sgst))) /
          100
      );

      self.state.rateWithTax = rate;
      self.setState({
        rateWithTax: self.state.rateWithTax,
      });
*/

      self.state.existingItemsData[i] = {
        SNO: count,
        ProductId: item.productId,
        Name: item.productName,
        Rate: self.state.rateWithOutTax,
        CGST: self.state.cgst,
        SGST: self.state.sgst,
        IGST: self.state.igst,
        TotalRate: self.state.rateWithTax,
      };

      self.state.totalPackageRate = Truncate_2DecimalPlaces(
        parseFloat(self.state.totalPackageRate) +
          parseFloat(self.state.rateWithTax)
      );

      self.setState({
        totalPackageRate: self.state.totalPackageRate,
      });
    });

    self.ClearProductRate();

    console.log(
      " self.state.existingTotalPackageRate AT START:",
      self.state.existingTotalPackageRate
    );

    self.state.existingTotalPackageRate = self.state.totalPackageRate;
    self.setState({
      existingTotalPackageRate: self.state.existingTotalPackageRate,
    });

    console.log(
      " self.state.existingTotalPackageRate AT END:",
      self.state.existingTotalPackageRate
    );
  }

  /*
      FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
      IMPLEMENTED BY PRIYANKA - 03-06-2022
      */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Edit Package - Add Items</h3>
        </div>

        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />

        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Package<span class="mandatory-fields">*</span>
            </label>

            {/* <CreatableSelectField
              multiSelectValue={false}
              onChange={this.handleChangeSelectPackage}
              onCreateOption={this.handleInputChangePackage}
              creatableSelectOpions={this.state.packageOptions}
              selectedOption={this.state.selectedPackage}
              readOnly
            /> */}
            <input
              type="text"
              placeholder="Package"
              className="form-control"
              name="packageName"
              value={this.state.packageName}
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Product/Service<span class="mandatory-fields">*</span>
            </label>

            <CreatableSelectField
              multiSelectValue={false}
              onChange={this.handleChangeSelectProduct}
              creatableSelectOpions={this.state.productOptions}
              selectedOption={this.state.selectedProduct}
              isValidNewOption={() => false}
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker">
              Rate Exclusive of Tax <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="Rate Exclusive of Tax"
              className="form-control"
              name="rateWithOutTax"
              value={this.state.rateWithOutTax}
            />
          </div>

          <div className="col-md-3">
            <label className="control-label selectpicker">
              CGST(%) <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="CGST"
              className="form-control"
              name="cgst"
              value={this.state.cgst}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker">
              SGST(%) <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="SGST"
              className="form-control"
              name="sgst"
              value={this.state.sgst}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <label className="control-label selectpicker">
              IGST(%)<span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              placeholder="IGST"
              className="form-control"
              name="igst"
              value={this.state.igst}
              readOnly
            />
          </div>

          <div className="col-md-3">
            <label className="control-label selectpicker">
              Rate Inclusive of Tax <span class="mandatory-fields">*</span>
            </label>
            <input
              type="text"
              readOnly
              placeholder="Rate Inclusive of Tax"
              className="form-control"
              value={this.state.rateWithTax}
              name="rateWithTax"
            />
          </div>
          <div className="col-md-3">
            <div className="text-center" style={{ padding: "25px 0 0 0" }}>
              <button
                style={{ fontWeight: "bold" }}
                type="button"
                onClick={() => this.AddProductFunc()}
                className="btn btn-default btnfirst"
              >
                Add
              </button>
              <span></span>
              <button
                style={{ fontWeight: "bold" }}
                type="button"
                onClick={() => this.ClearField()}
                className="btn btn-default"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center" style={{ marginBottom: "15px" }}>
            <SubmitButtonComponent
              onClick={this.SubmitFunc}
              style={{ width: "100px" }}
            />
            <CancelButtonComponent
              onClick={this.CancelFunc}
              style={{ width: "100px" }}
            />
          </div>
        </div>

        <h3>Package Rate:{this.state.totalPackageRate}</h3>

        <div>
          <div id="tableOverflow" className="hideContent">
            <ReactTableWithOutOnClick
              data={this.state.existingItemsData}
              columns={this.state.columns}
              pageSize={this.state.pageSize}
            />
          </div>
        </div>

        <DeleteIcons onInvoiceDelete={this.DeleteProduct} />
        <div>
          <div id="tableOverflow" className="hideContent">
            <ReactTableWithOnClick
              data={this.state.data}
              columns={this.state.columns}
              pageSize={this.state.pageSize}
              onTrRowClick={this.onTrRowClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export class RemoveItems_From_Package_Component extends Component {
  constructor(props) {
    super(props);

    window.RemoveItems_From_Package_Component = this;

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      site: GetCurrentSite(),
      modalIsOpen: false,

      existingItemsData: [],

      columns: [
        { Header: "SNO", accessor: "SNO" },
        { Header: "ProductId", accessor: "ProductId", show: false },
        { Header: "KeyField", accessor: "KeyField", show: false },
        { Header: "Name", accessor: "Name" },
        { Header: "Rate", accessor: "Rate" },
        { Header: "CGST", accessor: "CGST" },
        { Header: "SGST", accessor: "SGST" },
        { Header: "IGST", accessor: "IGST" },
        { Header: "Total Rate", accessor: "TotalRate" },
      ],

      pageSize: 10,
      selected: -1,

      rateWithOutTax: 0,
      rateWithTax: 0,
      totalPackageRate: 0,
      existingTotalPackageRate: 0,

      cgst: 0,
      sgst: 0,
      igst: 0,

      packageId: props.stateData.packageId,
      packageName: props.stateData.packageName,

      selectAll: false,
      selection: [],

      selectedArray: [],
    };
    this.ProductCalculation = this.ProductCalculation.bind(this);
    this.PopulateExistingItemsData = this.PopulateExistingItemsData.bind(this);
    this.GetSelectedData = this.GetSelectedData.bind(this);
    this.SubmitFunc = this.SubmitFunc.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("EditPackage");

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });

    console.log("ADD ITEMS TO PACKAGE PROPS DATA :", this.props);
  }

  /*
        FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
        IMPLEMENTED BY PRIYANKA - 03-06-2022
        */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={PackageList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  PopulateExistingItemsData(dataList) {
    var self = this;
    console.log("PopulateExistingItemsData  :", dataList);
    this.state.existingItemsData = [];
    this.state.totalPackageRate = 0;

    this.setState({
      existingItemsData: this.state.existingItemsData,
      totalPackageRate: this.state.totalPackageRate,
    });

    var count = 0;

    $.each(dataList, function (i, item) {
      count = Number(count) + Number(1);

      var productInfo = _.where(dataList, {
        productId: item.productId,
      });

      console.log("item.productId, :", item.productId);
      console.log("productInfo :", productInfo);

      self.state.rateWithOutTax = productInfo[0].productRate;
      self.state.cgst = productInfo[0].cgst;
      self.state.sgst = productInfo[0].sgst;
      self.state.igst = productInfo[0].igst;

      self.setState({
        productId: self.state.productId,
        KeyField: self.state.productId,
        productName: self.state.productName,
        rateWithOutTax: self.state.rateWithOutTax,
        cgst: self.state.cgst,
        sgst: self.state.sgst,
        igst: self.state.igst,
      });

      self.ProductCalculation("rateWithOutTax");

      /*  self.state.cgst = self.state.cgst != null ? self.state.cgst : 0;
        self.state.sgst = self.state.sgst != null ? self.state.sgst : 0;
        self.state.igst = self.state.igst != null ? self.state.igst : 0;
  
        var rateWithOutTax = self.state.rateWithOutTax;
  
        var rate = Truncate_2DecimalPlaces(
          (Number(rateWithOutTax) *
            (100 + Number(self.state.cgst) + Number(self.state.sgst))) /
            100
        );
  
        self.state.rateWithTax = rate;
        self.setState({
          rateWithTax: self.state.rateWithTax,
        });
  */

      self.state.existingItemsData[i] = {
        SNO: count,
        ProductId: item.productId,
        KeyField: item.productId,
        Name: item.productName,
        Rate: self.state.rateWithOutTax,
        CGST: self.state.cgst,
        SGST: self.state.sgst,
        IGST: self.state.igst,
        TotalRate: self.state.rateWithTax,
      };

      self.state.totalPackageRate = Truncate_2DecimalPlaces(
        parseFloat(self.state.totalPackageRate) +
          parseFloat(self.state.rateWithTax)
      );

      self.setState({
        totalPackageRate: self.state.totalPackageRate,
      });
    });

    // self.ClearProductRate();

    console.log(
      " self.state.existingTotalPackageRate AT START:",
      self.state.existingTotalPackageRate
    );

    self.state.existingTotalPackageRate = self.state.totalPackageRate;
    self.setState({
      existingTotalPackageRate: self.state.existingTotalPackageRate,
    });

    console.log(
      " self.state.existingTotalPackageRate AT END:",
      self.state.existingTotalPackageRate
    );

    /*  window.ReactTableWithCheckBox_Component.SetData(
      this.state.existingItemsData
    );
    */
  }

  ProductCalculation(rateType) {
    /* CHECKING IF TAX VAUES ARE NULL -- 
   IF IT'S NULL ASSIGN 0 ELSE ASSIGN ORIGINAL VALUE
   */
    this.state.cgst = this.state.cgst != null ? this.state.cgst : 0;
    this.state.sgst = this.state.sgst != null ? this.state.sgst : 0;
    this.state.igst = this.state.igst != null ? this.state.igst : 0;

    if (rateType == "rateWithOutTax") {
      var rateWithOutTax = this.state.rateWithOutTax;

      var rate = Truncate_2DecimalPlaces(
        (Number(rateWithOutTax) *
          (100 + Number(this.state.cgst) + Number(this.state.sgst))) /
          100
      );

      this.state.rateWithTax = rate;
      this.setState({
        rateWithTax: this.state.rateWithTax,
      });
    } else if (rateType == "rateWithTax") {
      var rateWithTax = this.state.rateWithTax;

      var rate = Truncate_2DecimalPlaces(
        Number(rateWithTax * 100) /
          Number(
            Number(100) + Number(this.state.cgst) + Number(this.state.sgst)
          )
      );

      this.state.rateWithOutTax = rate;
      this.setState({
        rateWithOutTax: this.state.rateWithOutTax,
      });
    }
  }

  GetSelectedData(selectedArray) {
    console.log("SEECTED ARRAY IN PARENT PAGE :", selectedArray);

    this.state.selectedArray = selectedArray;
    this.setState({
      selectedArray: this.state.selectedArray,
    });
  }

  SubmitFunc() {
    var self = this;

    console.log(
      "REMOVE SUBMIT DATA :",
      JSON.stringify({
        companyId: this.state.companyId,
        site: GetCurrentSite(),
        data: this.state.selectedArray.toString(),
        packageId: this.state.packageId,
      })
    );

    if (this.state.selectedArray.length > 0) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: this.state.companyId,
          site: GetCurrentSite(),
          data: this.state.selectedArray.toString(),
          packageId: this.state.packageId,
        }),
        contentType: "application/json",
        dataType: "json", //15.206.129.105:8080
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/RemoveProductServicePackageItems",
        async: false,
        crossDomain: true,
        success: function (data, textStatus, jqXHR) {
          self.state.modalIsOpen = false;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
          if (data.response == "Removed_Items_From_Package") {
            AlertMessageInfoComponent(
              "center",
              "success",
              "Items removed succesfully from the  package " +
                self.state.packageName,
              false,
              "3000"
            );
            self.BackbtnFunc();
          } else {
            AlertMessageInfoComponent(
              "center",
              "warning",
              "Failed to remove items from the package " +
                self.state.packageName +
                ", try after sometime",
              false,
              "3000"
            );
          }
        },
        error: function (data) {
          self.state.modalIsOpen = true;
          self.setState({
            modalIsOpen: self.state.modalIsOpen,
          });
        },
      });
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "No data to submit",
        false,
        "3000"
      );
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Edit Package - Remove Items</h3>
        </div>

        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />

        <h3>Package Rate:{this.state.totalPackageRate}</h3>

        <div className="row">
          <div className="text-center" style={{ marginBottom: "15px" }}>
            <SubmitButtonComponent
              onClick={this.SubmitFunc}
              style={{ width: "100px" }}
            />
            <CancelButtonComponent
              onClick={this.BackbtnFunc}
              style={{ width: "100px" }}
            />
          </div>
        </div>

        <ReactTableWithCheckBox
          // onRef={(ref) => (this.normalChild = ref)}
          data={this.state.existingItemsData}
          columns={this.state.columns}
          keyField="KeyField"
          GetSelectedData={this.GetSelectedData}
          pageSize={this.state.pageSize}
        />
      </div>
    );
  }
}
