//IMPORT STATEMENTS FOR REACT COMPONENT
import React, {lazy, Component,Suspense } from 'react';
import _ from 'underscore';
//IMPORT REACT COMPONENT CSS
import 'sweetalert2/src/sweetalert2.scss';
import "react-table/react-table.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
//IMPORT CLASS COMPONENT
import { titleCase } from "title-case";


let CapitalCaseFunc;

export  default CapitalCaseFunc=function(textValue){

    var lowerval=textValue.toLowerCase();
    var capitalCase=titleCase(lowerval);

    return capitalCase;

}

export const TextLengthFunc=function(textValue,lengthValue){

    var lengthValidationData=false;
    if(textValue.length <= lengthValue){
        lengthValidationData=true;
    }
    return lengthValidationData;

}