//IMPORT FOR REACT NPM
import React, { Component } from 'react'
import _ from 'underscore';
import PhoneInput from 'react-phone-number-input' //import for contact no validation
import SelectSearch from 'react-select';
import Select from 'react-select';


//IMPORT CSS
import './SiteRegister.css';
import 'animate.css';

//IMPORT FOR REACT CLASS
import { ErrorClass } from '../FormErrors';

class AccountSetup extends Component 
{
  
continue = e => {
  e.preventDefault();
  this.props.nextStep();
};
/*USED TO DISPLAY THE UI BOX AS RED COLOR-added by durga */
errorClass(error) {
return (error.length === 0 ? '' : 'has-error');
}
render() 
    {
      const { values, handleUserInput ,handleChangeSelectedPlan,handleUserInputCurrency} = this.props;
        
      
      return (

            <div className="form-container">
                <h1 className="mb-5 animate__animated animate__bounce">Company Details</h1>
                {/* <div className="form-group"> */}
                <div className={`form-group ${this.errorClass(values.companyNameErr)}`}>
                    <label htmlFor="name">Company Name<span className="mandatory-fields">*</span></label>
                    <input type="text" className="form-control" placeholder="Company Name.."
                    //  onChange={this.handleUserInput}
                    name="companyName" maxLength="100" onChange={handleUserInput("companyName")} value={values.companyName} />
                    <ErrorClass errorContent={values.companyNameErr}></ErrorClass>
                </div>
                
                <div className={`form-group ${this.errorClass(values.emailIdErr)}`}>
                    <label htmlFor="email">Email Id<sapn className="mandatory-fields">*</sapn></label>
                    <input type="text" className="form-control"
                     name="emailId" placeholder="Your EmailID.." maxLength="51"
                     onChange={handleUserInput("emailId")} value={values.emailId} />
                     <ErrorClass errorContent={values.emailIdErr}></ErrorClass>
                </div>
                <div className={`form-group ${this.errorClass(values.contactNoErr)}`}>
                    <label htmlFor="phone">Phone Number<span className="mandatory-fields">*</span></label>
                    {/* <input type="text" className="form-control" name="contactNo" placeholder="Your Mobile No.." 
                   onChange={this.handleUserInput} maxLength="20" value={this.state.contactNo} />   onChange={handleOnChange("contactNo")} */} 
                   <div class="phonoicon">
                   <PhoneInput class="form-control" country={'us'}  name="contactNo" placeholder="Enter phone number"  value={ values.contactNo } onChange={this.props.handleOnChange}  /> 
                   </div>
                   <div className="message error-position" style={{ color: values.contactNoColor,fontSize: '13px' }}>{values.contactNoErr}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Currency<span className="mandatory-fields">*</span></label>
                    {/* <input type="text" className="form-control" name="password" placeholder="Select Currency.." onChange={inputChange('currency')} value={values.currency} /> */}
                    <SelectSearch options={values.currencyOptions}
                            value={values.currencyOption}
                            onChange={(e) =>handleUserInputCurrency(e)}
                            name="currency" placeholder="Select Currency " /> 
                </div>
                <div className="form-group">
                    <label htmlFor="plan">Plan<span className="mandatory-fields">*</span></label>
                    {/* <input type="text" className="form-control" name="plan" placeholder="Select Plan.." onChange={inputChange('plan')} value={values.plan} /> */}
                    <Select
                                id="#productMenu"
                                name="planOption"
                                isMulti={false}
                                value={values.planOption}
                                options={values.planOptions}
                                onChange={handleChangeSelectedPlan}
                            />
                          
                </div>
                <br />
                <div className="text-right">
                    <button className="btn btn-primary" disabled={!values.formValid} onClick={this.continue}>Next</button>
                </div>
            </div>
        )
    }
}
export default AccountSetup
