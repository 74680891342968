//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";

import Swal from "sweetalert2/dist/sweetalert2.js";

import Modal from "react-modal";

import noDataFound from "../../image/no-data.gif";
import networkError from "../../image/Network.gif";

import "../MessagePage_Components/MessagePage_Components.css";

const customModalStyles = {
  content: {
    width: "100%",
    height: "100vh"
  },
};

/*
USED TO RENDER NODATA INFO IN THE TABLE &
OTHER NECESSARY COMPONENTS THROUGHOUT THE PROJECT - IMPEMENTED BY PRIYANKA
*/
export const NoDataComponent = () => (
  <div className="noData-styles">
    <img className="glogo-sec nodata-img" src={noDataFound} />
    <p className="nodate-text">No Data Available</p>
  </div>
);

/*
USED TO RENDER NETWORK ERROR INFO IN THE API ERROR PART &
OTHER NECESSARY COMPONENTS THROUGHOUT THE PROJECT - IMPEMENTED BY PRIYANKA
*/
export const NetworkErrorComponent = (modalIsOpen) => (
  <Modal
    isOpen={modalIsOpen.modalIsOpen}
    //  onAfterOpen={this.customerafterOpenModal}
    // onRequestClose={this.state.modalIsOpen}
    style={customModalStyles}
    contentLabel="Example Modal"
  >
    <div className="noData-styles">
      <img className="glogo-sec nodata-img" src={networkError} />
      <p className="nodate-text">Network Error</p>
    </div>
  </Modal>
);

/*
USED TO RENDER MESSAGE ALERT IN NECESSARY COMPONENTS 
THROUGHOUT THE PROJECT - IMPEMENTED BY PRIYANKA
*/
export const AlertMessageInfoComponent = function (
  position,
  messageType,
  messageText,
  buttonStatus,
  timer
) {
  console.log(
    "AlertMessageInfoComponent :",
    messageType,
    messageText,
    buttonStatus,
    timer
  );

  return Swal.fire({
    position: position,
    icon: messageType,
    text: messageText,
    showConfirmButton: buttonStatus,
    timer: timer,
  });
};

