//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from 'react';

import Pagination from "react-js-pagination";


export const PaginationComponent = ({ activePage, itemsPerPage, totlaItemCount, pageRange, onPageChange }) => (

    console.log("******** PaginationComponent **************  ", activePage),
    <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={totlaItemCount}
        pageRangeDisplayed={pageRange}
        onChange={(activePage) => {
            onPageChange(activePage);
          }}
    />
);



