//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import registerServiceWorker from "../registerServiceWorker";
import $ from "jquery";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ImageUploading from "react-images-uploading";
import CreatableSelect from "react-select/creatable";
import _ from "underscore";
import SlidingPane from "react-sliding-pane";
import Compressor from "compressorjs";
import Modal from "react-modal";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
import "react-widgets/dist/css/react-widgets.css";
import "react-sliding-pane/dist/react-sliding-pane.css";

//IMPORT CLASS COMPONENT CSS
import "./ServiceRegistrationCSS.css";

//IMPORT REACT ICONS
import * as TiIcons from "react-icons/ti";
import * as FaIcons from "react-icons/fa";
import * as GoIcons from "react-icons/go";
import * as GiIcons from "react-icons/gi";
import * as FcIcons from "react-icons/fc";
import * as RiIcons from "react-icons/ri";

//IMPORT CLASS COMPONENT
import LoadingComponent from "../Loading/LoadingComponent";
import {
  CheckNumberFormat,
  CheckNumberFormat_WithoutDecimal,
  Truncate_2DecimalPlaces,
} from "../Invoice/InvoiceValidations";
import {
  BackButtonComponent,
  Double_BackButtonComponent,
} from "./ButtonComponent";
import { vehicleRegNo_NumberValidation } from "./ValidationComponent";
import {
  GetEmployeeSite,
  GetCurrentSite,
  OffsetValueCalcFunc,
  SetCurrentPage,
} from "../ConstSiteFunction";
import VehicleComponent from "./VehicleComponent";
import CapitalCaseFunc from "./CommonTextFormatComponent";
import contactNoValidationFunc from "./ValidationComponent";
import VehicleMakeModelComponent from "./VehicleMakeModelComponent";
import CustomerComponent from "./CustomerComponent";
import ServiceRegistartionEstimate from "./ServiceRegistrationEstimate";
import { EstimateProductTaxChoiceChangeModal } from "../CommonModalPages";
import { AlertMessageInfoComponent } from "../Assets_Components/MessagePage_Components/MessagePage_Components";

const VehicleRegistrationList = lazy(() =>
  import("../VehicleRegistrationList")
);

var customerArray = [];
var vehicleArray = [];
var vehicleMakeModelArray = [];
var productArray = [];
var totalProductArray = [];
var serviceArray = [];
var customerList = [];
var flagset = true;

const maxNumber = 10;
var totalImages = [10];
var imagearray = [];
var filearray = [];
var temparray = [];

var errorcount = 0;
var engine;
var chassis;
var odometer;
var color;
var year;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "491px",
  },
};

class ServiceRegistration extends Component {
  constructor(props) {
    super(props);

    const year = new Date().getFullYear();
    this.years = Array.from(new Array(100), (val, index) => year - index);

    var staffId = CryptoJS.AES.decrypt(
      localStorage.getItem("staffId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var employeeName = CryptoJS.AES.decrypt(
      localStorage.getItem("EmployeeName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var role = CryptoJS.AES.decrypt(
      localStorage.getItem("Role"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var companyName = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var companyId = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    this.state = {
      pageCalledFrom: this.props.pageCalledFrom,
      appointmentId: "",
      serviceList: [],
      companyId: companyId,
      staffId: staffId,
      employeeName: employeeName,
      role: role,
      customerList: [],
      vehicleList: [],
      vehcileMakeList: [],
      vehicleModelList: [],
      vehcileFuelTypeList: [],
      productServiceList: [],
      flag: true,

      selectedCustomerId: "",
      selectedCustomer: [],
      selectedVehicleRegNo: [],
      selectedVehicleMake: [],
      selectedVehicleModel: [],
      selectedVehicleFuelType: [],
      selectedServiceList: [],

      customerNamereadonly: true,
      emailIdreadonly: true,

      customerId: "",
      customerName: "",
      vehicleRegNo: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleFuelType: "",
      emailId: "", //added
      comments: "",
      vehicleColor: "",
      chasisNumber: "",
      vehicleColor: "",
      modelYear: "",
      engineNumber: "",
      odometerReading: "",
      customerCreateStatus: "No",
      vehicleCreateStatus: "No",
      vehicleMakeCreateStatus: "No",
      vehicleModelCreateStatus: "No",
      vehicleFuelTypeCreateStatus: "No",
      serviceCreateStatus: "No",
      isPaneOpen: false,
      isVehiclePaneOpen: false,
      stateData: "",
      imageslists: [],

      baseFiles: [],
      jobCard_With_Estimate: false,

      sgst: "",
      cgst: "",
      igst: "",

      jobCard_With_Estimate: false, //CONFIRMATION TO KNOW WHETHER JOB CARD SHOULD BE SAVED WITH ESTIMATE
      //jobCard_With_Estimate_Tax:false,
      taxStatus: false, //CONFIRMATION FOR TAX FIELD False -- involves the rate without tax,{true} -- involves the rate with tax
    };
    this.SubmitCustomerInfoSlide = this.SubmitCustomerInfoSlide.bind(this);
    this.SubmitVehicleMakeModelInfoSlide =
      this.SubmitVehicleMakeModelInfoSlide.bind(this);
    this.SubmitVehicleInfoSlide = this.SubmitVehicleInfoSlide.bind(this);

    this.OpenJobCardEstimateSlide = this.OpenJobCardEstimateSlide.bind(this);
    this.CloseJobCardEstimateSlide = this.CloseJobCardEstimateSlide.bind(this);

    this.JobCardEstimateFunc = this.JobCardEstimateFunc.bind(this);
    this.SetEstimateTableData = this.SetEstimateTableData.bind(this);
    this.TaxchoiceFunc = this.TaxchoiceFunc.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("ServiceRegistration");
    window.scrollTo(0, 0);

    $("#chasisNo").delay(32000).fadeOut(300);
    $("#emailerrormsg").empty();
    $("#contactnoerror").hide();

    $("#odometerNo").hide();
    $("#vehicleColor").hide();
    $("#chasisNo").hide();
    $("#engineNo").hide();
    $("#modelYear").hide();

    this.GetData();

    //SETTING UP  DATE & TIME BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;

    this.setState({
      date: this.state.date,
      time: this.state.time,
    });
  }

  imageresponse() {
    //alert("Inside of image response");
    var MAX_FILE_SIZE = 5 * 1024 * 1024;

    imagearray = this.state.images;
    //console.log(imagearray,imagearray.length);

    var i = 0;
    var count = 0;
    filearray = [];
    var self = this;
    var filesizeIssue = false;
    var fileTypeIssue = false;
    while (i < imagearray.length) {
      if (imagearray[i].file.type.match(/.(jpg|jpeg|png)$/i)) {
        if (imagearray[i].file.size < MAX_FILE_SIZE) {
          var imageFile = dataURLtoFile(
            imagearray[i].data_url,
            imagearray[i].file.name
          );
          new Compressor(imageFile, {
            quality: 0.3,
            // 0.6 can also be used, but its not recommended to go below.
            convertSize: 1500000,
            success: (compressedResult) => {
              // compressedResult has the compressed file.
              // Use the compressed file to upload the images to your server.
              self
                .getBase64(compressedResult)
                .then((result) => {
                  // result is converted base64
                  filearray[count] = result;
                  count++;
                })
                .catch((err) => {
                  console.log(err);
                });
            },
          });
        } else {
          filesizeIssue = true;
          imagearray.splice(i, 1);
        }
      } else {
        fileTypeIssue = true;
        imagearray.splice(i, 1);
      }
      i++; // move to next image
    }
    if (fileTypeIssue) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Upload PNG,JPG or JPE file !!",
        timer: 3000,
      });
    } else if (filesizeIssue) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Upload image  less than 5MB",
        timer: 3000,
      });
    }
    //file array get value in reverse order
    this.setState({
      baseFiles: filearray,
      images: imagearray,
    });
  }
  // New function for converting base 64
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        ////console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  onChange = (imageList, addUpdateIndex) => {
    // data for submit
    //imageList.preventDefault();
    //alert("Changes happen here");
    if (imageList.length > 10) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Limit Exceeds",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      totalImages = imageList;
      //console.log(imageList,totalImages,totalImages.length);
      this.setState({ images: imageList });

      if (totalImages.length != 0) {
        ////console.log("photo Selected working",this.state.flag);
        this.setState({ flag: false });
        // //console.log("photo Selected working",this.state.flag);
      } else {
        this.setState({ flag: true });
      }

      //console.log(imageList, addUpdateIndex);
      this.imageresponse();
    }
  };

  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={VehicleRegistrationList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  GetData() {
    var self = this;
    $(".btn-default").css("background-color", "#05a4b5");
    $(".btn-default").css("color", "white");
    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        //  empSites:GetEmployeeSite(),
        empSites: GetCurrentSite(),
      }),
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ServiceRegistration/GetServiceDetails",
      contentType: "application/json",
      dataType: "json",
      success: function (data, textStatus, jqXHR) {
        console.log("SERVICE REGISTARTION PAGE DATA :", data);

        self.AssignData_Fields(data);
      },
      error: function (data, textStatus, jqXHR) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  AssignData_Fields(data) {
    var self = this;

    customerArray = [];
    vehicleArray = [];
    vehicleMakeModelArray = [];
    productArray = [];
    totalProductArray = [];
    serviceArray = [];

    customerArray = data.customerList;
    productArray = data.productServiceList;
    vehicleArray = data.vehicleList;
    vehicleMakeModelArray = data.vehicleMakeModelList;

    var subVehicleMakeArray = _.uniq(vehicleMakeModelArray, "vehicleMake");
    var subVehicleModelArray = _.uniq(vehicleMakeModelArray, "vehicleModel");
    var subVehicleFuelTypeArray = _.uniq(
      vehicleMakeModelArray,
      "vehicleFuelType"
    );

    totalProductArray = [...data.productServiceList];
    var serviceData = _.where(data.productServiceList, {
      productType: "service",
    });
    serviceArray = serviceData;

    self.state.customerList = [];
    self.state.productList = [];
    self.state.vehcileMakeList = [];
    self.state.vehicleModelList = [];
    self.state.vehcileFuelTypeList = [];
    self.state.vehicleList = [];

    $.each(data.customerList, function (i, item) {
      self.state.customerList.push({
        value: item.contactNo,
        label: item.contactNo + " - " + item.customerName,
      });
    });

    $.each(serviceArray, function (i, item) {
      self.state.productList.push({
        value: item.serviceName,
        label: item.serviceName,
      });
    });

    $.each(subVehicleMakeArray, function (i, item) {
      self.state.vehcileMakeList.push({
        value: item.vehicleMake,
        label: item.vehicleMake,
      });
    });

    $.each(subVehicleModelArray, function (i, item) {
      self.state.vehicleModelList.push({
        value: item.vehicleModel,
        label: item.vehicleModel,
      });
    });

    $.each(subVehicleFuelTypeArray, function (i, item) {
      self.state.vehcileFuelTypeList.push({
        value: item.vehicleFuelType,
        label: item.vehicleFuelType,
      });
    });

    $.each(data.vehicleList, function (i, item) {
      self.state.vehicleList.push({
        value: item.vehicleRegNo,
        label: item.vehicleRegNo,
      });
    });

    self.setState({
      customerList: self.state.customerList,
      productList: self.state.productList,
      vehcileMakeList: self.state.vehcileMakeList,
      vehicleModelList: self.state.vehicleModelList,
      vehcileFuelTypeList: self.state.vehcileFuelTypeList,
      vehicleList: self.state.vehicleList,
    });

    if (this.state.pageCalledFrom == "ImportToJobCardComponent") {
      //  alert("IMPORT PAGE");
      console.log("STATE DATA :", this.props.stateData);
      this.state.appointmentId = this.props.stateData.appointmentId;
      this.setState({
        appointmentId: this.state.appointmentId,
      });

      var customerData = _.where(customerArray, {
        contactNo: this.props.stateData.contactNo,
      });
      console.log("customerData :", customerData);
      if (customerData.length > 0) {
        //  alert("OLD CUSTOMER");

        var customerValueData = {
          value: this.props.stateData.contactNo,
          label:
            this.props.stateData.contactNo +
            " - " +
            customerData[0].customerName,
        };
        self.handleChangeSelectCustomer(customerValueData, "");
        self.state.customerName = customerNameData;

        self.setState({
          customerName: self.state.customerName,
        });
      } else {
        //   alert("NEW CUSTOMER");
        self.handleInputChangeCustomer(this.props.stateData.contactNo, "");
        var customerNameData = CapitalCaseFunc(
          this.props.stateData.customerName
        );
        self.state.customerName = customerNameData;

        self.setState({
          customerName: self.state.customerName,
        });
      }

      console.log("vehicleArray :", vehicleArray);
      console.log(
        "this.props.stateData.vehicleRegNo :",
        String(this.props.stateData.vehicleRegNo)
      );

      var vehicleData = _.where(vehicleArray, {
        vehicleRegNo: String(this.props.stateData.vehicleRegNo),
      });
      console.log("vehicleData :", vehicleData);
      if (vehicleData.length > 0) {
        //  alert("OLD VEHICLE");
        var vehicleRegNoValueData = {
          value: this.props.stateData.vehicleRegNo,
          label: this.props.stateData.vehicleRegNo,
        };
        self.handleChangeSelectVehicleRegNo(vehicleRegNoValueData, "");
      } else {
        //  alert("NEW VEHICLE");
        if (this.props.stateData.vehicleRegNo != undefined) {
          self.handleInputChangeVehicleRegNo(
            this.props.stateData.vehicleRegNo,
            ""
          );
        }
      }
      console.log("subVehicleMakeArray :", subVehicleMakeArray);
      console.log("subVehicleModelArray :", subVehicleModelArray);
      console.log("subVehicleFuelTypeArray :", subVehicleFuelTypeArray);

      console.log(
        "this.props.stateData.vehicleMake  :",
        this.props.stateData.vehicleMake
      );
      console.log(
        "this.props.stateData.vehicleModel :",
        this.props.stateData.vehicleModel
      );
      console.log(
        "this.props.stateData.vehicleFuelType :",
        this.props.stateData.vehicleFuelType
      );

      var vehicleMakeData = _.where(subVehicleMakeArray, {
        vehicleMake: String(this.props.stateData.vehicleMake),
      });
      var vehicleModelData = _.where(subVehicleModelArray, {
        vehicleModel: String(this.props.stateData.vehicleModel),
      });
      var vehicleFuelType = _.where(subVehicleFuelTypeArray, {
        vehicleFuelType: String(this.props.stateData.vehicleFuelType),
      });

      //  alert("this.props.stateData.vehicleMake :" + this.props.stateData.vehicleMake);
      console.log("vehicleMakeData :", vehicleMakeData);
      console.log("vehicleModelData :", vehicleModelData);
      console.log("vehicleFuelType :", vehicleFuelType);

      if (vehicleMakeData.length > 0) {
        //  alert("OLD VEHICLE MAKE");
        var vehicleMakeValueData = {
          value: this.props.stateData.vehicleMake,
          label: this.props.stateData.vehicleMake,
        };
        self.handleChangeSelectVehicleMake(vehicleMakeValueData, "");
      } else {
        //  alert("NEW VEHICLE MAKE");
        if (this.props.stateData.vehicleMake != undefined) {
          self.handleInputChangeVehicleMake(
            this.props.stateData.vehicleMake,
            ""
          );
        }
      }

      if (vehicleModelData.length > 0) {
        //   alert("OLD VEHICLE MODEL");
        var vehicleModelValueData = {
          value: this.props.stateData.vehicleModel,
          label: this.props.stateData.vehicleModel,
        };
        self.handleChangeSelectVehicleModel(vehicleModelValueData, "");
      } else {
        //  alert("NEW VEHICLE MODEL");
        if (this.props.stateData.vehicleModel != undefined) {
          self.handleInputChangeVehicleModel(
            this.props.stateData.vehicleModel,
            ""
          );
        }
      }

      if (vehicleFuelType.length > 0) {
        // alert("OLD VEHICLE FUEL TYPE");
        var vehicleFuelTypeValueData = {
          value: this.props.stateData.vehicleFuelType,
          label: this.props.stateData.vehicleFuelType,
        };
        self.handleChangeSelectFuelType(vehicleFuelTypeValueData, "");
      } else {
        // alert("NEW VEHICLE FUEL TYPE");
        if (this.props.vehicleFuelType != undefined) {
          //remove this if condition alone once fueltype is got from the user via app
          self.handleInputChangeVehicleFuelType(
            this.props.stateData.vehicleFuelType,
            ""
          );
        }
      }

      console.log(
        "this.props.stateData.service :",
        this.props.stateData.service
      );
      console.log("serviceData :", serviceData);

      var serviceData = this.props.stateData.service.split(",");
      console.log("serviceData :", serviceData);
      self.state.serviceList = [];
      self.state.selectedServiceList = [];

      $.each(serviceData, function (i, item) {
        self.state.serviceList.push(item);
        self.state.selectedServiceList.push({ value: item, label: item });
      });

      self.setState({
        serviceList: self.state.serviceList,
        selectedServiceList: self.state.selectedServiceList,
      });

      console.log(" self.state.serviceList :", self.state.serviceList);
    } else {
      //   alert("LIST PAGE");
    }
  }

  handleUserInputCustomerName = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var customerNameData = CapitalCaseFunc(value);
    this.state.customerName = customerNameData;

    this.setState({
      customerName: this.state.customerName,
    });
  };

  handleUserInputComments = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var customerCommentData = value;
    this.state.comments = CommetsValidationFunc(customerCommentData);

    this.setState({
      comments: this.state.comments,
    });
  };

  handleUserInputModelYear = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var ModelYear = value;
    if (YearValidationFunc(ModelYear)) {
      this.state.modelYear = ModelYear;
      this.setState({
        modelYear: this.state.modelYear,
      });
      $("#modelYear").hide();
    } else {
      $("#modelYear").show();
    }
  };

  handleUserInputColor = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var color = value;
    if (ColorValidationFunc(color)) {
      this.state.vehicleColor = color.replace(/\b(\w)/g, (s) =>
        s.toUpperCase()
      );
      this.setState({
        vehicleColor: this.state.vehicleColor,
      });
      color = false;
      $("#vehicleColor").hide();
    } else {
      color = true;
      $("#vehicleColor").show();
    }
  };

  handleUserInputChasisNo = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var ChasisNo = value;
    if (ChasisValidationFunc(ChasisNo)) {
      this.state.chasisNumber = ChasisNo;
      this.setState({
        chasisNumber: this.state.chasisNumber,
      });
      $("#chasisNo").hide();
    } else {
      chassis = true;
      $("#chasisNo").show();
    }
    $("#chasisNo").delay(1600).fadeOut(300);
  };

  handleUserInputEngineNumber = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var EngineNo = value;
    if (EngineValidationFunc(EngineNo)) {
      this.state.engineNumber = EngineNo;

      this.setState({
        engineNumber: this.state.engineNumber,
      });
      $("#engineNo").hide();
    } else {
      errorcount++;
      $("#engineNo").show();
    }
    $("#engineNo").delay(1600).fadeOut(300);
  };
  handleUserInputOdometerReading = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var Odometer = value;
    if (OdometerValidationFunc(Odometer)) {
      this.state.odometerReading = Odometer;

      this.setState({
        odometerReading: this.state.odometerReading,
      });
      $("#odometerNo").hide();

      odometer = false;
    } else {
      odometer = true;
      $("#odometerNo").show();
    }
    $("#odometerNo").delay(1600).fadeOut(300);
  };

  handleUserInputEmailId = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.state.EmailErrorCount = 0;
    $("#emailerrormsg").empty();

    this.state[name] = value;
    this.setState({ [name]: value });

    if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value == "") {
      //   this.state.emailId=value;
      this.state.EmailErrorCount = 0;
      $("#emailerrormsg").empty();
    } else {
      // this.state.emailId="";
      this.state.EmailErrorCount = 1;
      $("#emailerrormsg").append("Incorrect EmailId");
    }

    // this.state.emailId=value;
    this.setState({
      //   emailId: this.state.emailId ,
      EmailErrorCount: this.state.EmailErrorCount,
    });
  };

  VehicleRegistrationFunc() {
    var self = this;

    /*console.log("this.state.selectedCustomer :",this.state.selectedCustomer.value ,
    "this.state.vehicleRegNo :",this.state.vehicleRegNo,
     "this.state.selectedServiceList",this.state.serviceList);
     */

    if (
      this.state.selectedCustomer.value != undefined &&
      this.state.vehicleRegNo != "" &&
      this.state.serviceList.length > 0
    ) {
      var customerDetails = _.findWhere(customerArray, {
        customerId: this.state.selectedCustomerId,
      });

      var companyName = "";
      var gstNo = "";
      var address = "";
      var vehicleMakeInfo = "Proceed";
      var vehicleModelInfo = "Proceed";
      var vehicleFuelType = "Proceed";

      if (customerDetails != undefined) {
        companyName = customerDetails.companyName;
        gstNo = customerDetails.gstinNo;
        address = customerDetails.address;
      }

      if (
        (this.state.vehicleMake != undefined && this.state.vehicleMake != "") ||
        (this.state.vehicleModel != undefined &&
          this.state.vehicleModel != "") ||
        (this.state.vehicleFuelType != undefined &&
          this.state.vehicleFuelType != "")
      ) {
        if (
          this.state.vehicleMake == undefined ||
          this.state.vehicleMake == ""
        ) {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Kindly provide vehiclemake to proceed",
            false,
            "3000"
          );
          vehicleMakeInfo = "No_Proceed";
        }
        if (
          this.state.vehicleModel == undefined ||
          this.state.vehicleModel == ""
        ) {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Kindly provide vehiclemodel to proceed",
            false,
            "3000"
          );
          vehicleModelInfo = "No_Proceed";
        }
        if (
          this.state.vehicleFuelType == undefined ||
          this.state.vehicleFuelType == ""
        ) {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Kindly provide vehiclefueltype to proceed",
            false,
            "3000"
          );
          vehicleFuelType = "No_Proceed";
        }
      }

      console.log("taxStatus");
      console.log(this.state.estimateData);
      if (this.state.taxStatus === true) {
        this.state.totalEstimateAmount = this.state.totalEstimateAmountwithTax;
        this.setState({
          totalEstimateAmount: this.state.totalEstimateAmount,
        });
      }
      console.log(this.state.totalEstimateAmount);

      console.log(
        "color:",
        this.state.vehicleColor,
        JSON.stringify({
          companyId: this.state.companyId,
          contactNo: this.state.selectedCustomer.value,
          customerName: this.state.customerName,
          vehicleRegNo: this.state.vehicleRegNo,
          vehicleMake: this.state.vehicleMake,
          vehicleModel: this.state.vehicleModel, //added
          vehicleColor: this.state.vehicleColor,
          chasisNumber: this.state.chasisNumber,
          modelYear: this.state.modelYear,
          engineNumber: this.state.engineNumber,
          odometerReading: this.state.odometerReading,
          comments: this.state.comments,
          vehicleFuelType: this.state.vehicleFuelType,
          emailId: this.state.emailId,
          serviceName: this.state.serviceList.toString(),
          customerCreateStatus: this.state.customerCreateStatus,
          vehicleCreateStatus: this.state.vehicleCreateStatus,
          vehicleMakeCreateStatus: this.state.vehicleMakeCreateStatus,
          vehicleModelCreateStatus: this.state.vehicleModelCreateStatus,
          vehicleFuelTypeCreateStatus: this.state.vehicleFuelTypeCreateStatus,
          serviceCreateStatus: this.state.serviceCreateStatus,
          customerId: this.state.selectedCustomerId,
          Image: this.state.baseFiles,
          created_DateTime: this.state.date + " " + this.state.time,

          jobCard_With_Estimate: this.state.jobCard_With_Estimate,
          estimateData: JSON.stringify(this.state.estimateData),

          //INVOICE DETAILS
          cust_gstNo: gstNo,
          companyName: companyName,
          cust_address: address,
          product_serviceByStaffName: this.state.employeeName,
          product_serviceByStaffId: this.state.staffId,
          date: this.state.date,
          time: this.state.time,
          invoiceAmt: this.state.invoiceAmt,
          discountAmt: 0,
          invoiceAmt_Paid: 0,
          balanceAmt: 0,
          invoiceAdvisor: this.state.employeeName,
          invoiceAdvisorId: this.state.staffId,
          total_Itemqty: 0,
          paymentStatus: "UnPaid",
          paymentMode: "",
          totalEstimateAmount: this.state.totalEstimateAmount,
          invoiceDate: this.state.date,
          invoiceDueDate: this.state.date,
          pageCalledFrom: this.state.pageCalledFrom,
          appointmentId: this.state.appointmentId,
        })
      );

      if (
        (vehicleMakeInfo =
          "Proceed" &&
          vehicleModelInfo == "Proceed" &&
          vehicleFuelType == "Proceed")
      ) {
        $.ajax({
          type: "POST",
          data: JSON.stringify({
            companyId: this.state.companyId,
            contactNo: this.state.selectedCustomer.value,
            customerName: this.state.customerName,
            vehicleRegNo: this.state.vehicleRegNo,
            vehicleMake: this.state.vehicleMake,
            vehicleModel: this.state.vehicleModel,
            vehicleFuelType: this.state.vehicleFuelType,
            emailId: this.state.emailId,
            customerId: this.state.selectedCustomerId,
            serviceName: this.state.serviceList.toString(),
            comments: this.state.comments, //added
            odometerReading: this.state.odometerReading,
            color: this.state.vehicleColor,
            chasisNumber: this.state.chasisNumber,
            modelYear: this.state.modelYear,
            engineNumber: this.state.engineNumber,

            customerCreateStatus: this.state.customerCreateStatus,
            vehicleCreateStatus: this.state.vehicleCreateStatus,
            vehicleMakeCreateStatus: this.state.vehicleMakeCreateStatus,
            vehicleModelCreateStatus: this.state.vehicleModelCreateStatus,
            vehicleFuelTypeCreateStatus: this.state.vehicleFuelTypeCreateStatus,
            staffId: this.state.staffId,
            employeeName: this.state.employeeName,
            role: this.state.role,
            site: GetCurrentSite(),
            baseFiles: this.state.baseFiles,
            created_DateTime: this.state.date + " " + this.state.time,

            jobCard_With_Estimate: this.state.jobCard_With_Estimate,
            estimateData: JSON.stringify(this.state.estimateData),

            //INVOICE DETAILS
            cust_gstNo: gstNo,
            companyName: companyName,
            cust_address: address,
            product_serviceByStaffName: this.state.employeeName,
            product_serviceByStaffId: this.state.staffId,
            date: this.state.date,
            time: this.state.time,
            invoiceAmt: this.state.invoiceAmt,
            discountAmt: 0,
            invoiceAmt_Paid: 0,
            balanceAmt: 0,
            invoiceAdvisor: this.state.employeeName,
            invoiceAdvisorId: this.state.staffId,
            total_Itemqty: 0,
            paymentStatus: "UnPaid",
            paymentMode: "",
            totalEstimateAmount: this.state.totalEstimateAmount,
            invoiceDate: this.state.date,
            invoiceDueDate: this.state.date,

            subtotal: this.state.subtotal,
            taxStatus: this.state.taxStatus,
            // rateWithTaxTotal:this.state.totalEstimateAmountwithTax,
            pageCalledFrom: this.state.pageCalledFrom,
            appointmentId: this.state.appointmentId,
          }),
          url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ServiceRegistration/AddServiceRegistration",
          //   url:"https://wildfly.garageapp.in:443/GarageAppIN_API/QuickLinkWebservices/GetVehiclemakemodelDetails",
          contentType: "application/json",
          dataType: "json",
          async: false,
          //  crossDomain: true,

          success: function (data, textStatus, jqXHR) {
            //console.log("BASIC DATA :", data);

            if (_.contains(data.responseList, "NoDuplicateCustomer")) {
              Swal.fire({
                position: "center",
                icon: "success",
                text: "Added Customer Successfuly",
                showConfirmButton: false,
                timer: 2000,
              });
            } else if (_.contains(data.responseList, "DuplicateCustomer")) {
              Swal.fire({
                position: "center",
                icon: "warning",
                text: "Customer Already Exist",
                showConfirmButton: false,
                timer: 2000,
              });
            }

            if (_.contains(data.responseList, "NoDuplicateVehicle")) {
              Swal.fire({
                position: "center",
                icon: "success",
                text: "Added Vehicle Successfuly",
                showConfirmButton: false,
                timer: 2000,
              });
            } else if (_.contains(data.responseList, "DuplicateVehicle")) {
              Swal.fire({
                position: "center",
                icon: "warning",
                text: "Vehicle Already Exist",
                showConfirmButton: false,
                timer: 2000,
              });
            }

            if (_.contains(data.responseList, "NoDuplicateVehicleMakeModel")) {
              Swal.fire({
                position: "center",
                icon: "success",
                text: "Added Vehicle Make & Model Information Successfuly",
                showConfirmButton: false,
                timer: 2000,
              });
            } else if (
              _.contains(data.responseList, "DuplicateVehicleMakeModel")
            ) {
              Swal.fire({
                position: "center",
                icon: "warning",
                text: "Vehicle MakeModel Information Already Exist Already Exist",
                showConfirmButton: false,
                timer: 2000,
              });
            }

            if (_.contains(data.responseList, "Addded_BookingDetails")) {
              Swal.fire({
                position: "center",
                icon: "success",
                text: "Added Service Registartion Successfuly",
                showConfirmButton: false,
                timer: 2000,
              });

              ReactDOM.render(
                <Router>
                  <Suspense fallback={<LoadingComponent />}>
                    <div>
                      <Route path="/" component={VehicleRegistrationList} />
                    </div>
                  </Suspense>
                </Router>,
                document.getElementById("contentRender")
              );
              registerServiceWorker();
            }
            if (
              _.contains(
                data.responseList,
                "Addded_BookingDetails_With_Estimate"
              )
            ) {
              Swal.fire({
                position: "center",
                icon: "success",
                text: "Added Service Registartion Successfuly with Estimation",
                showConfirmButton: false,
                timer: 2000,
              });
              ReactDOM.render(
                <Router>
                  <Suspense fallback={<LoadingComponent />}>
                    <div>
                      <Route path="/" component={VehicleRegistrationList} />
                    </div>
                  </Suspense>
                </Router>,
                document.getElementById("contentRender")
              );
              registerServiceWorker();
            }

            self.ClearFunc();
          },
          error: function (data) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Network Connection Problem ",
              showConfirmButton: false,
              timer: 2000,
            });
          },
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Kindly FillIn All Mandatory Fields",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  OpenSlide() {
    this.state.isPaneOpen = true;

    this.setState({
      isPaneOpen: this.state.isPaneOpen,
    });
  }

  CloseSlide() {
    this.state.isPaneOpen = false;

    this.setState({
      isPaneOpen: this.state.isPaneOpen,
    });
  }

  CloseCancelMakeModelInfoSlide = (currentState) => {
    var self = this;
    self.state.isPaneOpen = false;
    self.setState({
      isPaneOpen: self.state.isPaneOpen,
    });
  };

  OpenCustomerSlide() {
    this.state.isCustomerPaneOpen = true;

    this.setState({
      isCustomerPaneOpen: this.state.isCustomerPaneOpen,
    });
  }

  CloseCustomerSlide() {
    this.state.isCustomerPaneOpen = false;

    this.setState({
      isCustomerPaneOpen: this.state.isCustomerPaneOpen,
    });
  }

  CloseCancelCustomerInfoSlide = (currentState) => {
    var self = this;
    self.state.isCustomerPaneOpen = false;
    self.setState({
      isCustomerPaneOpen: self.state.isCustomerPaneOpen,
    });
  };

  SubmitCustomerInfoSlide(stateData, submit_proceed) {
    //console.log("stateDate :",stateData);
    //console.log("this :",this);

    //console.log("selfStateData :",submit_proceed);

    if (submit_proceed == "Yes") {
      this.state.isCustomerPaneOpen = false;
      this.state.isVehiclePaneOpen = true;

      this.setState({
        isCustomerPaneOpen: this.state.isCustomerPaneOpen,
        isVehiclePaneOpen: this.state.isVehiclePaneOpen,
      });
      this.state.stateData = stateData;
      this.setState({
        stateData: this.state.stateData,
      });
    }

    this.state.selectedCustomerList = [];
    this.state.customerList = [];

    this.setState({
      selectedCustomerList: this.state.selectedCustomerList,
      customerList: this.state.customerList,
    });

    var self = this;

    customerArray = [];

    customerArray = stateData.customerList;
    self.state.customerList = [];

    $.each(stateData.customerList, function (i, item) {
      //  self.state.customerList.push(item.contactNo);
      self.state.customerList.push({
        value: item.contactNo,
        label: item.contactNo + " - " + item.customerName,
      });
    });

    this.setState({
      customerList: [...this.state.customerList],
    });
  }

  ClearFunc() {
    this.state.selectedCustomer = [];
    this.state.customerName = "";
    this.state.vehicleRegNo = "";
    this.state.vehicleMake = "";
    this.state.vehicleFuelType = "";
    this.state.emailId = "";
    this.state.selectedServiceList = [];
    this.state.customerCreateStatus = "No";
    this.state.vehicleCreateStatus = "No";
    this.state.vehicleMakeCreateStatus = "No";
    this.state.vehicleModelCreateStatus = "No";
    this.state.vehicleFuelTypeCreateStatus = "No";
    this.state.serviceCreateStatus = "No";
    this.state.selectedCustomerId = "";
    //added

    this.state.vehicleColor = "";
    this.state.chasisNumber = "";
    this.state.modelYear = "";
    this.state.engineNumber = "";

    this.state.comments = "";
    this.state.odometerReading = "";
    this.state.selectedVehicleRegNo = [];
    this.state.selectedVehicleMake = [];
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.serviceList = [];
    this.state.images = [];
    this.state.baseFiles = [];
    this.state.flag = true;

    this.state.jobCard_With_Estimate = false;
    this.state.estimateData = [];

    this.setState({
      flag: this.state.flag,
      selectedCustomer: this.state.selectedCustomer,
      customerName: this.state.customerName,
      vehicleRegNo: this.state.vehicleRegNo,
      vehicleMake: this.state.vehicleMake,
      vehicleModel: this.state.vehicleModel,
      //added
      odometerReading: this.state.odometerReading,
      comments: this.state.comments,
      vehicleColor: this.state.vehicleColor,
      chasisNumber: this.state.chasisNumber,
      modelYear: this.state.modelYear,
      engineNumber: this.state.engineNumber,

      vehicleFuelType: this.state.vehicleFuelType,
      emailId: this.state.emailId,
      selectedServiceList: this.state.selectedServiceList,
      customerCreateStatus: this.state.customerCreateStatus,
      vehicleCreateStatus: this.state.vehicleCreateStatus,
      vehicleMakeCreateStatus: this.state.vehicleMakeCreateStatus,
      vehicleModelCreateStatus: this.state.vehicleModelCreateStatus,
      vehicleFuelTypeCreateStatus: this.state.vehicleFuelTypeCreateStatus,
      serviceCreateStatus: this.state.serviceCreateStatus,
      customerId: this.state.selectedCustomerId,

      selectedVehicleRegNo: this.state.selectedVehicleRegNo,
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      baseFiles: this.state.baseFiles,
      images: this.state.images,
      serviceList: this.state.serviceList,

      jobCard_With_Estimate: this.state.jobCard_With_Estimate,
      estimateData: this.state.estimateData,
    });
  }

  SubmitVehicleMakeModelInfoSlide(stateData) {
    //console.log(" SubmitVehicleMakeModelInfoSlide stateDate :",stateData);
    //console.log("this :",this);

    var self = this;

    vehicleMakeModelArray = [];
    vehicleMakeModelArray = stateData.vehicleMakeModelList;

    var subVehicleMakeArray = _.uniq(vehicleMakeModelArray, "vehicleMake");
    var subVehicleModelArray = _.uniq(vehicleMakeModelArray, "vehicleModel");
    var subVehicleFuelTypeArray = _.uniq(
      vehicleMakeModelArray,
      "vehicleFuelType"
    );

    this.state.vehcileMakeList = [];
    this.state.vehicleModelList = [];
    this.state.vehcileFuelTypeList = [];

    this.state.selectedVehicleMakeList = [];
    this.state.selectedVehicleModelList = [];
    this.state.selectedFuelTypeList = [];

    this.setState({
      vehcileMakeList: [...this.state.vehcileMakeList],
      vehicleModelList: [...this.state.vehicleModelList],
      vehcileFuelTypeList: [...this.state.vehcileFuelTypeList],

      selectedVehicleMakeList: [...this.state.selectedVehicleMakeList],
      selectedVehicleModelList: [...this.state.selectedVehicleModelList],
      selectedFuelTypeList: [...this.state.selectedFuelTypeList],
    });

    $.each(subVehicleMakeArray, function (i, item) {
      //console.log("vehicleMake :",item.vehicleMake);
      // self.state.vehcileMakeList.push(item.vehicleMake);
      self.state.vehcileMakeList.push({
        value: item.vehicleMake,
        label: item.vehicleMake,
      });
    });

    $.each(subVehicleModelArray, function (i, item) {
      //console.log("vehicleModel :",item.vehicleModel);
      //  self.state.vehicleModelList.push(item.vehicleModel);
      self.state.vehicleModelList.push({
        value: item.vehicleModel,
        label: item.vehicleModel,
      });
    });

    $.each(subVehicleFuelTypeArray, function (i, item) {
      //console.log("vehicleFuelType :",item.vehicleFuelType);
      // self.state.vehcileFuelTypeList.push(item.vehicleFuelType);
      self.state.vehcileFuelTypeList.push({
        value: item.vehicleFuelType,
        label: item.vehicleFuelType,
      });
    });

    this.setState({
      vehcileMakeList: [...this.state.vehcileMakeList],
      vehicleModelList: [...this.state.vehicleModelList],
      vehcileFuelTypeList: [...this.state.vehcileFuelTypeList],
    });
  }

  OpenVehicleSlide() {
    this.state.isVehiclePaneOpen = true;
    this.state.stateData = "";

    this.setState({
      isVehiclePaneOpen: this.state.isVehiclePaneOpen,
      stateData: this.state.stateData,
    });
  }

  CloseVehicleSlide() {
    this.state.isVehiclePaneOpen = false;
    this.state.stateData = "";

    this.setState({
      isVehiclePaneOpen: this.state.isVehiclePaneOpen,
      stateData: this.state.stateData,
    });
  }

  CloseCancelVehicleSlide = () => {
    var self = this;
    self.state.isVehiclePaneOpen = false;
    self.state.stateData = "";

    self.setState({
      isVehiclePaneOpen: self.state.isVehiclePaneOpen,
      stateData: self.state.stateData,
    });
  };

  SubmitVehicleInfoSlide(stateData) {
    //console.log("SubmitVehicleInfoSlide stateData :",stateData);

    this.state.selectedVehicleRegNoList = [];
    this.state.vehicleList = [];

    this.setState({
      selectedVehicleRegNoList: this.state.selectedVehicleRegNoList,
      vehicleList: this.state.vehicleList,
    });

    var self = this;

    vehicleArray = [];

    vehicleArray = stateData.vehicleList;
    self.state.vehicleList = [];

    $.each(stateData.vehicleList, function (i, item) {
      //self.state.vehicleList.push(item.vehicleRegNo);
      self.state.vehicleList.push({
        value: item.vehicleRegNo,
        label: item.vehicleRegNo,
      });
    });

    this.setState({
      vehicleList: [...this.state.vehicleList],
    });
  }

  handleChangeSelectCustomer = (newValue, actionMeta) => {
    /*console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    console.groupEnd(); */

    console.log("SELECT CUSTOMER :", newValue);
    //  //console.log("SELECT CUSTOMER MOBILE NO:",newValue.value);

    var self = this;
    $("#contactnoerror").hide();
    this.state.errorStatus = 0;

    this.state.customerName = "";
    this.state.selectedCustomerId = "";
    this.state.emailId = "";
    this.state.selectedServiceList = [];
    this.state.comments = ""; //added
    this.state.odometerReading = "";
    this.state.vehicleColor = "";
    this.state.chasisNumber = "";
    this.state.modelYear = "";
    this.state.engineNumber = "";

    this.state.vehicleRegNo = "";
    this.state.vehicleMake = "";
    this.state.vehicleFuelType = "";
    this.state.customerCreateStatus = "No";
    this.state.selectedCustomer = "";

    this.state.selectedVehicleRegNo = [];
    this.state.selectedVehicleMake = [];
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.selectedProductList = [];

    this.setState({
      customerName: this.state.customerName,
      selectedCustomerId: this.state.selectedCustomerId,
      emailId: this.state.emailId,
      comments: this.state.comments, //added
      odometerReading: this.state.odometerReading,

      vehicleColor: this.state.vehicleColor,
      chasisNumber: this.state.chasisNumber,
      modelYear: this.state.modelYear,
      engineNumber: this.state.engineNumber,

      vehicleRegNo: this.state.vehicleRegNo,
      vehicleMake: this.state.vehicleMake,
      vehicleFuelType: this.state.vehicleFuelType,
      selectedProductList: this.state.selectedProductList,
      customerCreateStatus: this.state.customerCreateStatus,
      selectedCustomer: this.state.selectedCustomer,
      selectedVehicleRegNo: this.state.selectedVehicleRegNo,

      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedServiceList: this.state.selectedServiceList,
    });

    if (newValue != null) {
      var currentCustomerArray = _.where(customerArray, {
        contactNo: newValue.value,
      });

      this.state.selectedCustomer = newValue;

      //console.log("currentCustomerArray :",currentCustomerArray);

      var vehicleRegDetails = _.where(vehicleArray, {
        customerId: currentCustomerArray[0].customerId,
      });

      self.state.vehicleList = [];
      $.each(vehicleRegDetails, function (i, item) {
        self.state.vehicleList.push({
          value: item.vehicleRegNo,
          label: item.vehicleRegNo,
        });
      });

      this.state.selectedCustomerId = currentCustomerArray[0].customerId;
      this.state.customerName = currentCustomerArray[0].customerName;
      this.state.emailId = currentCustomerArray[0].emailId;
      this.state.customerNamereadonly = true;
      this.state.emailIdreadonly = true;

      this.setState({
        customerName: this.state.customerName,
        customerNamereadonly: this.state.customerNamereadonly,
        emailIdreadonly: this.state.emailIdreadonly,
        emailId: this.state.emailId,
        vehicleList: self.state.vehicleList,
        selectedCustomerId: this.state.selectedCustomerId,
        selectedCustomer: this.state.selectedCustomer,
      });
    }
  };

  handleInputChangeCustomer = (inputValue, actionMeta) => {
    // alert("handleInputChangeCustomer");

    console.group("Input Changed");
    console.log(inputValue);
    //   console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    var self = this;
    this.state.errorStatus = 0;

    //console.log("CREATE CUSTOMER :",inputValue);

    $("#contactnoerror").hide();

    this.state.selectedCustomer = "";
    this.state.customerName = "";

    this.state.selectedCustomerId = "";
    this.state.emailId = "";
    this.state.comments = ""; //added
    this.state.odometerReading = "";
    this.state.vehicleColor = "";
    this.state.chasisNumber = "";
    this.state.modelYear = "";
    this.state.engineNumber = "";

    this.state.vehicleRegNo = "";
    this.state.vehicleMake = "";
    this.state.vehicleFuelType = "";
    this.state.customerCreateStatus = "No";
    this.state.selectedCustomer = "";

    this.state.selectedVehicleRegNo = [];
    this.state.selectedVehicleMake = [];
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.selectedServiceList = [];
    self.state.vehicleList = [];

    this.setState({
      customerName: this.state.customerName,
      selectedCustomerId: this.state.selectedCustomerId,
      emailId: this.state.emailId,
      comments: this.state.comments, //added

      vehicleColor: this.state.vehicleColor,
      chasisNumber: this.state.chasisNumber,
      modelYear: this.state.modelYear,
      engineNumber: this.state.engineNumber,

      odometerReading: this.state.odometerReading,
      vehicleRegNo: this.state.vehicleRegNo,
      vehicleMake: this.state.vehicleMake,
      vehicleFuelType: this.state.vehicleFuelType,
      selectedServiceList: this.state.selectedServiceList,
      customerCreateStatus: this.state.customerCreateStatus,
      selectedCustomer: this.state.selectedCustomer,
      selectedVehicleRegNo: this.state.selectedVehicleRegNo,

      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedProductList: this.state.selectedProductList,
      vehicleList: self.state.vehicleList,
    });

    var validationData = contactNoValidationFunc(inputValue);

    if (validationData == true) {
      $("#contactnoerror").hide();
      self.state.customerCreateStatus = "Yes";
      self.setState({
        customerCreateStatus: self.state.customerCreateStatus,
      });

      this.state.selectedCustomer = { value: inputValue, label: inputValue };

      self.state.customerNamereadonly = false;
      self.state.emailIdreadonly = false;

      self.setState({
        customerNamereadonly: self.state.customerNamereadonly,
        emailIdreadonly: self.state.emailIdreadonly,
        selectedCustomer: this.state.selectedCustomer,
        customerName: this.state.customerName,
      });
    } else {
      $("#contactnoerror").show();
      this.state.errorStatus = Number(this.state.errorStatus) + Number(1);
    }
  };

  handleChangeSelectVehicleRegNo = (newValue, actionMeta) => {
    console.log("VEHCILE :", newValue);

    this.state.vehicleRegNo = "";

    this.state.selectedVehicleMake = [];
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.selectedProductList = [];

    this.state.vehicleMake = "";
    this.state.vehicleModel = "";
    this.state.vehicleFuelType = "";

    this.state.vehicleCreateStatus = "No";

    this.setState({
      vehicleRegNo: this.state.vehicleRegNo,
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedProductList: this.state.selectedProductList,
      vehicleCreateStatus: this.state.vehicleCreateStatus,

      vehicleMake: this.state.vehicleMake,
      vehicleModel: this.state.vehicleModel,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    if (newValue != null) {
      this.state.selectedVehicleRegNo = newValue;
      this.state.vehicleRegNo = newValue.value;
      this.setState({
        selectedVehicleRegNo: this.state.selectedVehicleRegNo,
        vehicleRegNo: this.state.vehicleRegNo,
      });

      var vehicleRegDetails = _.where(vehicleArray, {
        vehicleRegNo: newValue.value,
      });

      console.log("vehicleRegDetails ARRAY :", vehicleRegDetails);

      this.state.customerId = vehicleRegDetails[0].customerId;
      this.state.vehicleRegNo = vehicleRegDetails[0].vehicleRegNo;
      this.state.vehicleMake = vehicleRegDetails[0].vehicleMake;
      this.state.vehicleModel = vehicleRegDetails[0].vehicleModel;
      this.state.vehicleFuelType = vehicleRegDetails[0].vehicleFuelType;

      this.state.selectedVehicleMake = {
        value: vehicleRegDetails[0].vehicleMake,
        label: vehicleRegDetails[0].vehicleMake,
      };
      this.state.selectedVehicleModel = {
        value: vehicleRegDetails[0].vehicleModel,
        label: vehicleRegDetails[0].vehicleModel,
      };
      this.state.selectedVehicleFuelType = {
        value: vehicleRegDetails[0].vehicleFuelType,
        label: vehicleRegDetails[0].vehicleFuelType,
      };

      this.setState({
        customerId: this.state.customerId,
      });

      if (this.state.selectedCustomerId != this.state.customerId) {
        this.state.customerName = "";
        this.state.selectedCustomerId = "";
        this.state.emailId = "";

        var customerName = _.where(customerArray, {
          customerId: this.state.customerId,
        });
        //  alert("this.state.customerId :" + this.state.customerId);
        console.log(
          "handleChangeSelectVehicleRegNo customerName :",
          customerName
        );

        this.state.customerName = customerName[0].customerName;
        this.state.emailId = customerName[0].emailId;
        this.state.selectedCustomer = {
          value: customerName[0].contactNo,
          label:
            customerName[0].contactNo + " - " + customerName[0].customerName,
        };
      }

      this.setState({
        vehicleRegNo: this.state.vehicleRegNo,
        vehicleMake: this.state.vehicleMake,
        vehicleModel: this.state.vehicleModel,
        vehicleFuelType: this.state.vehicleFuelType,
        selectedVehicleMake: this.state.selectedVehicleMake,
        selectedVehicleModel: this.state.selectedVehicleModel,
        selectedVehicleFuelType: this.state.selectedVehicleFuelType,
        customerId: this.state.customerId,
        odometerReading: this.state.odometerReading,
        comments: this.state.comments,
        vehicleColor: this.state.vehicleColor,
        chasisNumber: this.state.chasisNumber,
        modelYear: this.state.modelYear,
        engineNumber: this.state.engineNumber,
        //added

        customerName: this.state.customerName,
        emailId: this.state.emailId,
        selectedCustomer: this.state.selectedCustomer,
      });
    }
  };

  handleInputChangeVehicleRegNo = (inputValue, actionMeta) => {
    //console.log("INPUT VECHILE CHANGE :",inputValue);

    this.state.vehicleRegNo = "";

    this.state.selectedVehicleMake = [];
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.selectedProductList = [];

    this.state.vehicleMake = "";
    this.state.vehicleModel = "";
    this.state.vehicleFuelType = "";

    this.state.vehicleCreateStatus = "Yes";

    this.setState({
      vehicleRegNo: this.state.vehicleRegNo,
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      selectedProductList: this.state.selectedProductList,
      vehicleCreateStatus: this.state.vehicleCreateStatus,

      vehicleMake: this.state.vehicleMake,
      vehicleModel: this.state.vehicleModel,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    var validationData = vehicleRegNo_NumberValidation(inputValue);

    if (validationData == true) {
      var regNoData = inputValue.toUpperCase();

      this.state.selectedVehicleRegNo = { value: regNoData, label: regNoData };
      this.state.vehicleRegNo = regNoData;
      this.setState({
        selectedVehicleRegNo: this.state.selectedVehicleRegNo,
        vehicleRegNo: this.state.vehicleRegNo,
      });
    } else {
      $("#vehicleRegNoErrorMsgAlert").append("! Incorrect value");
    }
    HideFieldErroeMsgs("vehicleRegNoErrorMsgAlert");
  };

  handleChangeSelectVehicleMake = (newValue, actionMeta) => {
    console.log("vehicle make:", newValue);
    var self = this;
    this.state.selectedVehicleModel = [];
    this.state.selectedVehicleFuelType = [];
    this.state.vehicleMakeCreateStatus = "No";

    this.state.vehicleModel = "";
    this.state.vehicleFuelType = "";

    this.setState({
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      vehicleMakeCreateStatus: this.state.vehicleMakeCreateStatus,
      vehicleModel: this.state.vehicleModel,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    if (newValue != null) {
      this.state.selectedVehicleMake = newValue;

      this.setState({
        selectedVehicleMake: this.state.selectedVehicleMake,
      });

      var vehicleMakeModelDetails = _.where(vehicleMakeModelArray, {
        vehicleMake: newValue.value,
      });

      var subVehicleModelArray = _.uniq(
        vehicleMakeModelDetails,
        "vehicleModel"
      );

      self.state.vehicleModelList = [];
      $.each(subVehicleModelArray, function (i, item) {
        self.state.vehicleModelList.push({
          value: item.vehicleModel,
          label: item.vehicleModel,
        });
      });

      this.state.vehicleMake = newValue.value;
      this.state.vehicleModel = "";
      this.state.vehicleFuelType = "";
      this.state.comments = ""; //added
      this.state.odometerReading = "";
      this.state.vehicleColor = "";
      this.state.chasisNumber = "";
      this.state.modelYear = "";
      this.state.engineNumber = "";

      this.state.selectedVehicleModel = [];
      this.state.selectedVehicleFuelType = [];

      this.setState({
        vehicleMake: this.state.vehicleMake,
        vehicleModel: this.state.vehicleModel,
        vehicleFuelType: this.state.vehicleFuelType,
        //added

        vehicleColor: this.state.vehicleColor,
        chasisNumber: this.state.chasisNumber,
        modelYear: this.state.modelYear,
        engineNumber: this.state.engineNumber,

        comments: this.state.comments,
        odometerReading: this.state.odometerReading,
        selectedVehicleMake: this.state.selectedVehicleMake,
        selectedVehicleModel: this.state.selectedVehicleModel,
        selectedVehicleFuelType: this.state.selectedVehicleFuelType,

        vehicleModelList: self.state.vehicleModelList,
      });
    }
  };

  handleInputChangeVehicleMake = (inputValue, actionMeta) => {
    var self = this;
    self.state.selectedVehicleMake = [];

    self.state.selectedVehicleModel = [];
    self.state.selectedVehicleFuelType = [];
    self.state.vehicleMake = "";
    self.state.vehicleModel = "";
    self.state.vehicleFuelType = "";
    self.state.vehicleMakeCreateStatus = "Yes";
    //added
    self.state.chasisNumber = "";
    self.state.vehicleColor = "";
    self.state.modelYear = "";
    self.state.engineNumber = "";

    self.state.comments = "";
    self.state.odometerReading = "";
    var vechileMakeData = CapitalCaseFunc(inputValue);
    self.state.vehicleMake = vechileMakeData;

    self.state.selectedVehicleMake = {
      value: vechileMakeData,
      label: vechileMakeData,
    };

    self.setState({
      selectedVehicleMake: self.state.selectedVehicleMake,
      vehcileMakeList: self.state.vehcileMakeList,
      vehicleMake: self.state.vehicleMake,

      selectedVehicleFuelType: self.state.selectedVehicleFuelType,
      vehicleMakeCreateStatus: self.state.vehicleMakeCreateStatus,

      vehicleModel: self.state.vehicleModel,
      //added
      comments: this.state.comments,
      odometerReading: this.state.odometerReading,
      vehicleColor: this.state.vehicleColor,
      chasisNumber: this.state.chasisNumber,
      modelYear: this.state.modelYear,
      engineNumber: this.state.engineNumber,

      vehicleFuelType: self.state.vehicleFuelType,
    });
  };

  handleChangeSelectVehicleModel = (newValue, actionMeta) => {
    var self = this;
    console.log("vehicle model:", newValue);
    this.state.selectedVehicleFuelType = [];
    this.state.vehicleFuelType = "";
    this.state.vehicleModelCreateStatus = "No";

    this.setState({
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      vehicleModelCreateStatus: this.state.vehicleModelCreateStatus,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    if (newValue != null) {
      this.state.selectedVehicleModel = newValue;
      this.state.vehicleMake = newValue.value;

      this.setState({
        selectedVehicleModel: this.state.selectedVehicleModel,
        vehicleFuelType: this.state.vehicleFuelType,
      });

      var vehicleMakeModelDetails = _.where(vehicleMakeModelArray, {
        vehicleModel: newValue.value,
      });

      var subVehicleFuelTypeArray = _.uniq(
        vehicleMakeModelDetails,
        "vehicleFuelType"
      );

      if (subVehicleFuelTypeArray.length > 1) {
        self.state.vehcileFuelTypeList = [];
        $.each(subVehicleFuelTypeArray, function (i, item) {
          self.state.vehcileFuelTypeList.push({
            value: item.vehicleFuelType,
            label: item.vehicleFuelType,
          });
        });
      } else if (subVehicleFuelTypeArray.length == 1) {
        this.state.selectedVehicleFuelType = {
          value: subVehicleFuelTypeArray[0].vehicleFuelType,
          label: subVehicleFuelTypeArray[0].vehicleFuelType,
        };
        this.state.vehicleFuelType = subVehicleFuelTypeArray[0].vehicleFuelType;
      }

      this.state.vehicleModel = newValue.value;
      this.state.selectedVehicleModel = newValue;

      this.setState({
        vehicleModel: this.state.vehicleModel,
        selectedVehicleModel: this.state.selectedVehicleModel,
        vehcileFuelTypeList: self.state.vehcileFuelTypeList,

        selectedVehicleFuelType: this.state.selectedVehicleFuelType,
        vehicleFuelType: this.state.vehicleFuelType,
      });
    }
  };

  handleInputChangeVehicleModel = (inputValue, actionMeta) => {
    var self = this;
    self.state.selectedVehicleModel = [];
    self.state.selectedVehicleFuelType = [];
    self.state.vehicleModelCreateStatus = "Yes";
    self.state.vehicleModel = "";
    self.state.vehicleFuelType = "";

    self.setState({
      selectedVehicleModel: self.state.selectedVehicleModel,
      vehicleModel: self.state.vehicleModel,
      vehicleFuelType: self.state.vehicleFuelType,

      selectedVehicleFuelType: self.state.selectedVehicleFuelType,
      vehicleModelCreateStatus: self.state.vehicleModelCreateStatus,
    });

    var vehicleModelData = CapitalCaseFunc(inputValue);
    self.state.vehicleModel = vehicleModelData;
    self.state.selectedVehicleModel = {
      value: vehicleModelData,
      label: vehicleModelData,
    };

    self.setState({
      vehicleModel: self.state.vehicleModel,
      selectedVehicleModel: self.state.selectedVehicleModel,
    });
  };

  handleChangeSelectFuelType = (newValue, actionMeta) => {
    console.log("vehicle fueltype:", newValue);
    var self = this;
    this.state.selectedVehicleFuelType = [];
    this.state.vehicleFuelTypeCreateStatus = "No";

    this.setState({
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      vehicleFuelTypeCreateStatus: this.state.vehicleFuelTypeCreateStatus,
    });

    if (newValue != null) {
      this.state.selectedVehicleFuelType = newValue;
      this.state.vehicleFuelType = newValue.value;

      this.setState({
        vehicleFuelType: this.state.vehicleFuelType,
        selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      });
    }
  };

  handleInputChangeVehicleFuelType = (inputValue, actionMeta) => {
    var self = this;
    this.state.selectedVehicleFuelType = [];
    this.state.vehicleFuelTypeCreateStatus = "Yes";

    this.setState({
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
      vehicleFuelTypeCreateStatus: this.state.vehicleFuelTypeCreateStatus,
    });

    var vechileFuelTypeData = CapitalCaseFunc(inputValue);
    this.state.vehicleFuelType = vechileFuelTypeData;
    this.state.selectedVehicleFuelType = {
      value: vechileFuelTypeData,
      label: vechileFuelTypeData,
    };

    this.setState({
      vehicleFuelType: this.state.vehicleFuelType,
      selectedVehicleFuelType: this.state.selectedVehicleFuelType,
    });
  };

  handleChangeSelectService = (newValue, actionMeta) => {
    var self = this;
    this.state.selectedServiceList = [];
    this.state.serviceList = [];
    this.state.serviceCreateStatus = "No";

    this.setState({
      selectedServiceList: this.state.selectedServiceList,
      serviceCreateStatus: this.state.serviceCreateStatus,
      serviceList: this.state.serviceList,
    });

    console.log("SELECTED SERVICE NEW VALUE :", newValue);

    this.state.selectedServiceList = newValue;
    var selectedServiceArray = newValue;

    if (selectedServiceArray != null) {
      $.each(selectedServiceArray, function (i, item) {
        self.state.serviceList.push(item.value);
      });
    }

    this.setState({
      selectedServiceList: this.state.selectedServiceList,
      serviceList: this.state.serviceList,
    });

    this.JobCardEstimateFunc();
  };

  JobCardEstimateFunc() {
    var self = this;
    this.state.estimateData = [];
    var count = 0;
    var arrayCount = 0;

    var totalRate = Truncate_2DecimalPlaces(0);
    var totalRatewithtax = Truncate_2DecimalPlaces(0);
    console.log(self.state.serviceList.slice().reverse());

    self.state.serviceList
      .slice()
      .reverse()
      .forEach(function (item) {
        var RatewithTaxtemp; //to calculate each service

        var currentProductInfo = _.findWhere(totalProductArray, {
          serviceName: item,
          productType: "service",
        });
        console.log("currentProductInfo :", currentProductInfo);
        count = Number(count) + 1;

        console.log(currentProductInfo.product_CGST_Percentage);
        console.log(currentProductInfo);
        console.log(currentProductInfo);
        //code for calculating ratewithtax by using 3 tax frm data array

        //   var RatewithTaxtemp= Rate+( (Rate*cgst%/100) + (Rate*sgst%/100) + (Rate*igst%/100) );
        RatewithTaxtemp = Truncate_2DecimalPlaces(
          Number(parseInt(currentProductInfo.productRate_Each)) +
            ((currentProductInfo.productRate_Each *
              currentProductInfo.product_CGST_Percentage) /
              100 +
              (currentProductInfo.productRate_Each *
                currentProductInfo.product_SGST_Percentage) /
                100 +
              (currentProductInfo.productRate_Each *
                currentProductInfo.product_IGST_Percentage) /
                100)
        );
        console.log(RatewithTaxtemp);
        console.log("RatewithoutTax", currentProductInfo.productRate_Each);
        console.log("RatewithTaxtemp", RatewithTaxtemp);
        totalRate = Truncate_2DecimalPlaces(
          Number(totalRate) +
            Number(parseInt(currentProductInfo.productRate_Each))
        ); //total rate without tax
        totalRatewithtax = Truncate_2DecimalPlaces(
          Number(totalRatewithtax) + Number(RatewithTaxtemp)
        ); //total rate with tax

        self.state.estimateData[arrayCount] = {
          SNO: count,
          ProductId: currentProductInfo.serviceId,
          ProductType: currentProductInfo.productType,
          Item: item,
          //  "Rate":currentProductInfo.productRate_Each,
          RatewithoutTax: currentProductInfo.productRate_Each, //without tax productRate_Each
          RatewithTax: RatewithTaxtemp,
          OriginalRate: currentProductInfo.productRate_Each,
          cgst: currentProductInfo.product_CGST_Percentage,
          sgst: currentProductInfo.product_SGST_Percentage,
          igst: currentProductInfo.product_IGST_Percentage,
        };
        arrayCount = Number(arrayCount) + 1;
      });
    this.state.totalEstimateAmount = totalRate;
    this.state.totalEstimateAmountwithTax = totalRatewithtax;

    //console.log(self.state.estimateData[arrayCount]);
    this.setState({
      estimateData: this.state.estimateData,
      totalEstimateAmount: this.state.totalEstimateAmount,
      totalEstimateAmountwithTax: this.state.totalEstimateAmountwithTax,
    });
  }

  GenerateEstimateFunc() {
    /* Swal.fire({
      position: "center",
      icon: "warning",
      title: "InProgress",
      showConfirmButton: false,
      timer: 2000,
    });
    */

    console.log(
      "GenerateEstimateFunc -- ESTIMATE DATA :",
      this.state.estimateData
    );
    this.state.jobCard_With_Estimate = true;
    this.setState({
      jobCard_With_Estimate: this.state.jobCard_With_Estimate,
    });
    this.VehicleRegistrationFunc();
  }

  ProductInfoModal() {
    var self = this;
    self.state.productInfoModal = true;
    self.setState({
      productInfoModal: self.state.productInfoModal,
    });
  }

  ProductInfoModalClose() {
    var self = this;
    self.state.productInfoModal = false;
    self.setState({
      productInfoModal: self.state.productInfoModal,
    });
  }

  ProductInfoCloseModal() {
    var self = this;
    self.state.productInfoModal = false;
    self.state.productInfoCloseModal = true;

    self.setState({
      productInfoModal: self.state.productInfoModal,
      productInfoCloseModal: self.state.productInfoCloseModal,
    });
    this.OpenJobCardEstimateSlide();
  }

  OpenJobCardEstimateSlide() {
    this.state.openJobCardEstimate = true;
    this.setState({
      openJobCardEstimate: this.state.openJobCardEstimate,
    });
  }
  CloseJobCardEstimateSlide() {
    this.state.openJobCardEstimate = false;
    this.setState({
      openJobCardEstimate: this.state.openJobCardEstimate,
    });
  }

  //GETTING DATAS FRM SERVICE REGISTRATION ESTIMATE AND DISPLAY IN TABLE
  SetEstimateTableData(
    estimateData,
    totalEstimateAmount,
    totalEstimateAmountwithTax
  ) {
    console.log(" SetEstimateTableData , estimateData :", estimateData);

    //SETTING NEW RATE[EDITED] TO PRODUCT ARRAY
    estimateData.forEach(function (item) {
      var index = totalProductArray
        .map(function (e) {
          return e.serviceName;
        })
        .indexOf(item.Item);

      if (totalProductArray[index].productRate_Each !== item.RatewithoutTax) {
        totalProductArray[index].productRate_Each = item.RatewithoutTax;
      }
    });

    this.state.estimateData = [];
    this.setState({
      estimateData: this.state.estimateData,
    });
    this.state.estimateData = [...estimateData];
    this.state.totalEstimateAmount = totalEstimateAmount;
    this.state.totalEstimateAmountwithTax = totalEstimateAmountwithTax;

    this.setState({
      estimateData: this.state.estimateData,
      totalEstimateAmount: this.state.totalEstimateAmount,
      totalEstimateAmountwithTax: this.state.totalEstimateAmountwithTax,
    });
  }

  //GETTING VALUE FROM COMMON MODAL PAGE COMPONENT BASED ON TAX CHOICE
  TaxchoiceFunc = (value) => {
    var taxchoice = value;
    console.log(taxchoice);
    // console.log("taxStatus");
    console.log(this.state.taxStatus); // hitting
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <Double_BackButtonComponent click={() => this.BackbtnFunc()} />
          <h3>Job Card</h3>
        </div>
        <div className="row">
          <div className="ser_reg_info ser-div">
            {/*   <a href="#" onClick={() => this.OpenSlide()}>
              <span>
                <TiIcons.TiInfo />
              </span>
              Vehicle Make & Model
            </a>
            <a href="#" onClick={() => this.OpenCustomerSlide()}>
              <span className="GrDocumentUser">
                <FaIcons.FaUserCircle />
              </span>
              Customer
            </a>
            <a href="#" onClick={() => this.OpenVehicleSlide()}>
              <span>
                <FaIcons.FaCar />
              </span>
              Vehicle
            </a>
             <a href="#" onClick={() => this.ProductInfoModal()}>
              <span>
                <FaIcons.FaCar />
              </span>
              JobCard Estimate-Edit
            </a> */}
            {/*POPUP WINDOW ASK FOR TAX CHOICE*/}
            <Modal
              isOpen={this.state.productInfoModal}
              //  onAfterOpen={this.customerafterOpenModal}
              onRequestClose={this.state.productInfoCloseModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <EstimateProductTaxChoiceChangeModal
                stateData={this.state}
                TaxchoiceFunc={this.TaxchoiceFunc}
                OpenJobCardEstimateSlide={this.OpenJobCardEstimateSlide}
              />
              <div className="text-center">
                <button
                  onClick={() => this.ProductInfoCloseModal()}
                  className="btn btn-primary btnfirst"
                >
                  Submit
                </button>

                <button
                  onClick={() => this.ProductInfoModalClose()}
                  className="btn btn-primary"
                >
                  cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="control-label selectpicker" for="customerName">
              Contact No<span className="color-red">*</span>
            </label>
            <CreatableSelect
              isClearable
              onChange={this.handleChangeSelectCustomer}
              onCreateOption={this.handleInputChangeCustomer}
              options={this.state.customerList}
              value={this.state.selectedCustomer}
            />
            <span id="contactnoerror" className="mandatory-fields">
              ! ContactNo Invalid
            </span>
          </div>
          <div className="col-md-3">
            <label className="control-label" for="vehicleRegistrationNo">
              Customer Name<span>*</span>
            </label>

            <input
              type="text"
              name="customerName"
              placeholder="Customer Name"
              onChange={this.handleUserInputCustomerName}
              readOnly={this.state.customerNamereadonly}
              value={this.state.customerName}
              className="form-control form-input-width"
            />
          </div>
          <div className="col-md-3 ">
            <label className="control-label" for="email">
              Email ID
            </label>
            <input
              type="email"
              className="form-control"
              name="emailId"
              value={this.state.emailId}
              onChange={this.handleUserInputEmailId}
              readOnly={this.state.emailIdreadonly}
              id="email"
              placeholder="Email ID"
            />
            <p id="emailerrormsg" className="color-red email-font-weight"></p>
          </div>
          <div className="col-md-3">
            <label className="control-label" for="vehicleRegistrationNo">
              Vehicle No<span className="color-red">*</span>
            </label>
            <CreatableSelect
              isClearable
              onChange={this.handleChangeSelectVehicleRegNo}
              onCreateOption={this.handleInputChangeVehicleRegNo}
              options={this.state.vehicleList}
              value={this.state.selectedVehicleRegNo}
            />
            <span
              id="vehicleRegNoErrorMsgAlert"
              className="color-red font-weight-700"
            ></span>
          </div>
        </div>
        <div className="row row-margin-top">
          <div className="col-md-3">
            <label className="control-label font-weight-bold" for="vehicleMake">
              Vehicle Make
            </label>
            <CreatableSelect
              isClearable
              onChange={this.handleChangeSelectVehicleMake}
              onCreateOption={this.handleInputChangeVehicleMake}
              options={this.state.vehcileMakeList}
              value={this.state.selectedVehicleMake}
            />
          </div>
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Vehicle Model
            </label>

            <CreatableSelect
              isClearable
              onChange={this.handleChangeSelectVehicleModel}
              onCreateOption={this.handleInputChangeVehicleModel}
              options={this.state.vehicleModelList}
              value={this.state.selectedVehicleModel}
            />
          </div>

          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Model Year
            </label>

            <div>
              <select
                name="modelYear"
                onChange={this.handleUserInputModelYear}
                className="form-control form-input-width"
              >
                {this.years.map((year, index) => {
                  return (
                    <option key={`year${index}`} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              <span id="modelYear" className="mandatory-fields">
                !Model Year should be valid Year
              </span>
            </div>
          </div>
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Vehicle Color
            </label>

            <input
              type="text"
              name="vehicleColor"
              placeholder="Enter Vehicle Color"
              onChange={this.handleUserInputColor}
              value={this.state.vehicleColor}
              className="form-control form-input-width"
            />
            <span id="vehicleColor" className="mandatory-fields">
              !Vehicle Color Should be in Text of 10 characters
            </span>
          </div>
        </div>

        <div className="row row-margin-top">
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Engine No
            </label>

            <input
              type="text"
              name="engineNumber"
              placeholder="Enter Engine Number"
              onChange={this.handleUserInputEngineNumber}
              value={this.state.engineNumber}
              className="form-control form-input-width"
            />
            <span id="engineNo" className="mandatory-fields">
              !Engine Number should have character and Number
            </span>
          </div>
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Chasis No
            </label>

            <input
              type="text"
              name="chasisNumber"
              placeholder="Enter Chasis Number"
              onChange={this.handleUserInputChasisNo}
              value={this.state.chasisNumber}
              className="form-control form-input-width"
            />
            <span id="chasisNo" className="mandatory-fields">
              !Chassis Number should have character and Number
            </span>
          </div>

          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleFuelType"
            >
              Fuel Type
            </label>

            <CreatableSelect
              isClearable
              onChange={this.handleChangeSelectFuelType}
              onCreateOption={this.handleInputChangeVehicleFuelType}
              options={this.state.vehcileFuelTypeList}
              value={this.state.selectedVehicleFuelType}
            />
          </div>

          <div className="col-md-3 ">
            <label className="control-label font-weight-bold" for="batch">
              Services<span className="mandatory-fields">*</span>
            </label>
            <CreatableSelect
              // isClearable.
              isMulti={true}
              onChange={this.handleChangeSelectService}
              /*  onCreateOption={this.handleInputChangeVehicleFuelType}  */
              options={this.state.productList}
              value={this.state.selectedServiceList}
              isValidNewOption={() => false}
            />
          </div>
        </div>
        <div className="row row-margin-top">
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Odometer Reading
            </label>

            <input
              type="text"
              name="odometerReading"
              placeholder="Enter Odometer Reading"
              onChange={this.handleUserInputOdometerReading}
              value={this.state.odometerReading}
              className="form-control form-input-width"
            />
            <span id="odometerNo" className="mandatory-fields">
              !Odometer should be in number
            </span>
          </div>
          <div className="col-md-3 ">
            <label
              className="control-label font-weight-bold"
              for="vehicleModel"
            >
              Comment
            </label>
            <textarea
              onChange={this.handleUserInputComments}
              value={this.state.comments}
              name="comments"
              placeholder="Your message.."
              className="form-control"
              rows="5"
              id="message"
            ></textarea>
          </div>
          <div className="col-md-3">
            <div className="Irt_labeld">
              <label className="Irt_col_Label1">
                Image Attachment(Less Than 10)
              </label>
            </div>

            <ImageUploading
              multiple
              value={this.state.images}
              onChange={this.onChange}
              // maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <button
                    className="uploadimage"
                    style={isDragging ? { color: "red" } : null}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <GoIcons.GoCloudUpload
                      size={40}
                      className="margin-right-10"
                    />
                  </button>
                  &nbsp;
                  <button className="uploadimage" onClick={onImageRemoveAll}>
                    <GiIcons.GiPoisonCloud
                      size={40}
                      className="margin-right-10"
                    />
                  </button>
                </div>
              )}
            </ImageUploading>
          </div>
        </div>

        <ImageUploading
          multiple
          value={this.state.images}
          onChange={this.onChange}
          // maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className="row imgshow">
              {imageList.map((image, index) => (
                <div key={index} className="image-item column">
                  <img src={image.data_url} width="100" height="100" />
                  <div className="image-item__btn-wrapper">
                    <button
                      onClick={() => onImageUpdate(index)}
                      className="btn-margin-right-5"
                    >
                      <FcIcons.FcEditImage className="color-red" />
                    </button>
                    <button onClick={() => onImageRemove(index)}>
                      <RiIcons.RiDeleteBin2Fill className="color-red" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
        <div className="row row-bot row-margin-left-2">
          <div className="col-md-12 rowbtn">
            <button
              type="button"
              onClick={() => this.VehicleRegistrationFunc()}
              className="btn btn-default"
            >
              Submit
            </button>
            <span></span>
            <button
              type="button"
              onClick={() => this.ClearFunc()}
              className="btn btn-default"
            >
              Clear
            </button>
            <span></span>
            {/*  <button
              type="button"
              onClick={() => this.GenerateEstimateFunc()}
              className="btn btn-default"
            >
              Jobcard with GenerateEstimate
            </button> */}
          </div>
        </div>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.isPaneOpen}
          title={"Vehicle MakeModel Info"}
          subtitle="Can Add & Edit Vehicle MakeModel Info Here"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            // setState({ isPaneOpen: false });
            this.CloseSlide();
          }}
        >
          {/* {this.RenderUpdateComponenets(this.state.taskSelected)} */}
          <VehicleMakeModelComponent
            SubmitClicked={this.SubmitVehicleMakeModelInfoSlide}
            CancelClicked={this.CloseCancelMakeModelInfoSlide}
          />
        </SlidingPane>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.isCustomerPaneOpen}
          title={"Customer Info"}
          subtitle="Can Add Customer Info Here"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            // setState({ isPaneOpen: false });
            this.CloseCustomerSlide();
          }}
        >
          {/* {this.RenderUpdateComponenets(this.state.taskSelected)} */}
          <CustomerComponent
            SubmitClicked={this.SubmitCustomerInfoSlide}
            CancelClicked={this.CloseCancelCustomerInfoSlide}
          />
        </SlidingPane>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.isVehiclePaneOpen}
          title={"Vehicle Info"}
          subtitle="Can Add Vehicle Info with Customer Here"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            // setState({ isPaneOpen: false });
            this.CloseVehicleSlide();
          }}
        >
          {/* {this.RenderUpdateComponenets(this.state.taskSelected)} */}
          <VehicleComponent
            stateData={this.state.stateData}
            SubmitClicked={this.SubmitVehicleInfoSlide}
            CancelClicked={this.CloseCancelVehicleSlide}
            pageCalledFrom="AddJobCard"
          />
        </SlidingPane>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.openJobCardEstimate}
          title={"Jobcard Estimate Info"}
          subtitle="Can Edit jobcard Estimate Info"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            // setState({ isPaneOpen: false });
            this.CloseJobCardEstimateSlide();
          }}
        >
          {/* {this.RenderUpdateComponenets(this.state.taskSelected)} */}
          <ServiceRegistartionEstimate
            stateData={this.state}
            SetEstimateTableData={this.SetEstimateTableData}
            CancelClicked={this.CloseJobCardEstimateSlide}
          />
        </SlidingPane>
      </div>
    );
  }
}

export default ServiceRegistration;

// Add this after export default  for converting base 64 to file format
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

//VALIDATIONS
export const EngineValidationFunc = function (EngineNo) {
  var Valid;
  if (EngineNo != "") {
    //ENGINE NO IN CHAR AND NUMBER
    var RegEx = /^[a-z0-9]+$/i;
    Valid = RegEx.test(EngineNo);
    //console.log("EngineNo",Valid)
  } else {
    Valid = true;
  }
  return Valid;
};
export const ChasisValidationFunc = function (chassisNo) {
  var Valid;
  if (chassisNo != "") {
    //CHASSIS IN NUMBER ABD CHARECTER
    var RegEx = /^[a-z0-9]+$/i;
    Valid = RegEx.test(chassisNo);
  } else {
    Valid = true;
  }
  //console.log("chassisNo",Valid)
  return Valid;
};

export const ColorValidationFunc = function (color) {
  var Valid;
  if (color != "") {
    //COLOR ACCEPTS SPACE AND CHATECTER OF SIZE 15
    var RegEx = /^[a-z ]{0,15}$/i;
    Valid = RegEx.test(color);
  } else {
    Valid = true;
  }
  return Valid;
};
export const YearValidationFunc = function (year) {
  //YEAR IN NUMBER
  var RegEx = /^[0-9]{0,3}$/i;
  var Valid;
  if (year != "") {
    Valid = RegEx.test(year);
  } else {
    Valid = true;
  }
  //console.log("Year Test",Valid)
  return Valid;
};
export const OdometerValidationFunc = function (odometer) {
  var validationData = false;
  var Valid;
  if (odometer != "") {
    //ALLOWS NUMBER AND . OPERATOR
    var val = odometer;
    var RegEx = /^[0-9\.]+$/i;
    Valid = RegEx.test(odometer);
    //console.log("Odometer Validation Test",Valid)
  } else {
    Valid = true;
  }

  return Valid;
};
//comments nees to add
export const CommetsValidationFunc = function (comments) {
  //UPPERCASE EACH WORD
  return comments.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

/*
  USED TO HIDE THE ERROR MESSAGES OF THE FIELDS DISPLAYED
  */
function HideFieldErroeMsgs(fieldId) {
  setTimeout(function () {
    $("#" + fieldId).empty();
  }, 4000);
}
