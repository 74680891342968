import React, { lazy, Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'bootstrap';
import _ from 'underscore';
import { join } from 'lodash';
import SelectSearch from 'react-select';
import "react-table/react-table.css";
import ReactTable from "react-table";
import { BackButtonComponent } from '../../ServiceRegistration/ButtonComponent';
import LoadingComponent from '../../Loading/LoadingComponent';
import ReactApexChart from 'react-apexcharts';

// Import Css
import '../../MaincontentDashboard/Card.scss';
import '../../MaincontentDashboard/card.css';
import './JobcardDashboardCss.css';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, } from "reactstrap";
import { GetEmployeeSite, SetCurrentPage, GetCurrentSite } from '../../ConstSiteFunction';
import { SiteCurrencySymbol } from '../../Invoice/CurrencyFormater';

import fileregister from '../../MaincontentDashboard/image/file_icon.svg';
import inprogess from '../../MaincontentDashboard/image/pending_icon.svg';
import complete from '../../MaincontentDashboard/image/filecomplete.svg';
import deleted from '../../MaincontentDashboard/image/delete_icon.svg';
import closed from '../../MaincontentDashboard/image/close_icon.svg';
import finished from '../../MaincontentDashboard/image/finish_icon.svg';

const DashboardOverall = lazy(() => import('../../MaincontentDashboard/DashboardOverall'));

const jobcard = lazy(() => import('../../VehicleRegistrationList'));


class JobcardDashboard extends Component {

  constructor() {
    super()
    this.state = {
      // options: [],
      data: [],
      series: [
        {
          name: 'Desktops',
          data: [
            {
              x: 'Maruti Suzuki',
              y: 10
            },
            {
              x: 'Mahindra',
              y: 60
            },
            {
              x: 'Hyundai',
              y: 41
            }
          ]
        },
        {
          name: 'Mobile',
          data: [
            {
              x: 'Mahindra Marazzo',
              y: 10
            },
            {
              x: 'Mahindra Bolero',
              y: 20
            },
            {
              x: 'Suzuki Dzire',
              y: 51
            },
            {
              x: 'Suzuki Swift',
              y: 30
            },
            {
              x: 'Hyundai Aura',
              y: 20
            },
            {
              x: 'Hyundai Santro',
              y: 30
            }
          ]
        }
      ],
      options: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap'
        },
        title: {
          text: 'Most Serviced Make and Model',
          align: 'center'
        }
      },
      radialseries: [76, 67, 61, 90],
      radialoptions: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
        labels: ['Water Wash', 'Fuel Oil Cap Tight', 'Door Handle Work', 'Interior Cleaning'],
        legend: {
          show: true,
          floating: true,
          fontSize: '16px',
          position: 'left',
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0
          },
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
          },
          itemMargin: {
            vertical: 3
          }
        },
        title: {
          text: 'Most Used Services',
          align: 'center'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
                show: false
            }
          }
        }]
      },


      recentJobCardData: [],
      recentJobCardColumns: [{
        Header: "Booking Id",
        accessor:"Booking Id"
      },
      {
        Header: "Customer Name",
        accessor:"Customer Name"
      },
      {
        Header: "Vehicle No",
        accessor:"Vehicle No"
      },
      {
        Header: "Service Count",
        accessor:"Service Count"
      },
      {
        Header: "Date",
        accessor:"Date"
      },
      ],
      quickJobCardData: [],
      quickJobCardColumns: [{
        Header: "Booking Id",
        accessor:"Booking Id"
      },
      {
        Header: "Customer Name",
        accessor:"Customer Name"
      },
      {
        Header: "Vehicle No",
        accessor:"Vehicle No"
      },
      {
        Header: "Service Count",
        accessor:"Service Count"
      },
      {
        Header: "Date",
        accessor:"Date"
      },
      ],
      pendingJobCarddata: [],
      pendingJobCardColumns: [{
        Header: "Booking Id",
        accessor:"Booking Id"
      },
      {
        Header: "Customer Name",
        accessor:"Customer Name"
      },
      {
        Header: "Vehicle No",
        accessor:"Vehicle No"
      },
      {
        Header: "Service Count",
        accessor:"Service Count"
      },
      {
        Header: "Date",
        accessor:"Date"
      },
      ],
      highJobCardData: [],
      highJobCardColumns: [{
        Header: "Booking Id",
        accessor:"Booking Id"
      },
      {
        Header: "Customer Name",
        accessor:"Customer Name"
      },
      {
        Header: "Vehicle No",
        accessor:"Vehicle No"
      },
      {
        Header: "Service Count",
        accessor:"Service Count"
      },
      {
        Header: "Date",
        accessor:"Date"
      },
      ],
      employeeData: [],
      employeeColumns: [{
        Header: "Employee Id",
        accessor:"Employee Id"
      },
      {
        Header: "Employee Name",
        accessor:"Employee Name"
      },
      {
        Header: "Role",
        accessor:"Role"
      },
      ],
      customerColumns: [{
        Header: "Customer Id",
      },
      {
        Header: "Customer Name",
      },
      {
        Header: "Visited Count",
      },
      ],
      saleColumns: [{
        Header: "Booking Id",
      },
      {
        Header: "Invoice No",
      },
      {
        Header: "Customer Name",
      },
      {
        Header: "Vehicle No",
      },
      {
        Header: "Invoice Amount",
      },
      {
        Header: "Date",
      },
      ],


    }
  }

  componentDidMount() {

    var self=this;

    var empSites = CryptoJS.AES.decrypt(localStorage.getItem('EmpSites'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
    var currentSite = CryptoJS.AES.decrypt(localStorage.getItem('CurrentSite'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
    var emparray = empSites.split(",");
    this.state.siteOptions = _.map(emparray, function (site) { return { label: site, value: site }; });
    this.state.siteOptions.push({ label: "All", value: "All" });
    this.setState({
      siteOptions: this.state.siteOptions
    })

    //SETTING UP SAMPLE DATA

    //--SETTING UP RECENT JOBCARD DATA 
    var recentJobCardDataList = [
      {
        bookingId: 'BID-105',
        customerName: 'Bobby',
        vehicleNo: 'TN459 569',
        serviceCount: '5',
        date: '2022-03-19'

      },
      {
        bookingId: 'BID-104',
        customerName: 'Gaze',
        vehicleNo: 'TN526 478',
        serviceCount: '10',
        date: '2022-03-19',
      }
    ];
    var quickJobCardDataList = [
      {
        bookingId: 'BID-201',
        customerName: 'Renuka',
        vehicleNo: 'TN65 9884',
        serviceCount: '5',
        date: '2022-03-18'

      },
      {
        bookingId: 'BID-202',
        customerName: 'Thanshika',
        vehicleNo: 'TN65 9988',
        serviceCount: '10',
        date: '2022-03-19',
      },
      {
        bookingId: 'BID-206',
        customerName: 'Veni',
        vehicleNo: 'TN65 7888',
        serviceCount: '5',
        date: '2022-03-19',
      }
    ];
    var pendingJobCarddataList = [
      {
        bookingId: 'BID-120',
        customerName: 'Nandhini',
        vehicleNo: 'TN65 0984',
        serviceCount: '5',
        date: '2022-03-18'

      },
      {
        bookingId: 'BID-123',
        customerName: 'Janani',
        vehicleNo: 'TN65 8878',
        serviceCount: '9',
        date: '2022-03-19',
      },
      {
        bookingId: 'BID-125',
        customerName: 'Hari',
        vehicleNo: 'TN65 5644',
        serviceCount: '7',
        date: '2022-03-19',
      }
    ];
    var highJobCardDataList = [
      {
        bookingId: 'BID-111',
        customerName: 'Nancy',
        vehicleNo: 'TN65 8874',
        serviceCount: '5',
        date: '2022-03-18'

      },
      {
        bookingId: 'BID-334',
        customerName: 'Thanshika',
        vehicleNo: 'TN65 9988',
        serviceCount: '10',
        date: '2022-03-19',
      },
    
    ];
    var employeeDataList = [
      {
        employeeId: '101',
        employeeName: 'Roshini',
        Role: 'It',
        

      },
      {
        employeeId: '102',
        employeeName: 'Veni',
        Role: 'Developer',
        

      },
      {
        employeeId: '103',
        employeeName: 'Durga',
        Role: 'IT',
        

      },
      {
        employeeId: '104',
        employeeName: 'Pradhap',
        Role: 'Admin',
        

      },
      {
        employeeId: '105',
        employeeName: 'Usha',
        Role: 'Developer',
        

      },
    ];


    //RENDERING THE SAMPLE TABLE TO TABLE

    //--RENDERING SAMPLE DATA FOR RECENT JOBCARD TABLE
    $.each(recentJobCardDataList, function (i, item) {

      self.state.recentJobCardData[i] = {
        "Booking Id": item.bookingId,
        "Customer Name": item.customerName,
        "Vehicle No": item.vehicleNo,
        "Service Count": item.serviceCount,
        "Date": item.date
      }
    })
    $.each(quickJobCardDataList, function (i, item) {

      self.state.quickJobCardData[i] = {
        "Booking Id": item.bookingId,
        "Customer Name": item.customerName,
        "Vehicle No": item.vehicleNo,
        "Service Count": item.serviceCount,
        "Date": item.date
      }
    })
    $.each(pendingJobCarddataList, function (i, item) {

      self.state.pendingJobCarddata[i] = {
        "Booking Id": item.bookingId,
        "Customer Name": item.customerName,
        "Vehicle No": item.vehicleNo,
        "Service Count": item.serviceCount,
        "Date": item.date
      }
    })
    $.each(highJobCardDataList, function (i, item) {

      self.state.highJobCardData[i] = {
        "Booking Id": item.bookingId,
        "Customer Name": item.customerName,
        "Vehicle No": item.vehicleNo,
        "Service Count": item.serviceCount,
        "Date": item.date
      }
    })
    $.each(employeeDataList, function (i, item) {

      self.state.employeeData[i] = {
        "Employee Id": item.employeeId,
        "Employee Name": item.employeeName,
        "Role": item.Role,
        
      }
    })




    self.setState({
      recentJobCardData:[...self.state.recentJobCardData],
      quickJobCardData:[...self.state.quickJobCardData],
      pendingJobCarddata:[...self.state.pendingJobCarddata],
      highJobCardData:[...self.state.highJobCardData],
      employeeData:[...self.state.employeeData],
    })

    console.log("recentJobCardData :",self.state.recentJobCardData);




  }

  handleCurrentSite = (e) => {
    const value = e.value;
    this.state.currentSite = value;
    this.state.empSites = value;
    if (value === "All") {
      var empSites = CryptoJS.AES.decrypt(localStorage.getItem('EmpSites'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
      this.state.empSites = empSites;
    }
    this.setState({
      selectedSite: e,
      currentSite: value,
      empSites: this.state.empSites
    });
    this.DashboardDisplayFunc();
  }

  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />} >
          <div>
            <Route path="/" component={DashboardOverall} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById('contentRender'));
  }

  JobCard() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />} >
          <div>
            <Route path="/" component={jobcard} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById('contentRender'));
  }

  render() {

    return (
      <div class="container">
        <div className="">
          <div className="">
            <BackButtonComponent name={"Dashboard"} click={() => this.BackbtnFunc()} />
          </div>
          <div className="inv_HeaderCls">
            <h3>Jobcard Dashboard</h3>
          </div>
        </div>

        <div class="dash_site_sel" styles={{ color: "#263eac",width:'300px',marginLeft:'-100px' }}>
          <p class="link_text" click={() => this.jobcard()}>Job card</p>
          <SelectSearch options={this.state.siteOptions} value={this.state.selectedSite}
            onChange={(e) => this.handleCurrentSite(e)} name="WorkingSite" placeholder="Select Working Site " styles={{ color: "#263eac" }} />
        </div>

        <div class="container" style={{ marginTop: '100px' }}>
        <Row style={{marginBottom:'20px'}}>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{ }}>
                        <img src={fileregister} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">Registed</h4>
                        <h4>45</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>

              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{  }}>
                        <img src={inprogess} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">InProgress</h4>
                        <h4>20</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{  }}>
                        <img src={complete} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">Completed</h4>
                        <h4>10</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
          </Row>

          <Row>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{  }}>
                        <img src={closed} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">Closed</h4>
                        <h4>5</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{ }}>
                        <img src={finished} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">Finished</h4>
                        <h4>5</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div class="col-md-4">
                      <div class="jobcard_icon" style={{ }}>
                        <img src={deleted} style={{ padding: '10px' }} />
                      </div>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                      <div >
                        <h4 class="tiles_text">Deleted</h4>
                        <h4>5</h4>
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
          </Row>
        </div>

        <div class="">
          <Card className="card-stats">
            <CardBody>
              <ReactApexChart options={this.state.options} series={this.state.series} type="treemap" height={350} />
            </CardBody>
          </Card>
        </div>
        <div class="">
          <Card className="card-stats">
            <CardBody>
            <div id="chart">
                <ReactApexChart options={this.state.radialoptions} series={this.state.radialseries} type="radialBar" height={390} />
              </div>
            </CardBody>
          </Card>
        </div>

     
        <div class="">
          <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Recent Jobcard</h4>
              </div>



              <div style={{ borderRadius: '10px' }}>
                <ReactTable style={{ marginBottom: "5%" }}
                  id="reportTable"
                  data={this.state.recentJobCardData}
                  columns={this.state.recentJobCardColumns}
                  noDataText="No Data Available"
                //  filterable
                  defaultPageSize={10}
                  className="-striped -highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  // getTdProps={this.onColumnClick}
                  getTdProps={this.onTrRowClickNew}
                  getTrProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                        color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Quick Jobcard</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.quickJobCardData}
                columns={this.state.quickJobCardColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                      .toLowerCase()
                      .indexOf(filter.value.toLowerCase()) !== -1
                    : true;
                }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
          </Row>
        </div>

        <div class="">
          <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Long Pending Jobcards</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.pendingJobCarddata}
                columns={this.state.pendingJobCardColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                      .toLowerCase()
                      .indexOf(filter.value.toLowerCase()) !== -1
                    : true;
                }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Jobcard with High Services</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.highJobCardData}
                columns={this.state.highJobCardColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                      .toLowerCase()
                      .indexOf(filter.value.toLowerCase()) !== -1
                    : true;
                }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
          </Row>
        </div>

        <div class="">
          <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Top 5 Employee</h4>
              </div>

              <div style={{ borderRadius: '10px' }}>
                <ReactTable style={{ marginBottom: "5%" }}
                  id="reportTable"
                  data={this.state.employeeData}
                  columns={this.state.employeeColumns}
                  noDataText="No Data Available"
                  // filterable
                  defaultPageSize={10}
                  className="-striped -highlight"
                  defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                      ? String(row[id])
                        .toLowerCase()
                        .indexOf(filter.value.toLowerCase()) !== -1
                      : true;
                  }}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  // getTdProps={this.onColumnClick}
                  getTdProps={this.onTrRowClickNew}
                  getTrProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                        color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Most Visited Customers</h4>
              </div>
              <ReactTable style={{ marginBottom: "5%" }}
                id="reportTable"
                data={this.state.customerdata}
                columns={this.state.customerColumns}
                noDataText="No Data Available"
                // filterable
                defaultPageSize={10}
                className="-striped -highlight"
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                      .toLowerCase()
                      .indexOf(filter.value.toLowerCase()) !== -1
                    : true;
                }}
                showPaginationTop={false}
                showPaginationBottom={false}
                // getTdProps={this.onColumnClick}
                getTdProps={this.onTrRowClickNew}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                      color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                    }
                  }
                }}
              />
            </div>
          </Row>
        </div>

        <div class="col-md-12">
          <div>
            <h4 class="tiles_text">Recent Billed Jobcards (SaleInvoice)</h4>
          </div>
          <ReactTable style={{ marginBottom: "5%" }}
            id="reportTable"
            data={this.state.data}
            columns={this.state.saleColumns}
            noDataText="No Data Available"
            // filterable
            defaultPageSize={10}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
            showPaginationTop={false}
            showPaginationBottom={false}
            // getTdProps={this.onColumnClick}
            getTdProps={this.onTrRowClickNew}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                  color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                }
              }
            }}
          />
        </div>

        <div class="col-md-12">
          <div>
            <h4 class="tiles_text">Recent Billed Jobcards (EstimateInvoice)</h4>
          </div>
          <ReactTable style={{ marginBottom: "5%" }}
            id="reportTable"
            data={this.state.data}
            columns={this.state.saleColumns}
            noDataText="No Data Available"
            // filterable
            defaultPageSize={10}
            className="-striped -highlight"
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id])
                  .toLowerCase()
                  .indexOf(filter.value.toLowerCase()) !== -1
                : true;
            }}
            showPaginationTop={false}
            showPaginationBottom={false}
            // getTdProps={this.onColumnClick}
            getTdProps={this.onTrRowClickNew}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  backgroundColor: rowInfo ? rowInfo.original.rowopted === "yes" ? '#263eac' : '' : '',
                  color: rowInfo ? rowInfo.original.rowopted === "yes" ? 'white' : '' : '',
                }
              }
            }}
          />
        </div>


      </div>
    );
  }
}
export default JobcardDashboard;