//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
//IMPORT REACT ICONS
import ReactDOM from "react-dom";
import registerServiceWorker from "../../../../registerServiceWorker";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import $ from "jquery";
import CryptoJS from "crypto-js";
import ReactTable from "react-table";
import _ from "underscore";

import Swal from "sweetalert2/dist/sweetalert2.js";
//IMPORT REACT COMPONENT CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-table/react-table.css";
import "sweetalert2/src/sweetalert2.scss";
//IMPORT CLASS COMPONENT CSS
import "../../../../ReactTableCSS.css";
//IMPORT CLASS COMPONENT

import DashboardOverall from "../../../../MaincontentDashboard/DashboardOverall";
import { BackButtonComponent } from "../../../../ServiceRegistration/ButtonComponent";
import {
  AlertMessageInfoComponent,
  NoDataComponent,
} from "../../../../Assets_Components/MessagePage_Components/MessagePage_Components";
import { PaginationComponent } from "../../../../Assets_Components/Pagination_Components/Pagination_Components";
import {
  GetCurrentSite,
  GetEmployeeSite,
  OffsetValueCalcFunc,
  SetCurrentPage,
} from "../../../../ConstSiteFunction";
import LoadingComponent from "../../../../Loading/LoadingComponent";
// import PackageIcons  from "../../../components/ServiceRegistration/IconComponents.js";
import { PackageIcons } from "../../../../ServiceRegistration/IconComponents";
import { FilterOptions, SiteDropDown_JobCard } from "../../../../SiteDropDown";
import AddPackage from "./AddPackage";
import { ReactTableWithOnClick } from "../../../../Assets_Components/Table_Components/Table_Components";
import EditPackage from "./EditPackage";
import { SelectField } from "material-ui";
import ViewPackage from "./ViewPackage";

var dataList = [];
class PackageList extends Component {
  constructor() {
    super();

    this.state = {
      companyId: CryptoJS.AES.decrypt(
        localStorage.getItem("CompanyId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffId: CryptoJS.AES.decrypt(
        localStorage.getItem("staffId"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffName: CryptoJS.AES.decrypt(
        localStorage.getItem("EmployeeName"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),
      staffRole: CryptoJS.AES.decrypt(
        localStorage.getItem("Role"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8),

      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 5,
      startCount: 0,
      endCount: 5,
      pageRange: 5,
      pageSize: 10,

      fromDate: "",
      toDate: "",

      site: GetCurrentSite(),
      packageId: "",

      data: [],
      columns: [
        {
          Header: "SNO",
          accessor: "SNO",
        },
        {
          Header: "PackageId",
          accessor: "PackageId",
          show: false,
        },
        {
          Header: "Name",
          accessor: "Name",
        },
        {
          Header: "Site",
          accessor: "Site",
        },
      ],
    };

    this.Submit = this.Submit.bind(this);
    this.PopuateTableData = this.PopuateTableData.bind(this);
    this.GetInitialData = this.GetInitialData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.AddPackage = this.AddPackage.bind(this);
    this.AddItemsToPackage = this.AddItemsToPackage.bind(this);
    this.RemoveItemsFromPackage = this.RemoveItemsFromPackage.bind(this);
    this.EditPackageName = this.EditPackageName.bind(this);
    this.ViewPackage = this.ViewPackage.bind(this);
    this.DeletePackage = this.DeletePackage.bind(this);
    this.handleSite = this.handleSite.bind(this);
  }

  componentDidMount() {
    SetCurrentPage("PackageList");

    var empSites = GetEmployeeSite();
    var emparray = empSites.split(",");
    //   console.log("emparray :", emparray);

    //SETTING UP DATE BASED ON EMPLOYEE WORKING SITE TIMEZONE
    var dateTimeData = OffsetValueCalcFunc();

    //console.log("dateTimeData :",dateTimeData);
    this.state.date = dateTimeData.date;
    this.state.time = dateTimeData.time;
    this.state.fromDate = dateTimeData.date;
    this.state.toDate = dateTimeData.date;

    this.setState({
      date: this.state.date,
      time: this.state.time,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    this.GetInitialData();
  }

  /*
    FUNCTION USED FOR CALLING THE PARENT PAGE WHEN BACK BUTTON IS CLICKED - 
    IMPLEMENTED BY PRIYANKA - 03-06-2022
    */
  BackbtnFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={DashboardOverall} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  /*
       FUNCTION USED FOR GETTING THE INITIAL DATA REQUIRED FOR POPULATING THE FIELDS - 
       IMPLEMENTED BY PRIYANKA - 04-06-2022
       */
  GetInitialData() {
    var self = this;

    console.log(
      "SUBMIT DATA :",
      JSON.stringify({
        companyId: this.state.companyId,
        empSites: GetEmployeeSite(),
        startCount: this.state.startCount,
        endCount: this.state.endCount,
      })
    );

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        empSites: GetEmployeeSite(),
        startCount: this.state.startCount,
        endCount: this.state.endCount,
      }),
      contentType: "application/json",
      dataType: "json",
      // url: "http://localhost:8080/GarageAppAPI_Cust/master/ProductMigrationDisplayData",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/ProductServicePackageList",

      async: false,
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        console.log("PRODUCT MIGRATION LIST DATA :", data);

        dataList = data.packageList;
        self.handleSite(self.state.site);
      },
      error: function (data) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  handlePageChange(pageNumber) {
    this.state.activePage = pageNumber;

    var startCount = 0;
    var endCount = this.state.endCount;

    if (pageNumber > 1) {
      startCount =
        Number(Number(pageNumber) - Number(1)) *
        Number(this.state.itemsCountPerPage);
      endCount = Number(startCount) + Number(this.state.itemsCountPerPage);
    } else {
      startCount = 0;
      endCount = this.state.itemsCountPerPage;
    }

    this.state.startCount = startCount;
    this.state.endCount = endCount;

    this.setState({
      activePage: pageNumber,
      startCount: this.state.start,
      endCount: this.state.endCount,
    });

    this.GetInitialData();
  }

  Submit(submitData) {
    console.log("submitData :", submitData);

    this.state.fromDate = submitData.fromDate;
    this.state.toDate = submitData.toDate;
    this.setState({
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });

    this.GetInitialData();
  }

  PopuateTableData(data) {
    var self = this;

    self.state.data = [];
    self.state.totalItemsCount = 0;

    self.setState({
      data: self.state.data,
      totalItemsCount: self.state.totalItemsCount,
    });
    console.log("PASSING self.state.data :", data);

    if (data.length > 0) {
      self.state.totalItemsCount = data.dataCount;

      var count = 0;
      count =
        Number(self.state.endCount) - Number(self.state.itemsCountPerPage);
      $.each(data, function (i, item) {
        count = Number(count) + Number(1);
        self.state.data[i] = {
          SNO: count,
          PackageId: item.packageId,
          Name: item.packageName,
          Site: item.site,
        };
      });

      var stateTableData = [...self.state.data];

      self.state.data = [];
      self.state.data = stateTableData;
      self.setState({
        data: self.state.data,
      });
    }

    console.log("PASSING self.state.data :", self.state.data);

    self.setState({
      //    data: self.state.data,
      totalItemsCount: self.state.totalItemsCount,
    });
  }

  AddPackage() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={AddPackage} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  AddItemsToPackage() {
    if (this.state.packageId != "") {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route
                path="/"
                component={() => (
                  <EditPackage
                    module={"AddItems_To_Package"}
                    packageId={this.state.packageId}
                    packageName={this.state.packageName}
                  />
                )}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
      registerServiceWorker();
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly select a package to add items",
        false,
        "3000"
      );
    }
  }

  RemoveItemsFromPackage() {
    if (this.state.packageId != "") {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route
                path="/"
                component={() => (
                  <EditPackage
                    module={"RemoveItems_From_Package"}
                    packageId={this.state.packageId}
                    packageName={this.state.packageName}
                  />
                )}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
      registerServiceWorker();
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly select a package to remove items",
        false,
        "3000"
      );
    }
  }

  ViewPackage() {
    if (this.state.packageId != "") {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route
                path="/"
                component={() => (
                  <ViewPackage
                    packageId={this.state.packageId}
                    packageName={this.state.packageName}
                  />
                )}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
      registerServiceWorker();
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly select a package to view",
        false,
        "3000"
      );
    }
  }

  EditPackageName() {
    if (this.state.packageId != "") {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route
                path="/"
                component={() => (
                  <EditPackage
                    module={"Edit_Package_Name"}
                    packageId={this.state.packageId}
                    packageName={this.state.packageName}
                  />
                )}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
      registerServiceWorker();
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly select a package to edit",
        false,
        "3000"
      );
    }
  }

  DeletePackage() {
    var self = this;

    if (this.state.packageId != "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Do you Want to Delete " + self.state.packageName,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        //   timer: 1500
      }).then((result) => {
        if (result.value) {
          self.DeleteFunc(this.state.rowIndexValue);

          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "Cancelled Deletion Of " + self.state.packageName,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    } else {
      AlertMessageInfoComponent(
        "center",
        "warning",
        "Kindly select a package to delete",
        false,
        "3000"
      );
    }
  }

  handleSite = (e) => {
    this.handleSite = this.handleSite.bind(this);
    this.state.site = e.toString();
    //console.log("e ", this.state.site);
    this.setState({
      site: e.toString(),
    });
    //console.log("e ", dataList, e.toString());
    var result = FilterOptions(dataList, this.state.site);
    console.log("PASSING result :", result);

    this.PopuateTableData(result);
  };

  onTrRowClick = (state, rowInfo, column, instance) => {
    var self = this;

    if (typeof rowInfo !== "undefined") {
      return {
        onClick: (e, handleOriginal) => {
          //  var column=column;

          this.setState({
            selected: rowInfo.index,
          });
          if (handleOriginal) {
            handleOriginal();
          }

          console.log("ROW INFO :", rowInfo);

          this.state.rowIndexValue = rowInfo.index;
          this.state.packageId = rowInfo.original.PackageId;
          this.state.packageName = rowInfo.original.Name;

          this.setState({
            rowIndexValue: this.state.rowIndexValue,
            packageId: this.state.packageId,
            packageName: this.state.packageName,
          });
        },
        style: {
          background: rowInfo.index === this.state.selected ? "lightgrey " : "",
          color: rowInfo.index === this.state.selected ? "black" : "",
        },
      };
    } else {
      return {
        onClick: (e, handleOriginal) => {
          if (handleOriginal) {
            handleOriginal();
          }
        },
      };
    }
  };

  DeleteFunc(rowIndexValue) {
    var self = this;

    self.state.startCount = 0;
    self.state.endCount = 5;
    self.setState({
      startCount: self.state.startCount,
      endCount: self.state.endCount,
    });

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: self.state.companyId,
        packageId: self.state.packageId,
        empSites: GetEmployeeSite(),
        startCount: self.state.startCount,
        endCount: self.state.endCount,
      }),
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/ProductServicePackage/DeleteProductServicePackage",
      contentType: "application/json",
      dataType: "json",
      async: false,

      success: function (data, textStatus, jqXHR) {
        console.log("DEETED DATA :", data);

        dataList = [];
        dataList = data.packageList;
        self.handleSite(self.state.site);

        if (data.response == "Success") {
          AlertMessageInfoComponent(
            "center",
            "success",
            "Succesfully deleted the  package " + self.state.packageName,
            false,
            "3000"
          );
        } else if (data.response == "Failed") {
          AlertMessageInfoComponent(
            "center",
            "warning",
            "Failed to deleted the  package " +
              self.state.packageName +
              ", try after sometime",
            false,
            "3000"
          );
        }

        self.state.packageId = "";
        self.state.selected = -1;
        self.setState({
          packageId: self.state.packageId,
          selected: self.state.selected,
        });
      },
      error: function (data) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="two_HeaderCls">
          <BackButtonComponent
            name={"Dashboard"}
            click={() => this.BackbtnFunc()}
          />
          <h3>Package List</h3>
        </div>

        <div className="inv_list_cls_sel_search">
          <label className="control-label">Location</label>
          <SiteDropDown_JobCard
            onSiteDropDown={this.handleSite}
            data={this.state.site}
          />
        </div>

        <div className="inv_list_cls">
          <div id="paginationdiv">
            <PaginationComponent
              activePage={this.state.activePage}
              itemsPerPage={this.state.itemsCountPerPage}
              totlaItemCount={this.state.totalItemsCount}
              pageRange={this.state.pageRange}
              onPageChange={this.handlePageChange.bind(this)}
            />
          </div>

          <PackageIcons
            onAddPackage={this.AddPackage}
            onAddItemsToPackage={this.AddItemsToPackage}
            onRemoveItemsFromPackage={this.RemoveItemsFromPackage}
            onViewPackage={this.ViewPackage}
            onEditPackageName={this.EditPackageName}
            onDeletePackage={this.DeletePackage}
            onDownloadPackage={this.DownLoadPackage}
          />
        </div>

        <div>
          <div id="tableOverflow" className="hideContent">
            <ReactTableWithOnClick
              data={this.state.data}
              columns={this.state.columns}
              pageSize={this.state.pageSize}
              onTrRowClick={this.onTrRowClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PackageList;
