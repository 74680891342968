//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import ReactDOM from "react-dom";
import $ from "jquery";
import "bootstrap";
import ReactApexChart from "react-apexcharts";
import AvailableStockReport from "../AvailableStockReport.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import registerServiceWorker from "../registerServiceWorker";
import SelectSearch from "react-select";
import _ from "underscore";
//IMPORT REACT COMPONENT CSS
import "react-confirm-alert/src/react-confirm-alert.css";
import "sweetalert2/src/sweetalert2.scss";
import "react-table/react-table.css";
//IMPORT CLASS COMPONENT CSS
import "./DashboardOverallCss.css";
import "./Card.scss";
import "./card.css";
import coins from "./image/coins.png";
import purchase from "./image/purchase.png";
import increase from "./image/profit.png";
import moneyloss from "./image/moneyloss.png";
//IMPORT CLASS COMPONENT
import LoadingComponent from "../Loading/LoadingComponent";
import {
  GetEmployeeSite,
  SetCurrentPage,
  GetCurrentSite,
} from "../ConstSiteFunction";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import ServiceRegistration from "../ServiceRegistration/ServiceRegistration";
import { SiteCurrencySymbol } from "../Invoice/CurrencyFormater";
import { Truncate_2DecimalPlaces } from "../Invoice/InvoiceValidations.js";
import JobcardDashboard from "../Module Based DashBoard/JobCard DashBoard/JobcardDashboard.js";
import InventoryDashboard from "../Module Based DashBoard/inventory dashboard/InventoryDashboard.js";
import AppoinmentDashboard from "../Module Based DashBoard/Appointment DashBoard/AppoinmentDashboard.js";

const InvoiceMenuPage = lazy(() => import("../Invoice/InvoiceMenuPage"));
const AddProduct = lazy(() => import("../AddProduct"));
const VendorEntryForm = lazy(() => import("../VendorEntryForm"));
const CustomerEntryForm = lazy(() => import("../CustomerEntryForm"));
const InvoiceList = lazy(() => import("../InvoiceList"));

var dashboardData;

var i = 1;
const data1 = [
  ["Task", "Hours per Day"],
  ["Work" + " (11)", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
  ["Work1" + " (11)", 11],
  ["Eat1", 2],
  ["Commute1", 2],
  ["Watch TV1", 2],
  ["Sleep1", 7],
  ["Work2" + " (11)", 11],
  ["Eat2", 2],
  ["Commute3", 2],
  ["Watch TV4", 2],
  ["Sleep4", 7], // CSS-style declaration
];
const options = {
  title: "",
  pieHole: 10,
  is3D: true,
  sliceVisibilityThreshold: 0,
};

class DashboardOverall extends Component {
  constructor() {
    super();
    var companyId = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var staffId = CryptoJS.AES.decrypt(
      localStorage.getItem("staffId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var configValue = CryptoJS.AES.decrypt(
      localStorage.getItem("ConfigValue"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var companyName = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var today = new Date();

    /*  var companyId = "001";
     var configValue = "0"; */

    this.state = {
      date: "",
      current_Month: "",
      current_Year: today.getFullYear(),
      companyId: companyId,
      staffId: staffId,
      empSites: GetEmployeeSite(),
      configValue: configValue,
      SaleInvoice_Total_Amt: 0,
      EstimateInvoice_Total_Amt: 0,
      monthly_Purchase: 0,
      monthly_Expense: 0,
      monthly_Profit: 0,
      monthly_PurchaseInvoice: 0,
      monthly_ExpenseInvoice: 0,
      total_No_of_Vendors: 0,
      total_No_of_Customers: 0,
      total_No_of_ProductList: 0,
      total_No_of_SaleInvoice: 0,
      total_No_of_WithGST_Quotation: 0,
      total_No_of_SaleInvoice_Qty: 0,
      total_No_of_SaleInvoice_Qty_Estimate: 0,
      total_No_of_Salary_paid: 0,

      total_Sales_Amount_Annually: 0,
      total_Estimate_Amount_Annually: 0,
      total_Purchase_Amount_Annually: 0,
      yearlyExpense: 0,
      dailyExpense: 0,
      dailySales: 0,
      yearlySales: 0,

      sale: 0,
      saleQty: 0,
      purchase: 0,
      expense: 0,
      profits: 0,
      last_sale: 0,
      last_purchase: 0,
      last_expense: 0,
      last_profit: 0,
      options: [],

      seriesPaymentStats: [10, 20, 30, 40, 50],
      optionsPaymentStats: {
        chart: {
          id: "paymentStatistics",
          height: 250,
          type: "polarArea",
          background: "#fff",
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: true,
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
          style: {
            fontSize: "12px",
            color: "#333",
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                formatter: function (val) {
                  return val;
                },
                color: "#111",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: "14px",
          position: "left",
          offsetX: 15,
          width: 200,
          offsetY: 30,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            var val = opts.w.globals.series[opts.seriesIndex];
            return seriesName + ":  " + val;
          },
          itemMargin: {
            vertical: 3,
          },
        },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
          },
        },
        title: {
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238",
          },
          formatter: function (seriesName, opts) {
            var val = opts.w.globals.series[opts.seriesIndex];
            return seriesName + ":  " + val;
          },
          itemMargin: {
            vertical: 3,
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                id: "paymentStatistics",
                height: 250,
                type: "donut",
              },
              legend: {
                fontSize: "10px",
                position: "right",
              },
              plotOptions: {
                donut: {
                  dataLabels: {
                    name: {
                      show: true,
                    },
                    value: {
                      show: true,
                    },
                  },
                },
              },
            },
          },
        ],
      },
      seriesGenStats: [10, 20, 30, 40, 50],
      optionsGenStats: {
        chart: {
          id: "generalStatistics",
          height: 250,
          type: "donut",
          background: "#fff",
        },
        tooltip: {
          enabled: true,
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
          style: {
            fontSize: "12px",
            color: "#333",
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -20,
                show: true,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                formatter: function (val) {
                  return val;
                },
                color: "#111",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: "14px",
          position: "left",
          offsetX: 15,
          width: 200,
          offsetY: 30,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            var val = opts.w.globals.series[opts.seriesIndex];
            return seriesName + ":  " + val;
          },
          itemMargin: {
            vertical: 3,
          },
        },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
          },
        },
        title: {
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238",
          },
          formatter: function (seriesName, opts) {
            var val = opts.w.globals.series[opts.seriesIndex];
            return seriesName + ":  " + val;
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                id: "generalStatistics",
                height: 250,
                type: "donut",
              },
              legend: {
                fontSize: "10px",
                position: "right",
              },
              plotOptions: {
                donut: {
                  dataLabels: {
                    name: {
                      show: true,
                    },
                    value: {
                      show: true,
                    },
                  },
                },
              },
            },
          },
        ],
      },
      seriesyearly: [
        {
          name: "Sales",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Purchase",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],

      optionsyearly: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#fda45d", "#75c1ce"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Amount",
          },
          min: 5,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      seriesTopSelling: [
        {
          name: "",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
      ],
      optionsTopSelling: {
        chart: {
          height: 350,
          width: "100%",
          type: "bar",
          id: "topSellingChart",
          toolbar: {
            show: true,
          },
          background: "#ffffff",
        },
        //colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
          formatter: function (val, opt) {
            //  return opt.w.globals.categoryLabels[opt.dataPointIndex] + ":  " + val + " mins"
            return val;
          },
        },
        tooltip: {
          enabled: true,
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
          style: {
            fontSize: "12px",
            color: "#333",
          },
        },
        plotOptions: {
          bar: {
            barHeight: "65%",
            endingShape: "rounded",
            columnWidth: "30%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        /*    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
       */ dataLabels: {
          enabled: true,
          textAnchor: "start",
          position: "top",
          style: {
            colors: ["#000000"],
            overflow: "hidden",
          },

          formatter: function (val, opt) {
            return val;

            // return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "mins"
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "",
          align: "center",
          fontSize: "14px",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Sale Quantity",
          },
        },
        yaxis: {
          title: {
            text: "Product Name",
          },
          style: {
            overflow: "hidden",
          },
        },

        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "right",
          floating: true,
          offsetY: -20,
          offsetX: -10,
        },
        theme: {
          mode: "light",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      doughnutData: {
        labels: ["Sale Qty", "Paid Salary", "Total Expense"],
        datasets: [
          {
            label: "Statistics",
            backgroundColor: ["#f75f00", "#13abc4", "#2b580c"],
            data: [65, 59, 80],
          },
        ],
      },
      formErrors: { passwordValid: "" },

      //YEARLY EARNINGS CHART/GRAPH DATA SETUP BEGINGS
      yearlyEarnings_Series: [
        {
          name: "Sales",
          data: [31, 40, 28, 51, 42, 109, 100, 50, 45, 28, 12, 59],
        },
        {
          name: "Purchase",
          data: [11, 32, 45, 32, 34, 52, 41, 47, 58, 69, 58, 10],
        },
      ],

      yearlyEarnings_Options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "month",
          categories: [
            "Jan",
            "Feb",
            "March",
            "Apr",
            "May",
            "Jub",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        tooltip: {
          x: {
            format: "MM",
          },
        },
      },

      //YEARLY EARNINGS CHART/GRAPH DATA SETUP ENDS

      //JOBCARD DETAILS CHART/GRAPH DATA SETUP BEGINS

      jobCard_Series: [
        /* {
          name: 'Total',
          data: [44, 55, 41, 64, 22, 43, 21, 58, 59, 58, 56, 58] //TOTAL
        }, */
        {
          name: "Created",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //CREATED
        },
        {
          name: "InProgress",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //INPROGRESS
        },
        {
          name: "Completed",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //COMPLETED
        },
        {
          name: "Closed",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //CLOSED
        },
        {
          name: "Finished",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //FINISHED
        },
        {
          name: "Deleted",
          data: [53, 32, 33, 52, 13, 44, 32, 58, 59, 58, 56, 58], //DELETED
        },
      ],
      jobCard_Options: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "March",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },

      //JOBCARD DETAILS CHART/GRAPH DATA SETUP ENDS
    };
    this.setState({
      configValue: configValue,
    });
  }

  componentDidMount() {
    SetCurrentPage("DashboardOverall");

    //CURRENCY SYMBOL & CODE
    var siteName = GetCurrentSite();
    var siteCurrencyData = SiteCurrencySymbol(siteName);
    this.state.currencySymbol = siteCurrencyData.currencySymbol;
    this.state.currencyCode = siteCurrencyData.currencyCode;
    this.setState({
      currencySymbol: this.state.currencySymbol,
      currencyCode: this.state.currencyCode,
    });

    window.scrollTo(0, 0);
    // var a=['"06-12-2020","07-12-2020","08-12-2020"'].replace(/"/g, '');

    // alert(a);

    var array = ["06-12-2020", "07-12-2020", "08-12-2020"],
      list = array.map((s) => `'${s}'`).join(",");

    const set = new Set(`${list}`);
    var array2 = Array.from(set);

    $(".btn-default").css("background-color", "#263eac");
    $(".btn-default").css("color", "white");
    this.DashboardDisplayFunc();
    $(".btn-default").css("background-color", "#263eac");
    $(".btn-default").css("color", "white");
    var configValue = CryptoJS.AES.decrypt(
      localStorage.getItem("ConfigValue"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    /*  var configValue = "0"; */
    if (configValue == 0) {
      $("#ContentPlaceHolder1_Lbl_monthlysale").append(
        Number(this.state.SaleInvoice_Total_Amt)
      );
      this.state.monthly_Profit = Math.round(
        Number(this.state.SaleInvoice_Total_Amt) -
          Number(this.state.monthly_PurchaseInvoice) -
          Number(this.state.monthly_ExpenseInvoice)
      );
      if (Number(this.state.monthly_Profit) < 0) {
        this.state.monthly_Profit = 0;
      }
    } else {
      $("#ContentPlaceHolder1_Lbl_monthlysale").append(
        Number(this.state.SaleInvoice_Total_Amt) +
          Number(this.state.EstimateInvoice_Total_Amt)
      );
      this.state.monthly_Profit = Math.round(
        Number(this.state.SaleInvoice_Total_Amt) +
          Number(this.state.EstimateInvoice_Total_Amt) -
          Number(this.state.monthly_PurchaseInvoice) -
          Number(this.state.monthly_ExpenseInvoice)
      );
      if (Number(this.state.monthly_Profit) < 0) {
        this.state.monthly_Profit = 0;
      }
    }
    var empSites = CryptoJS.AES.decrypt(
      localStorage.getItem("EmpSites"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var currentSite = CryptoJS.AES.decrypt(
      localStorage.getItem("CurrentSite"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var emparray = empSites.split(",");
    this.state.options = _.map(emparray, function (site) {
      return { label: site, value: site };
    });
    this.state.options.push({ label: "All", value: "All" });
    this.setState({
      options: this.state.options,
    });
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  componentWillUnmount() {
    clearInterval(this.state.interval_refresh);
  }

  DashboardDisplayFunc() {
    var today = new Date();
    this.state.date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    this.state.current_Month = today.getMonth() + 1;
    this.state.current_Year = today.getFullYear();

    var self = this;

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        date: this.state.date,
        current_Month: this.state.current_Month,
        current_Year: this.state.current_Year,
        companyId: this.state.companyId,
        empSites: this.state.empSites,
        // yesterday:yesterday,
        // lastMonth:lastMonth,
        // lastYear:lastYear,
      }),

      //   url:  "https://wildfly.garageapp.in:443/GarageAppIN_API/DashboardDisplay/DashboardDisplayFrontpage",
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/DashboardDisplay/DashboardDisplayFrontpage",
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        console.log("DASHBOARD DATA :", data);

        dashboardData = data;

        self.state.current_type = "Daily";
        self.state.last_current_type = "Yesterday";
        self.state.seriesyearly = [];

        self.state.yearlyEarnings_Series = [];

        self.RepeatData();

        if (data.monthly_ExpenseInvoice != null)
          self.state.monthly_ExpenseInvoice = data.monthly_ExpenseInvoice;

        self.state.total_No_of_Vendors = data.total_No_of_Vendors;
        self.state.total_No_of_Customers = data.total_No_of_Customers;
        self.state.total_No_of_ProductList = data.total_No_of_ProductList;
        self.state.total_No_of_SaleInvoice = data.total_No_of_SaleInvoice;
        self.state.total_No_of_WithGST_Quotation =
          data.total_No_of_WithGST_Quotation;

        if (data.total_No_of_SaleInvoice_Qty != null)
          self.state.total_No_of_SaleInvoice_Qty =
            data.total_No_of_SaleInvoice_Qty;

        if (data.total_No_of_SaleInvoice_Qty_Estimate != null)
          self.state.total_No_of_SaleInvoice_Qty_Estimate =
            data.total_No_of_SaleInvoice_Qty_Estimate;

        if (data.total_No_of_Salary_paid != null)
          self.state.total_No_of_Salary_paid = data.total_No_of_Salary_paid;

        if (data.total_Sales_Amount_Annually != null)
          self.state.total_Sales_Amount_Annually = Truncate_2DecimalPlaces(
            data.total_Sales_Amount_Annually
          );

        if (data.total_Estimate_Amount_Annually != null)
          self.state.total_Estimate_Amount_Annually =
            data.total_Estimate_Amount_Annually;

        if (data.total_Purchase_Amount_Annually != null)
          self.state.total_Purchase_Amount_Annually =
            data.total_Purchase_Amount_Annually;

        if (data.total_No_of_SaleInvoice_Qty == null)
          self.state.doughnutData.datasets[0].data[0] = 99;

        if (data.total_No_of_Salary_paid != null)
          self.state.doughnutData.datasets[0].data[1] =
            self.state.total_No_of_Salary_paid;

        if (data.monthly_ExpenseInvoice != null)
          self.state.doughnutData.datasets[0].data[2] =
            self.state.monthly_ExpenseInvoice;

        var configValue = CryptoJS.AES.decrypt(
          localStorage.getItem("ConfigValue"),
          "shinchanbaby"
        ).toString(CryptoJS.enc.Utf8);
        //alert("sale_amt_annual"+self.state.total_Sales_Amount_Annually);
        //alert("Estimate_amt_annual"+self.state.total_Estimate_Amount_Annually);

        if (configValue == 0) {
          $("#sale_amount_annual").append(
            Number(self.state.total_Sales_Amount_Annually)
          );
          // this.state.monthly_Profit = Math.round(((Number(this.state.SaleInvoice_Total_Amt) - Number(this.state.monthly_PurchaseInvoice)) - Number(this.state.monthly_ExpenseInvoice)));
        } else {
          $("#sale_amount_annual").append(
            Number(self.state.total_Sales_Amount_Annually) +
              Number(self.state.total_Estimate_Amount_Annually)
          );
          //     this.state.monthly_Profit = Math.round((((Number(this.state.SaleInvoice_Total_Amt) + Number(this.state.EstimateInvoice_Total_Amt)) - Number(this.state.monthly_PurchaseInvoice)) - Number(this.state.monthly_ExpenseInvoice)));
        }

        var janvalue = 0;
        var febvalue = 0;
        var marvalue = 0;
        var aprvalue = 0;
        var mayvalue = 0;
        var junvalue = 0;
        var julvalue = 0;
        var augvalue = 0;
        var sepvalue = 0;
        var octvalue = 0;
        var novvalue = 0;
        var decvalue = 0;
        $.each(data.dashboard_LineChart_List_estimate, function (i, item) {
          if (item.current_Month == 1) {
            self.state.janEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              janEstimateValue: self.state.janEstimateValue,
            });
          } else if (item.current_Month == 2) {
            self.state.FebEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              FebEstimateValue: self.state.FebEstimateValue,
            });
          } else if (item.current_Month == 3) {
            self.state.MarEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              MarEstimateValue: self.state.MarEstimateValue,
            });
          } else if (item.current_Month == 4) {
            self.state.AprilEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              AprilEstimateValue: self.state.AprilEstimateValue,
            });
          } else if (item.current_Month == 5) {
            self.state.MayEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              MayEstimateValue: self.state.MayEstimateValue,
            });
          } else if (item.current_Month == 6) {
            self.state.JuneEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              JuneEstimateValue: self.state.JuneEstimateValue,
            });
          } else if (item.current_Month == 7) {
            self.state.JulyEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              JulyEstimateValue: self.state.JulyEstimateValue,
            });
          } else if (item.current_Month == 8) {
            self.state.AugEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              AugEstimateValue: self.state.AugEstimateValue,
            });
          } else if (item.current_Month == 9) {
            self.state.SepEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              SepEstimateValue: self.state.SepEstimateValue,
            });
          } else if (item.current_Month == 10) {
            self.state.OctEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              OctEstimateValue: self.state.OctEstimateValue,
            });
          } else if (item.current_Month == 11) {
            self.state.NovEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              NovEstimateValue: self.state.NovEstimateValue,
            });
          } else if (item.current_Month == 12) {
            self.state.DecEstimateValue = item.monthly_EstimateInvoice;
            self.setState({
              DecEstimateValue: self.state.DecEstimateValue,
            });
          }
        });

        if (configValue == 0) {
          $.each(data.dashboard_LineChart_List, function (i, item) {
            if (item.current_Month == 1) {
              janvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 2) {
              febvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 3) {
              marvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 4) {
              aprvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 5) {
              mayvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 6) {
              junvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 7) {
              julvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 8) {
              augvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 9) {
              sepvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 10) {
              octvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 11) {
              novvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            } else if (item.current_Month == 12) {
              decvalue = Truncate_2DecimalPlaces(item.monthly_SalesInvoice);
            }
          });
        } else {
          $.each(data.dashboard_LineChart_List, function (i, item) {
            if (item.current_Month == 1) {
              janvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.janEstimateValue)
                )
              );
            } else if (item.current_Month == 2) {
              febvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.FebEstimateValue)
                )
              );
            } else if (item.current_Month == 3) {
              marvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.MarEstimateValue)
                )
              );
            } else if (item.current_Month == 4) {
              aprvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.AprilEstimateValue)
                )
              );
            } else if (item.current_Month == 5) {
              mayvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.MayEstimateValue)
                )
              );
            } else if (item.current_Month == 6) {
              junvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.JuneEstimateValue)
                )
              );
            } else if (item.current_Month == 7) {
              julvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.JulyEstimateValue)
                )
              );
            } else if (item.current_Month == 8) {
              augvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.AugEstimateValue)
                )
              );
            } else if (item.current_Month == 9) {
              sepvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.SepEstimateValue)
                )
              );
            } else if (item.current_Month == 10) {
              octvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.OctEstimateValue)
                )
              );
            } else if (item.current_Month == 11) {
              novvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.NovEstimateValue)
                )
              );
            } else if (item.current_Month == 12) {
              decvalue = Truncate_2DecimalPlaces(
                Math.round(
                  Number(item.monthly_SalesInvoice) +
                    Number(self.state.DecEstimateValue)
                )
              );
            }
          });
        }

        self.state.seriesyearly.push({
          name: "Sales",
          data: [
            janvalue,
            febvalue,
            marvalue,
            aprvalue,
            mayvalue,
            junvalue,
            julvalue,
            augvalue,
            sepvalue,
            octvalue,
            novvalue,
            decvalue,
          ],
        });

        self.state.yearlyEarnings_Series.push({
          name: "Sales",
          data: [
            janvalue,
            febvalue,
            marvalue,
            aprvalue,
            mayvalue,
            junvalue,
            julvalue,
            augvalue,
            sepvalue,
            octvalue,
            novvalue,
            decvalue,
          ],
        });

        janvalue = 0;
        febvalue = 0;
        marvalue = 0;
        aprvalue = 0;
        mayvalue = 0;
        junvalue = 0;
        julvalue = 0;
        augvalue = 0;
        sepvalue = 0;
        octvalue = 0;
        novvalue = 0;
        decvalue = 0;

        $.each(data.dashboard_LineChart_List_purchase, function (i, item) {
          if (item.current_Month == 1) {
            janvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 2) {
            febvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 3) {
            // alert("monthlyPurchaseinvoicevalue"+item.monthly_PurchaseInvoice);
            marvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 4) {
            aprvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 5) {
            mayvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 6) {
            junvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 7) {
            julvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 8) {
            augvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 9) {
            sepvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 10) {
            octvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 11) {
            novvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          } else if (item.current_Month == 12) {
            decvalue = Truncate_2DecimalPlaces(item.monthly_PurchaseInvoice);
          }
        });

        self.state.seriesyearly.push({
          name: "Purchase",
          data: [
            janvalue,
            febvalue,
            marvalue,
            aprvalue,
            mayvalue,
            junvalue,
            julvalue,
            augvalue,
            sepvalue,
            octvalue,
            novvalue,
            decvalue,
          ],
        });

        self.state.yearlyEarnings_Series.push({
          name: "Purchase",
          data: [
            janvalue,
            febvalue,
            marvalue,
            aprvalue,
            mayvalue,
            junvalue,
            julvalue,
            augvalue,
            sepvalue,
            octvalue,
            novvalue,
            decvalue,
          ],
        });

        self.setState({
          SaleInvoice_Total_Amt: self.state.SaleInvoice_Total_Amt,
          EstimateInvoice_Total_Amt: self.state.EstimateInvoice_Total_Amt,
          monthly_PurchaseInvoice: self.state.monthly_PurchaseInvoice,
          monthly_ExpenseInvoice: self.state.monthly_ExpenseInvoice,
          total_No_of_Vendors: self.state.total_No_of_Vendors,
          total_No_of_Customers: self.state.total_No_of_Customers,
          total_No_of_ProductList: self.state.total_No_of_ProductList,
          total_No_of_SaleInvoice: self.state.total_No_of_SaleInvoice,
          total_No_of_WithGST_Quotation:
            self.state.total_No_of_WithGST_Quotation,
          total_No_of_SaleInvoice_Qty: self.state.total_No_of_SaleInvoice_Qty,
          total_No_of_SaleInvoice_Qty_Estimate:
            self.state.total_No_of_SaleInvoice_Qty_Estimate,
          total_No_of_Salary_paid: self.state.total_No_of_Salary_paid,
          total_Sales_Amount_Annually: self.state.total_Sales_Amount_Annually,
          total_Estimate_Amount_Annually:
            self.state.total_Estimate_Amount_Annually,
          total_Purchase_Amount_Annually:
            self.state.total_Purchase_Amount_Annually,
          seriesyearly: [...self.state.seriesyearly],

          yearlyEarnings_Series: self.state.yearlyEarnings_Series,
        });

        var no;
        var tab =
          '<thead style="overflow:auto;"><tr className="headcolor" style="background-color: #91c5f5;"><th>S.No</th><th>Invoice</th><th>Date</th><th>Name</th><th>Status</th><th>Total</th></tr></thead>';

        if (data.dailyInvoiceList != null) {
          $.each(data.dailyInvoiceList, function (i, item) {
            no = parseInt(i) + 1;
            tab +=
              '<tbody id= "myTable" ><tr className="" style:"background-color:white;" id="tabletextcol" ><td>' +
              no +
              "</td><td>" +
              item.invoiceNo +
              "</td><td>" +
              item.date +
              "</td><td>" +
              item.userName +
              "</td>" +
              "<td>" +
              item.status +
              "</td><td>" +
              item.subTotal +
              "</td></tr></tbody>";
          });
          $("#tableHeadings").append(tab);
          /*dummy */
          /*  $("#tableHeadings1").append(tab);
           $("#tableHeadings2").append(tab); */
        } else {
          $("#tableHeadings").append("<h4>No Recent Sales</h3>");
          $("#loginSubmitButton").hide();
        }

        var no;
        var tab =
          '<thead><tr className="headcolor" style="background-color: #91c5f5;"><th>S.No</th><th>Name</th><th>Quantity</th></tr></thead>';

        if (data.topSalesPerMonth != null) {
          $.each(data.topSalesPerMonth, function (i, item) {
            no = parseInt(i) + 1;
            tab +=
              '<tbody id= "myTable" ><tr className=""  id="tabletextcol" ><td>' +
              no +
              "</td>" +
              "<td>" +
              item.productName +
              "</td><td>" +
              item.quantity +
              "</td></tr></tbody>";
          });
          $("#salesofmonth").append(tab);
        } else {
          $("#salesofmonth").append("<h4>No Recent Sales</h3>");
          $("#loginSubmitButton").hide();
        }

        var no;
        var tab =
          '<thead><tr className="headcolor" style="background-color: #91c5f5;"><th>S.No</th><th>Name</th><th>Quantity</th></tr></thead>';

        if (data.topSalesPerYear != null) {
          $.each(data.topSalesPerYear, function (i, item) {
            no = parseInt(i) + 1;
            tab +=
              '<tbody id= "myTable" ><tr className=""  id="tabletextcol" ><td>' +
              no +
              "</td>" +
              "<td>" +
              item.productName +
              "</td><td>" +
              item.quantity +
              "</td></tr></tbody>";
          });
          $("#salesofyear").append(tab);
        } else {
          $("#salesofyear").append("<h4>No Recent Sales</h3>");
          $("#loginSubmitButton").hide();
        }

        var no;
        var tab =
          '<thead><tr className="headcolor" style="background-color: #91c5f5;"><th>S.No</th><th>ProductName</th><th>Quantity</th></tr></thead>';
        /*  alert(data.criticalReportDataList); */
        if (data.criticalReportDataList != null) {
          $.each(data.criticalReportDataList, function (i, item) {
            no = parseInt(i) + 1;
            tab +=
              '<tbody id= "myTable" ><tr className=""  id="tabletextcol" ><td>' +
              no +
              "</td>" +
              "<td>" +
              item.productName +
              "</td><td>" +
              item.quantity +
              "</td></tr></tbody>";
          });
          $("#tableHeadings1").append(tab);
        } else {
          $("#tableHeadings1").append("<h4>No Product</h3>");
          $("#viewAllButton").hide();
        }

        self.SetJobCardGraphData(data.jobcardList);

        self.state.interval_refresh = setInterval(
          () => self.RepeatData(),
          5000
        );
      },
      error: function (data) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }
  RepeatData() {
    var self = this;
    self.state.seriesPaymentStats = [0, 0, 0, 0, 0];
    self.state.seriesGenStats = [0, 0, 0, 0, 0];
    self.state.optionsPaymentStats.labels = [
      " Cash ",
      " Card ",
      " Cheque ",
      " Online ",
      " UPI ",
    ];
    self.state.optionsGenStats.labels = [
      " Sale Qty ",
      " Netsale ",
      " Purchase ",
      " Expense ",
      " Tax ",
    ];
    self.state.seriesTopSelling = [];
    switch (i) {
      case 1:
        $.each(dashboardData.dailyList, function (i, item) {
          self.state.current_type = "Daily";
          self.state.last_current_type = "Yesterday";

          if (item.dailySales != null) {
            self.state.sale = item.dailySales;
          } else {
            self.state.sale = 0;
          }

          if (item.dailySalesQty != null) {
            self.state.saleQty = item.dailySalesQty;
          } else {
            self.state.saleQty = 0;
          }

          if (item.dailyExpense != null) {
            self.state.expense = item.dailyExpense;
          } else {
            self.state.expense = 0;
          }

          if (item.daily_PurchaseInvoice != null) {
            self.state.purchase = item.daily_PurchaseInvoice;
          } else {
            self.state.purchase = 0;
          }

          //jeeva google chart data

          self.state.seriesPaymentStats = [
            Number(dashboardData.dailyList[0].dailyCashPaymentStatics),
            Number(dashboardData.dailyList[0].dailyCardPaymentStatics),
            Number(dashboardData.dailyList[0].dailyChequePaymentStatics),
            Number(dashboardData.dailyList[0].dailyOnlinePaymentStatics),
            Number(dashboardData.dailyList[0].dailyUPIPaymentStatics),
          ];

          //jeeva google chart data for overall Statistics
          self.state.sale = Number(self.state.sale).toFixed(2);
          self.state.purchase = Number(self.state.purchase).toFixed(2);
          self.state.expense = Number(self.state.expense).toFixed(2);
          self.state.seriesGenStats = [
            Number(self.state.saleQty),
            Number(self.state.sale),
            Number(self.state.purchase),
            Number(self.state.expense),
            Number(dashboardData.dailyList[0].taxAmount),
          ];

          /********************************* */
          //jeeva google graph data for Employees Graph

          /********************************************* */
          //jeeva google graph data for google_Bargraph_TopSelling_Product Graph

          var topsellinglabel = [];
          var topsellingQuantity = [];
          //amul kindly replace the value of criticalreportdatalist and
          // it should be daily services & daily service Amount
          // ["Product Name", "Sale Qty",]
          // format value should be ['lorel shampoo', 30,],

          if (dashboardData.criticalReportDataList.length > 0) {
            $.each(dashboardData.criticalReportDataList, function (i, item) {
              var title = item.productName;
              if (title.length > 15) {
                var shortText =
                  $.trim(title)
                    .substring(0, 200)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ") + "...";
              } else {
                var shortText = title;
              }

              topsellinglabel.push(shortText);
              topsellingQuantity.push(Number(item.quantity));
            });
          } else {
          }

          self.state.seriesTopSelling.push({
            name: "Product Name",
            data: topsellingQuantity,
          });
          self.state.optionsTopSelling.labels = topsellinglabel;
          /********************************** */
          //jeeva google graph data for google_Bargraph_Critical_Product Graph

          if (item.dailySales != null) {
            self.state.last_sale = 0;
          }

          if (item.dailyExpense != null) {
            self.state.last_expense = 0;
          }

          if (item.daily_PurchaseInvoice != null) {
            self.state.last_purchase = 0;
          }
        });
        self.state.profit =
          Number(self.state.sale) -
          (Number(self.state.expense) + Number(self.state.purchase));
        if (Number(self.state.profit) < 0) {
          self.state.profit = 0;
        }
        self.state.last_profit =
          Number(self.state.last_sale) -
          (Number(self.state.last_expense) + Number(self.state.last_purchase));

        i = i + 1;
        this.setState({
          sale: this.state.sale,
          purchase: this.state.purchase,
          expense: this.state.expense,

          last_sale: this.state.last_sale,
          last_purchase: this.state.last_purchase,
          last_expense: this.state.last_expense,
          last_profit: this.state.last_profit,
          current_type: this.state.current_type,
          last_current_type: this.state.last_current_type,

          seriesPaymentStats: self.state.seriesPaymentStats,
          optionsPaymentStats: {
            ...self.state.optionsPaymentStats.labels,
            labels: self.state.optionsPaymentStats.labels,
          },
          seriesGenStats: self.state.seriesGenStats,
          optionsGenStats: {
            ...self.state.optionsGenStats.labels,
            labels: self.state.optionsGenStats.labels,
          },
          seriesTopSelling: [...self.state.seriesTopSelling],
          optionsTopSelling: {
            ...self.state.optionsTopSelling.labels,
            labels: self.state.optionsTopSelling.labels,
          },
        });

        break;

      case 2:
        $.each(dashboardData.monthlyList, function (i, item) {
          self.state.current_type = "Monthly";
          self.state.last_current_type = "Last Month";

          if (item.monthlySales != null) {
            self.state.sale = item.monthlySales;
          } else {
            self.state.sale = 0;
          }
          if (item.monthlySalesQty != null) {
            self.state.saleQty = item.monthlySalesQty;
          } else {
            self.state.saleQty = 0;
          }

          if (item.monthly_ExpenseInvoice != null) {
            self.state.expense = item.monthly_ExpenseInvoice;
          } else {
            self.state.expense = 0;
          }

          if (item.monthly_PurchaseInvoice != null) {
            self.state.purchase = item.monthly_PurchaseInvoice;
          } else {
            self.state.purchase = 0;
          }

          self.state.seriesPaymentStats = [
            Number(dashboardData.monthlyList[0].monthlyCashPaymentStatics),
            Number(dashboardData.monthlyList[0].monthlyCardPaymentStatics),
            Number(dashboardData.monthlyList[0].monthlyChequePaymentStatics),
            Number(dashboardData.monthlyList[0].monthlyOnlinePaymentStatics),
            Number(dashboardData.monthlyList[0].monthlyUPIPaymentStatics),
          ];

          //jeeva google chart data for overall Statistics

          self.state.sale = Number(self.state.sale).toFixed(2);
          self.state.purchase = Number(self.state.purchase).toFixed(2);
          self.state.expense = Number(self.state.expense).toFixed(2);
          self.state.seriesGenStats = [
            Number(self.state.saleQty),
            Number(self.state.sale),
            Number(self.state.purchase),
            Number(self.state.expense),
            Number(dashboardData.dailyList[0].taxAmount),
          ];

          /********************************* */

          /********************************************* */
          //jeeva google graph data for google_Bargraph_TopSelling_Product Graph

          var topsellinglabel = [];
          var topsellingQuantity = [];
          //amul kindly replace the value of criticalreportdatalist and
          // it should be daily services & daily service Amount
          // ["Product Name", "Sale Qty",]
          // format value should be ['lorel shampoo', 30,],

          if (dashboardData.criticalReportDataList.length > 0) {
            $.each(dashboardData.criticalReportDataList, function (i, item) {
              var title = item.productName;
              if (title.length > 15) {
                var shortText =
                  $.trim(title)
                    .substring(0, 200)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ") + "...";
              } else {
                var shortText = title;
              }

              topsellinglabel.push(shortText);
              topsellingQuantity.push(Number(item.quantity));
            });
          } else {
          }

          self.state.seriesTopSelling.push({
            name: "Product Name",
            data: topsellingQuantity,
          });
          self.state.optionsTopSelling.labels = topsellinglabel;

          /********************************** */
          //jeeva google graph data for google_Bargraph_Critical_Product Graph

          //last value
          /** yesterday value Replace  item.dailySales with yesterday sale value*/
          if (item.dailySales != null) {
            self.state.last_sale = 0;
          }

          if (item.dailyExpense != null) {
            self.state.last_expense = 0;
          }

          if (item.daily_PurchaseInvoice != null) {
            self.state.last_purchase = 0;
          }
        });
        self.state.profit =
          Number(self.state.sale) -
          (Number(self.state.expense) + Number(self.state.purchase));
        if (Number(self.state.profit) < 0) {
          self.state.profit = 0;
        }
        self.state.last_profit =
          Number(self.state.last_sale) -
          (Number(self.state.last_expense) + Number(self.state.last_purchase));

        i = i + 1;
        this.setState({
          sale: this.state.sale,
          purchase: this.state.purchase,
          expense: this.state.expense,

          last_sale: this.state.last_sale,
          last_purchase: this.state.last_purchase,
          last_expense: this.state.last_expense,
          last_profit: this.state.last_profit,
          current_type: this.state.current_type,
          last_current_type: this.state.last_current_type,
          seriesPaymentStats: self.state.seriesPaymentStats,
          optionsPaymentStats: {
            ...self.state.optionsPaymentStats.labels,
            labels: self.state.optionsPaymentStats.labels,
          },
          seriesGenStats: self.state.seriesGenStats,
          optionsGenStats: {
            ...self.state.optionsGenStats.labels,
            labels: self.state.optionsGenStats.labels,
          },
          seriesTopSelling: [...self.state.seriesTopSelling],
          optionsTopSelling: {
            ...self.state.optionsTopSelling.labels,
            labels: self.state.optionsTopSelling.labels,
          },
        });

        break;

      case 3:
        $.each(dashboardData.yearlyList, function (i, item) {
          self.state.current_type = "Yearly";
          self.state.last_current_type = "Last Year";

          if (item.yearlySales != null) {
            self.state.sale = item.yearlySales;
          } else {
            self.state.sale = 0;
          }
          if (item.yearlySalesQty != null) {
            self.state.saleQty = item.yearlySalesQty;
          } else {
            self.state.saleQty = 0;
          }

          if (item.yearlyExpense != null) {
            self.state.expense = item.yearlyExpense;
          } else {
            self.state.expense = 0;
          }
          if (item.yearly_PurchaseInvoice != null) {
            self.state.purchase = item.yearly_PurchaseInvoice;
          } else {
            self.state.purchase = 0;
          }

          //jeeva google chart data

          self.state.seriesPaymentStats = [
            Number(dashboardData.yearlyList[0].yearlyCashPaymentStatics),
            Number(dashboardData.yearlyList[0].yearlyCardPaymentStatics),
            Number(dashboardData.yearlyList[0].yearlyChequePaymentStatics),
            Number(dashboardData.yearlyList[0].yearlyOnlinePaymentStatics),
            Number(dashboardData.yearlyList[0].yearlyUPIPaymentStatics),
          ];

          //jeeva google chart data for overall Statistics
          self.state.sale = Number(self.state.sale).toFixed(2);
          self.state.purchase = Number(self.state.purchase).toFixed(2);
          self.state.expense = Number(self.state.expense).toFixed(2);
          self.state.seriesGenStats = [
            Number(self.state.saleQty),
            Number(self.state.sale),
            Number(self.state.purchase),
            Number(self.state.expense),
            Number(dashboardData.dailyList[0].taxAmount),
          ];

          /********************************* */
          //jeeva google graph data for Employees Graph

          /********************************************* */
          //jeeva google graph data for google_Bargraph_TopSelling_Product Graph

          var topsellinglabel = [];
          var topsellingQuantity = [];
          //amul kindly replace the value of criticalreportdatalist and
          // it should be daily services & daily service Amount
          // ["Product Name", "Sale Qty",]
          // format value should be ['lorel shampoo', 30,],

          if (dashboardData.criticalReportDataList.length > 0) {
            $.each(dashboardData.criticalReportDataList, function (i, item) {
              var title = item.productName;
              if (title.length > 15) {
                var shortText =
                  $.trim(title)
                    .substring(0, 200)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ") + "...";
              } else {
                var shortText = title;
              }

              topsellinglabel.push(shortText);
              topsellingQuantity.push(Number(item.quantity));
            });
          } else {
          }

          self.state.seriesTopSelling.push({
            name: "Product Name",
            data: topsellingQuantity,
          });
          self.state.optionsTopSelling.labels = topsellinglabel;

          /********************************** */
          //jeeva google graph data for google_Bargraph_Critical_Product Graph

          //last value
          /** yesterday value Replace  item.dailySales with yesterday sale value*/
          if (item.dailySales != null) {
            self.state.last_sale = 0;
          }

          if (item.dailyExpense != null) {
            self.state.last_expense = 0;
          }

          if (item.daily_PurchaseInvoice != null) {
            self.state.last_purchase = 0;
          }
        });
        self.state.profit =
          Number(self.state.sale) -
          (Number(self.state.expense) + Number(self.state.purchase));
        if (Number(self.state.profit) < 0) {
          self.state.profit = 0;
        }
        self.state.last_profit =
          Number(self.state.last_sale) -
          (Number(self.state.last_expense) + Number(self.state.last_purchase));

        i = 1;
        this.setState({
          sale: this.state.sale,
          purchase: this.state.purchase,
          expense: this.state.expense,

          last_sale: this.state.last_sale,
          last_purchase: this.state.last_purchase,
          last_expense: this.state.last_expense,
          last_profit: this.state.last_profit,
          current_type: this.state.current_type,
          last_current_type: this.state.last_current_type,
          seriesPaymentStats: self.state.seriesPaymentStats,
          optionsPaymentStats: {
            ...self.state.optionsPaymentStats.labels,
            labels: self.state.optionsPaymentStats.labels,
          },
          seriesGenStats: self.state.seriesGenStats,
          optionsGenStats: {
            ...self.state.optionsGenStats.labels,
            labels: self.state.optionsGenStats.labels,
          },
          seriesTopSelling: [...self.state.seriesTopSelling],
          optionsTopSelling: {
            ...self.state.optionsTopSelling.labels,
            labels: self.state.optionsTopSelling.labels,
          },
        });
    }
  }

  SetJobCardGraphData(jobcardList) {
    var self = this;

    self.state.jobCard_Series = [];
    self.setState({
      jobCard_Series: self.state.jobCard_Series,
    });
    var groupByStatusData = _.groupBy(jobcardList, "status");
    console.log(
      "SET JOBCARD GRAPH DATA groupByStatusData :",
      groupByStatusData
    );

    var partionedStatusData = _.partition(groupByStatusData, ",")[1];
    console.log(
      "SET JOBCARD GRAPH DATA partionedStatusData :",
      partionedStatusData
    );

    for (var i = 0; i < _.size(partionedStatusData); i++) {
      var currentPartionedDataMain = partionedStatusData[i];

      console.log(
        "SET JOBCARD GRAPH DATA currentPartionedDataMain:",
        currentPartionedDataMain
      );

      var currentPartionedData = _.sortBy(currentPartionedDataMain, "month");
      console.log(
        "SET JOBCARD GRAPH DATA currentPartionedData -- SORT BY:",
        currentPartionedData
      );

      var janValue = 0;
      var febValue = 0;
      var marValue = 0;
      var aprValue = 0;
      var mayValue = 0;
      var juneValue = 0;
      var julValue = 0;
      var augValue = 0;
      var septValue = 0;
      var octValue = 0;
      var novValue = 0;
      var decValue = 0;

      for (var j = 0; j < _.size(currentPartionedData); j++) {
        if (currentPartionedData[j].month == 1) {
          janValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 2) {
          febValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 3) {
          marValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 4) {
          aprValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 5) {
          mayValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 6) {
          juneValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 7) {
          julValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 8) {
          augValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 9) {
          septValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 10) {
          octValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 11) {
          novValue = currentPartionedData[j].count;
        } else if (currentPartionedData[j].month == 12) {
          decValue = currentPartionedData[j].count;
        }
      }
      var jobCardSeriesName;
      if (currentPartionedDataMain[0].status == "Created") {
        jobCardSeriesName = "Created";
      } else if (currentPartionedDataMain[0].status == "InProgress") {
        jobCardSeriesName = "Accepted";
      } else if (currentPartionedDataMain[0].status == "Completed") {
        jobCardSeriesName = "Completed";
      } else if (currentPartionedDataMain[0].status == "Closed") {
        jobCardSeriesName = "Closed";
      } else if (currentPartionedDataMain[0].status == "Finished") {
        jobCardSeriesName = "Finished";
      } else if (currentPartionedDataMain[0].status == "Deleted") {
        jobCardSeriesName = "Deleted";
      }

      self.state.jobCard_Series.push({
        name: jobCardSeriesName,
        data: [
          janValue,
          febValue,
          marValue,
          aprValue,
          mayValue,
          juneValue,
          julValue,
          augValue,
          septValue,
          octValue,
          novValue,
          decValue,
        ],
      });
    }

    self.setState({
      jobCard_Series: [...self.state.jobCard_Series],
    });
  }

  viewAll() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={InvoiceList} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  viewAllReport() {
    ReactDOM.render(
      <Router>
        <div>
          <Route path="/" component={AvailableStockReport} />
        </div>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  addProduct() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={AddProduct} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  addQuotation() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={CustomerEntryForm} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  addSalesInvoice() {
    var flag = this.CheckPermission("SaleInvoice");
    if (flag == 0) {
      ReactDOM.render(
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <div>
              <Route
                path="/"
                component={() => (
                  <InvoiceMenuPage
                    pageCalledFrom="BashboardOverall"
                    data="SaleInvoice"
                    stateData={this.state}
                  />
                )}
              />
            </div>
          </Suspense>
        </Router>,
        document.getElementById("contentRender")
      );
    } else {
      this.AccesDeniedMessage();
    }
  }
  vehicleRegistrationFunc() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={ServiceRegistration} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }
  addVendors() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={VendorEntryForm} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
    registerServiceWorker();
  }

  handleCurrentSite = (e) => {
    const value = e.value;
    this.state.currentSite = value;
    this.state.empSites = value;
    if (value === "All") {
      var empSites = CryptoJS.AES.decrypt(
        localStorage.getItem("EmpSites"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8);
      this.state.empSites = empSites;
    }
    this.setState({
      selectedSite: e,
      currentSite: value,
      empSites: this.state.empSites,
    });
    this.DashboardDisplayFunc();
  };

  CheckPermission(moduleName) {
    var permission = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("Permissions"),
        "shinchanbaby"
      ).toString(CryptoJS.enc.Utf8)
    );

    var flag = 1; //false

    //console.log("PERMISSION LIST :", permission);
    var presentStatus = _.where(permission, { permission: moduleName });

    if (presentStatus.length > 0) {
      flag = 0; //true
      //console.log("PERMISSION FLAG 0 ");
    } else {
      var permission = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("PermissionHeader"),
          "shinchanbaby"
        ).toString(CryptoJS.enc.Utf8)
      );
      var presentHeaderStatus = _.where(permission, {
        permissionHeader: moduleName,
      });

      if (presentHeaderStatus.length > 0) {
        flag = 0; //true
        //console.log("PERMISSION HEADER FLAG 0 ");
      }
    }

    return flag;
  }

  /*
   *********************************************************
   ********* MODULE BASED DASH BOARD FUNCTION CALL BEGINS
   ********************************************************
   */
  JobCardDashBoard() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={JobcardDashboard} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
  }

  AppointmentDashboard() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={AppoinmentDashboard} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
  }

  inventoryDashboard() {
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={InventoryDashboard} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("contentRender")
    );
  }
  /*
   *********************************************************
   ********* MODULE BASED DASH BOARD FUNCTION CALL ENDS
   ********************************************************
   */

  render() {
    return (
      <div
        className="container-fluid"
        id="wrap_content"
        style={{ backgroundColor: "#eaedfa" }}
      >
        <div className="dash_site_sel" styles={{ color: "#263eac" }}>
          <SelectSearch
            options={this.state.options}
            value={this.state.selectedSite}
            onChange={(e) => this.handleCurrentSite(e)}
            name="WorkingSite"
            placeholder="Select Working Site "
            styles={{ color: "#263eac" }}
          />
        </div>
        <div className="title_livedata">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="2" xs="5">
                      <div className="icon-big text-center icon-warning">
                        {/* // style={{ background: "#01a941db ", padding: '6px', width: "70px", borderRadius: "50px", fontSize: '30px' }} */}
                        {/* <i className="fas fa-coins" style={{ color: "white", fontSize: '22px' }}></i> */}
                        <img src={coins} style={{ width: "60px" }} />
                      </div>
                    </Col>
                    <Col md="10" xs="7">
                      <div className="numbers">
                        <p className="card-category">
                          {this.state.current_type} Netsale
                        </p>
                        <CardTitle tag="p">
                          <span className="info-currency">
                            {this.state.currencySymbol}
                          </span>
                          <span style={{ fontSize: "18px" }}>
                            {Math.round(this.state.sale)}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    {/*  <i className="fas fa-sync-alt" /> Update Now */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="2" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <img src={purchase} style={{ width: "60px" }} />
                      </div>
                    </Col>
                    <Col md="10" xs="7">
                      <div className="numbers">
                        <p className="card-category">
                          {this.state.current_type} Purchase
                        </p>
                        <CardTitle tag="p">
                          <span className="info-currency">
                            {this.state.currencySymbol}
                          </span>
                          <span style={{ fontSize: "18px" }}>
                            {Math.round(this.state.purchase)}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr /> */}
                  <div className="stats">
                    {/*  <i className="far fa-calendar" /> Last day */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="2" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <img src={moneyloss} style={{ width: "65px" }} />
                      </div>
                    </Col>
                    <Col md="10" xs="7">
                      <div className="numbers">
                        <p className="card-category">
                          {this.state.current_type} Expense
                        </p>
                        <CardTitle tag="p">
                          <span className="info-currency">
                            {this.state.currencySymbol}
                          </span>
                          <span style={{ fontSize: "18px" }}>
                            {Math.round(this.state.expense)}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr /> */}
                  <div className="stats">
                    {/*   <i className="far fa-clock" /> In the last hour */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="2" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <img src={increase} style={{ width: "65px" }} />
                      </div>
                    </Col>
                    <Col md="10" xs="7">
                      <div className="numbers">
                        <p className="card-category">
                          {this.state.current_type} Profits
                        </p>
                        <CardTitle tag="p">
                          <span className="info-currency">
                            {this.state.currencySymbol}
                          </span>
                          <span style={{ fontSize: "18px" }}>
                            {Math.round(this.state.profit)}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr /> */}
                  <div className="stats">
                    {/*    <i className="fas fa-sync-alt" /> Update now */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>

        {/*<div id="sidebar_quick_menu" className="sidebar_quick_menu_c afterPay">
          <div className="social add_emp">
            <a onClick={() => this.addSalesInvoice()} target="_blank">
              <p><i className="fa fa-plus"></i><span className="s_icon_title" > Sales Invoices({this.state.total_No_of_SaleInvoice})</span></p>
            </a>
          </div>
          <div className="social loc_emp">
            <a onClick={() => this.addProduct()} target="_blank">
              <p><i className="fa fa-cubes"></i><span className="s_icon_title" >Products({this.state.total_No_of_ProductList})</span> </p>
            </a>
          </div>
          <div className="social msg_center">
            <a onClick={() => this.addVendors()} target="_blank">
              <p><i className="fa fa-address-book-o"></i><span className="s_icon_title" >Vendors({this.state.total_No_of_Vendors})</span> </p>
            </a>
          </div>
          <div className="social app_conf">
            <a onClick={() => this.vehicleRegistrationFunc()} target="_blank">
              <p><i className="fa fa-car"></i><span className="s_icon_title" >Intake({this.state.total_No_of_Customers})</span></p>
            </a>
          </div>
        </div>
        */}

        <section>
          <div className="row sec1_dash ">
            <div className="col-lg-6 col-sm-12 col-xs-12 sec_o_row ">
              <h3 className="Dashboard_sub_head">
                {this.state.current_type} Payment Statistics
              </h3>
              <Card className="card-stats">
                <CardBody>
                  <ReactApexChart
                    style={{ background: "transparent", borderRadius: "10px" }}
                    // style={{ boxShadow: "rgb(181 177 177 / 8%) 4px 1px 18px 2px, inset rgb(0 0 0 / 22%) 0px -1px 0px 0px" ,borderRadius:'22px'}}
                    options={this.state.optionsPaymentStats}
                    series={this.state.seriesPaymentStats}
                    type="polarArea"
                    height={250}
                  />
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 sec_o_row ">
              <h3 className="Dashboard_sub_head">
                {this.state.current_type} Statistics
              </h3>
              <Card>
                <CardBody>
                  <ReactApexChart
                    options={this.state.optionsGenStats}
                    series={this.state.seriesGenStats}
                    type="donut"
                    height={250}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </section>

        <div className="row">
          <div
            className="col-lg-6 info-box_chart" /*style={{ border: "5px solid rgb(238, 238, 238)" }} */
          >
            <div className="flex-wrap">
              <h3 className="Dashboard_sub_head">
                Yearly earning {this.state.current_Year}
              </h3>
              <Card>
                <CardBody>
                  {/*<ReactApexChart
                    style={{ boxShadow: "rgb(181 177 177 / 8%) 4px 1px 18px 2px, inset rgb(0 0 0 / 22%) 0px -1px 0px 0px" }}
                    options={this.state.optionsyearly}
                    series={this.state.seriesyearly} type="line" height={350} /> */}
                  <ReactApexChart
                    // style={{ boxShadow: "rgb(181 177 177 / 8%) 4px 1px 18px 2px, inset rgb(0 0 0 / 22%) 0px -1px 0px 0px" }}
                    options={this.state.yearlyEarnings_Options}
                    series={this.state.yearlyEarnings_Series}
                    type="area"
                    height={350}
                  />
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="col-lg-6">
            {/* <div style={{display:'flex',right:0,justifyContent:'right'}}>
            <button className="btn btn-primary"   onClick={() => this.AppointmentDashboard()}>Appointment Dashboard</button>
          </div> */}
            <h3 className="Dashboard_sub_head">Top Selling/Service</h3>

            <Card>
              <CardBody>
                <ReactApexChart
                  // style={{ boxShadow: "rgb(181 177 177 / 8%) 4px 1px 18px 2px, inset rgb(0 0 0 / 22%) 0px -1px 0px 0px", overflow: "hidden" }}
                  options={this.state.optionsTopSelling}
                  series={this.state.seriesTopSelling}
                  type="bar"
                  height={350}
                />
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
         {/* <div className="col-md-12 text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.JobCardDashBoard()}
            >
              Go to Jobcard Dashboard
            </button>
          </div> */}
          <div className="col-md-12">
            <h3 className="Dashboard_sub_head">Yearly JobCard Statistics</h3>
            <Card>
              <div className="card_tiles">
                <ReactApexChart
                  options={this.state.jobCard_Options}
                  series={this.state.jobCard_Series}
                  type="bar"
                  height={430}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardOverall;
