import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Saliders from "./salider";

import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

//import './d.css';
import "./LandingPage_tagaragecss.css";
import "./newstyles.css";

import LoginPage from "../LoginPage";
import "../LoginPage.css";

import CustomerAppointments from "../CustomerAppointments";
import time from "./image/features/time.png";
import app from "./image/features/app.png";
import customerimg from "./image/features/customerimg.png";
import calendar1 from "./image/features/calendar1.png";
import Throughapplogo from "./image/Throughapps-MasterLogo.webp";
import Androidlogo from "./image/Androiddownload.png";
import Addresslogo from "./image/address.gif";
import calllogo from "./image/phonecalls.png";
import maillogo from "./image/gmail.gif";
import main_image from "./image/features/main_image.png";
import client1 from "./image/client1.png";
import client2 from "./image/client2.png";
import client3 from "./image/client3.png";
import client4 from "./image/client4.png";
import image3 from "./image/image3.png";
import image4 from "./image/image4.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

class LandingPage_tagarage extends Component {
  constructor() {
    super();
    this.state = {
      emailId: "",
      name: "",
      comments: "",
      password: "",
      date: "",
      formErrors: { emailId: "", password: "" },
      emailIdValid: false,
      passwordValid: false,
    };
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  /*  Fpassword() {
       ReactDOM.render(<ForgotPassword />, document.getElementById("root"));
     } */

  /*  LoginBtn() {
         ReactDOM.render(<LoginPage />, document.getElementById("root"));
     } */

  componentDidMount() {
    // The function toggles more (hidden) text when the user clicks on "Read more".
    //The IF ELSE statement ensures that the text 'read more' and 'read less' changes
    //interchangeably when clicked on.

    /*  SignUpFunc() {
           ReactDOM.render(<SiteRegister />, document.getElementById("root"));
         } */
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");
    const navLink = document.querySelectorAll(".nav-link");

    hamburger.addEventListener("click", mobileMenu);
    navLink.forEach((n) => n.addEventListener("click", closeMenu));

    function mobileMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    }

    function closeMenu() {
      hamburger.classList.remove("active");
      navMenu.classList.remove("active");
    }
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  login() {
    ReactDOM.render(
      <Router>
        <div>
          <Route exact path="/" component={LoginPage} />
        </div>
      </Router>,
      document.getElementById("root")
    );
  }

  Submit() {
    var self = this;
    if (
      this.state.name.trim().length > 0 &&
      this.state.emailId.trim().length > 0 &&
      this.state.comments.trim().length > 0
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          emailId: this.state.emailId,
          userName: this.state.name,
          comments: this.state.comments,
        }),
        //url:"https://wildfly.garageapp.in:443/EmployeeAttendenceAPI/ContactPageMail/ContactPage",
        url: "https://wildfly.tictoks.com:443/EmployeeAttendenceAPI/ContactPageMail/ContactPage",
        contentType: "application/json",
        dataType: "json",
        async: false,
        success: function (data, textStatus, jqXHR) {
          confirmAlert({
            title: "Success", // Title dialog
            message: "Message Sent Successfully. ", // Message dialog
            confirmLabel: "Ok", // Text button confirm
          });
          self.state.emailId = "";
          self.state.name = "";
          self.state.comments = "";
        },

        error: function (data) {
          confirmAlert({
            title: "Error", // Title dialog
            message: "Try Again Later", // Message dialog
            confirmLabel: "Ok", // Text button confirm
          });
        },
      });
    } else {
      confirmAlert({
        title: "Error", // Title dialog
        message: "Enter all the Fields", // Message dialog
        confirmLabel: "Ok", // Text button confirm /* col-xs-12 col-md-3 col-lg-3 ++++ col-xs-12 col-md-9 col-lg-9 */
      });
    }
  }

  CustomerBookServiceFunc() {
    ReactDOM.render(
      <Router>
        <div>
          <Route path="/" component={CustomerAppointments} />
        </div>
      </Router>,
      document.getElementById("root")
    );
  }
  render() {
    return (
      <div className=" landingpage">
        <div
          className="body_menu"
          id="myPage"
          data-spy="scroll"
          data-target="#myScrollspy"
          data-offset="60"
        >
          <div className="header">
            <nav className="navbar_main">
              <div className="nav_head_text">
                <h3 className="head_text">Garage App</h3>
              </div>

              <ul className="nav-menu">
                <li className="nav-item">
                  <a href="#home" className="nav-link">
                    Home
                  </a>
                </li>

                <li className="nav-item">
                  <a href="#about" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#features" className="nav-link">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#grid" className="nav-link">
                    Benefits
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#contact" className="nav-link">
                    Contact
                  </a>
                </li>

                <button
                  type="button"
                  onClick={() => this.login()}
                  className="modal-trigger login_btn  btn btn-outline-info loginbtn"
                >
                  Login
                </button>
                <a
                  href="GarageApp_3.5.apk"
                  className="btn-slide2 btn-donwload"
                  download="GarageApp_3.5.apk"
                >
                  <span className="circle2 cir-dow">
                    <i className="fa fa-download"></i>
                  </span>
                  <span className="title2">Download App</span>
                  <span className="title-hover2">Click here</span>
                </a>
              </ul>
              <div className="hamburger_btn">
                <a
                  href="GarageApp_3.5.apk"
                  className="btn-slide2 btn-donwload"
                  download="GarageApp_3.5.apk"
                >
                  <span className="circle2 cir-dow">
                    <i className="fa fa-download"></i>
                  </span>
                  <span className="title2">Download App</span>
                  <span className="title-hover2">Click here</span>
                </a>
              </div>
              <div className="hamburger">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </nav>
          </div>
          <br id="home" />
          <div className="landingpage_content">
            <div className="container-fluid sec_1_cont" id="section1">
              <section className="sect_1">
                <div className="row">
                  <div className="text-center ">
                    <div className="col-md-6">
                      <div className="main_heading ">
                        <h1 className="heading_sec">
                          Garage Management Software
                        </h1>

                        <div>
                          <p className="heading_para">
                            Get free bookings from customers and manage your
                            Garage Business in All in One Business Suite of
                            Application.
                          </p>
                        </div>
                        <div className="pull-left">
                          <div id="wrap">
                            <a
                              href="https://throughapps.com/book-a-demo/"
                              className="btn-slide"
                            >
                              <span className="circle">
                                <i className="fa fa-rocket"></i>
                              </span>
                              <span className="title">Book a Demo</span>
                              <span className="title-hover">Click here</span>
                            </a>
                            <a
                              href="#"
                              className="btn-slide"
                              onClick={() => this.CustomerBookServiceFunc()}
                            >
                              <span className="circle">
                                <i className="fa fa-rocket"></i>
                              </span>
                              <span className="title">Book a Service</span>
                              <span className="title-hover">Click here</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={main_image} className="main_image" />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div id="section2">
              <div
                className="container-fluid text-center"
                style={{ marginTop: "20px" }}
              >
                <h2 className="sub_heading" style={{ textAlign: "center" }}>
                  Our Clients
                </h2>

                <div className="row">
                  <div className="container ">
                    <ul className="client_sec ">
                      <li>
                        <img className="grid" src={client1} alt="client" />
                      </li>
                      <li>
                        <img className="grid" src={client2} alt="client" />
                      </li>
                      <li>
                        <img className="grid" src={client3} alt="client" />
                      </li>
                      <li>
                        <img className="grid" src={client4} alt="client" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className=" container-fluid feat_container">
              <div className="row">
                <div className="col-xll-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="card cardhover">
                    <div className="img_css">
                      <img src={time} className="feat_image" />
                    </div>
                    <h3 className="feat_title_head">Ease of Use</h3>
                    <p className="cardpara">
                      Garages Spend an average 40% of their time managing admin
                      activities.
                    </p>
                  </div>
                </div>

                <div className="col-xll-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="card cardhover">
                    <div className="img_css">
                      <img src={app} className="feat_image" />
                    </div>
                    <h3 className="feat_title_head">Web Progressive App</h3>
                    <p className="cardpara">
                      Accessible across any devices and data is synced across
                      all the devices
                    </p>
                  </div>
                </div>

                <div className="col-xll-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="card cardhover">
                    <div className="img_css">
                      <img src={calendar1} className="feat_image" />
                    </div>
                    <h3 className="feat_title_head">Service Calendar</h3>
                    <p className="cardpara">
                      Get all the current and future booking details in a neat
                      Service Calendar
                    </p>
                  </div>
                </div>

                <div className="col-xll-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="card cardhover">
                    <div className="img_css">
                      <img src={customerimg} className="feat_image" />
                    </div>
                    <h3 className="feat_title_head">Expand Customer</h3>
                    <p className="cardpara">
                      Interact with the Customers so easi ly via E-Mail and SMS
                      seamlessly
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br id="about" />
            <section>
              <div className="bannersec">
                <h2 className="sub_heading" style={{ color: "white" }}>
                  Why Garageapp?
                </h2>
                <h4
                  className="lead"
                  style={{
                    fontSize: "22px",
                    marginBottom: "30px",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                >
                  Everything you need to manage your Garage Smoothly .
                </h4>
                <div className="row section_row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <span className="banner_para">
                      <i className="fas fa-check-circle"></i>
                      Quick setup
                    </span>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Receive Online Payment
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Unlimited Invoices
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Unlimited Estimates
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row section_row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Track business expenses
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Create Sales Orders
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Unlimited Inventory & Vendors
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Financial Reporting
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row section_row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Multiple Locations Facility
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Integrated Payment Gateway
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Accessible across devices
                      </span>
                    </p>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <p>
                      <span className="banner_para">
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "white" }}
                        ></i>
                        Whatsapp Integration
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid content_sec" id="features">
              <div className="row justify-content-center">
                <div>
                  <div className="section-heading text-center mb-5">
                    <h2 className="sub_heading">
                      Features - Everything your business needs
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div
                    className="features-box-content bounceIn"
                    data-wow-duration="1.75s"
                    data-wow-offset="20"
                  >
                    <i
                      className="fas fa-calendar-check-o fa-2x icon_sec"
                      aria-hidden="true"
                    ></i>
                    <h3>Appointment Booking</h3>
                    <p className="para">
                      Our intuitive control panel gives you admin access to all
                      of your products and services. Easily update domain
                      information, add users, adjust email settings gain access.
                    </p>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box-content">
                    <i className="fas fa-file-alt fa-2x icon_sec"></i>
                    <h3>Invoice</h3>
                    <p className="para">
                      Create flexible & branded invoices for your workshop
                      operation in moments then save, print and share with your
                      customers via SMS, Email, Whatsapp. Bulk invoicing can be
                      done easily with Garage App
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box-content">
                    <i className="fas fa-tools fa-2x icon_sec"></i>
                    <h3>Inventory & Vendors</h3>
                    <p className="para">
                      Manage your Automobile vendors & spares list and get
                      overall visibility on complete sales including costs,
                      stock value, and multiple garage transfer.
                    </p>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box-content">
                    <i className="fas fa-truck-loading fa-2x icon_sec"></i>
                    <h3>Towing Service</h3>
                    <p className="para">
                      Any time your vehicle breaks down and you need a towing
                      vehicle at your location. Book a towing service at
                      garageapp.in.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <span>
                        <i className="fas fa-money-check fa-2x icon_sec"></i>
                        <h3>Expenses & Payments</h3>
                      </span>
                      <p className="para">
                        Add daily expenses and track your daily cash book. Fast,
                        accurate payments via Razorpay to keep you and the
                        Customer Happy!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <i className="fas fa-users fa-2x icon_sec"></i>
                      <h3>CRM</h3>
                      <p className="para">
                        Create memorable customer experiences with our system,
                        keep them coming back and increase your revenue by 30%.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <span>
                        <i className="far fa-address-card fa-2x icon_sec"></i>
                        <h3>Job Creation</h3>
                      </span>
                      <p className="para">
                        Create Job Card easily in a minute using our Job Card
                        module and it helps you to keep track of all the works
                        in progress
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <span>
                        <i className="fas fa-file-invoice-dollar icon_sec"></i>
                        <h3>Quick Estimation</h3>
                      </span>
                      <p className="para">
                        Build service estimates quickly with our in-built
                        catalog of OEM/ After sale parts and labour pricing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <span>
                        <i className="fas fa-car fa-2x icon_sec"></i>
                        <h3>Multi-garage Management</h3>
                      </span>
                      <p className="para">
                        Manage multiple garage locations in a single dashbaord.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="features-box p-4">
                    <div className="features-box-content">
                      <span>
                        <i className="fas fa-user-clock fa-2x icon_sec"></i>
                        <h3>Customer Enquiry Service </h3>
                      </span>
                      <p className="para">
                        Garages can create enquiry for unavailable items and
                        send reminders to customers once the product is
                        available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br id="grid" />
            <div className="container-fluid grid_sec" id="grid">
              <div className=" text-center mb-5">
                <h2 className="sub_heading">
                  Transform the Car Service Experience!
                </h2>
              </div>
              <div className="row">
                <div className="col-md-6 column_size">
                  <div className=" sect_1_4_con">
                    <h3 className="feat_title_sec1_4">
                      Turn your garage into a car magnet. Push the limits of
                      personalized service.
                    </h3>
                    <p className="text_para">
                      Our clean, Centralized Customer & Vehicle data helps you
                      to understand your customers better.
                    </p>
                    <p className="text_para">
                      Garage App’s advanced CRM tools create memorable customer
                      experiences and ensure they keep coming back. We have it
                      all from service bookings, automatic service reminders,
                      alerts, feedback requests, marketing campaigns, and
                      integrated payment solutions.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <img className="grid" src={image4} alt="App" />
                </div>
              </div>
            </div>
            <div className="container-fluid grid_sec">
              <div className=" text-center mb-5" id="">
                <h2 className="sub_heading">
                  Take full control of your Garage
                </h2>
              </div>
              <div className="row">
                <div className="col-md-6 column_size track-row-img">
                  <img className="grid" src={image3} alt="App" />
                </div>
                <div className="col-md-6 column_size1">
                  <div className=" sect_1_4_con1">
                    <h3 className="feat_title_sec1_4">
                      Build a reputable neighborhood garage. Garage App’s
                      in-built tools promote Transparency & Trust.
                    </h3>
                    <p className="text_para">
                      Generate quick, accurate repair estimations and build
                      trust with your Customers. Conduct detailed vehicle
                      condition checks and easily capture pictures of the
                      vehicle with dents, scratches, damages in the presence of
                      the Customer and avoid disputes.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <section className="video_bg">
                <div className="col-md-6 video_sec1">
                  <h2 className="sub_heading"> Boost your Invoicing</h2>

                  <p className="text_para">
                    Garages Spend an average 40% of their time managing admin
                    activities. Our software saves time & increase your revenue
                    by allowing you to focus more on business related
                    activities.
                  </p>
                  <div id="wrap">
                    <a
                      href="#"
                      className="btn-slide"
                      onClick={() => this.CustomerBookServiceFunc()}
                    >
                      <span className="circle">
                        <i className="fa fa-rocket"></i>
                      </span>
                      <span className="title">Book a Service</span>
                      <span className="title-hover">Click here</span>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <iframe
                    className="video_sec"
                    src="https://www.youtube.com/embed/a-IcidsPiMA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </section>
            </div>

            <div id="section4">
              <section id="contact">
                <div className="footer-section">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="res-center">
                          <img
                            className="Throughapp-logo"
                            src={Throughapplogo}
                          />
                          <ul className="footer-ul">
                            <li>
                              <img className="icons-sec" src={Addresslogo} />
                              <span>
                                264, Village High Road, <br />
                                Shollinganallur,
                                <br /> Chennai - 600119
                              </span>
                            </li>
                            <li>
                              <img className="icons-sec" src={calllogo} />
                              <span>+91 90031 14243</span>
                            </li>
                            <li>
                              <img className="icons-sec" src={maillogo} />
                              <span>sales@throughapps.com</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="res-center">
                          <h1 className="">Get In Touch</h1>
                          <p>Contact Us and we'll get back within 24 hours</p>
                          <a
                            href="GarageApp_3.5.apk"
                            download="GarageApp_3.5.apk"
                          >
                            <img className="Android-logo" src={Androidlogo} />
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="res-center">
                          <h3 className="font-wet">About Us</h3>
                          <h3 className="font-wet">Terms and Conditions</h3>
                          <h3 className="font-wet">Privacy Policy</h3>

                          <div className="rounded-social-buttons">
                            <a
                              className="social-button facebook"
                              href="https://www.facebook.com/"
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                            <a
                              className="social-button twitter"
                              href="https://www.twitter.com/"
                              target="_blank"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                            <a
                              className="social-button linkedin"
                              href="https://www.linkedin.com/"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                            <a
                              className="social-button youtube"
                              href="https://www.youtube.com/"
                              target="_blank"
                            >
                              <i className="fab fa-youtube"></i>
                            </a>
                            <a
                              className="social-button instagram"
                              href="https://www.instagram.com/"
                              target="_blank"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 copy-text">
                        Copyright © 2018 Throughapp Technologies. All Rights
                        Reserved
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage_tagarage;
