import React, { lazy, Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import $ from 'jquery';
import "react-table/react-table.css";
import {Scheduler,Toolbar,MonthView,WeekView,ViewSwitcher,Appointments,AppointmentTooltip,AppointmentForm,DragDropProvider,EditRecurrenceMenu,AllDayPanel,
  DateNavigator ,
  TodayButton,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState, EditingState, DayView, IntegratedEditing, SelectOption  } from '@devexpress/dx-react-scheduler';

import ReactTable from "react-table";
import ReactApexChart from 'react-apexcharts';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// Import Css

import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, } from "reactstrap";
import _ from 'underscore';
import CryptoJS from 'crypto-js';
// import calendericon from './image/calender1.png';
import calenderconfirm from '../../image/confirm.png';
import calenderreschedule from '../../image/reschedule.png';
import cancel from '../../image/cancel1.png';
import calendertoday from '../../image/today.png';
import calendericon from '../../image/calender1.png'
import { BackButtonComponent } from '../../ServiceRegistration/ButtonComponent';
import LoadingComponent from '../../Loading/LoadingComponent';

const DashboardOverall = lazy(() => import('../../MaincontentDashboard/DashboardOverall'));

class AppoinmentDashboard extends Component {
constructor(){
    super()
    this.state={

      RecentData : [],
      RecentColumns: [{
        Header: "SNo",
        accessor:"SNo"
      },
      {
        Header: "Name",
        accessor:"Name"
      },
      {
        Header: "Contact No",
        accessor:"Contact"
      },
      {
        Header: "Booking Date",
        accessor:"Date"
      },
      {
        Header: "Appoinment Date",
        accessor:"Date2"
      },
      {
        Header: "",
        accessor:"Indicator"
      },
    
      ],
    
        TodayData : [],
        TodayColumns: [{
          Header: "SNo",
          accessor:"SNo"
        },
        {
          Header: "Name",
          accessor:"Name"
        },
        {
          Header: "Contact No",
          accessor:"Contact"
        },
        {
          Header: "Booking Date",
          accessor:"BookingDate"
        },
        {
            Header: "Appoinment Date",
             accessor:"AppoinmentDate"
          },
          {
            Header: " ",
            accessor:"Indicator"
          },
      
        ],
        MostCustomerData : [],
        MostCustomerColumns: [{
          Header: "SNo",
          accessor:"SNo"
        },
        {
          Header: "Name",
          accessor:"Name"
        },
        {
          Header: "Contact No",
          accessor:"Contact"
        },
       
      
        ],

        FutureData : [],
        FutureColumns: [{
          Header: "SNo",
          accessor:"SNo"
        },
        {
          Header: "Name",
          accessor:"Name"
        },
        {
          Header: "Contact No",
          accessor:"Contact"
        },
        {
          Header: "Booking Date",
          accessor:"Date"
        },
        {
          Header: "Appoinment Date",
          accessor:"Date2"
        },
        {
          Header: "",
          accessor:"Indicator"
        },
      
        ],
        series: [44, 55, 41],
        options: {
          chart: {
            type: 'donut',
          },
          labels: ['Call', 'Message', 'Walkin'],

          responsive: [{
            breakpoint: 480,
            options: {
              // chart: {
              //   width: 100
              // },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
    }
}

componentDidMount() {

  var self=this;

  var empSites = CryptoJS.AES.decrypt(localStorage.getItem('EmpSites'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
  var currentSite = CryptoJS.AES.decrypt(localStorage.getItem('CurrentSite'), "shinchanbaby").toString(CryptoJS.enc.Utf8);
  var emparray = empSites.split(",");
  this.state.siteOptions = _.map(emparray, function (site) { return { label: site, value: site }; });
  this.state.siteOptions.push({ label: "All", value: "All" });
  this.setState({
    siteOptions: this.state.siteOptions
  })
  var RecentDataList = [
    {
      sNo: '1',
      Name: 'Raju',
      Contact: '988989898',
      date: '2022-06-18',
      appoinmentdate: '2022-03-19',
      indicator:'Confirmed'

    },
    {
      sNo: '2',
    Name: 'Bhuvana',
      Contact: '9790302323',
      date: '2022-06-19',
      appoinmentdate: '2022-06-20',
      indicator:'Confirmed'
    },
    {
      sNo: '3',
      Name: 'Karthi',
      Contact: '9823982398',
      date: '2022-06-20',
      appoinmentdate: '2022-06-23',
      indicator:'Confirmed'
    }
  ];

  var  TodayDataList = [
    {
      sNo: '1',
      Name: 'Revathi',
      Contact: '988989898',
      date: '2022-06-25',
      appoinmentdate: '2022-06-25',
      indicator:'Rescheduled'

    },
    {
      sNo: '2',
    Name: 'Anushka',
      Contact: '9790302323',
      date: '2022-03-25',
      appoinmentdate: '2022-03-24',
      indicator:'Confirmed'
    },
    {
      sNo: '3',
      Name: 'Yash',
      Contact: '9823982398',
      date: '2022-06-20',
      appoinmentdate: '2022-06-19',
      indicator:'Cancelled'
    }
  ];

  var  FutureDataList = [
    {
      sNo: '1',
      Name: 'Revathi',
      Contact: '988989898',
      date: '2022-06-18',
      appoinmentdate: '2022-06-19',
      indicator:'Rescheduled'

    },
    {
      sNo: '2',
    Name: 'Anushka',
      Contact: '9790302323',
      date: '2022-06-19',
      appoinmentdate: '2022-06-19',
      indicator:'Confirmed'
    },
    {
      sNo: '3',
      Name: 'Yash',
      Contact: '9823982398',
      date: '2022-06-20',
      appoinmentdate: '2022-06-19',
      indicator:'Cancelled'
    }
  ];

  var  MostCustomerDataList = [
    {
      sNo: '1',
      Name: 'Revathi',
      Contact: '988989898',
      

    },
    {
      sNo: '2',
    Name: 'Anushka',
      Contact: '9790302323',
     
    },
    {
      sNo: '3',
      Name: 'Yash',
      Contact: '9823982398',
  
    }
  ];

  $.each(RecentDataList, function (i, item) {

    self.state.RecentData[i] = {
      "SNo": item.sNo,
      "Name": item.Name,
      "Contact": item.Contact,
      "Date": item.date,
      "Date2": item.appoinmentdate,
      "Indicator": item.indicator
    }
  })

  $.each(FutureDataList, function (i, item) {

    self.state.FutureData[i] = {
      "SNo": item.sNo,
      "Name": item.Name,
      "Contact": item.Contact,
      "Date": item.date,
      "Date2": item.appoinmentdate,
      "Indicator": item.indicator
    }
  })
  
  $.each(MostCustomerDataList, function (i, item) {

    self.state.MostCustomerData[i] = {
      "SNo": item.sNo,
      "Name": item.Name,
      "Contact": item.Contact,
     
    }
  })
  
  
  $.each(TodayDataList, function (i, item) {

    self.state.TodayData[i] = {
      "SNo": item.sNo,
      "Name": item.Name,
      "Contact": item.Contact,
      "BookingDate": item.date,
      "AppoinmentDate": item.appoinmentdate,
      "Indicator": item.indicator
    }
  })


 
  self.setState({
    RecentData:[...self.state.RecentData],
    TodayData:[...self.state.TodayData],
    MostCustomerData:[...self.state.MostCustomerData],
    FutureData:[...self.state.FutureData],


    
  })


}



    BackbtnFunc() {
        ReactDOM.render(
          <Router>
            <Suspense fallback={<LoadingComponent />} >
              <div>
                <Route path="/" component={DashboardOverall} />
              </div>
            </Suspense>
          </Router>,
          document.getElementById('contentRender'));
      }

    render() {
        return (

            <div>

            <div class="container-fluid">
              <div className="">
                <div className="">
                  <BackButtonComponent name={"Dashboard"} click={() => this.BackbtnFunc()} />
                </div>
                <div className="inv_HeaderCls">
                  <h3>Appoinment Dashboard</h3>
                </div>
            </div>

            <Row style={{marginBottom:'20px'}}>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                <h4 class="tiles_text">Total Appoinment</h4>
                  <Row>
                    <div class="col-md-4">
                     <h4>5</h4>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                    <div class="inventory_icon" style={{ }}>
                    <img src={calendericon} style={{ padding: '10px',width:'60px' }} />
                       </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
             
              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                <h4 class="tiles_text">Confirmed Appoinments</h4>
                  <Row>
                    <div class="col-md-4">
                    <h4>20</h4>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                    <div class="inventory_icon" style={{  }}>
                    <img src={calenderconfirm} style={{ padding: '10px',width:'60px'  }} />
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
            <div class="col-md-4">
              <Card className="card-stats">
                <CardBody>
                <h4 class="tiles_text">Cancelled Appoinments</h4>
                  <Row>
                    <div class="col-md-4">
                      <h4>20</h4>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                    <div class="inventory_icon" style={{  }}>
                    <img src={cancel} style={{ padding: '10px' ,width:'60px' }} />
                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
             
            
          </Row>
          <Row>
         
              <div class="col-md-4">

              <Card className="card-stats">
                <CardBody>
                <h4 class="tiles_text">Rescheduled Appoinments</h4>
                  <Row>
                    <div class="col-md-4">
                      <h4>10</h4>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                    <div class="inventory_icon" style={{  }}>
                    <img src={calenderreschedule} style={{ padding: '10px' ,width:'60px' }} />

                      </div>
                    </div>
                  </Row>
                  <CardFooter>
                    <hr />
                  </CardFooter>
                </CardBody>
              </Card>
            </div>
            <div class="col-md-4">

            <Card className="card-stats">
                <CardBody>
                <h4 class="tiles_text">Today's Appoinments</h4>
                    <Row>
                    <div class="col-md-4">
                        <h4>10</h4>
                    </div>
                    <div class="col-md-8" style={{ display: 'flex', justifyContent: 'end', marginTop: '-20px' }} >
                    <div class="inventory_icon" style={{  }}>
                    <img src={calendertoday} style={{ padding: '10px' ,width:'60px' }} />

                        </div>
                    </div>
                    </Row>
                    <CardFooter>
                    <hr />
                    </CardFooter>
                </CardBody>
                </Card>
                </div>
          </Row>
{/* table begins */}
          <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Recent Appoinments</h4>
              </div>
                <ReactTable style={{ overflow: "auto",borderRadius:'10px' }}
                        data={this.state.RecentData}
                        columns={this.state.RecentColumns}
                        noDataText="No Data Available"
                        filterable
                        defaultPageSize={10}
                        className="-highlight"
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                            : true;
                        }}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                       // getTdProps={this.onTrRowClick}
                    />
              </div>
              <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Mode of Appoinments</h4>
              </div>
              <div class="card"style={{height:'430px'}}>

<div id="chart" style={{width:'450px',position:'absolute',padding:'80px 0px 80px 0px'}}>
<ReactApexChart options={this.state.options} series={this.state.series} type="donut"  />
</div>
</div>
              </div>
             
                    </Row>

                    <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Today's Appoinments</h4>
              </div>
                <ReactTable style={{ overflow: "auto",borderRadius:'10px' }}
                        data={this.state.TodayData}
                        columns={this.state.TodayColumns}
                        noDataText="No Data Available"
                        filterable
                        defaultPageSize={10}
                        className="-highlight"
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                            : true;
                        }}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                       // getTdProps={this.onTrRowClick}
                    />
              </div>
              <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Frequent Booking Customer</h4>
              </div>
                <ReactTable style={{ overflow: "auto",borderRadius:'10px' }}
                        data={this.state.MostCustomerData}
                        columns={this.state.MostCustomerColumns}
                        noDataText="No Data Available"
                        filterable
                        defaultPageSize={10}
                        className="-highlight"
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                            : true;
                        }}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                       // getTdProps={this.onTrRowClick}
                    />
              </div>
             
                    </Row>

                    <Row>
            <div class="col-md-6">
              <div>
                <h4 class="tiles_text">Future Appoinments</h4>
              </div>
                <ReactTable style={{ overflow: "auto",borderRadius:'10px' }}
                        data={this.state.FutureData}
                        columns={this.state.FutureColumns}
                        noDataText="No Data Available"
                        filterable
                        defaultPageSize={10}
                        className="-highlight"
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id;
                        return row[id] !== undefined
                            ? String(row[id])
                            .toLowerCase()
                            .indexOf(filter.value.toLowerCase()) !== -1
                            : true;
                        }}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                       // getTdProps={this.onTrRowClick}
                    />
              </div>
              <div class="col-md-6">
           
            
              </div>
             
                    </Row>

            </div>
            </div>
        );
    }
}

export default AppoinmentDashboard;
