//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import $ from "jquery";
import CryptoJS from "crypto-js";
import _ from "underscore";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SelectSearch from "react-select";

//IMPORT REACT COMPONENT CSS
import "sweetalert2/src/sweetalert2.scss";
import "react-table/react-table.css";
import "react-widgets/dist/css/react-widgets.css";

//IMPORT CLASS COMPONENT
import { vehicleRegNo_NumberValidation } from "./ValidationComponent";
import SubmitButtonComponent from "./ButtonComponent";
import {
  ClearButtonComponent,
  CancelButtonComponent,
  AddButtonComponent,
  UpdateButtonComponent,
  SubmitProceedButtonComponent,
} from "./ButtonComponent";
import { GetEmployeeSite, GetCurrentSite } from "../ConstSiteFunction";

var customerArray = [];
var vehicleMakeModelArray = [];

export default class VehicleComponent extends Component {
  constructor(props) {
    super(props);
    var companyId = CryptoJS.AES.decrypt(
      localStorage.getItem("CompanyId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var staffId = CryptoJS.AES.decrypt(
      localStorage.getItem("staffId"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var employeeName = CryptoJS.AES.decrypt(
      localStorage.getItem("EmployeeName"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);
    var role = CryptoJS.AES.decrypt(
      localStorage.getItem("Role"),
      "shinchanbaby"
    ).toString(CryptoJS.enc.Utf8);

    this.state = {
      companyId: companyId,
      staffId: staffId,
      employeeName: employeeName,
      role: role,
      customerNamereadonly: true,
      vehicleRegNo: "",
      vehcileMake: "",
      vehicleModel: "",
      vehcileFuelType: "",
      contactNo: "",
      customerId: "",

      selectedVehicleMake: "",
      selectedVehicleModel: "",
      selectedFuelType: "",
      customerArray: [],
      module:props.pageCalledFrom,
    };

    this.AddVehicleSubmitFunc = this.AddVehicleSubmitFunc.bind(this);
    this.CancelVehicleFunc = this.CancelVehicleFunc.bind(this);
    this.ClearVehicleFunc = this.ClearVehicleFunc.bind(this);
  }

  componentDidMount() {
    //console.log("VEHICLE INFO DATA stateData :",this.props.stateData);

    this.GetData();

    //console.log("this.state.customerArray :",this.state.customerArray);
  }

  GetData() {
    var self = this;
    $.ajax({
      type: "POST",
      data: JSON.stringify({
        companyId: this.state.companyId,
        empSites: GetEmployeeSite(),
      }),
      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/QuickLinkWebservices/GetCustVehicleBasicDetails",
      contentType: "application/json",
      dataType: "json",
      success: function (data, textStatus, jqXHR) {
        //console.log("datat", data);

        self.AssignData_Fields(data);
      },
      error: function (data, textStatus, jqXHR) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Network Connection Problem",
          showConfirmButton: false,
          timer: 2000,
        });
      },
    });
  }

  AssignData_Fields(data) {
    var self = this;

    this.state.customerArray = [];
    customerArray = [];
    vehicleMakeModelArray = [];

    this.state.customerArray = data.customerList;
    this.setState({
      customerList: this.state.customerList,
    });

    customerArray = data.customerList;
    vehicleMakeModelArray = data.vehicleMakeModelList;

    if (this.props.stateData != undefined) {
      if (this.props.stateData.customerName != undefined) {
        //console.log("STATE DATA :",this.props.stateData);
        //console.log("TASK OPTIONS :",this.props.stateData.customerName);

        this.state.contactNo = this.props.stateData.contactNo;

        var customerDetails = _.findWhere(customerArray, {
          contactNo: this.props.stateData.contactNo,
        });
        var customerInfo = {
          label: customerDetails.contactNo + " " + customerDetails.customerName,
          value: customerDetails.customerId,
        };

        this.state.selectedCustomerName = customerInfo;
        this.state.customerName = this.props.stateData.customerName;
        this.state.selectedCustomerId = customerDetails.customerId;

        this.setState({
          selectedCustomerName: this.state.selectedCustomerName,
          customerName: this.state.customerName,
          contactNo: this.state.contactNo,
          selectedCustomerId: this.state.selectedCustomerId,
        });
      }
    }

    var subVehicleMakeArray = _.uniq(vehicleMakeModelArray, "vehicleMake");
    var subVehicleModelArray = _.uniq(vehicleMakeModelArray, "vehicleModel");
    var subVehicleFuelTypeArray = _.uniq(
      vehicleMakeModelArray,
      "vehicleFuelType"
    );

    self.state.customerOptions = [];
    self.state.vehicleMakeOptions = [];
    self.state.vehicleModelOptions = [];
    self.state.vehicleFuelTypeOptions = [];

    var customerOptions = [];
    $.each(data.customerList, function (i, item) {
      // self.state.customerList.push(item.contactNo+" - "+item.customerName);
      customerOptions.push({
        label: item.contactNo + " " + item.customerName,
        value: item.customerId,
      });
    });
    self.state.customerOptions = customerOptions;
    self.setState({
      customerOptions: self.state.customerOptions,
    });

    var vehicleMakeOptions = [];
    $.each(subVehicleMakeArray, function (i, item) {
      //self.state.vehcileMakeList.push(item.vehicleMake);
      vehicleMakeOptions.push({
        label: item.vehicleMake,
        value: item.vehicleMake,
      });
    });
    self.state.vehicleMakeOptions = vehicleMakeOptions;
    self.setState({
      vehicleMakeOptions: self.state.vehicleMakeOptions,
    });

    var vehicleModelOptions = [];
    $.each(subVehicleModelArray, function (i, item) {
      //  self.state.vehicleModelList.push(item.vehicleModel);
      vehicleModelOptions.push({
        label: item.vehicleModel,
        value: item.vehicleModel,
      });
    });
    self.state.vehicleModelOptions = vehicleModelOptions;
    self.setState({
      vehicleModelOptions: self.state.vehicleModelOptions,
    });

    var vehicleFuelTypeOptions = [];
    $.each(subVehicleFuelTypeArray, function (i, item) {
      //self.state.vehcileFuelTypeList.push(item.vehicleFuelType);
      vehicleFuelTypeOptions.push({
        label: item.vehicleFuelType,
        value: item.vehicleFuelType,
      });
    });
    self.state.vehicleFuelTypeOptions = vehicleFuelTypeOptions;
    self.setState({
      vehicleFuelTypeOptions: self.state.vehicleFuelTypeOptions,
    });

    if (self.state.contactNo != "") {
      var customerName = _.where(customerArray, {
        contactNo: this.state.contactNo,
      });

      this.state.selectedCustomerId = customerName[0].customerId;
      this.setState({
        selectedCustomerId: this.state.selectedCustomerId,
      });
    }
  }

  ClearVehicleFunc() {
    this.state.customerId = "";
    this.state.customerName = "";
    this.state.vehicleRegNo = "";
    this.state.vehcileMake = "";
    this.state.vehicleModel = "";
    this.state.vehcileFuelType = "";
    this.state.contactNo = "";

    this.state.selectedCustomerName = "";
    this.state.selectedVehicleMake = "";
    this.state.selectedVehicleModel = "";
    this.state.selectedFuelType = "";

    this.setState({
      customerId: "",
      customerName: "",
      vehicleRegNo: "",
      vehcileMake: "",
      vehicleModel: "",
      vehcileFuelType: "",
      contactNo: "",

      selectedCustomerName: "",
      selectedVehicleMake: "",
      selectedVehicleModel: "",
      selectedFuelType: "",
    });
  }

  AddVehicleSubmitFunc() {
    var self = this;
// alert("this.state.module :"+this.state.module);
    /*console.log("ADD VEHCUILE DATA :",JSON.stringify({
        companyId: this.state.companyId,
        customerId:this.state.selectedCustomerId,
        customerName:this.state.customerName,
        vehicleRegNo:this.state.vehicleRegNo,
        vehicleMake:this.state.vehicleMake,
        vehicleModel:this.state.vehicleModel,
        vehicleFuelType:this.state.vehicleFuelType,

    }));
    */

    if (
      this.state.selectedCustomerId != "" &&
      this.state.vehicleRegNo != "" &&
      this.state.vehicleMake != "" &&
      this.state.vehicleModel != "" &&
      this.state.vehicleFuelType != ""
    ) {
      $.ajax({
        type: "POST",
        data: JSON.stringify({
          companyId: this.state.companyId,
          customerId: this.state.selectedCustomerId,
          customerName: this.state.customerName,
          vehicleRegNo: this.state.vehicleRegNo,
          vehicleMake: this.state.vehicleMake,
          vehicleModel: this.state.vehicleModel,
          vehicleFuelType: this.state.vehicleFuelType,
          staffId: this.state.staffId,
          employeeName: this.state.employeeName,
          role: this.state.role,
          site: GetCurrentSite(),
          empSites: GetEmployeeSite(),
          module:this.state.module,
        }),
        // url: "https://wildfly.garageapp.in:443/GarageAppIN_API/enquiry/AddEnquiry",
        url: "https://wildfly.garageapp.in:443/GarageAppIN_API/QuickLinkWebservices/AddVehicleDetails",
        contentType: "application/json",
        dataType: "json",
        async: false,

        success: function (data, textStatus, jqXHR) {
          console.log("BASIC DATA :", data);

          if (data.response == "Success") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Added Vehicle Successfully",
              showConfirmButton: false,
              timer: 2000,
            });

            self.props.SubmitClicked(data);
            self.ClearVehicleFunc();
          } else if (data.response == "Duplicate") {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: "Vehicle RegistrationNo Already Exist",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        },
        error: function (data) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Network Connection Problem",
            showConfirmButton: false,
            timer: 2000,
          });
        },
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Kindly fill all mandatory fields to proceed",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  CancelVehicleFunc() {
    this.ClearVehicleFunc();

    this.props.CancelClicked();
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    //  var capitalCaseData=CapitalCaseFunc(value);

    var res = value.toUpperCase();

    this.state[name] = res;
    this.setState({ [name]: res });
  };

  handleUserInputVehicleRegNo = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    //  var capitalCaseData=CapitalCaseFunc(value);
    var validationData = vehicleRegNo_NumberValidation(value);

    if (validationData == true) {
      var res = value.toUpperCase();

      this.state[name] = res;
      this.setState({ [name]: res });
    } else {
      $("#vehicleRegNoErrorMsgAlert").append("! Incorrect value");
    }
    HideFieldErroeMsgs("vehicleRegNoErrorMsgAlert");
  };

  handleCustomerNameDetails = (e) => {
    var self = this;
    const name = e.name;
    const value = e.value;

    this.state.customerName = "";
    this.state.selectedCustomerId = "";
    this.state.emailId = "";

    this.state.selectedVehicleMake = "";
    this.state.selectedVehicleModel = "";
    this.state.selectedFuelType = "";

    this.state.vehicleRegNo = "";
    this.state.vehicleMake = "";
    this.state.vehicleFuelType = "";

    this.setState({
      customerName: this.state.customerName,
      selectedCustomerId: this.state.selectedCustomerId,
      emailId: this.state.emailId,
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedFuelType: this.state.selectedFuelType,
      vehicleRegNo: this.state.vehicleRegNo,
      vehicleMake: this.state.vehicleMake,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    this.state.selectedCustomerId = value;
    this.state.selectedCustomerName = e;

    var customerName = _.where(customerArray, {
      customerId: this.state.selectedCustomerId,
    });

    this.state.selectedCustomerId = customerName[0].customerId;
    this.state.customerName = customerName[0].customerName;
    this.state.emailId = customerName[0].emailId;
    this.state.customerNamereadonly = true;
    this.state.emailIdreadonly = true;

    this.setState({
      customerName: this.state.customerName,
      customerNamereadonly: this.state.customerNamereadonly,
      emailIdreadonly: this.state.emailIdreadonly,
      emailId: this.state.emailId,
      selectedCustomerId: this.state.selectedCustomerId,
    });
  };

  handleVehicleMakeDetails = (e) => {
    var self = this;
    const name = e.name;
    const value = e.value;

    //console.log("E VALUE :",e);
    this.state.selectedVehicleMake = e;
    this.state.selectedVehicleModel = "";
    this.state.selectedFuelType = "";

    this.state.vehicleMake = value;
    this.state.vehicleModel = "";
    this.state.vehicleFuelType = "";

    this.setState({
      selectedVehicleMake: this.state.selectedVehicleMake,
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedFuelType: this.state.selectedFuelType,

      vehicleMake: this.state.vehicleMake,
      vehicleModel: this.state.vehicleModel,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    var vehicleModelDetails = _.where(vehicleMakeModelArray, {
      vehicleMake: this.state.vehicleMake,
    });
    var subVehicleModelArray = _.uniq(vehicleModelDetails, "vehicleModel");

    self.state.vehicleModelOptions = [];
    var vehicleModelOptions = [];
    $.each(subVehicleModelArray, function (i, item) {
      vehicleModelOptions.push({
        label: item.vehicleModel,
        value: item.vehicleModel,
      });
    });

    self.state.vehicleModelOptions = vehicleModelOptions;
    self.setState({
      vehicleModelOptions: self.state.vehicleModelOptions,
    });

    var vehicleFuelTypeDetails = _.where(vehicleMakeModelArray, {
      vehicleMake: this.state.vehicleMake,
    });
    var subVehicleFuelTypeArray = _.uniq(
      vehicleFuelTypeDetails,
      "vehicleFuelType"
    );

    self.state.vehicleFuelTypeOptions = [];
    var vehicleFuelTypeOptions = [];
    $.each(subVehicleFuelTypeArray, function (i, item) {
      vehicleFuelTypeOptions.push({
        label: item.vehicleFuelType,
        value: item.vehicleFuelType,
      });
    });

    self.state.vehicleFuelTypeOptions = vehicleFuelTypeOptions;
    self.setState({
      vehicleFuelTypeOptions: self.state.vehicleFuelTypeOptions,
    });
  };

  handleVehicleModelDetails = (e) => {
    var self = this;
    const name = e.name;
    const value = e.value;

    //console.log("E VALUE :",e);
    this.state.selectedVehicleModel = e;
    this.state.selectedFuelType = "";

    this.state.vehicleModel = e.value;
    this.state.vehicleFuelType = "";

    this.setState({
      selectedVehicleModel: this.state.selectedVehicleModel,
      selectedFuelType: this.state.selectedFuelType,

      vehicleModel: this.state.vehicleModel,
      vehicleFuelType: this.state.vehicleFuelType,
    });

    var vehicleFuelTypeDetails = _.where(vehicleMakeModelArray, {
      vehicleModel: this.state.vehicleModel,
    });
    var subVehicleFuelTypeArray = _.uniq(
      vehicleFuelTypeDetails,
      "vehicleFuelType"
    );

    self.state.vehicleFuelTypeOptions = [];
    var vehicleFuelTypeOptions = [];
    $.each(subVehicleFuelTypeArray, function (i, item) {
      vehicleFuelTypeOptions.push({
        label: item.vehicleFuelType,
        value: item.vehicleFuelType,
      });
    });

    self.state.vehicleFuelTypeOptions = vehicleFuelTypeOptions;
    self.setState({
      vehicleFuelTypeOptions: self.state.vehicleFuelTypeOptions,
    });
  };

  handleVehicleFuelTypeDetails = (e) => {
    var self = this;
    const name = e.name;
    const value = e.value;

    //console.log("E VALUE :",e);
    this.state.selectedFuelType = e;

    this.state.vehicleFuelType = e.value;

    this.setState({
      selectedFuelType: this.state.selectedFuelType,

      vehicleFuelType: this.state.vehicleFuelType,
    });
  };

  render() {
    return (
      <div>
        <form>
          <div className="mandatory_feilds">
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label
                  className="control-label selectpicker "
                  for="customerName"
                >
                  Contact No
                </label>
                <span className="mandatory-fields">*</span>
                <SelectSearch
                  options={this.state.customerOptions}
                  value={this.state.selectedCustomerName}
                  id="customerName"
                  onChange={(e) => this.handleCustomerNameDetails(e)}
                  name="customerName"
                  placeholder="Select Customer"
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label
                  className="control-label "
                  style={{ fontWeight: "bold" }}
                  for="vehicleRegistrationNo"
                >
                  Customer Name<span className="mandatory-fields">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    placeholder="Customer Name"
                    name="customerName"
                    onChange={this.handleUserInputCustomerName}
                    readOnly={this.state.customerNamereadonly}
                    value={this.state.customerName}
                    className="form-control"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label
                  className="control-label selectpicker "
                  for="customerName"
                >
                  Vehicle RegNo
                </label>
                <span className="mandatory-fields">*</span>

                <input
                  type="text"
                  className="form-control"
                  style={{ color: "black" }}
                  id="vehicleRegNo"
                  name="vehicleRegNo"
                  value={this.state.vehicleRegNo}
                  onChange={this.handleUserInputVehicleRegNo}
                  placeholder="Vehicle Reg No"
                />
                <span
                  id="vehicleRegNoErrorMsgAlert"
                  className="mandatory-fields"
                ></span>
              </div>
              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label
                  className="control-label selectpicker"
                  for="vehicleRegistrationNo"
                >
                  Vehicle Make
                </label>
                <span className="mandatory-fields">*</span>
                <SelectSearch
                  options={this.state.vehicleMakeOptions}
                  value={this.state.selectedVehicleMake}
                  id="customerName"
                  onChange={(e) => this.handleVehicleMakeDetails(e)}
                  name="selectedVehicleMake"
                  placeholder="Select Vehicle Make"
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label className="control-label " for="bookingId">
                  Vehicle Model
                </label>
                <span className="mandatory-fields">*</span>
                <SelectSearch
                  options={this.state.vehicleModelOptions}
                  value={this.state.selectedVehicleModel}
                  id="customerName"
                  onChange={(e) => this.handleVehicleModelDetails(e)}
                  name="selectedVehicleModel"
                  placeholder="Select Vehicle Model"
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-lg-4 ">
                <label className="control-label " for="bookingId">
                  Fuel Type
                </label>
                <span className="mandatory-fields">*</span>
                <SelectSearch
                  options={this.state.vehicleFuelTypeOptions}
                  value={this.state.selectedFuelType}
                  id="customerName"
                  onChange={(e) => this.handleVehicleFuelTypeDetails(e)}
                  name="selectedFuelType"
                  placeholder="Select FuelType"
                />
              </div>
            </div>
          </div>
        </form>

        <div className="text-center mt-20">
          <SubmitButtonComponent onClick={this.AddVehicleSubmitFunc} />
          <CancelButtonComponent onClick={this.CancelVehicleFunc} />
          <ClearButtonComponent onClick={this.ClearVehicleFunc} />
        </div>
      </div>
    );
  }
}

export const vehicleDefaultData = function (stateData, currentState) {
  //console.log("VEHICLE DEFAULT DATA :",stateData)

  var self = currentState;

  self.state.customerName = stateData.customerName;

  self.setState({
    customerName: self.state.customerName,
  });
};

/*
  USED TO HIDE THE ERROR MESSAGES OF THE FIELDS DISPLAYED
  */
function HideFieldErroeMsgs(fieldId) {
  setTimeout(function () {
    $("#" + fieldId).empty();
  }, 4000);
}
