//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { lazy, Component, Suspense } from "react";
import ReactDOM from "react-dom";
import Timekeeper from "react-timekeeper";
import Select from "react-select";
import _ from "underscore";
import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.js";
import datepicker from "jquery-ui/ui/widgets/datepicker";
import { NetworkErrorComponent } from "../components/Assets_Components/MessagePage_Components/MessagePage_Components";

//IMPORT REACT COMPONENT CSS
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./datepicker.css";
import "react-table/react-table.css";
import "sweetalert2/src/sweetalert2.scss";
import "./datepicker.css";

//IMPORT CLASS COMPONENT CSS
import "./ReactTableCSS.css";
import "./AddAppointment.css";

//IMPORT CLASS COMPONENT
import { SetCurrentPage } from "./ConstSiteFunction";
import LoadingComponent from "./Loading/LoadingComponent";
import { BackButtonComponent } from "./ServiceRegistration/ButtonComponent";

const LandingPage_tagarage = lazy(() =>
  import("./LandingPage_tagarage/LandingPage_tagarage")
);

var serviceArray = [];
var employeeArray = [];
var serviceListArray = [];
var serviceTimeArray = [];
var appointmentArray = [];

class CustomerAppointments extends Component {
  constructor() {
    super();

    var today = new Date();

    var date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    // var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var currentTime = today.getHours() + ":" + today.getMinutes();

    this.state = {
      modalIsOpen: false,
      currentDate: date,
      data: [],
      columns: [],
      timekeeperData: false,
      time: currentTime,
      appointmentTime: currentTime,

      customerName: "",
      mobileNo: "",
      gender: "",
      appointmentDate: "",
      employeeDetails: "",
      action: "Done",
    };
    this.setState({
      currentDate: date,
    });
  }

  componentDidMount() {
    SetCurrentPage("CustomerAppointments");
    window.scrollTo(0, 0);
    var self = this;
    //  $("#timekeeperdiv").hide();
    $("#myModal1").hide();
    $(".btn-default").css("background-color", "#263eac");
    $(".btn-default").css("color", "white");
    $("#appointmentdate").datepicker({
      onSelect: function (date) {
        var dt = new Date(date);
        self.state.appointmentDate = date;

        self.setState({
          appointmentDate: self.state.appointmentDate,
        });
      },

      dateFormat: "yy-mm-dd",
      minDate: "0",
      maxDate: "1M",
      numberOfMonths: 1,
    });

    var genderArray = [];
    genderArray.push({ label: "Male", value: "Male" });
    genderArray.push({ label: "Female", value: "Female" });
    genderArray.push({ label: "Other", value: "Other" });
    this.state.genderOptions = genderArray;
    this.setState({
      genderOptions: this.state.genderOptions,
    });

    this.GetSaloonDetails();
  }

  GetSaloonDetails() {
    var self = this;

    $.ajax({
      type: "POST",
      data: JSON.stringify({
        date: this.state.currentDate,
      }),

      url: "https://wildfly.garageapp.in:443/GarageAppIN_API/Appointments/GetAllGarageDetails",
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (data, textStatus, jqXHR) {
        self.state.modalIsOpen = false;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
        var saloonArray = [];
        serviceArray = [];
        employeeArray = [];
        appointmentArray = [];

        $.each(data.saloonDetails, function (i, item) {
          saloonArray.push({
            label: item.companyName + " - " + item.site + " - " + item.area,
            value: item.companyId,
            site: item.site,
            comp_ContactNo: item.comp_ContactNo,
            companyName: item.companyName,
          });
        });

        serviceArray = data.serviceDetails;
        employeeArray = data.employeeDetails;
        appointmentArray = data.appointmentDetails;

        self.state.saloonOptions = saloonArray;

        self.setState({
          saloonOptions: self.state.saloonOptions,
        });
      },
      error: function (data) {
        self.state.modalIsOpen = true;
        self.setState({
          modalIsOpen: self.state.modalIsOpen,
        });
      },
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^A-Za-z]/g, "");
    this.setState({
      [name]: value,
    });
  };

  handleUserInputMobileNo = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^0-9.,]+/, "");
    this.setState({
      [name]: value,
    });
  };

  handleChangeSelectedGenderOption = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.genderOption = e;
    this.state.gender = e.value;
    this.setState({
      genderOption: this.state.genderOption,
      gender: this.state.gender,
    });
  };

  handleChangeSelectedSaloonOption = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.saloonOption = e;
    var companyId = e.value;
    var site = e.site;
    var companyName = e.companyName;
    var comp_ContactNo = e.comp_ContactNo;

    this.setState({
      saloonOption: this.state.saloonOption,
      companyId: companyId,
      site: site,
      companyName: companyName,
      comp_ContactNo: comp_ContactNo,
    });

    console.log("handleChangeSelectedSaloonOption :", e);

    this.state.serviceOption = [];
    this.state.employeeOption = "";

    serviceListArray = [];

    var currentServiceArray = [];
    var serviceArrayOptions = [];
    var currentLocationServiceArray = [];

    console.log("serviceArray :", serviceArray);

    currentServiceArray = _.where(serviceArray, { companyId: companyId });

    currentLocationServiceArray = _.where(serviceArray, { site: site });

    console.log("currentServiceArray :", currentServiceArray);

    console.log("currentLocationServiceArray :", currentLocationServiceArray);

    $.each(currentLocationServiceArray, function (i, item) {
      serviceArrayOptions.push({
        label: item.serviceName,
        value: item.serviceName,
      });
    });
    //    serviceArrayOptions.push({ label:'Towe', value:'Towe' });

    var currentEmployeeArray = [];
    var employeeArrayOptions = [];
    currentEmployeeArray = _.where(employeeArray, { companyId: companyId });
    $.each(currentEmployeeArray, function (i, item) {
      employeeArrayOptions.push({
        label: item.employeeName,
        value: item.employeeId + "," + item.employeeName,
      });
    });

    this.state.serviceOptions = serviceArrayOptions;
    this.state.employeeOptions = employeeArrayOptions;

    this.setState({
      serviceOptions: this.state.serviceOptions,
      employeeOptions: this.state.employeeOptions,
    });
  };

  handleChangeSelectedServiceOption = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.serviceOption = e;
    this.setState({
      serviceOption: this.state.serviceOption,
    });

    serviceListArray = [];
    serviceTimeArray = [];
    $.each(e, function (i, item) {
      serviceListArray.push(item.value);
      var timeArray = _.where(serviceArray, { serviceName: item.value });
      serviceTimeArray.push(timeArray[0].serviceTime);
    });
  };

  handleChangeSelectedEmployeeOption = (e) => {
    // Display selected value for user
    var currentValue = e;
    this.state.employeeOption = e;
    this.state.employeeDetails = e.value;
    this.setState({
      employeeOption: this.state.employeeOption,
      employeeDetails: this.state.employeeDetails,
    });
  };

  keeperchangetime(newTime) {
    this.state.appointmentTime = newTime;
    this.setState({
      appointmentTime: this.state.appointmentTime,
    });
  }

  OpenTimeKeeper() {
    if (this.state.timekeeperData == true) {
      $("#myModal1").hide();
    } else {
      $("#myModal1").show();
    }
  }
  CloseTimeKeeper() {
    this.state.timekeeperData = false;
    $("#myModal1").hide();
  }

  CloseTimeKeepernone() {
    this.state.timekeeperData = false;
    this.state.action = "Cancel";
    this.keeperchangetime(this.state.appointmentTime);
    $("#myModal1").hide();
  }

  BackbtnFunc() {
    ReactDOM.render(
      <Suspense fallback={<LoadingComponent />}>
        <LandingPage_tagarage />
      </Suspense>,
      document.getElementById("root")
    );
  }

  MakeAppointmentFunc() {
    var self = this;

    var proceedData = "Yes";

    if (
      this.state.customerName != "" &&
      this.state.mobileNo != "" &&
      this.state.appointmentDate != "" &&
      serviceListArray.length != 0
    ) {
      var appointmentFixedDate = Date.parse(this.state.appointmentDate);
      var currentDate = Date.parse(this.state.currentDate);

      if (appointmentFixedDate == currentDate) {
        var currentTime = new Date();
        var appointmentFixedTime = new Date();

        currentTime.setHours(
          this.state.time.split(":")[0],
          this.state.time.split(":")[1],
          "0"
        );
        appointmentFixedTime.setHours(
          this.state.appointmentTime.split(":")[0],
          this.state.appointmentTime.split(":")[1],
          "0"
        );

        if (appointmentFixedTime <= currentTime) {
          proceedData = "No";
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "Select Service Time In Future",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      if (proceedData == "Yes") {
        $.ajax({
          type: "POST",
          data: JSON.stringify({
            customerName: this.state.customerName,
            mobileNo: this.state.mobileNo,
            //   gender:this.state.gender,
            appointmentDate: this.state.appointmentDate,
            bookingDate: this.state.currentDate,
            appointmentTime: this.state.appointmentTime,
            //        appointmentEndTime:endTimings,
            companyId: this.state.companyId,
            service: serviceListArray.toString(),
            // employeedetails:this.state.employeeDetails,
            appointmentBy: "Client",
            modeofAppointment: "Online",
            getDataType: "AllGarage",
            date: this.state.currentDate,
            status: "0",
            description: "-",
            site: this.state.site,
            companyName: this.state.companyName,
            comp_ContactNo: this.state.comp_ContactNo,
            messageStatus: "",
            parameter: "",
          }),

          url: "https://wildfly.garageapp.in:443/GarageAppIN_API/Appointments/MakeAppointments",
          contentType: "application/json",
          dataType: "json",
          async: false,
          success: function (data, textStatus, jqXHR) {
            self.state.modalIsOpen = false;
            self.setState({
              modalIsOpen: self.state.modalIsOpen,
            });
            if (data.response == "Success") {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Service Booked Successfully",
                showConfirmButton: false,
                timer: 2000,
              });
              self.cancelFunc();
            } else if (data.response == "EmployeeEngaged") {
              Swal.fire({
                position: "center",
                icon: "warning",
                title:
                  "Employee Is Already Engaged Kindly Select Some Other Time",
                showConfirmButton: false,
                timer: 2000,
              });
            }

            var saloonArray = [];
            serviceArray = [];
            employeeArray = [];
            appointmentArray = [];

            $.each(data.saloonDetails, function (i, item) {
              saloonArray.push({
                label: item.companyName + " - " + item.site + " - " + item.area,
                value: item.companyId,
                site: item.site,
                comp_ContactNo: item.comp_ContactNo,
                companyName: item.companyName,
              });
            });

            serviceArray = data.serviceDetails;
            employeeArray = data.employeeDetails;
            appointmentArray = data.appointmentDetails;

            self.state.saloonOptions = saloonArray;

            self.setState({
              saloonOptions: self.state.saloonOptions,
            });
          },
          error: function (data) {
            self.state.modalIsOpen = true;
            self.setState({
              modalIsOpen: self.state.modalIsOpen,
            });
          },
        });
      } else {
        /*Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Select Appointment Time In Future',
          showConfirmButton: false,
          timer: 2000
        })
        */
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Kindly Fill In All Fields To Proceed",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  cancelFunc() {
    this.state.customerName = "";
    this.state.mobileNo = "";
    this.state.gender = "";
    this.state.appointmentDate = "";
    this.state.bookingDate = "";
    this.state.appointmentTime = this.state.time;
    this.state.companyId = "";
    serviceListArray = [];
    serviceTimeArray = [];
    this.state.employeeDetails = "";
    this.state.saloonOption = "";
    this.state.serviceOption = "";
    this.state.employeeOption = "";
    this.state.genderOption = "";
    this.state.empAppointmentTime = "";

    this.state.employeeOptions = [];
    this.state.serviceOptions = [];

    this.setState({
      customerName: this.state.customerName,
      mobileNo: this.state.mobileNo,
      gender: this.state.gender,
      appointmentDate: this.state.appointmentDate,
      bookingDate: this.state.currentDate,
      appointmentTime: this.state.appointmentTime,
      companyId: this.state.companyId,
      employeeDetails: this.state.employeeDetails,
      saloonOption: this.state.saloonOption,
      serviceOption: this.state.serviceOption,
      employeeOption: this.state.employeeOption,
      genderOption: this.state.genderOption,
      empAppointmentTime: this.state.empAppointmentTime,
      employeeArrayOptions: this.state.employeeArrayOptions,
      serviceOptions: this.state.serviceOptions,
    });
  }

  closeFunc() {}

  keeperchangetime(newTime) {
    if (this.state.action == "Done") {
      this.state.appointmentTime = newTime;
    } else if (this.state.action == "Cancel") {
      this.state.appointmentTime = this.state.time;
    }

    this.state.action = "Done";

    this.setState({
      appointmentTime: this.state.appointmentTime,
      action: this.state.action,
    });
  }

  render() {
    const label_style = {
      paddingTop: "20px",
    };

    return (
      <div className="container-fluid">
        <NetworkErrorComponent modalIsOpen={this.state.modalIsOpen} />
        <div className="two_HeaderCls">
          <BackButtonComponent click={() => this.BackbtnFunc()} />
          <h3>Book a Service-Customer</h3>
        </div>

        <div className="row">
          <div className="col-md-6 " style={label_style}>
            <label className="control-label selectpicker" for="customerName">
              Contact No:
            </label>
            <input
              type="text"
              id="mobileno"
              name="mobileNo"
              style={{ color: "black", marginBottom: "0px" }}
              onChange={this.handleUserInputMobileNo}
              value={this.state.mobileNo}
              className="form-control"
              autocomplete="off"
              placeholder="Contact No"
            />
          </div>
          <div className="col-md-6 " style={label_style}>
            <label className="control-label selectpicker " for="customerName">
              Customer Name:
            </label>
            <input
              type="text"
              id="customername"
              name="customerName"
              onChange={this.handleUserInput}
              value={this.state.customerName}
              style={{ color: "black", marginBottom: "0px" }}
              className="form-control"
              autocomplete="off"
              placeholder="customer Name"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6" style={label_style}>
            <label className="control-label selectpicker" for="customerName">
              Date Of Service Visit:
            </label>
            <input
              onChange={this.handleUserInput}
              className="form-control"
              id="appointmentdate"
              name="appointmentDate"
              placeholder="Date Of Visit"
              value={this.state.appointmentDate}
              required
            />
          </div>
          <div className="col-md-6" style={label_style}>
            <label className="control-label selectpicker " for="customerName">
              Time Of Service Visit:
            </label>
            <input
              onClick={() => this.OpenTimeKeeper()}
              className="form-control"
              id="appointmenttime"
              name="appointmentTime"
              placeholder="Visit Time"
              value={this.state.appointmentTime}
              readOnly
            />
          </div>
          <div className="col-md-6 " style={label_style}>
            {/* modal start  */}
            <div
              style={{ opacity: "1" }}
              className="modal fade"
              id="myModal1"
              data-backdrop="false"
            >
              <div className="modal-dialog">
                <div className=" text-center modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => this.CloseTimeKeepernone()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div
                      className="text-center"
                      style={{ fontSize: "17px" }}
                      onClick={() => this.CloseTimeKeeper()}
                    >
                      <button
                        className="btn-primary "
                        data-dismiss="modal"
                        style={{
                          marginRight: "20px",
                          width: "100px",
                          borderRadius: "5px",
                          marginLeft: "-20px",
                          marginBottom: "20px",
                        }}
                        onClick={() => this.CloseTimeKeeper()}
                      >
                        Done
                      </button>
                      <button
                        className=" btn-primary"
                        data-dismiss="modal"
                        style={{ borderRadius: "5px", width: "100px" }}
                        onClick={() => this.CloseTimeKeepernone()}
                      >
                        Cancel
                      </button>
                    </div>
                    <Timekeeper
                      onChange={(newTime) =>
                        this.keeperchangetime(newTime.formatted24)
                      }
                      hour24Mode
                      //   coarseMinutes={15}
                      //  forceCoarseMinutes
                      switchToMinuteOnHourSelect
                      doneButton={(newTime) => <div></div>}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* modal end  */}
          </div>

          {/* <div className="col-md-6 " style={label_style}>
           
           <div id="timekeeperdiv" 
             style={{}}>
    
  
         <div className="card2" >
         <div className="text-center"
                 style={{  fontSize:'17px' }}
                 onClick={() => this.CloseTimeKeeper()}  
               >
                <button  className="btn-primary " data-dismiss="modal" style={{marginRight:'20px',width:'100px',borderRadius:'5px',marginLeft:'-20px',marginBottom:'20px'}} onClick={() => this.CloseTimeKeeper()} >Done</button>
                 <button  className=" btn-primary" data-dismiss="modal" style={{borderRadius:'5px',width:'100px'}} onClick={() => this.CloseTimeKeeper()} >Cancel</button>
               </div>
          <Timekeeper 
      
             onChange={(newTime) => this.keeperchangetime(newTime.formatted24)}
             hour24Mode 
             
             //   coarseMinutes={15}
             //  forceCoarseMinutes
             switchToMinuteOnHourSelect
             doneButton={(newTime) => (
               <div>
           
               
             
               </div>
               
             )}

         
           />
           </div>
            
           
      
         </div>
       </div> */}
        </div>

        <div className="row">
          <div className="col-md-6">
            <label className="control-label selectpicker" for="customerName">
              Garage List:
            </label>
            <Select
              id="selectedOption"
              name="selectedOption"
              isMulti={false}
              options={this.state.saloonOptions}
              onChange={this.handleChangeSelectedSaloonOption}
              value={this.state.saloonOption}
            />
          </div>
          <div className="col-md-6">
            <label className="control-label selectpicker " for="customerName">
              Service:
            </label>
            <Select
              id="selectedOption"
              name="selectedOption"
              isMulti={true}
              options={this.state.serviceOptions}
              onChange={this.handleChangeSelectedServiceOption}
              value={this.state.serviceOption}
            />
          </div>

          {/*  <div className="col-md-6" style={label_style}>
                <label className="control-label selectpicker" id="quantity" for="customerName">Employee:</label>
                <Select
                  id="selectedOption"
                  name="selectedOption"
                  isMulti={false}
                  options={this.state.employeeOptions}
                  onChange={this.handleChangeSelectedEmployeeOption}
                  value={this.state.employeeOption}
                />
              </div>  */}
        </div>
        <div
          className="text-center"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <button
            type="button"
            onClick={() => this.MakeAppointmentFunc()}
            style={{ marginTop: " 2%" }}
            className="btn btn-default "
          >
            Book a Service
          </button>
        </div>
      </div>
    );
  }
}
export default CustomerAppointments;
