//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import $ from 'jquery';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';
import LoadingComponent from '../Loading/LoadingComponent';
import LoginPage from '../LoginPage';

/*
FUNCTION USED FOR SETTING LOCAL STORAGE VALUES-USED IN ALL PAGES
IMPLEMENTED BY PRIYANKA 
*/
export const SetLocalStorageData = (fieldName, fieldValue) => {

    var key = "IceilPrintSolutions";
    localStorage.setItem((fieldName), CryptoJS.AES.encrypt((fieldValue), key));
}


/*
FUNCTION USED FOR GETTING LOCAL STORAGE VALUES
ALMOST USED IN ALL PAGES
IMPLEMENTED BY PRIYANKA 
*/
export const GetLocalStorageData = (fieldName) => {

    var key = "IceilPrintSolutions";

    var localStorageData = CryptoJS.AES.decrypt(localStorage.getItem(fieldName), key).toString(CryptoJS.enc.Utf8);

    return localStorageData;

}




/*
FUNCTION USED TO CHECK LENGTH OF THE TEXT 
ENTERED
IMPLEMENETED BY PRIYANKA 
*/
export const TextLengthFunc = function (textValue, lengthValue) {

    var lengthValidationData = false;
    if (textValue.length <= lengthValue) {
        lengthValidationData = true;
    }
    return lengthValidationData;

}


/*
FUNCTION USED FOR LOGOUT OF THE ACCOUNT
COMMON TO BOTH ADMIN & FRANCHISE MODULE
IMPLEMENTED BY PRIYANKA - 19-05-2022
*/
export const LogOut = async () => {

    localStorage.clear();

    localStorage.clear();
    ReactDOM.render(
      <Router>
        <Suspense fallback={<LoadingComponent />}>
          <div>
            <Route path="/" component={LoginPage} />
          </div>
        </Suspense>
      </Router>,
      document.getElementById("root")
    );

}












