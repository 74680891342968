//IMPORT STATEMENTS FOR REACT COMPONENT
import React, { Component } from "react";
import $ from "jquery";
import {
  CheckNumberFormat_WithoutDecimal,
  CheckNumberFormat,
  Truncate_2DecimalPlaces,
} from "./Invoice/InvoiceValidations";
import { ToastContainer, toast } from "react-toastify";
import SelectSearch from "react-select";

//IMPORT REACT COMPONENT CSS
import "react-toastify/dist/ReactToastify.css";
import { TextLengthFunc } from "./ServiceRegistration/CommonTextFormatComponent";
import { OnlyNumber_NoDecimal_SpecialCharacters } from "./ServiceRegistration/ValidationComponent";

export class EnquiryProductTypeModal extends React.Component {
  state = {
    productType: "",
    selectedProductMeasurementUnit: "",
    productMeasurementUnit: "",
  };

  onProductTypeChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;
    this.setState({
      [name]: value,
    });

    this.props.onSelectProductType(value);
  };

  componentDidMount() {
    var productMeasurementUnitOptions = [];
    productMeasurementUnitOptions.push({
      label: "Units (Pcs)",
      value: "Units (Pcs)",
    });
    productMeasurementUnitOptions.push({
      label: "Meters (M)",
      value: "Meters (M)",
    });
    productMeasurementUnitOptions.push({
      label: "Liters (L)",
      value: "Liters  (L)",
    });
    productMeasurementUnitOptions.push({
      label: "Kilograms (Kg)",
      value: "Kilograms (Kg)",
    });
    productMeasurementUnitOptions.push({
      label: "Centimeters (Cm)",
      value: "Centimeters (Cm)",
    });

    this.state.productMeasurementUnitOptions = productMeasurementUnitOptions;
    this.setState({
      productMeasurementUnitOptions: this.state.productMeasurementUnitOptions,
    });

    /* this.state.selectedProductMeasurementUnit = { label: "Units (Pcs)", value: "Units" };
    this.setState({
      selectedProductMeasurementUnit: this.state.selectedProductMeasurementUnit,
    })
    */
  }

  handleProductMeasurementUnitDetails = (e) => {
    var self = this;
    const name = e.name;
    const value = e.value;

    this.state.selectedProductMeasurementUnit = e;
    this.state.productMeasurementUnit = e.value;
    this.setState({
      selectedProductMeasurementUnit: this.state.selectedProductMeasurementUnit,
      productMeasurementUnit: this.state.productMeasurementUnit,
    });

    this.props.onSelectProductMeasurementUnit(
      this.state.productMeasurementUnit
    );
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Select Product Type
        </h5>
        <div className="row" style={{ marginTop: "32px" }}>
          <div className="text-center">
            <div>
              <label>
                <input
                  style={{ marginRight: "5px" }}
                  type="radio"
                  id="product"
                  name="productType"
                  value="product"
                  onChange={this.onProductTypeChange}
                />
                Product
              </label>
            </div>
            <div>
              <label>
                <input
                  style={{ marginRight: "5px" }}
                  type="radio"
                  id="service"
                  name="productType"
                  value="service"
                  onChange={this.onProductTypeChange}
                />
                Service
              </label>
            </div>
          </div>
        </div>
        <div id="purchaseRate1" className="purchaseRate1">
          <label className="control-label " for="purchaseRate">
            Product Measurement<span className="mandatory-fields">*</span>
          </label>
          <div>
            <SelectSearch
              options={this.state.productMeasurementUnitOptions}
              value={this.state.selectedProductMeasurementUnit}
              id="productMeasurementUnit"
              onChange={(e) => this.handleProductMeasurementUnitDetails(e)}
              name="productMeasurementUnit"
              placeholder="Select Product Measurement "
            />
          </div>
        </div>
      </div>
    );
  }
}

export class InvoiceProductQtyAddModal extends React.Component {
  state = {
    addQty: "",
  };

  componentDidMount() {
    console.log(
      " AddProductQtyFuncproductDetails :",
      this.props.AddProductQtyFuncproductDetails
    );

    this.state.productMeasurementUnit =
      this.props.AddProductQtyFuncproductDetails.productMeasurementUnit;
    this.setState({
      productMeasurementUnit: this.state.productMeasurementUnit,
    });
  }

  onProductAddChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;

    //  var qtyValidationData = CheckNumberFormat_WithoutDecimal(this.state.productFieldKeys, value);

    var qtyValidationData;
    if (this.state.productMeasurementUnit == "Units (Pcs)") {
      //ON PRODUCT MEASUREMENT UNIT BEING Unit (Pcs) DECIMAL VALUES ARE NOT ALLOWED
      qtyValidationData = CheckNumberFormat_WithoutDecimal(
        this.state.productFieldKeys,
        value
      );
    } else {
      //ON PRODUCT MEASUREMENT UNIT BEING OTHER THAN Unit (Pcs) DECIMAL VALUES ARE ALLOWED WITH 2 VALUES AFTER DECIMAL POINT
      qtyValidationData = CheckNumberFormat(this.state.productFieldKeys, value);
    }

    if (qtyValidationData == true) {
      this.setState({
        [name]: value,
      });
      this.props.ProductQtyAddFunc(value);
    } else {
      this.Set_QuantityAlertMsg("IncorrectFormat");
      var fieldId = "modalquantityalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Product_Qty_KeyPress_Func = (e) => {
    this.state.productFieldKeys = e.charCode;

    this.setState({
      productFieldKeys: this.state.productFieldKeys,
    });
  };

  Set_QuantityAlertMsg(messageInfo) {
    $("#modalquantityalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalquantityalertmsg").append("! Incorrect Number Format");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Add Product Quantity
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3"> </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Qty</label>
            <input
              type="text"
              className="form-control"
              value={this.state.addQty}
              id="addQty"
              name="addQty"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.onProductAddChange}
            />
            <span
              id="modalquantityalertmsg"
              className="mandatory-fields"
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export class InvoiceProductQtyMinusModal extends React.Component {
  state = {
    minusQty: "",
  };

  componentDidMount() {
    console.log(
      " MinusProductQtyFuncproductDetails :",
      this.props.MinusProductQtyFuncproductDetails
    );

    this.state.productMeasurementUnit =
      this.props.MinusProductQtyFuncproductDetails.productMeasurementUnit;
    this.setState({
      productMeasurementUnit: this.state.productMeasurementUnit,
    });
  }

  onProductMinusChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;

    // var qtyValidationData = CheckNumberFormat_WithoutDecimal(this.state.productFieldKeys, value);

    var qtyValidationData;
    if (this.state.productMeasurementUnit == "Units (Pcs)") {
      //ON PRODUCT MEASUREMENT UNIT BEING Unit (Pcs) DECIMAL VALUES ARE NOT ALLOWED
      qtyValidationData = CheckNumberFormat_WithoutDecimal(
        this.state.productFieldKeys,
        value
      );
    } else {
      //ON PRODUCT MEASUREMENT UNIT BEING OTHER THAN Unit (Pcs) DECIMAL VALUES ARE ALLOWED WITH 2 VALUES AFTER DECIMAL POINT
      qtyValidationData = CheckNumberFormat(this.state.productFieldKeys, value);
    }

    if (qtyValidationData == true) {
      if (value == 0 || value < 0) {
        this.Set_QuantityAlertMsg("NoZeroQty");
        var fieldId = "modalquantityalertmsg";
        HideFieldErroeMsgs(fieldId);
      } else {
        this.setState({
          [name]: value,
        });
        this.props.ProductQtyMinusFunc(value);
      }
    } else {
      this.Set_QuantityAlertMsg("IncorrectFormat");
      var fieldId = "modalquantityalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Product_Qty_KeyPress_Func = (e) => {
    this.state.productFieldKeys = e.charCode;

    this.setState({
      productFieldKeys: this.state.productFieldKeys,
    });
  };

  Set_QuantityAlertMsg(messageInfo) {
    $("#modalquantityalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalquantityalertmsg").append("! Incorrect Number Format");
    } else if (messageInfo == "NoZeroQty") {
      $("#modalquantityalertmsg").append("! Product Qty Cannot be Zero");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Minus Product Quantity
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3"> </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Qty</label>
            <input
              type="text"
              className="form-control"
              value={this.state.minusQty}
              id="minusQty"
              name="minusQty"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.onProductMinusChange}
            />
            <span
              id="modalquantityalertmsg"
              className="mandatory-fields"
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export class InvoiceProductQtyModal extends React.Component {
  state = {
    minusQty: "",
  };

  componentDidMount() {
    console.log("InvoiceProductQtyModal :", this.props);

    this.state.productName = this.props.stateData.cartEnquiryProductName;
    this.state.productMeasurementUnit =
      this.props.stateData.cart_EnquiryProductUnit;
    this.setState({
      productName: this.state.productName,
      productMeasurementUnit: this.state.productMeasurementUnit,
    });
  }

  onProductChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;

    var qtyValidationData;
    //  var qtyValidationData = CheckNumberFormat_WithoutDecimal(this.state.productFieldKeys, value);

    if (this.state.productMeasurementUnit == "Units (Pcs)") {
      //ON PRODUCT MEASUREMENT UNIT BEING Unit (Pcs) DECIMAL VALUES ARE NOT ALLOWED
      qtyValidationData = CheckNumberFormat_WithoutDecimal(
        this.state.productFieldKeys,
        value
      );
    } else {
      //ON PRODUCT MEASUREMENT UNIT BEING OTHER THAN Unit (Pcs) DECIMAL VALUES ARE ALLOWED WITH 2 VALUES AFTER DECIMAL POINT
      qtyValidationData = CheckNumberFormat(this.state.productFieldKeys, value);
    }

    if (qtyValidationData == true) {
      this.setState({
        [name]: value,
      });
      this.props.ProductQtyFunc(value);
    } else {
      this.Set_QuantityAlertMsg("IncorrectFormat");
      var fieldId = "modalquantityalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Product_Qty_KeyPress_Func = (e) => {
    this.state.productFieldKeys = e.charCode;

    this.setState({
      productFieldKeys: this.state.productFieldKeys,
    });
  };

  Set_QuantityAlertMsg(messageInfo) {
    $("#modalquantityalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalquantityalertmsg").append("! Incorrect Number Format");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Product Quantity
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3">
            <label style={{ marginLeft: "5px" }}> Product</label>
            <label style={{ marginLeft: "5px" }}>
              {this.state.productName}
            </label>
          </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Qty</label>
            <input
              type="text"
              className="form-control"
              value={this.state.productQty}
              id="productQty"
              name="productQty"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.onProductChange}
            />
            <span
              id="modalquantityalertmsg"
              className="mandatory-fields"
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export class InvoiceJobCardProductEditModal extends React.Component {
  state = {
    minusQty: "",
    productName: "",
  };

  componentDidMount() {
    console.log("InvoiceJobCardProductEditModal :", this.props);

    this.state.productName = this.props.stateData.cartItem;
    this.state.rate = this.props.stateData.cart_ItemRate;
    this.state.total = this.props.stateData.cart_ItemTotal;
    this.state.discountPercentage =
      this.props.stateData.cart_ItemDiscountPercentage;
    this.state.discountAmount = this.props.stateData.cart_ItemDiscountAmt;
    this.state.subTotal = this.props.stateData.cart_ItemSubtotal;
    this.state.CGST_Percentage = this.props.stateData.cart_ItemCGST_Percentage;
    this.state.SGST_Percentage = this.props.stateData.cart_ItemSGST_Percentage;
    this.state.finalAmount = this.props.stateData.cart_ItemFinalAmt;
   // this.state.prodcutMeasurementUnit=this.props.stateData.cart_ProdcutMeasurementUnit;

    this.setState({
      productName: this.state.productName,
      rate: this.state.rate,
      total: this.state.total,
      discountPercentage: this.state.discountPercentage,
      discountAmount: this.state.discountAmount,
      subTotal: this.state.subTotal,
      CGST_Percentage: this.state.CGST_Percentage,
      SGST_Percentage: this.state.SGST_Percentage,
      finalAmount: this.state.finalAmount,
    //  productMeasurementUnit:this.state.productMeasurementUnit,
    });
  }

  handleRate_Discount_Tax_FinalAmountCalFunc = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var validationData = CheckNumberFormat(this.state.productFieldKeys, value);

    var rate = 0;
    var discountPercentage = 0;
    var discountAmount = 0;
    var CGST_Percentage = 0;
    var SGST_Percentage = 0;
    var CGST_Amount = 0;
    var SGST_Amount = 0;
    var finalAmount = 0;
    var qty = 1;
    var total = 0;
    var oldFinalAmount = this.props.stateData.cart_ItemFinalAmt;

    this.state.oldFinalAmount = Truncate_2DecimalPlaces(
      this.state.productFinalAmount
    );

    if (validationData == true) {
      if (name == "discountPercentage") {
        if (Number(value) <= 100) {
          this.state.discountPercentage = value;

          var discountAmount =
            (this.state.total * this.state.discountPercentage) / 100;
          this.state.discountAmount = Truncate_2DecimalPlaces(discountAmount);
        } else {
          this.state.discountPercentage = Truncate_2DecimalPlaces(0);
          this.state.discountAmount = Truncate_2DecimalPlaces(0);

          toast.error("! Discount cannot exceed 100%", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            marginTop: "60px",
          });
        }

        this.setState({
          discountPercentage: this.state.discountPercentage,
          discountAmount: this.state.discountAmount,
        });
      } else if (name == "discountAmount") {
        this.state.discountAmount = value;
        var discountAmount = value;

        if (discountAmount == "") {
          discountAmount = 0;
        }

        this.state.discountPercentage = Truncate_2DecimalPlaces(
          (value / this.state.total) * 100
        );

        this.setState({
          discountPercentage: this.state.discountPercentage,
          discountAmount: this.state.discountAmount,
        });
      } else if (name == "finalAmount") {
        //  alert("FINAL AMOUNT CHANGED :"+value);

        this.state.discountAmount = Truncate_2DecimalPlaces(0);
        this.state.discountPercentage = Truncate_2DecimalPlaces(0);

        this.state.finalAmount = value;

        var finalAmount = value;

        if (finalAmount == "") {
          finalAmount = 0;
        }

        var taxAmt = Truncate_2DecimalPlaces(
          Number(finalAmount) -
            Number(finalAmount) *
              Number(
                100 /
                  (100 +
                    (Number(this.state.CGST_Percentage) +
                      Number(this.state.SGST_Percentage)))
              )
        );

        this.state.rate = Truncate_2DecimalPlaces(
          Number(finalAmount) - Number(taxAmt)
        );

        var total = Number(1) * Number(this.state.rate);
        this.state.total = Truncate_2DecimalPlaces(total);

        var subTotal = Number(total) - Number(this.state.discountAmount);
        this.state.subTotal = Truncate_2DecimalPlaces(subTotal);

        var CGST_Amount =
          (Number(this.state.subTotal) * Number(this.state.CGST_Percentage)) /
          100;
        this.state.CGST_Amount = Truncate_2DecimalPlaces(CGST_Amount);

        var SGST_Amount =
          (Number(subTotal) * Number(this.state.SGST_Percentage)) / 100;
        this.state.SGST_Amount = Truncate_2DecimalPlaces(SGST_Amount);

        this.setState({
          rate: this.state.rate,
          total: this.state.total,
          discountAmount: this.state.discountAmount,
          discountPercentage: this.state.discountPercentage,
          subTotal: this.state.subTotal,
          CGST_Percentage: this.state.CGST_Percentage,
          SGST_Percentage: this.state.SGST_Percentage,
          CGST_Amount: this.state.CGST_Amount,
          SGST_Amount: this.state.SGST_Amount,
          finalAmount: this.state.finalAmount,
        });
      }

      //FOR CHANGE IN RATE OR CGST OR SGST JUST PROCEED FURTHER

      if (
        name == "rate" ||
        name == "CGST_Percentage" ||
        name == "SGST_Percentage"
      ) {
        this.state[name] = value;

        this.setState({
          [name]: value,
        });
      }

      if (name != "finalAmount") {
        this.Total_CartFields_CalFunc();
      }

      if (name != "discountPercentage") {
        this.Check_Discount_Exceeds_Rate();
      }
    } else if (validationData == false) {
      this.Set_AlertMsg("IncorrectFormat");
      var fieldId = "modalquantityalertmsg";
      HideFieldErroeMsgs(fieldId);
    } else if (validationData == "ExtraDecimal") {
      this.Set_AlertMsg("Incorrect Decimal Format");
      var fieldId = "modalquantityalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  /*
FUNCTION USED TO CALCULATE THE CART FIELD VALUE
*/

  Total_CartFields_CalFunc() {
    //console.log("Total_CartFields_CalFunc CALLED");

    var total = Number(1) * Number(this.state.rate);
    this.state.total = Truncate_2DecimalPlaces(total);

    var subTotal = Number(total) - Number(this.state.discountAmount);
    this.state.subTotal = Truncate_2DecimalPlaces(subTotal);

    var CGST_Amount =
      (Number(this.state.subTotal) * Number(this.state.CGST_Percentage)) / 100;
    this.state.CGST_Amount = Truncate_2DecimalPlaces(CGST_Amount);

    var SGST_Amount =
      (Number(subTotal) * Number(this.state.SGST_Percentage)) / 100;
    this.state.SGST_Amount = Truncate_2DecimalPlaces(SGST_Amount);

    var finalAmount =
      Number(subTotal) +
      Number(this.state.CGST_Amount) +
      Number(this.state.SGST_Amount);
    this.state.finalAmount = Truncate_2DecimalPlaces(finalAmount);

    //this.state.truncated_finalAmount=Truncate_2DecimalPlaces(finalAmount);

    this.setState({
      rate: this.state.rate,
      total: this.state.total,
      discountAmount: this.state.discountAmount,
      discountPercentage: this.state.discountPercentage,
      subTotal: this.state.subTotal,
      CGST_Percentage: this.state.CGST_Percentage,
      SGST_Percentage: this.state.SGST_Percentage,
      CGST_Amount: this.state.CGST_Amount,
      SGST_Amount: this.state.SGST_Amount,
      finalAmount: this.state.finalAmount,
      //  truncated_finalAmount:this.state.truncated_finalAmount,
    });
  }

  /*
  FUNCTION FOR CHECKING WHETHER DISCOUNT EXCEEDS RATE 
  */
  Check_Discount_Exceeds_Rate() {
    // var productFinalAmount=this.state.productFinalAmount - this.state.productDiscountAmount;

    //console.log("this.state.productDiscountAmount :", this.state.discountAmount);
    //console.log("this.state.productFinalAmount :", this.state.finalAmount);
    //console.log("this.state.productSubtotalAmount  :", this.state.subTotal);

    if (Number(this.state.discountAmount) > Number(this.state.total)) {
      this.state.discountAmount = 0;
      this.state.discountPercentage = 0;
      this.state.finalAmount = this.state.oldFinalAmount;

      //  //console.log("Check_Discount_Exceeds_Rate called EXCEED");

      //RECALCULATE WITH NEW DISCOUNT AMT & PERCENTAGE
      this.Total_CartFields_CalFunc();

      toast.error("! Discount Exceeds Amount", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        marginTop: "60px",
      });

      /* Swal.fire({
         position: 'center',
         icon: 'warning',
         title: 'Discount Exceeds Amount',
         showConfirmButton: false,
         timer: 2000
       })
       */
    } else {
      //  //console.log("Check_Discount_Exceeds_Rate called NO EXCEED");

      this.state.discountAmount = this.state.discountAmount;
      this.state.discountPercentage = this.state.discountPercentage;
      this.state.finalAmount = this.state.finalAmount;
    }

    this.setState({
      discountAmount: this.state.discountAmount,
      discountPercentage: this.state.discountPercentage,
      finalAmount: this.state.finalAmount,
    });

    this.props.SetEditedDataBackIntoCart(this.state);
  }

  handleUser_Product_Qty_KeyPress_Func = (e) => {
    this.state.productFieldKeys = e.charCode;

    this.setState({
      productFieldKeys: this.state.productFieldKeys,
    });
  };

  Set_AlertMsg(messageInfo) {
    $("#modalquantityalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalquantityalertmsg").append("! Incorrect Number Format");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Job Card Details Edit
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3">
            <label style={{ marginLeft: "5px" }}> Product</label>
            <label style={{ marginLeft: "5px" }}>
              {this.state.productName}
            </label>
          </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Rate</label>
            <input
              type="text"
              className="form-control"
              value={this.state.rate}
              id="productQty"
              name="rate"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span id="ratealertmsg" className="mandatory-fields"></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Total</label>
            <input
              type="text"
              className="form-control"
              value={this.state.total}
              id="productQty"
              name="total"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
              readOnly
            />
            <span id="totalalertmsg" className="mandatory-fields"></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Dicount %</label>
            <input
              type="text"
              className="form-control"
              value={this.state.discountPercentage}
              id="productQty"
              name="discountPercentage"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span
              id="discountPercentagealertmsg"
              className="mandatory-fields"
            ></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Discount Amt</label>
            <input
              type="text"
              className="form-control"
              value={this.state.discountAmount}
              id="productQty"
              name="discountAmount"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span
              id="discountAmountalertmsg"
              className="mandatory-fields"
            ></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> SubTotal</label>
            <input
              type="text"
              className="form-control"
              value={this.state.subTotal}
              id="productQty"
              name="subTotal"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
              readOnly
            />
            <span id="subtotalalertmsg" className="mandatory-fields"></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> CGST %</label>
            <input
              type="text"
              className="form-control"
              value={this.state.CGST_Percentage}
              id="productQty"
              name="CGST_Percentage"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span
              id="CGST_Percentagealertmsg"
              className="mandatory-fields"
            ></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> SGST %</label>
            <input
              type="text"
              className="form-control"
              value={this.state.SGST_Percentage}
              id="productQty"
              name="SGST_Percentage"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span
              id="SGST_Percentagealertmsg"
              className="mandatory-fields"
            ></span>
          </div>

          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> FinalAmount</label>
            <input
              type="text"
              className="form-control"
              value={this.state.finalAmount}
              id="productQty"
              name="finalAmount"
              onKeyPress={this.handleUser_Product_Qty_KeyPress_Func}
              onChange={this.handleRate_Discount_Tax_FinalAmountCalFunc}
            />
            <span id="finalAmountalertmsg" className="mandatory-fields"></span>
          </div>
        </div>
      </div>
    );
  }
}

export class EstimateProductChangeModal extends React.Component {
  state = {
    minusQty: "",
  };

  componentDidMount() {
    //console.log("EstimateProductChangeModal :", this.props);

    this.state.productName = this.props.stateData.estimateProductName;
    this.setState({
      productName: this.state.productName,
    });
  }

  onProductRateChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    var rateValidationData = CheckNumberFormat(
      this.state.productRateFieldKeys,
      value
    );

    if (rateValidationData == true) {
      this.state.productRate = value;
      this.setState({
        productRate: this.state.productRate,
      });
      this.props.ProductInfoFunc(value);
    } else {
      this.state.productRate = "";
      this.setState({
        productRate: this.state.productRate,
      });

      if (rateValidationData == "ExtraDecimal") {
        this.Set_RateAlertMsg("ExtraDecimal");
      } else {
        this.Set_RateAlertMsg("IncorrectFormat");
      }

      var fieldId = "modalratealertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Product_Rate_KeyPress_Func = (e) => {
    this.state.productRateFieldKeys = e.charCode;

    this.setState({
      productRateFieldKeys: this.state.productRateFieldKeys,
    });
  };

  Set_RateAlertMsg(messageInfo) {
    $("#modalratealertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalratealertmsg").append("! Incorrect Number Format");
    } else if (messageInfo == "ExtraDecimal") {
      $("#modalratealertmsg").append("! Only 2 Decimal Digits Allowed");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Product Information
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3">
            <label style={{ marginLeft: "5px" }}> Product</label>
            <label style={{ marginLeft: "5px" }}>
              {this.state.productName}
            </label>
          </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Rate</label>
            <input
              type="text"
              className="form-control"
              value={this.state.productRate}
              id="productRate"
              name="productRate"
              onKeyPress={this.handleUser_Product_Rate_KeyPress_Func}
              onChange={this.onProductRateChange}
            />
            <span id="modalratealertmsg" className="mandatory-fields"></span>
          </div>
        </div>
      </div>
    );
  }
}

export class MessageFeatureCountModal extends React.Component {
  state = {
    minusQty: "",
  };

  componentDidMount() {
    //console.log("InvoiceProductQtyModal :", this.props);

    this.state.featureName = this.props.stateData.featureData_Feature;
    this.state.count = this.props.stateData.featureData_Count;
    this.setState({
      featureName: this.state.featureName,
      count: this.state.count,
    });
  }

  onCountChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;

    var textLength = 8;
    var lengthValidationData = TextLengthFunc(value, textLength);
    // var countValidationData = CheckNumberFormat_WithoutDecimal(this.state.countFieldKeys, value);

    var countValidationData = OnlyNumber_NoDecimal_SpecialCharacters(value);

    if (lengthValidationData == true) {
      if (countValidationData == true) {
        if (value > 0) {
          this.setState({
            [name]: value,
          });
          this.props.CountFunc(value);
        } else {
          this.Set_CountAlertMsg("Zero");
          var fieldId = "modalcountalertmsg";
          HideFieldErroeMsgs(fieldId);
        }
      } else {
        this.Set_CountAlertMsg("IncorrectFormat");
        var fieldId = "modalcountalertmsg";
        HideFieldErroeMsgs(fieldId);
      }
    } else {
      $("#modalcountalertmsg").empty();
      $("#modalcountalertmsg").append("! Characters Length > " + textLength);
      var fieldId = "modalcountalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Count_KeyPress_Func = (e) => {
    this.state.countFieldKeys = e.charCode;

    this.setState({
      countFieldKeys: this.state.countFieldKeys,
    });
  };

  Set_CountAlertMsg(messageInfo) {
    $("#modalcountalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalcountalertmsg").append("! Incorrect Number Format");
    } else if (messageInfo == "Count") {
      $("#modalcountalertmsg").append("! Count cannot be Zero");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Feature Count
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3">
            <label style={{ marginLeft: "5px" }}> Feature</label>
            <label style={{ marginLeft: "5px" }}>
              {this.state.featureName}
            </label>
          </div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}> Count</label>
            <input
              type="text"
              className="form-control"
              value={this.state.count}
              id="count"
              name="count"
              onKeyPress={this.handleUser_Count_KeyPress_Func}
              onKeyDown={this.handleUser_Count_KeyPress_Func}
              onChange={this.onCountChange}
            />
            <span id="modalcountalertmsg" className="mandatory-fields"></span>
          </div>
        </div>
      </div>
    );
  }
}

export class LocationRestictCountModal extends React.Component {
  state = {
    minusQty: "",
  };

  componentDidMount() {}

  onCountChange = (e) => {
    const name = e.target.name;
    const value = e.currentTarget.value;

    var textLength = 8;
    var lengthValidationData = TextLengthFunc(value, textLength);
    // var countValidationData = CheckNumberFormat_WithoutDecimal(this.state.countFieldKeys, value);

    var countValidationData = OnlyNumber_NoDecimal_SpecialCharacters(value);

    if (lengthValidationData == true) {
      if (countValidationData == true) {
        if (value > 0) {
          this.setState({
            [name]: value,
          });
          this.props.LocationRestrictFunc(value);
        } else {
          this.Set_CountAlertMsg("Zero");
          var fieldId = "modalcountalertmsg";
          HideFieldErroeMsgs(fieldId);
        }
      } else {
        this.Set_CountAlertMsg("IncorrectFormat");
        var fieldId = "modalcountalertmsg";
        HideFieldErroeMsgs(fieldId);
      }
    } else {
      $("#modalcountalertmsg").empty();
      $("#modalcountalertmsg").append("! Characters Length > " + textLength);
      var fieldId = "modalcountalertmsg";
      HideFieldErroeMsgs(fieldId);
    }
  };

  handleUser_Count_KeyPress_Func = (e) => {
    this.state.countFieldKeys = e.charCode;

    this.setState({
      countFieldKeys: this.state.countFieldKeys,
    });
  };

  Set_CountAlertMsg(messageInfo) {
    $("#modalcountalertmsg").empty();
    if (messageInfo == "IncorrectFormat") {
      $("#modalcountalertmsg").append("! Incorrect Number Format");
    } else if (messageInfo == "Count") {
      $("#modalcountalertmsg").append("! Count cannot be Zero");
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ textAlign: "center", fontWeight: "800" }}>
          Location Restriction Count
        </h5>

        <div style={{ marginTop: "32px" }} className="row">
          <div className="col-sm-3 col-lg-3 col-md-3"></div>
          <div className="col-sm-4 col-lg-4 col-md-4">
            <label style={{ marginLeft: "5px" }}>
              Location Allowed To Added
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.locationCount}
              id="count"
              name="count"
              onKeyPress={this.handleUser_Count_KeyPress_Func}
              onKeyDown={this.handleUser_Count_KeyPress_Func}
              onChange={this.onCountChange}
            />
            <span id="modalcountalertmsg" className="mandatory-fields"></span>
          </div>
        </div>
      </div>
    );
  }
}

/*
USED FOR SELECTING THE TAX CHOICES IN JOBCARD WHEN ESTIMATE 
IS ASSOCIATED WITH IT - IMPLEMENTED BY DURGA 16-04-2022
*/

export class EstimateProductTaxChoiceChangeModal extends React.Component {
  state = {
    selectedOption: "",
    isOpen: false,
    modal: true,
  };

  componentDidMount() {
    // //console.log("EstimateProductChangeModal :", this.props);
    // console.log(this.props.stateData);
    // console.log(this.state.selectedOption);
  }
  /*FUNCTION FOR SETTING THE TAX CHOICE */
  onValueChange = (event) => {
    if (event.target.value == "WithTax") {
      this.props.stateData.taxStatus = true;
    } else {
      this.props.stateData.taxStatus = false;
    }

    this.state.selectedOption = event.target.value;

    this.setState({
      selectedOption: this.state.selectedOption,
    });

    // console.log( this.props.stateData.taxStatus );
    // console.log(this.state.selectedOption);

    this.props.TaxchoiceFunc(this.state.selectedOption); //UPDATE THE VALUE IN PARENT COMPONENT
  };

  render() {
    return (
      <div className="text-center">
        <h5 style={{ fontWeight: "800" }}>Tax Status</h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "20px 0px",
          }}
          className="row"
        >
          <div className="">
            <label>
              <input
                type="radio"
                style={{ marginRight: "5px" }}
                value="WithoutTax"
                checked={this.props.stateData.taxStatus === false}
                name="withouttax"
                onChange={this.onValueChange}
              />
              WithoutTax
            </label>
          </div>

          <div className="">
            <label>
              <input
                type="radio"
                style={{ marginRight: "5px" }}
                value="WithTax"
                checked={this.props.stateData.taxStatus === true}
                name="withtax"
                onChange={this.onValueChange}
              />
              WithTax
            </label>
          </div>
        </div>
      </div>
    );
  }
}

function HideFieldErroeMsgs(fieldId) {
  setTimeout(function () {
    $("#" + fieldId).empty();
  }, 4000);
}
