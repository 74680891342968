//IMPORT STATEMENTS FOR REACT COMPONENT
import React, {lazy, Component,Suspense } from 'react';
import _ from 'underscore';
//IMPORT REACT COMPONENT CSS
import 'sweetalert2/src/sweetalert2.scss';
import "react-table/react-table.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
//IMPORT CLASS COMPONENT

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';


let contactNoValidationFunc;

export  default contactNoValidationFunc=function(contactNo){

    var validationData=false;

    var phoneno = /^\d{10}$/;
    if ((contactNo.match(phoneno))) {
        validationData=true;
    }

    return validationData;

}

export const vehicleRegNo_NumberValidation=function(text){

    var validationData=false;

    var regex = /^[-\w\s]+$/;  
    if(text.match(regex)){
        validationData=true;
    }

    return validationData;

}

/*FUNCTION USED TO VALIATE THE PHONE NUMBER BASED ON COUNTRY CODE-implemented by durga*/
export const ContactNumberValidation=function(phoneNumber)
{
   var message='',color='';
    /*
    Phone number validation using google-libphonenumber react component
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber)); //phonenumber input having value with countrycode ex:+918765678987
    } catch (e)
     {
      valid = false;
    }
    if (!valid) {
      
      message= 'Phone Number is InValid';
      color= 'red'; 
    }
    else {
      
      message= 'Phone Number is Valid';
      color= 'green'; 
    }
    var validationData={message:message,color:color}

    return validationData;
}

export const OnlyNumber_NoDecimal_SpecialCharacters=function(value){

    var validationData=false;

    if (value.match(/^[0-9]+$/)) {
          validationData=true;
      } else {
         validationData=false;
      }

    return validationData;

}
