//IMPORT STATEMENTS FOR REACT COMPONENT
import TextField from "@mui/material/TextField";

import InputAdornment from "@mui/material/InputAdornment";
import {AccountCircle,  LockRoundedIcon,  Email,  Smartphone,} from "@material-ui/icons";
import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import CreatableSelect from 'react-select/creatable';


const styles = {
    width: "80%",
    marginTop: "10px"
}

/*
USED FOR RENDERING TEXT FIELD - PRIYANKA/04/01/2023
*/
export const CommonInputTextField = ({  onChange,  value,  label,  name,  errorStatus,  errorMessage,  iconStart,}) => (
  <IconTextField    error={errorStatus}    sx={styles}    required  
    size="small"    margin="normal"    multiline    variant="outlined"  
      label={label}    name={name}    onChange={onChange}    value={value} 
         helperText={errorMessage}   iconStart={iconStart}  />
);

/*
USED FOR RENDERING ICONS BEFORE AND AFTER TEXT FIELD - PRIYANKA/04/01/2023
*/
const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

/*
USED TO HANDLE CREATABLE SELECT WITHOUT ANY FIELD VALIDATION
AS THIS FIELD MAY ACCEPT ALPHA-NUMERIC, 
SPECIAL CHARACTER - PRIYANKA/04/01/2023
*/
export const CreatableSelectField = ({ multiSelectValue, onChange,  onCreateOption,  creatableSelectOpions,  selectedOption}) => (
<CreatableSelect
isClearable
isMulti={multiSelectValue}
onChange={onChange}
onCreateOption={onCreateOption} 
options={creatableSelectOpions}
value={selectedOption}
/*isValidNewOption={() => false} */
/>
)
